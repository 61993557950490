import React from "react";
import { PDFViewer, Document, Page, Text, View } from "@react-pdf/renderer";
import backIcon from "../../../../assets/back_icon.svg";
import { registerPoppins } from "../../../../fonts/registerFonts";
import styled from "styled-components";
import { getLocalizedDate } from "../../../../functions/localization";
import { useTranslation } from "react-i18next";

registerPoppins();

//region CSS
const ReportContainer = styled.div`
  display: flex;
  @media (min-width: 641px) and (max-width: 1007px) {
    min-width: 100vw;
  }
  @media (min-width: 1008px) {
    width: 100%;
  }
  flex-direction: column;
  box-sizing: border-box;
  padding: 30px 20px;
  max-height: 1024px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 75px;
  min-height: 75px;
  border-bottom: 1px solid #a7bbcf;
`;
const TitleGroup = styled.div`
  display: flex;
  align-items: center;
  width: 304px;
  min-width: 304px;
`;
const BackButton = styled.img`
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-right: 20px;
`;
const ReportTitle = styled.p`
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  color: #454b60;
`;
//endregion

const PDFTrayCheckSummaryReportViewer = ({
  user,
  title,
  headings,
  groupedField,
  data,
  onBack,
  selectionRange,
}) => {
  const { t } = useTranslation("pdfReportViewer");

  const headingsSpacing = [
    { columnWidth: 67, paddingRight: 0, textAlign: "left" },
    { columnWidth: 94, paddingRight: 0, textAlign: "left" },
    { columnWidth: 140, paddingRight: 0, textAlign: "left" },
    { columnWidth: 65, paddingRight: 0, textAlign: "left" },
    { columnWidth: 65, paddingRight: 0, textAlign: "left" },
    { columnWidth: 45, paddingRight: 0, textAlign: "left" },
    { columnWidth: 40, paddingRight: 5, textAlign: "left" },
    { columnWidth: 63, paddingRight: 0, textAlign: "left" },
    { columnWidth: 65, paddingRight: 0, textAlign: "left" },
    { columnWidth: 80, paddingRight: 0, textAlign: "left" },
    { columnWidth: 40, paddingRight: 0, textAlign: "left" },
  ];

  const dataSpacing = [
    { columnWidth: 67, paddingRight: 5, textAlign: "left" },
    { columnWidth: 94, paddingRight: 5, textAlign: "left" },
    { columnWidth: 140, paddingRight: 5, textAlign: "left" },
    { columnWidth: 65, paddingRight: 5, textAlign: "left" },
    { columnWidth: 65, paddingRight: 5, textAlign: "left" },
    { columnWidth: 45, paddingRight: 5, textAlign: "left" },
    { columnWidth: 40, paddingRight: 5, textAlign: "right" },
    { columnWidth: 63, paddingRight: 5, textAlign: "left" },
    { columnWidth: 65, paddingRight: 5, textAlign: "left" },
    { columnWidth: 80, paddingRight: 5, textAlign: "left" },
    { columnWidth: 40, paddingRight: 0, textAlign: "left" },
  ];

  let tableRowsData;

  if (!groupedField || groupedField === "No grouping") {
    tableRowsData = data;
  } else {
    tableRowsData = [];
    data.forEach((arrayOfArrays) => {
      arrayOfArrays.forEach((array) => {
        tableRowsData.push(array);
      });
    });
  }

  tableRowsData.forEach((dataRow, dataIndex) => {
    dataRow.forEach((rawValue, valueIndex) => {
      let value = rawValue;

      if (typeof rawValue === "string") {
        value = rawValue.substring(0, 750);
        if (rawValue.length > 750) {
          value += "...";
        }
      }
    });
  });


  let startDate = null;
  let endDate = null;

  if (selectionRange && selectionRange?.startDate && selectionRange?.endDate) {
    startDate = getLocalizedDate(
      selectionRange.startDate,
      user?.defaultSite?.shippingAddress?.country
    );
    endDate = getLocalizedDate(
      selectionRange.endDate,
      user?.defaultSite?.shippingAddress?.country
    );
  }

  const Report = () => (
    <Document title={title}>
      <Page
        wrap
        size={"LETTER"}
        orientation={"landscape"}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          boxSizing: "border-box",
          paddingTop: 20,
          paddingBottom: 20,
          paddingLeft: 6,
          paddingRight: 0,
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            fontFamily: "Poppins",
            fontSize: 12,
            fontWeight: 500,
            fontStyle: "normal",
            color: "#121A2D",
          }}
        >
          <Text
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: 0,
              padding: 0,
              paddingLeft: "10px",
            }}
          >
            {title}
          </Text>
          <Text
            style={{
              margin: 0,
              padding: 0,
              paddingRight: "10px",
            }}
          >
            {startDate && endDate && `${startDate} - ${endDate}`}
          </Text>
        </View>

        {/* headings */}
        <View
          fixed
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            alignItems: "flex-end",
            borderBottom: "2px solid #089BAB",
            paddingTop: "12px",
            paddingBottom: "12px",
          }}
        >
          {headings.map((heading, index) => {
            return (
              <Text
                key={index}
                style={{
                  boxSizing: "border-box",
                  fontFamily: "Poppins",
                  fontSize: 10,
                  fontWeight: 500,
                  fontStyle: "normal",
                  color: "#121A2D",
                  width: headingsSpacing[index].columnWidth,
                  textAlign: headingsSpacing[index].textAlign,
                  margin: 0,
                  paddingRight: headingsSpacing[index].paddingRight,
                }}
              >
                {heading}
              </Text>
            );
          })}
          {/* data */}
        </View>
        {data.map((row, r) => {
          return (
            <View
              key={r}
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                paddingTop: "5px",
                paddingBottom: "5px",
                backgroundColor: r % 2 ? "#EBEDF8" : "#ffffff",
              }}
            >
              {row.map((text, x) => {
                text = typeof text === "string" 
                  ? text.trim().replace(/-/g, "\u00AD")
                  : text;
                return (
                  <Text
                    key={x}
                    style={{
                      boxSizing: "border-box",
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      textAlign: dataSpacing[x].textAlign,
                      fontFamily: "Poppins",
                      fontSize: 10,
                      fontWeight: 400,
                      fontStyle: "normal",
                      color: "#121A2D",
                      width: dataSpacing[x].columnWidth,
                      maxWidth: dataSpacing[x].columnWidth,
                      paddingRight: dataSpacing[x].paddingRight,
                      overflow: "hidden",
                    }}
                  >
                    {text}
                  </Text>
                );
              })}
            </View>
          );
        })}
        <Text
          style={{
            margin: 0,
            fontFamily: "Poppins",
            fontSize: 10,
            fontWeight: 300,
            fontStyle: "normal",
            color: "#121A2D",
            position: "absolute",
            bottom: 6,
            left: 0,
            right: 0,
            textAlign: "center",
          }}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );

  return (
    <ReportContainer>
      <Header>
        <TitleGroup>
          <BackButton src={backIcon} onClick={onBack} />
          <ReportTitle>{t("title")}</ReportTitle>
        </TitleGroup>
      </Header>
      {/*PDF Area*/}
      <PDFViewer style={{ height: "100%" }}>
        <Report />
      </PDFViewer>
    </ReportContainer>
  );
};

export default PDFTrayCheckSummaryReportViewer;
