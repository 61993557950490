import React, { useState, useEffect, useContext } from "react";
import { useMediaQuery } from "react-responsive";
import { ScreenContext } from "../../contexts/ScreenContext";
import ErrorBanner from "../../components/ErrorBanner";
import StockWithoutReceiving from "./StockWithoutReceiving/StockWithoutReceiving";
import InventoryTransferView from "../Inventory/PullStockForInternalTransfer/InventoryTransferView";
import GetSiteView from "../Inventory/GetSiteView";
import StockFooter from "./StockWithoutReceiving/stockFooter/StockFooter";
import styles from "./Stock.module.css";
import { useTranslation } from "react-i18next";

const Stock = ({ user, onHideModal, menuChoice, onMenuChoiceHandler }) => {
  const { t } = useTranslation("stock");

  const [site, setSite] = useState("");
  const [activeIcon, setActiveIcon] = useState("Home");

  const [errorMsg, setErrorMsg] = useState("");

  const [inputValue, setInputValue] = useState("");

  const inputValueHandler = (value) => {
    setInputValue(value);
  };

  const setSiteHandler = (selectedSite) => {
    setSite(selectedSite);
  };

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const setErrorHandler = (msg) => {
    setErrorMsg(msg);
  };

  useEffect(() => {
    if (menuChoice === "Receive" || menuChoice === "Transfer") {
      setActiveIcon(menuChoice);
    }
  }, [menuChoice]);

  if (site && activeIcon === "Transfer") {
    return (
      <React.Fragment>
        {errorMsg && (
          <ErrorBanner message={errorMsg} onClose={() => setErrorMsg("")} />
        )}
        <div className={styles.stock}>
          <InventoryTransferView
            user={user}
            site={site}
            setSiteHandler={setSiteHandler}
            setActiveIcon={setActiveIcon}
            onHideModal={onHideModal}
            onInputValue={inputValueHandler}
            errorMsg={errorMsg}
            onSetErrorHandler={setErrorHandler}
          />
          {smScreen && (
            <StockFooter
              activeIcon={activeIcon}
              setActiveIcon={setActiveIcon}
            />
          )}
        </div>
      </React.Fragment>
    );
  } else if (!site && activeIcon === "Transfer") {
    return (
      <React.Fragment>
        {errorMsg && (
          <ErrorBanner message={errorMsg} onClose={() => setErrorMsg("")} />
        )}

        <div className={styles.stock}>
          <div className={styles.stock__getSite}>
            <GetSiteView
              title={t("transferTitle")}
              site={site}
              setSite={setSite}
              user={user}
              activeIcon={activeIcon}
              setActiveIcon={setActiveIcon}
              onMenuChoiceHandler={onMenuChoiceHandler}
              onHideModal={onHideModal}
              errorMsg={errorMsg}
              onSetErrorHandler={setErrorHandler}
            />
          </div>
          {smScreen && (
            <StockFooter
              activeIcon={activeIcon}
              setActiveIcon={setActiveIcon}
            />
          )}
        </div>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        {errorMsg && (
          <div className={styles.stock__errorBannerContainer}>
            <ErrorBanner message={errorMsg} onClose={() => setErrorMsg("")} />
          </div>
        )}
        <StockWithoutReceiving
          user={user}
          site={site}
          setSite={setSite}
          onHideModal={onHideModal}
          activeIcon={activeIcon}
          setActiveIcon={setActiveIcon}
          menuChoice={menuChoice}
          errorMsg={errorMsg}
          onSetErrorHandler={setErrorHandler}
        />
      </React.Fragment>
    );
  }
};

export default Stock;
