import React, { Fragment, useState } from "react";
import { PDFViewer, Document, Page, Text, View } from "@react-pdf/renderer";
import { registerPoppins } from "../../fonts/registerFonts";
import backIcon from "../../assets/back_icon.svg";
import { getPreferredFieldName } from "./lookups/getPreferredFieldName";
import AntRadioGroup from "../../components/Ant/AntRadioGroup/AntRadioGroup";
import styles from "./PDFReportViewer.module.css";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { getLocalizedDate } from "../../functions/localization";

registerPoppins();

//region CSS
const MyView = styled.div`
  display: flex;
  @media (min-width: 641px) and (max-width: 1007px) {
    min-width: 100vw;
  }
  @media (min-width: 1008px) {
    width: 100%;
  }
  flex-direction: column;
  box-sizing: border-box;
  padding: 30px 20px;
  max-height: 1024px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 75px;
  min-height: 75px;
  border-bottom: 1px solid #a7bbcf;
`;
const TitleGroup = styled.div`
  display: flex;
  align-items: center;
  width: 304px;
  min-width: 304px;
`;
const BackButton = styled.img`
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-right: 20px;
`;
const ReportTitle = styled.p`
  margin: 0;
  font-family: "Poppins";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  color: #454b60;
  min-width: 160px;
  width: 160px;
`;
//endregion

const PDFReportViewer = ({
  user,
  title,
  headings,
  groupedField,
  data,
  collectionSequence,
  onBack,
  columnSums,
  columnAverages,
  selectionRange,
  showAVGRow,
  showSUMRow,
  collectionFieldDictionary,
}) => {
  const { t } = useTranslation("pdfReportViewer")

  const pageWidth = 760;
  const columnWidth = pageWidth / headings.length;
  const charWidthInPx = 6.4;

  const [columnWidthTypeRadioValue, setColumnWidthTypeRadioValue] = useState(1);
  const [fontSizeRadioValue, setFontSizeRadioValue] = useState(1);
  const [headingsAndDataFontSize, setHeadingsAndDataFontSize] = useState(10);
  const [sumAndAvgFontSize, setSumAndAvgFontSize] = useState(12);

  let tempVariableColumnWidthsArray = headings.map((heading, index) => {
    let headingArray = [];

    if (heading.indexOf(" ") !== -1) {
      headingArray = heading.toString().trim().split(" ");
    } 

    if (headingArray.length > 1) {
      let maxLength = 0;
      headingArray.forEach((heading) => {
        if (heading.length > maxLength) {
          maxLength = heading.length;
        }
      });

      return maxLength * charWidthInPx;
    } else {
      return heading.length * charWidthInPx;
    }
  });

  let tableRowsData;

  if (!groupedField || groupedField === "No grouping") {
    tableRowsData = data;
  } else {
    tableRowsData = [];
    data.forEach((arrayOfArrays) => {
      arrayOfArrays.forEach((array) => {
        tableRowsData.push(array);
      });
    });
  }

  tableRowsData.forEach((dataRow, dataIndex) => {
    dataRow.forEach((rawValue, valueIndex) => {
      let value = rawValue;

      if (typeof rawValue === "string") {
        value = rawValue.substring(0, 750);
        if (rawValue.length > 750) {
          value += "...";
        }
      }

      let valueMaxWidth = tempVariableColumnWidthsArray[valueIndex];
      let valueArray = [value.toString().trim()];
      let indexFound = -1;

      if (value.length > 10 && typeof value === "string") {
        indexFound = value.indexOf(",");
      }

      if (indexFound !== -1) {
        tableRowsData[dataIndex][valueIndex] = valueArray.map((value) => value);
      }
      const stringArray = value.toString().trim().split("\n");

      stringArray.forEach((subString) => {
        if (
          subString.length * charWidthInPx >
          tempVariableColumnWidthsArray[valueIndex]
        ) {
          valueMaxWidth =
            subString.length * charWidthInPx <= 200
              ? subString.length * charWidthInPx
              : 200;
          tempVariableColumnWidthsArray[valueIndex] = valueMaxWidth;
        }
      });
    });
  });

  let totalColumnWidth = 0;
  tempVariableColumnWidthsArray.forEach((columnWidth) => {
    totalColumnWidth += columnWidth;
  });

  const proportionalityCorrectionFactor = 758 / totalColumnWidth;

  const variableColumnWidthsArray = tempVariableColumnWidthsArray.map(
    (column) => column * proportionalityCorrectionFactor
  );

  let totalVariableColumnWidth = 0;
  variableColumnWidthsArray.forEach((columnWidth) => {
    totalVariableColumnWidth += columnWidth;
  });

  let headingsWrapped = headings.map((heading) => {
    let headingArray = [];
    if (heading.indexOf(".") !== -1) {
    } else if (heading.indexOf(" ") !== -1) {
      headingArray = heading.split(" ");
    }
    if (headingArray.length > 1) {
      return headingArray[0] + "\n" + headingArray[1];
    } else {
      return heading;
    }
  });

  let startDate = null;
  let endDate = null;

  if (selectionRange && selectionRange?.startDate && selectionRange?.endDate) {
    startDate = getLocalizedDate(selectionRange.startDate, user?.defaultSite?.shippingAddress?.country);
    endDate = getLocalizedDate(selectionRange.endDate, user?.defaultSite?.shippingAddress?.country);
  }

  const onChangeWidthTypeRadio = (e) => {
    setColumnWidthTypeRadioValue(e.target.value);
  };

  const onChangeFontSizeRadio = (e) => {
    setFontSizeRadioValue(e.target.value);
    const value = e.target.value;
    if (value === 1) {
      setHeadingsAndDataFontSize(10);
      setSumAndAvgFontSize(12);
    } else {
      setHeadingsAndDataFontSize(8);
      setSumAndAvgFontSize(10);
    }
  };

  const Report = () => (
    <Document title={title}>
      <Page
        wrap
        size={"LETTER"}
        orientation={"landscape"}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          boxSizing: "border-box",
          paddingTop: 20,
          paddingBottom: 20,
          paddingLeft: 6,
          paddingRight: 0,
          fontFamily: "Poppins",
          fontWeight: 400,
          fontStyle: "normal",
          color: "#121A2D",
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            fontSize: 10,
            fontWeight: 500,
          }}
        >
          <Text
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: 0,
              padding: 0,
              paddingLeft: 10,
            }}
          >
            {title}
          </Text>
          <Text
            style={{
              margin: 0,
              padding: 0,
              paddingRight: 10,
            }}
          >
            {startDate && endDate && `${startDate} - ${endDate}`}
          </Text>
        </View>

        <View
          fixed
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            alignItems: "center",
            borderBottom: "2px solid #089BAB",
            paddingTop: "12px",
            paddingBottom: "12px",
          }}
        >
          {headings.map((heading, index) => {
            return (
              <Text
                key={index}
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  fontSize: headingsAndDataFontSize,
                  fontWeight: 500,
                  width:
                    columnWidthTypeRadioValue === 1
                      ? variableColumnWidthsArray[index]
                      : columnWidth,
                  margin: 0,
                  padding: 0,
                  paddingRight: 5,
                  height: "100%",
                  boxSizing: "border-box",
                  // backgroundColor: index % 2 === 0 ? "pink" : "yellow",
                }}
              >
                {heading}
              </Text>
            );
          })}
        </View>
        {tableRowsData.map((row, r) => {
          return (
            <View
              key={r}
              style={{
                display: "flex",
                // flexWrap: "wrap",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                paddingTop: "5px",
                paddingBottom: "5px",
                backgroundColor: r % 2 ? "#EBEDF8" : "#ffffff",
              }}
            >
              {row.map((text, t) => {
                text = typeof text === "string" 
                  ? text.trim().replace(/-/g, "\u00AD")
                  : text;
                return (
                  <Text
                    key={t}
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "row",
                      margin: 0,
                      padding: 0,
                      paddingRight: 5,
                      fontSize: headingsAndDataFontSize,
                      fontWeight: 300,
                      width:
                        columnWidthTypeRadioValue === 1
                          ? variableColumnWidthsArray[t]
                          : columnWidth,
                      boxSizing: "border-box"
                    }}
                  >
                    {text}
                  </Text>
                );
              })}
            </View>
          );
        })}

        {showSUMRow && (
          <Fragment>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            >
              <Text
                style={{
                  fontSize: sumAndAvgFontSize,
                  fontWeight: 500,
                  color: "#089BAB",
                  margin: 0,
                  padding: 0,
                  paddingTop: 12,
                  paddingBottom: 6,
                }}
              >
                {t("sumCol")}
              </Text>
            </View>
            <View
              fixed
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // borderTop: "2px solid #089BAB",
                paddingTop: 10,
                paddingRight: 5,
                boxSizing: "border-box",
              }}
            >
              {columnSums.map((total, index) => {
                return (
                  <View
                    key={index}
                    fixed
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                    render={({ pageNumber, totalPages }) =>
                      pageNumber === totalPages && (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            height: "100%",
                          }}
                        >
                          <Text
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              margin: 0,
                              padding: 0,
                              paddingRight: 5,
                              height: "100%",
                              fontSize: sumAndAvgFontSize,
                              fontWeight: 700,
                              width:
                                columnWidthTypeRadioValue === 1
                                  ? variableColumnWidthsArray[index]
                                  : columnWidth,
                              boxSizing: "border-box",
                            }}
                          >
                            {total >= 0
                              ? total
                                  .toFixed(2)
                                  .replace(
                                    /([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/,
                                    "$1"
                                  )
                              : "--"}
                          </Text>
                        </View>
                      )
                    }
                  />
                );
              })}
            </View>
          </Fragment>
        )}

        {showAVGRow && (
          <Fragment>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
              }}
            >
              <Text
                style={{
                  fontSize: sumAndAvgFontSize,
                  fontWeight: 500,
                  color: "#089BAB",
                  margin: 0,
                  padding: 0,
                  paddingTop: 12,
                  paddingBottom: 6,
                }}
              >
                {t("averageCol")}
              </Text>
            </View>
            <View
              fixed
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                // borderTop: "2px solid #089BAB",
                paddingTop: 10,
                paddingRight: 5,
                boxSizing: "border-box",
              }}
            >
              {columnAverages.map((total, index) => {
                return (
                  <View
                    key={index}
                    fixed
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                    render={({ pageNumber, totalPages }) =>
                      pageNumber === totalPages && (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            height: "100%",
                          }}
                        >
                          <Text
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              margin: 0,
                              padding: 0,
                              paddingRight: 5,
                              height: "100%",
                              fontSize: sumAndAvgFontSize,
                              fontWeight: 700,
                              width:
                                columnWidthTypeRadioValue === 1
                                  ? variableColumnWidthsArray[index]
                                  : columnWidth,
                              boxSizing: "border-box",
                            }}
                          >
                            {total >= 0
                              ? total
                                  .toFixed(2)
                                  .replace(
                                    /([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/,
                                    "$1"
                                  )
                              : "--"}
                          </Text>
                        </View>
                      )
                    }
                  />
                );
              })}
            </View>
          </Fragment>
        )}

        <Text
          style={{
            margin: 0,
            fontSize: 10,
            fontWeight: 300,
            position: "absolute",
            bottom: 6,
            left: 0,
            right: 0,
            textAlign: "center",
          }}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );

  return (
    <MyView>
      <Header>
        <TitleGroup>
          <BackButton src={backIcon} onClick={onBack} />
          <ReportTitle>{t("title")}</ReportTitle>
          <AntRadioGroup
            values={[1, 2]}
            labels={[t("variableColumnLabel"), t("equalColumnLabel")]}
            radioGroupClass="ant-radioGroup-000"
            onChange={onChangeWidthTypeRadio}
            value={columnWidthTypeRadioValue}
          />
          <div className={styles.PDFReportViewer__fontSizeRadioGroupContainer}>
            <AntRadioGroup
              values={[1, 2]}
              labels={[t("largeFontLabel"), t("smallFontLabel")]}
              radioGroupClass="ant-radioGroup-000"
              onChange={onChangeFontSizeRadio}
              value={fontSizeRadioValue}
            />
          </div>
        </TitleGroup>
      </Header>
      {/*PDF Area*/}
      <PDFViewer style={{ height: "100%" }}>
        <Report />
      </PDFViewer>
    </MyView>
  );
};

export default PDFReportViewer;
