import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCompressAlt } from "@fortawesome/free-solid-svg-icons";
import TextInput from "../../../components/TextInput";
import ErrorBanner from "../../../components/ErrorBanner";
import DropdownInput from "../../../components/DropDownInput/DropdownInput";
import Button from "../../../components/Button";
import { getOrderPriorityList } from "../../../functions/utilities";
import styles from "./AddRetailScriptLargeView.module.css";
import { useTranslation } from "react-i18next";

const AddRetailScriptLargeView = ({
  site,
  handleCollapse,
  error,
  onError,
  dispensingOrderItem,
  onDispensingOrderItem,
  errors,
  productFieldController,
  products,
  modifiedProducts,
  onErrors,
  isValid,
  onGenerateScript,
}) => {
  const { t } = useTranslation("addRetailScript");
  const [orderPriorityList, setOrderPriorityList] = useState([]);


  useEffect(() => {
    const tempOrderPriorityList = getOrderPriorityList().map((item) => ({
      ...item,
      displayName: t(item.description, {ns: "orderPriority"})
    }));
    setOrderPriorityList(tempOrderPriorityList);
  }, []);

  return (
    <div className={styles.AddRetailScript__container}>
      <div className={styles.AddRetailScript__header}>
        <h1>
          {site.name} <span>{t("title")}</span>
        </h1>
        <FontAwesomeIcon
          icon={faCompressAlt}
          color={"#FFAB2B"}
          styles={{ cursor: "pointer" }}
          onClick={handleCollapse}
        />
      </div>
      <br />
      {error && <ErrorBanner message={error} onClose={() => onError("")} />}
      <br />
      <div className={styles.AddRetailScript__col}>
        <div className={styles.AddRetailScript__row}>
          <div className={styles.AddRetailScript__col}>
            <TextInput
              isEnabled={true}
              placeholder={""}
              value={dispensingOrderItem.rxNumber || ""}
              labelName={t("rxNumberLabel")}
              type={"text"}
              focus={true}
              onChange={(val) => {
                if (val.length === 0) {
                  onErrors((prev) => {
                    return {
                      ...prev,
                      rxNumber: "missingRxNumber",
                    };
                  });
                }
                onDispensingOrderItem((prev) => {
                  return { ...prev, rxNumber: val };
                });
              }}
              validationText={t(errors.rxNumber, {ns: "validation"})}
            />
          </div>
          <div className={styles.AddRetailScript__col}>
            <TextInput
              isEnabled={true}
              placeholder={""}
              value={dispensingOrderItem.patientInfo?.firstName || ""}
              labelName={t("patientFirstLabel")}
              type={"text"}
              focus={false}
              onChange={(val) => {
                if (val.length === 0) {
                  onErrors((prev) => {
                    return {
                      ...prev,
                      patientFirstName: "missingFirstName",
                    };
                  });
                } else {
                  onErrors((prev) => {
                    return { ...prev, patientFirstName: "" };
                  });
                }
                onDispensingOrderItem((prev) => {
                  const cpy = { ...prev };
                  cpy.patientInfo = { ...cpy.patientInfo, firstName: val };
                  return { ...prev, patientInfo: cpy.patientInfo };
                });
              }}
              validationText={t(errors.patientFirstName, {ns: "validation"})}
            />
          </div>
          <div className={styles.AddRetailScript__col}>
            <TextInput
              isEnabled={true}
              placeholder={""}
              value={dispensingOrderItem.patientInfo?.lastName || ""}
              labelName={t("patientLastLabel")}
              type={"text"}
              focus={false}
              onChange={(val) => {
                if (val.length === 0) {
                  onErrors((prev) => {
                    return {
                      ...prev,
                      patientLastName: "missingLastName",
                    };
                  });
                } else {
                  onErrors((prev) => {
                    return { ...prev, patientLastName: "" };
                  });
                }
                onDispensingOrderItem((prev) => {
                  const cpy = { ...prev };
                  cpy.patientInfo = { ...cpy.patientInfo, lastName: val };
                  return { ...prev, patientInfo: cpy.patientInfo };
                });
              }}
              validationText={t(errors.patientLastName, {ns: "validation"})}
            />
          </div>
          <div className={styles.AddRetailScript__col}>
            <TextInput
              isEnabled={true}
              placeholder={t("optionalPlaceholder")}
              value={dispensingOrderItem.patientInfo?.id || ""}
              labelName={t("patientIdLabel")}
              type={"text"}
              focus={false}
              onChange={(val) => {
                onDispensingOrderItem((prev) => {
                  const cpy = { ...prev };
                  cpy.patientInfo = { ...cpy.patientInfo, id: val };
                  return { ...prev, patientInfo: cpy.patientInfo };
                });
              }}
              validationText={""}
            />
          </div>
        </div>
      </div>
      <div className={styles.AddRetailScript__row}>
        <div
          className={styles.AddRetailScript__col}
          style={{ marginLeft: "10px" }}
        >
          <DropdownInput
            isEnabled={true}
            placeholder={t("selectDrugPlaceholder")}
            validationText={t(errors.product, {ns: "validation"})}
            labelName={t("drugLabel")}
            value={
              dispensingOrderItem.product
                ? `${productFieldController.getDefaultName(
                    dispensingOrderItem.product
                  )} (${productFieldController.getPackageId(
                    dispensingOrderItem.product
                  )} )`
                : ""
            }
            listElements={modifiedProducts}
            onSelection={(item) => {
              const p = products.find((o) => o._id === item._id);
              onDispensingOrderItem((prev) => {
                return { ...prev, product: p };
              });
            }}
          />
        </div>
        <div className={styles.AddRetailScript__col} style={{ width: "100px" }}>
          <TextInput
            isEnabled={true}
            placeholder={""}
            value={dispensingOrderItem.qtyNeeded}
            labelName={t("quantityLabel")}
            type={"number"}
            focus={false}
            onChange={(val) => {
              let regex = new RegExp("^[1-9][0-9]*$");

              // Check if entered value is a whole number greater than 0, otherwise set val to null.  This allowss the user to delete the value and change it.
              if (regex.test(val)) {
                val = Number.parseInt(val);
                onErrors((prev) => {
                  return { ...prev, qtyNeeded: "" };
                });
              } else {
                val = null;
              }

              onDispensingOrderItem((prev) => {
                return { ...prev, qtyNeeded: val ? Number.parseInt(val) : "" };
              });
            }}
            validationText={t(errors.qtyNeeded, {ns: "validation"})}
          />
        </div>
        <div
          className={styles.AddRetailScript__col}
          style={{ width: "160px", marginRight: "10px" }}
        >
          <DropdownInput
            isEnabled={true}
            placeholder={""}
            validationText={t(errors.priority, {ns: "validation"})}
            labelName={t("priorityLabel")}
            value={t(dispensingOrderItem.priority || "LOW", {ns: "orderPriority"})}
            listElements={orderPriorityList}
            display={"displayName"}
            onSelection={(item) => {
              onDispensingOrderItem((prev) => {
                return { ...prev, priority: item.description };
              });
            }}
          />
        </div>
      </div>
      <br />
      <div className={styles.AddRetailScript__footer}>
        <Button
          labelName={t("generateButtonLabel")}
          isPrimary={true}
          isDisabled={!isValid}
          onClick={onGenerateScript}
        />
      </div>
    </div>
  );
};

export default AddRetailScriptLargeView;
