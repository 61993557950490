import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./StockIconBar.module.css";
import { ReactComponent as InventoryIcon } from "../../../../../assets/receive-icon.svg";
import { ReactComponent as ReceiveIcon } from "../../../../../assets/receive-icon.svg";
import { ReactComponent as TransferIcon } from "../../../../../assets/transfer-icon.svg";
import { ReactComponent as ProfileIcon } from "../../../../../assets/profile-icon.svg";
import { useTranslation } from "react-i18next";

const StockIconBar = ({ activeIcon, setActiveIcon }) => {
  const { t } = useTranslation("items")

  const navigate = useNavigate();

  const iconClickHandler = (e) => {
    e.stopPropagation();
    const clickedIcon = e.currentTarget.getAttribute("data-view");
    setActiveIcon(clickedIcon);
    if (clickedIcon === "Home") {
      navigate("/inventory");
    } else if (clickedIcon === "Receive") {
      navigate("/inventory/receive");
    } else if (clickedIcon === "Transfer") {
      navigate("/inventory/transfer");
    }
  };

  return (
    <Fragment>
      <div className={styles.stockIcon} onClick={iconClickHandler} data-view="Home">
        <InventoryIcon
          className={
            activeIcon === "Home"
              ? styles["stockIcon__fill--active"]
              : styles.stockIcon__fill
          }
        />
        <span
          className={
            activeIcon === "Home"
              ? [
                  styles["stockIcon__title"],
                  styles["stockIcon__title--active"],
                ].join(" ")
              : styles.stockIcon__title
          }
        >
          {t("inventoryButtonLabel")}
        </span>
      </div>

      <div className={styles.stockIcon} onClick={iconClickHandler} data-view="Receive">
        <ReceiveIcon
          className={
            activeIcon === "Receive"
              ? styles["stockIcon__fill--active"]
              : styles.stockIcon__fill
          }
        />
        <span
          className={
            activeIcon === "Receive"
              ? [
                  styles["stockIcon__title"],
                  styles["stockIcon__title--active"],
                ].join(" ")
              : styles.stockIcon__title
          }
        >
          {t("receiveButtonLabel")}
        </span>
      </div>

      <div className={styles.stockIcon} onClick={iconClickHandler} data-view="Transfer">
        <TransferIcon
          className={
            activeIcon === "Transfer"
              ? styles["stockIcon__stroke--active"]
              : styles.stockIcon__stroke
          }
        />
        <span
          className={
            activeIcon === "Transfer"
              ? [
                  styles["stockIcon__title"],
                  styles["stockIcon__title--active"],
                ].join(" ")
              : styles.stockIcon__title
          }
        >
          {t("transferButtonLabel")}
        </span>
      </div>
    </Fragment>
  );
};

export default StockIconBar;
