import { DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Input, Modal, Row, Table, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ReactComponent as CloseIcon } from "../../assets/x.svg";
import CreateButton from "../../components/CreateButton";
import ErrorBanner from "../../components/ErrorBanner";
import TextInput from "../../components/TextInput";
import WaitIndicator from "../../components/WaitIndicator";
import ProductFieldController from "../../controllers/ProductFieldController";
import ProductService from "../../services/ProductService";
import styles from "../Product/CreateProduct.module.css";
import antdTableStyles from "./CreateTherapeuticEquivalency.module.css";

const { Search } = Input;

const CreateTherapeuticEquivalency = ({ user, teCode }) => {
  const { t } = useTranslation("createTherapeuticEquivalency");

  const searchRef = useRef();
  // Variables
  const navigate = useNavigate();
  const isNew = !teCode;
  const productFieldController = new ProductFieldController();

  // State
  const [currentTECode, setCurrentTECode] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [equivalentProducts, setEquivalentProducts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [availableProducts, setAvailableProducts] = useState([]);

  const columns = [
    {
      title: t("productCol"),
      dataIndex: "_id",
      render: (text, product) => (
        <div>
          <p>{productFieldController.getDefaultName(product)}</p>
          <p>
            {t("packageIdLabel")}: {product?.packageId}
          </p>
        </div>
      ),
    },
    {
      title: "",
      key: "action",
      render: (_, product) => (
        <Tooltip title={t("removeProductTitle")}>
          <Button danger size={"small"} onClick={() => removeProduct(product)}>
            <DeleteOutlined />
          </Button>
        </Tooltip>
      ),
    },
  ];

  const addProduct = (product) => {
    product.therapeuticEquivalencyCode = currentTECode;
    ProductService.update(user, product)
      .then((p) => {
        setEquivalentProducts((o) => {
          return [...equivalentProducts, product];
        });
        handleCancel();
      })
      .catch((error) => {
        setError(error);
      });
  };

  const removeProduct = (product) => {
    product.therapeuticEquivalencyCode = "";
    ProductService.update(user, product)
      .then((p) => {
        const temp = [...equivalentProducts];
        const index = temp.findIndex((o) => o._id === p._id);
        if (index !== -1) {
          temp.splice(index, 1);
          setEquivalentProducts(temp);
        }
        if (temp.length === 0) {
          navigateBack();
        }
      })
      .catch((error) => {
        setError(error);
      });
  };

  // Effects
  useEffect(async () => {
    if (teCode) {
      setCurrentTECode(teCode);
      setIsLoading(true);
      try {
        const list = await ProductService.getTherapeuticEquivalentProducts(
          user,
          teCode
        );
        setEquivalentProducts(list);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    }
  }, [user]);

  // Event handlers
  const handleClose = () => {
    navigateBack();
  };

  const navigateBack = () => {
    navigate(-1, {
      state: { refresh: true },
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setAvailableProducts([]);
  };

  const onSearch = async (value, _e, info) => {
    setAvailableProducts([]);
    if (value.length >= 1) {
      try {
        const list = await ProductService.getWithNoTECode(user, value);
        setAvailableProducts(list);
      } catch (error) {
        setError(error);
      }
    }
  };

  if (isLoading) {
    return <WaitIndicator message={t("loadingProducts")} />;
  } else {
    return (
      <>
        <Row>
          <Col>
            <h1 className={styles.CreateProduct__title}>
              {isNew ? t("addTE") : t("updateTE")}
            </h1>
          </Col>
          <Col flex={1}>
            <Row justify={"end"}>
              <Col>
                <CloseIcon
                  fill={"rgba(18,26, 45, 0.5)"}
                  onClick={handleClose}
                />
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            {error && (
              <div className={styles.CreateProduct__errorBannerContainer}>
                <ErrorBanner message={error} onClose={() => setError(null)} />
              </div>
            )}
          </Col>
          <Col span={24}>
            <TextInput
              type={"text"}
              labelName={t("codeLabel")}
              isEnabled={equivalentProducts.length === 0}
              value={currentTECode}
              validationText={""}
              focus={true}
              onChange={(val) => {
                setCurrentTECode(val);
              }}
            />
          </Col>
          <Col span={24}>
            {currentTECode.length > 0 && (
              <div className={styles.CreateProduct__productCategory_row_end}>
                <CreateButton
                  onClick={showModal}
                  isEnabled={true}
                  labelName={t("addProductTitle")}
                />
              </div>
            )}
          </Col>
          <Col span={24}>
            <Table
              columns={columns}
              dataSource={equivalentProducts}
              className={antdTableStyles.tableDataRow}
              rowKey="_id"
              locale={{ emptyText: t("noDataLabel") }}
              scroll={{ x: true, y: window.innerHeight - 475 }}
            />
          </Col>
        </Row>

        <Modal
          title={t("addProductTitle")}
          open={isModalOpen}
          onCancel={handleCancel}
          footer={[]}
          afterOpenChange={() => {
            const { input } = searchRef.current;
            input.focus();
          }}
          height={"300px"}
          width={"768px"}
        >
          <br />
          <Search
            ref={searchRef}
            enterButton
            placeholder={t("searchPlaceholder")}
            onSearch={onSearch}
          />
          <Table
            columns={[
              {
                title: t("idCol"),
                dataIndex: "packageId",
                key: "packageId",
                render: (text) => text,
                ellipsis: true,
              },
              {
                title: t("productCol"),
                dataIndex: "_id",
                key: "_id",
                render: (text, product) =>
                  productFieldController.getDefaultName(product),
                ellipsis: true,
              },
              {
                title: "",
                key: "action",
                render: (_, product) => (
                  <Tooltip title={t("addButtonTooltip")}>
                    <a onClick={() => addProduct(product)}>
                      {t("addButtonLabel")}
                    </a>
                  </Tooltip>
                ),
              },
            ]}
            dataSource={availableProducts}
            locale={{ emptyText: t("noDataLabel") }}
            size="small"
          />
        </Modal>
      </>
    );
  }
};

export default CreateTherapeuticEquivalency;
