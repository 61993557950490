import React, {useEffect, useRef, useState} from 'react';
import {Button, Flex, Space, Table, Tooltip} from 'antd';
import StockLocationService from "../../../services/StockLocationService";
import { useTranslation } from 'react-i18next';

const TruPakStatus = ({user, stockLocation, onAlarms}) => {
    const { t } = useTranslation("trupakStatus")

    const timerRef = useRef(null);
    const [device, setDevice] = useState([]);

    const fetchData = () => {
        StockLocationService.get(user, stockLocation.site, stockLocation._id).then((data) => {
            setDevice([data.device]);
        }).catch(error=>{
            console.log(error);
        })
    }

    useEffect(() => {
        setDevice([stockLocation.device]);
        fetchData();
        // todo: start timer to fetch updated status every so often
        timerRef.current = setInterval(()=>{
            fetchData();
        }, 30000);
        return ()=>{
            clearInterval(timerRef.current);
            timerRef.current = null;
        }
    }, [stockLocation]);

    const columns = [
        {
            title: t("statusCol"),
            dataIndex: "_id",
            render: (text, device) => (
                <p>{device?.status?.status} </p>
            ),
        },
        {
            title: t("waitingCol"),
            dataIndex: "_id",
            render: (text, device) => (
                <p>{device.status?.waitingOn || ''}</p>
            ),
        },
        {
            title: t("batchesCol"),
            dataIndex: "_id",
            render: (text, device) => (
                <p>{device.status?.batchesInQueue || ''}</p>
            ),
        },
        {
            title: t("durationCol"),
            dataIndex: "_id",
            render: (text, device) => (
                <p>{device.status?.estimatedDuration || ''}</p>
            ),
        },
        {
            title: t("traysCol"),
            dataIndex: "_id",
            render: (text, device) => (
                <p>{device.status?.traysToProcess || ''}</p>
            ),
        },
        {
            title: t("bagsCol"),
            dataIndex: "_id",
            render: (text, device) => (
                <p>{device.status?.totalBagsInQueue || ''}</p>
            ),
        },
        {
            title: t("packedCol"),
            dataIndex: "_id",
            render: (text, device) => (
                <p>{device.status?.bagsPackedToday || ''}</p>
            ),
        },
        // {
        //     title: "Start Time",
        //     dataIndex: "_id",
        //     render: (text, device) => {
        //         if(device.status?.currentBatch?.startTime){
        //             const dt =  new Date(device.status.currentBatch?.startTime);
        //             const dStr = `${dt.getFullYear()}-${(dt.getMonth() + 1).toString().padStart(2,'0')}-${dt.getDate().toString().padStart(2,'0')} ${dt.getHours().toString().padStart(2,'0')}:${dt.getMinutes().toString().padStart(2,'0')}`;
        //             return (
        //                 <p>{dStr}</p>
        //             )
        //         } else {
        //             return <p></p>
        //         }
        //
        //     }
        // },
        // {
        //     title: "Trays Filled",
        //     dataIndex: "_id",
        //     render: (text, device) => {
        //         if(device.status?.currentBatch?.filledTrays) {
        //             return <p>{device.status?.currentBatch?.filledTrays} of {device.status?.currentBatch?.totalTrays}</p>
        //         } else {
        //             return <p></p>
        //         }
        //
        //     }
        //
        //
        // },
        // {
        //     title: "Bags Packed",
        //     dataIndex: "_id",
        //     render: (text, device) => {
        //         if (device.status?.currentBatch?.bagsPacked) {
        //             return <p>{device.status?.currentBatch?.bagsPacked} of {device.status?.currentBatch?.totalBags}</p>
        //         } else {
        //             return <p></p>
        //         }
        //     }
        // },
    ];

    return (
        <Space
            size="large"
            direction="vertical"
            style={{ width: "100%" }}
        >
            <Flex gap={"middle"}>
                <Table columns={columns} dataSource={device?.status ? device : []} locale={{emptyText: t("noDataLabel")}} onChange={d=>{
                    console.log(d)
                }}/>
            </Flex>
        </Space>
    )
}

export default TruPakStatus;