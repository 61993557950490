import React from "react";
import styles from "./PickListMainHeadingByLocation.module.css";
import { useTranslation } from "react-i18next";

const PickListMainHeadingByLocation = () => {
  const { t } = useTranslation("pickList");

  return (
    <div className={styles.pickList__mainHeading}>
      <p
        className={[
          styles["pickList__mainColumnName"],
          styles["pickList__mainColumnName--item1"],
        ].join(" ")}
      >
        {t("locationNameCol")}
      </p>
      <p
        className={[
          styles["pickList__mainColumnName"],
          styles["pickList__mainColumnName--item2"],
        ].join(" ")}
      >
        {t("locationTypeCol")}
      </p>
      <p
        className={[
          styles["pickList__mainColumnName"],
          styles["pickList__mainColumnName--item3"],
        ].join(" ")}
      >
        {t("barcodeCol")}
      </p>
      <p
        className={[
          styles["pickList__mainColumnName"],
          styles["pickList__mainColumnName--item4"],
        ].join(" ")}
      >
        {t("qohCol")}
      </p>
      <p
        className={[
          styles["pickList__mainColumnName"],
          styles["pickList__mainColumnName--item5"],
        ].join(" ")}
      >
        {t("needCol")}
      </p>
    </div>
  );
};

export default PickListMainHeadingByLocation;
