import React, { useState, useEffect } from "react";
import { Popconfirm, Space, Spin, notification } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { format, parseISO } from "date-fns";

import TrayItem from "./TrayItem";
import TrayLabel from "./TrayLabel";
import TrayCellModal from "./TrayCellModal";
import TrayCheckFinishedModal from "./TrayCheckFinishedModal";
import { ControlMedTag, PartialMedTag } from "./TrayCheckTags";

import Button from "../../components/Button";
import TabularInfo from "../../components/TabularInfo";
import useTrayChecksController from "../../controllers/TrayChecksController";

import {
  isPartial,
  getTrayDrugs,
  getTrayCheckGrid,
  getDrugQtyNeeded,
} from "../../utils/functions/trayChecks";

import { ReactComponent as BackIcon } from "../../assets/back_icon.svg";
import styles from "./TrayCheckView.module.css";
import { useTranslation } from "react-i18next";

const TrayCheckView = ({ user }) => {
  const { t } = useTranslation("trayCheck");

  // Definitions
  const { siteId, deviceId, orderId, trayId } = useParams();
  const navigate = useNavigate();
  const {
    getTray,
    getSite,
    lockTray,
    getOrder,
    getDevice,
    resetTray,
    approveDrug,
    approveTray,
    getDrugImage,
    correctedApproveDrug,
  } = useTrayChecksController();

  // Necessary info states
  const [site, setSite] = useState(null);
  const [order, setOrder] = useState(null);
  const [device, setDevice] = useState(null);

  // Tray check states
  const [selectedTray, setSelectedTray] = useState(null);
  const [isTrayLocked, setIsTrayLocked] = useState(false);
  const [isTrayCheckFinished, setIsTrayCheckFinished] = useState(false);

  // Drugs states
  const [drugsList, setDrugsList] = useState([]);
  const [drugImage, setDrugImage] = useState(null);
  const [drugsImages, setDrugsImages] = useState(null);
  const [selectedDrug, setSelectedDrug] = useState(null);

  // View states and controllers
  const [grid, setGrid] = useState([]);
  const [controlView, setControlView] = useState(false);
  const [partialView, setPartialView] = useState(false);
  const [totalCellView, setTotalCellView] = useState(false);
  const [selectedTrayCell, setSelectedTrayCell] = useState(null);
  const [trayRenderController, setTrayRenderController] = useState(0);
  const toggleTotalCellView = () => setTotalCellView((prevState) => !prevState);

  // Drug selection handler
  const handleDrugSelection = (drug) => {
    setSelectedDrug(drug);
  };

  // Approval button click handler
  const handleApproval = async (drug) => {
    const updatedTray = await approveDrug(
      user,
      site,
      device,
      selectedTray,
      drug
    );
    if (updatedTray) setSelectedTray(updatedTray);
  };

  // Corrected approval button click handler
  const handleCorrectedApproval = async (slot, drug, reason, comments) => {
    const updatedTray = await correctedApproveDrug(
      user,
      site,
      device,
      selectedTray,
      slot,
      drug,
      reason,
      comments
    );
    if (updatedTray) setSelectedTray(updatedTray);
  };

  // Tray check finishing handler
  const handleFinish = async () => {
    await approveTray(user, site, device, selectedTray);
    navigate("/devices");
  };

  // Handler to go to the next drug
  const checkFinished = () => {
    if (drugsList.filter((d) => !d.approved).length === 0)
      setIsTrayCheckFinished(true);
    else setIsTrayCheckFinished(false);
  };

  // Get drug image
  const getDrugsImages = async () => {
    if (drugsList) {
      const images = [];
      for (let i = 0; i < drugsList.length; i++) {
        if (drugsList[i].product && drugsList[i].product.defaultImage) {
          const image = await getDrugImage(
            user,
            drugsList[i].product.defaultImage
          );
          images.push({ productId: drugsList[i].product._id, image: image });
        }
      }
      setDrugsImages(images);
    }
  };

  useEffect(() => {
    if (drugsImages && selectedDrug) {
      setDrugImage(
        drugsImages.find((i) => i.productId === selectedDrug.product._id)
          ?.image || null
      );
    }
  }, [drugsImages, selectedDrug]);

  // Side effect to update the view based on the drug type
  useEffect(() => {
    if (selectedDrug) {
      let controlView = false;
      let partialView = false;
      if (isPartial(selectedDrug.partial)) partialView = true;
      if (selectedDrug.product.controlLevel.type > 0) controlView = true;
      setControlView(controlView);
      setPartialView(partialView);
      setTotalCellView(false);
    }
  }, [selectedDrug]);

  // Side effect to get all drug images when the drugs list is updated
  useEffect(() => {
    getDrugsImages();
    if (drugsList.length > 0) {
      checkFinished();
      setSelectedDrug(drugsList[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drugsList]);

  // Side effect to lock the tray and mount the tray check grid when the tray check is loaded
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (selectedTray) {
      if (!isTrayLocked) {
        const locked = await lockTray(user, site, device, selectedTray);
        if (!!locked) setIsTrayLocked(true);
      }
      const drugs = getTrayDrugs(selectedTray);
      setDrugsList(drugs);
      setGrid(getTrayCheckGrid(selectedTray));
      setTrayRenderController((prev) => prev + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTray]);

  // Side effect to load all the necessary information when the tray check page is open
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (user) {
      const site = await getSite(user, siteId);
      setSite(site);
      const device = await getDevice(user, site, deviceId);
      setDevice(device);
      const order = await getOrder(user, site, orderId);
      setOrder(order);
      const selectedTrayCheck = await getTray(user, site, device, trayId);
      if (selectedTrayCheck.isLocked) {
        notification.warning({ message: t("lockedTrayWarning") });
        navigate(-1);
      } else setSelectedTray(selectedTrayCheck);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, siteId, deviceId, orderId, trayId]);

  // Side effect to unlock the tray when the page is unmounted
  useEffect(() => {
    return () => {
      if (
        user &&
        site &&
        device &&
        selectedTray &&
        isTrayLocked &&
        !isTrayCheckFinished
      )
        resetTray(user, site, device, selectedTray, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, site, device, selectedTray, isTrayCheckFinished, isTrayLocked]);

  return selectedTray && selectedDrug ? (
    <div
      className={styles.TrayCheckViewContainer}
      key={`${trayId}-${selectedDrug.product._id}`}
    >
      <TrayCellModal
        tray={selectedTray}
        slot={selectedTrayCell}
        open={!!selectedTrayCell}
        totalView={totalCellView}
        drugsImages={drugsImages}
        selectedDrug={selectedDrug}
        handleDrugSelection={handleDrugSelection}
        onCancel={() => setSelectedTrayCell(null)}
        handleCorrectedApprove={handleCorrectedApproval}
      />
      <TrayCheckFinishedModal
        tray={selectedTray}
        onCancel={handleFinish}
        open={isTrayCheckFinished && !totalCellView}
        trayRange={selectedTray.rangeId}
        onTotalCell={() => {
          setTotalCellView(true);
        }}
      />
      <div className={styles.TrayCheckTitleContainer}>
        {totalCellView ? (
          <BackIcon onClick={() => toggleTotalCellView()} />
        ) : (
          <Popconfirm
            placement="rightBottom"
            onConfirm={() => navigate("/devices")}
            title={t("unapprovedConfirmation")}
          >
            <BackIcon />
          </Popconfirm>
        )}
        <span className={styles.TrayCheckTitle}>
          {totalCellView ? t("checkTitle") : t("viewTitle")}
        </span>
      </div>
      {!totalCellView ? (
        <div className={styles.TrayDataHeader}>
          <div className={styles.TrayInfoContainer}>
            <TabularInfo
              tableClassName={styles.TrayInfoTable}
              dataCellClassName={styles.TrayInfoCell}
              labelCellClassName={styles.TrayInfoLabelCell}
              data={[
                {
                  key: "order-id",
                  label: t("orderLabel"),
                  data: selectedTray.dispensingOrder._id,
                },
                {
                  key: "tray-id",
                  label: t("trayIdLabel"),
                  data: selectedTray.assignedTrayId,
                },
                {
                  key: "tray-status",
                  label: t("trayStatusLabel"),
                  data: t(selectedTray.status, {ns: "trayStatuses"}),
                },
                {
                  key: "trays-needed",
                  label: t("traysNeededLabel"),
                  data: order?.trayChecks?.length || 1,
                },
                {
                  key: "trays-filled",
                  label: t("traysFilledLabel"),
                  data: order?.trayChecks?.length || 1,
                },
                {
                  key: "tray-sequence",
                  label: t("traySequenceLabel"),
                  data: `${selectedTray.rangeId}${
                    order && order.trayNeeds
                      ? ` ${t("ofLabel")} ${order.trayNeeds.totalTrays}`
                      : ""
                  }`,
                },
                {
                  key: "medication-index",
                  label: t("medicationLabel"),
                  data: `${drugsList.filter((d) => d.approved).length + 1}/${
                    drugsList.length
                  }`,
                },
              ]}
            />
          </div>
          <div className={styles.MedicineInfoContainer}>
            <div className={styles.MedicineInfoTableContainer}>
              <TabularInfo
                tableClassName={styles.MedicineInfoTable}
                dataCellClassName={styles.MedicineInfoCell}
                labelCellClassName={styles.MedicineInfoLabelCell}
                data={[
                  {
                    key: "primary-name",
                    label: t("primaryNameLabel"),
                    data: selectedDrug.product.primaryName,
                  },
                  {
                    key: "description",
                    label: t("descriptionLabel"),
                    data: selectedDrug.product.comments,
                  },
                  {
                    key: "manufacturer",
                    label: t("manufacturerLabel"),
                    data: selectedDrug.product.manufacturer.abbrName,
                  },
                  {
                    key: "ndc",
                    label: t("ndcLabel"),
                    data: selectedDrug.product.productId,
                  },
                  {
                    key: "fill-qty",
                    label: t("fillQtyLabel"),
                    data: getDrugQtyNeeded(
                      selectedTray.slots,
                      selectedDrug.product._id,
                      selectedDrug.partial
                    ),
                  },
                  {
                    key: "dta-expiration",
                    label: t("expDateLabel"),
                    data: format(
                      parseISO(selectedDrug.dtaExpiration),
                      "yyyy-MM-dd"
                    ),
                  },
                ]}
              />
            </div>
          </div>
          {!!drugImage ? (
            <div className={styles.MedicineImageContainer}>
              <img
                alt="Drug"
                className={styles.MedicineImage}
                src={
                  drugImage.mimeType
                    ? `data:${drugImage.mimeType};base64,${drugImage.base64EncodedString}`
                    : drugImage.base64EncodedString
                }
              />
            </div>
          ) : (
            <div className={styles.NoImageContainer}>
              <h4>{t("noImageLabel")}</h4>
            </div>
          )}
        </div>
      ) : null}
      <div
        className={styles.TrayDataContainer}
        key={`data-${selectedDrug.product._id}-${trayRenderController}`}
      >
        <table className={styles.TrayGrid}>
          <tbody>
            {grid.map((line, rowIndex) => (
              <tr key={rowIndex}>
                {line.map((item, colIndex) =>
                  colIndex === 0 && rowIndex === 0 ? (
                    <TrayLabel
                      label={item.label}
                      key={`tray-label-${colIndex}`}
                    />
                  ) : (
                    <TrayItem
                      item={item}
                      controlView={controlView}
                      partialView={partialView}
                      selectedDrug={selectedDrug}
                      totalQtyView={totalCellView}
                      key={`tray-item-${item.slotNumber}`}
                      onClick={(cell) => setSelectedTrayCell(cell)}
                    />
                  )
                )}
              </tr>
            ))}
          </tbody>
        </table>
        <div className={styles.ButtonsContainer}>
          <div className={styles.TotalQtyButtonContainer}>
            <Button
              onClick={toggleTotalCellView}
              labelName={
                totalCellView
                  ? selectedTray.approved
                    ? t("finishButtonLabel")
                    : t("checksButtonLabel")
                  : t("totalButtonLabel")
              }
            />
          </div>
          <div className={styles.MedTagContainer}>
            {controlView && !totalCellView ? <ControlMedTag /> : null}
            {partialView && !totalCellView ? (
              <PartialMedTag
                label={`${selectedDrug.quantity}x ${selectedDrug.partial}`}
              />
            ) : null}
          </div>
          <div className={styles.ApproveButtonContainer}>
            {!totalCellView ? (
              !selectedDrug.approved ? (
                <Button
                  isPrimary
                  onClick={() => handleApproval(selectedDrug)}
                  labelName={
                    drugsList.filter((d) => !d.approved).length <= 1
                      ? t("completeButtonLabel")
                      : t("approveButtonLabel")
                  }
                />
              ) : drugsList.filter((d) => !d.approved).length <= 0 ? (
                <Button
                  isPrimary
                  labelName={t("completeButtonLabel")}
                  onClick={() => setIsTrayCheckFinished(true)}
                />
              ) : null
            ) : null}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Space size="large" direction="vertical" align="center">
      <Spin size="large" />
      <h2>{t("loadingOrders")}</h2>
    </Space>
  );
};

export default TrayCheckView;

