import React, { useState, useEffect, useRef, useContext } from "react";

import TruPakDashboardController from "../../TruPakDashboard/TruPakDashboardController";
import InventoryGraph from "../graphs/InventoryGraph";
import { ScreenContext } from "../../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "../TruPakDashboard.module.css";
import stylesMobile from "../MobileViews/TruPakDashboardMobileView.module.css";
import { useTranslation } from "react-i18next";

const GetInventoryGraph = ({
  onGraphClick,
  user,
  selectedDevices,
  viewWidth,
  loader,
  onTableData,
  soonToExpireThreshold,
  title,
}) => {
  const { t } = useTranslation("trupakDashboard")

  const [Graph, setGraph] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [fetchingData, setFetchingData] = useState(false);

  const _controllerRef = useRef(TruPakDashboardController);

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  useEffect(() => {
    if (selectedDevices.length && !fetchingData) {
      setGraph(
        InventoryGraphContainer(
          "inventoryGraphNew", // id
          graphData // data for selected site and dispensing device
        )
      );
    }
  }, [graphData, fetchingData, selectedDevices]);

  useEffect(() => {
    if (selectedDevices.length) {
      // Get data for Inventory graph
      if (selectedDevices.length) {
        setGraph([InventoryGraphContainer(1, [])]);
      }

      async function updateGraph() {
        setFetchingData(true);

        let belowMin = 0;
        let expired = 0;
        let overMax = 0;
        let soonToExpire = 0;
        let today = new Date();
        let soonToExpireDate = new Date(today);
        soonToExpireDate.setDate(
          soonToExpireDate.getDate() + soonToExpireThreshold
        );

        let dataResults = [];

        for (let i = 0; i < selectedDevices.length; i++) {
          const device = selectedDevices[i];
          const site = selectedDevices[i].site;

          await _controllerRef.current
            .getTruPakInventory(user, site, device)
            .then((results) => {
              let today = new Date();

              results.results.forEach((result) => {
                dataResults.push(result);
                // each result has a device, canister, min quantity, max quantity, and quantity
                if (result.quantity < result.min) belowMin++;
                if (result.quantity > result.max) overMax++;
                const isExpired = Date.parse(result.expiration) <= today;
                if (isExpired) expired++;
                if (
                  !isExpired &&
                  Date.parse(result.expiration) <= soonToExpireDate
                )
                  soonToExpire++;
              });
            });
        }

        function compare(a, b) {
          const canisterA = Number(a.canister.name.split("-")[2]);
          const canisterB = Number(b.canister.name.split("-")[2]);

          let comparison = 0;
          if (canisterA > canisterB) {
            comparison = 1;
          } else if (canisterA < canisterB) {
            comparison = -1;
          }
          return comparison;
        }

        let tempTableDataSorted = dataResults.filter(
          (result) =>
            result.quantity < result.min || result.quantity > result.max
        );
        tempTableDataSorted.sort(compare);

        onTableData(tempTableDataSorted);
        setFetchingData(false);

        setGraphData([
          { x: t("belowLabel"), y: belowMin },
          { x: t("overLabel"), y: overMax },
          { x: t("expiredLabel"), y: expired },
          { x: t("soonToExpireLabel"), y: soonToExpire },
        ]);
        setFetchingData(false);
      }
      if (selectedDevices.length) {
        updateGraph();
      } else {
        setGraph([]);
      }
    } else {
      setGraph([]);
    }
  }, [selectedDevices]);

  const InventoryGraphContainer = (id, data) => {
    return (
      <div
        key={id}
        className={
          smScreen
            ? `${stylesMobile.TruPakDashboard__graphContainerMobile} ${stylesMobile["TruPakDashboard__graphContainerMobile--paddingBottom"]}`
            : `${styles.TruPakDashboard__graphContainerMobile} ${styles["TruPakDashboard__graphContainer--paddingBottom"]}`
        }
        onClick={!fetchingData ? () => onGraphClick(3) : null}
      >
        {fetchingData && loader}
        <InventoryGraph
          graphData={data}
          graphId={"inventoryGraphNew"}
          graphTitle={t("inventoryTitle")}
          palette={["#318ce7"]}
          columnsName=""
          viewWidth={viewWidth}
        />
      </div>
    );
  };

  return Graph;
};

export default GetInventoryGraph;
