import i18n from "../../../i18nextConf";

export const getPreferredFieldName = (
  collectionName,
  fieldName,
  showCollection = true,
  showNameUnderscores = false,
  context = ""

) => {
  let separator;
  if (showCollection && typeof showCollection === "boolean" ) {
    separator = ": " 
  } else {
    separator = showCollection
  }

  let subCollectionName;
  let subFieldName;

  if (fieldName.includes(".")) {
    const parts = fieldName.split(".");
    subCollectionName = parts[parts.length - 2];
    subFieldName = parts[parts.length - 1];
  } else {
    subCollectionName = collectionName;
    subFieldName = fieldName;
  }

  let assignedName = i18n.t(subFieldName, {
    ns: `reportConcepts/${subCollectionName}`,
    defaultValue: fieldName,
    context: context,
  });

  if (showCollection) {
    assignedName = i18n.t(collectionName, {
      ns: "reportConcepts/collections",
      defaultValue: collectionName,
      context: context,
    }) + separator + assignedName;
  }
  
  if (showNameUnderscores) {
    assignedName = assignedName.split(" ").join("_");
  }

  return assignedName;
};
