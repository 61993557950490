
import i18n from "../i18nextConf";

export const getLocalizedError = (error) => {
  if (!error) {
      return "";
  } else if (error.data) {
      return i18n.t(error.data.message, {ns: "errors", ...error.data.values});
  } else {
      return i18n.t(error, {ns: "errors"});
  }
}

export const getLocalizedDate = (date, country, withTime=false) => {
    if (!(date instanceof Date) || !date) {
      return ""
    }

    let localeDate;
  
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
  
    if (country === "Canada") {
      localeDate = `${day}/${month}/${year}`;
    }
    else {
      localeDate = `${month}/${day}/${year}`
    }

    if (withTime) {
      localeDate += `, ${date.toTimeString().slice(0, 5)}`
    }

    return localeDate;
  }