import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  Fragment,
} from "react";
import { useNavigate } from "react-router-dom";
import EV54CanisterDetailsCardList from "./MobileViews/EV54CanisterDetails/EV54CanisterDetailsCardList";
import Button from "../../../../components/Button";
import Table from "../../../../components/Table/Table";
import Popup from "../../../../components/PopupMenu/Popup";
import { ReactComponent as MoreActionsIcon } from "../../../../assets/more-actions.svg";
import { ReactComponent as MoreActionsMobileIcon } from "../../../../assets/more-actions-mobile.svg";
import backIcon from "../../../../assets/back_icon.svg";
import ProductController from "../../../../controllers/ProductController";
import { ScreenContext } from "../../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./EV54CanisterDetailsTable.module.css";
import { useTranslation } from "react-i18next";

const EV54CanisterDetailsTable = ({
  tableData,
  goBackHandler,
  windowHeight,
  selectedSite,
  selectedGraph,
}) => {
  const { t } = useTranslation("truscriptCanisterDetailsTable")

  const [items, setItems] = useState([]);
  const [showPopup, setShowPopup] = useState(null);
  const [popupIndex, setPopupIndex] = useState(-1);
  const [tableDataSorted, setTableDataSorted] = useState([]);

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const navigate = useNavigate();

  const ProductControllerInstance = new ProductController();

  const _controllerRef = useRef(ProductControllerInstance);

  const actionMenu = [
    {
      type: "canisterManagement",
      description: t("canisterAction"),
    },
  ];

  const handleAction = (action) => {
    switch (action.type) {
      case "canisterManagement":
        navigate("/devices", {
          state: {
            returnURL: "/dashboard",
            deviceViaDashboard: tableDataSorted[popupIndex]?.device,
            canisterNumber: Number(tableDataSorted[popupIndex].x.split("-")[2]),
            dataFromDashboard: {
              source: "EV54CanisterDetailsTable",
              actionItem: "canisterManagement",
              selectedTabIndex: 0,
              returnURL: "/dashboard",
              device: tableDataSorted[popupIndex]?.device,
              canister: tableDataSorted[popupIndex]?.canister,
              canisterNo: Number(tableDataSorted[popupIndex].x.split("-")[2]),
              canisterBarcode: tableDataSorted[popupIndex]?.canister.barcode,
              returnView: "EV-54",
              selectedSite:
                typeof selectedSite === "object" ? selectedSite : null,
              selectedGraph: selectedGraph,
            },
          },
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (tableData.length) {
      function compare(a, b) {
        const canisterA = Number(a.x.split("-")[2]);
        const canisterB = Number(b.x.split("-")[2]);

        let comparison = 0;
        if (canisterA > canisterB) {
          comparison = 1;
        } else if (canisterA < canisterB) {
          comparison = -1;
        }
        return comparison;
      }
      let tempTableDataSorted = tableData.map((result) => result);
      tempTableDataSorted.sort(compare);
      setTableDataSorted(tempTableDataSorted);
    } else setTableDataSorted([]);
  }, [tableData]);

  useEffect(() => {
    const tempItems = [];
    try {
      tableDataSorted.forEach((tableRow, index) => {
        const actions = (
          <div
            className={styles.EV54CanisterDetailsTable__actionsContainer}
            onClick={(e) => {
              if (!showPopup) {
                setShowPopup(true);
                setPopupIndex(index);
              }
            }}
          >
            {smScreen ? <MoreActionsMobileIcon /> : <MoreActionsIcon />}
            {showPopup && popupIndex === index && (
              <Popup
                menuItems={actionMenu}
                onAction={(action) => {
                  setShowPopup(null);
                  setPopupIndex(-1);
                  handleAction(action);
                }}
                onClose={() => setShowPopup(null)}
                rightJustify={true}
              />
            )}
          </div>
        );
        tempItems.push([
          tableRow?.device?.name,
          tableRow?.x,
          _controllerRef.current.getDefaultName(tableRow.product),
          tableRow?.y,
          actions,
        ]);
      });
      setItems(tempItems);
    } catch (e) {
      console.log(e.message);
    }
  }, [showPopup, popupIndex, tableDataSorted]);

  const tableHeight = (+windowHeight - 200).toString() + "px";

  const headers = [
    t("col0"),
    t("col1"),
    t("col2"),
    t("col3"),
    <div className={styles.EV54CanisterDetailsTable__actionsHeadingContainer}>
      {t("col4")}
    </div>,
  ];

  const DesktopView = (
    <Fragment>
      <div className={styles.EV54CanisterDetailsTable__header}>
        <h1>{t("title")}</h1>
        <p>{t("contact")}: 555-555-5555</p>
      </div>
      <div
        style={{ height: tableHeight }}
        className={styles.EV54CanisterDetailsTable__mainContainer}
      >
        {/* <p>{deviceName}</p> */}
        <div className={styles.EV54CanisterDetailsTable__tableContainer}>
          <Table
            padScrollBar={true}
            // searchText={searchText}
            headers={headers}
            records={items}
            tableRowHeight={37}
            scrollAdjustConstant={160}
          />
        </div>
        <div className={styles.EV54CanisterDetailsTable__buttonGroup}>
          <Button
            onClick={goBackHandler}
            labelName={t("backButtonLabel")}
            isPrimary={false}
            isDisabled={false}
            minWidth={null}
          />
        </div>
      </div>
    </Fragment>
  );

  const MobileView = (
    <div className={styles.EV54CanisterDetailsTable__mainContainer}>
      <header className={styles.EV54CanisterDetailsTable__header}>
        <div className={styles.EV54CanisterDetailsTable__backButton}>
          <img src={backIcon} onClick={goBackHandler} />
        </div>
        <div className={styles.EV54CanisterDetailsTable__reportTitle}>
          <h1>{t("title")}</h1>
          <p>{t("contact")}: 555-555-5555</p>
        </div>
      </header>
      {
        <div className={styles.EV54CanisterDetailsTable__cardListContainer}>
          <EV54CanisterDetailsCardList headings={headers} data={items} />
        </div>
      }
    </div>
  );

  return smScreen ? MobileView : DesktopView;
};

export default EV54CanisterDetailsTable;
