import React, { useEffect, useState, useContext } from "react";
import SearchBar from "../../components/SearchBar";
import UsersDataCard from "./UsersDataCard";
import Spacer from "../../components/Spacer";
import CreateButton from "../../components/CreateButton";
import RoleCheckService from "../../services/RoleCheckService";
import ErrorBanner from "../../components/ErrorBanner";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { ReactComponent as MoreActionsIcon } from "../../assets/more-actions.svg";
import { ReactComponent as MoreActionsIconMobile } from "../../assets/more-actions-mobile.svg";

import { ReactComponent as PicFrameEmpty } from "../../assets/pic-frame-empty.svg";
import ImageService from "../../services/ImageService";
import UserService from "../../services/UserService";
import MyModal from "../../components/Modal";
import ConfigService from "../../services/ConfigService";
import ContextMenu from "../../components/ContextMenu";
import { composeInitialProps, useTranslation } from "react-i18next";
import {
  Outlet,
  useInRouterContext,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ReactComponent as MoreRolesIcon } from "../../assets/role-icon.svg";
import Popup from "../../components/PopupMenu/Popup";
import { deepEqual } from "../../functions/utilities.js";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./Users.module.css";
import { Modal, Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { toCamelCase } from "../../utils/functions/strings.js"

const Users = ({ user, profilePic, refresh }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation("users");

  const actionListItems = [
    {
      type: "enable",
      description: t("tab0"),
    },
    {
      type: "disable",
      description: t("tab1"),
    },
    {
      type: "edit",
      description: t("tab2"),
    },

  ];



  const [error, setError] = useState("");
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState("");
  const [modal, setModal] = useState(false);
  const [roleList, setRoleList] = useState([]);
  const [contextMenu, setContextMenu] = useState(null);
  const [filterText, setFilterText] = useState("");
  const [showPopup, setShowPopup] = useState(null);
  const [usersProfilePics, setUsersProfilePics] = useState([]);
  const [showPasswordResetModal, setShowPasswordResetModal] = useState(null);
  const [locationPathname, setLocationPathname] = useState("");

  const { handleShowSmScreenHeader, smallScreen, mediumScreen, largeScreen } =
    useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);
  const mdScreen = useMediaQuery(mediumScreen);
  const lgScreen = useMediaQuery(largeScreen);

  useEffect(() => {
    smScreen && handleShowSmScreenHeader(true);
    !smScreen && contextMenu && setContextMenu(null);
  }, [smScreen]);

  const storeUserProfilePics = (index) => {
    if (
      // true only for the logged-in user and if this user has a profile pic
      users[index].profilePic &&
      users[index].profilePic === user.profilePic
    ) {
      // With this format, React guarantees that the 'prevState' snapshot here will always be the latest snapshot keeping all scheduled state updates in mind.
      setUsersProfilePics((prevState) => {
        return [...prevState, profilePic];
      });
    } else if (users[index].profilePic) {
      // users with a profile pic other ( excluding the logged-in the user )
      ImageService.read(user, users[index].profilePic).then((imageDoc) => {
        setUsersProfilePics((prevState) => {
          return [...prevState, imageDoc];
        });
      });
    }
  };

  useEffect(() => {
    if (users.length && profilePic) {
      for (let index = 0; index < users.length; index++) {
        storeUserProfilePics(index);
      }
    }
  }, [users, profilePic]);

  useEffect(() => {
    setLocationPathname(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    UserService.list(user)
      .then((users) => {
        setUsers(users);
      })
      .catch((error) => {
        setError(error);
      });
  }, [refresh]);

  useEffect(() => {
    if (roleList.length === 0) {
      ConfigService.roles()
        .then((roles) => {
          roles.forEach((role) => {
            role.description = t(role.type, { ns: "roles" });
            role.additionalInfo = t(`${role.type}Info`, { ns: "roles" });
          });
          setRoleList(roles);
        })
        .catch((error) => {
          setError(error);
        });
    }
  }, []);

  const getUserProfilePic = (profilePicId) => {
    for (let i = 0; i < usersProfilePics.length; i++) {
      if (usersProfilePics[i].id === profilePicId) {
        return usersProfilePics[i];
      }
    }

    return null;
  };

  const handleSearch = (searchText) => {
    setFilterText(searchText.toUpperCase());
  };

  const handleCreate = () => {
    navigate("create");
  };

  const getRoleProperty = (property, role) => {
    let masterRole = roleList.find((o) => o.type === role.type);
    if (toCamelCase(role.type) === "superAdministrator" && property === "color") {
      return "green";
    }
        if(masterRole){
      return masterRole[property];
    } else {
      return "UN"
    }

  };

  const getActionMenu = (usr) => {
    if (usr.isPending) {
      const actionMenu = [
        {
          type: "edit",
          description: t("tab2"),
        },
        {
          type: "resendInvite",
          description: t("tab3"),
        },
      ];
      if (RoleCheckService.deleteUsers(user)) {
        actionMenu.push({
          type: "delete",
          description: t("tab4"),
        });
      }
      return actionMenu;
    }

    let listItems = [...actionListItems];

    if(usr._id !== user._id && RoleCheckService.canInviteUsers(user) && user.account.accountSecurity.type === 'USERNAME'){
      listItems.push({
        type: "resetPassword",
        description: t("tab5")
      })
    }

    if (usr._id === user._id) {
      listItems.splice(0, 2);
      return listItems;
    } else if (usr.isEnabled) {
      listItems.splice(0, 1);
      return listItems;
    } else if (!usr.isEnabled) {
      listItems.splice(1, 2);
      return listItems;
    } else {
      return listItems;
    }


  };

  const handleAction = (action, usr) => {
    let newUsr = { ...usr };
    switch (action.type) {
      case "enable":
        newUsr.isEnabled = true;
        updateUser(newUsr);
        break;
      case "disable":
        newUsr.isEnabled = false;
        updateUser(newUsr);
        break;
      case "edit":
        navigate("create", { state: { user: usr } });
        break;
      case "resendInvite":
        handleResendInvite(usr);
        break;
      case "delete":
        deleteUser(usr);
        break;
      case "resetPassword":
        setShowPasswordResetModal(usr);
        break
      default:
        break;
    }
  };

  const handleResendInvite = async (usr) => {
    try {
      await UserService.sendInvite(user.account, usr);
      setModal(true);
    } catch (error) {
      setError(error);
    }
  };

  const updateUser = (usr) => {
    UserService.update(user, usr)
      .then((userDoc) => {
        let index = users.findIndex((o) => o._id === userDoc._id);
        let list = [...users];
        list[index] = userDoc;
        setUsers(list);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const deleteUser = (usr) => {
    UserService.delete(user, usr)
      .then((userDoc) => {
        let index = users.findIndex((o) => o._id === userDoc._id);
        let list = [...users];
        list.splice(index, 1);
        setUsers(list);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const handleHideContextMenu = () => {
    setContextMenu(null);
  };

  const handleDisplayUser1 = (e, role) => {
    if (
      smScreen &&
      role &&
      contextMenu &&
      contextMenu.listItems &&
      contextMenu.listItems[0] &&
      deepEqual(role, contextMenu.listItems[0])
    ) {
      handleHideContextMenu();
    } else {
      e.stopPropagation();
      let left = e.currentTarget.offsetLeft;

      if (smScreen) {
        left = left - 55;
      }

      let rect = e.currentTarget.getBoundingClientRect();
      let top = rect.top - 74;

      setContextMenu({
        left: left,
        top: top,
        listItems: [role],
        onSelect: (val) => {
          setContextMenu(null);
        },
      });
    }
  };

  const handleDisplayUser2 = (e, listItems) => {
    if (
      smScreen &&
      contextMenu &&
      listItems &&
      listItems.length > 0 &&
      deepEqual(listItems, contextMenu.listItems)
    ) {
      handleHideContextMenu();
    } else {
      e.stopPropagation();
      let left = e.currentTarget.offsetLeft;

      if (smScreen) {
        left = left - 55;
      }

      let rect = e.currentTarget.getBoundingClientRect();

      // empirically derived
      const top = rect.top - (73 + (listItems.length - 1) * 33);

      setContextMenu({
        left: left,
        top: top,
        listItems: listItems,
        onSelect: (val) => {
          setContextMenu(null);
        },
      });
    }
  };

  const handleActions = (e, usr) => {
    let left = e.currentTarget.offsetLeft + e.currentTarget.offsetWidth;
    let top = e.currentTarget.offsetTop + e.currentTarget.offsetHeight;
    setContextMenu({
      left: left,
      top: top,
      listItems: getActionMenu(usr),
      isRightAligned: true,
      onSelect: (action) => {
        handleAction(action, usr);
        setContextMenu(null);
      },
    });
  };

  const getRolesMobile1 = (role, usr, index) => {
    return (
      <div
        key={role._id + role.description}
        className={styles.users__roleContainer}
        style={{
          zIndex: usr.roles.length - index,
          marginLeft: index ? (smScreen || mdScreen ? "-6px" : "-8px") : 0,
        }}
        onClick={(e) => handleDisplayUser1(e, role)}
      >
        <div
          className={styles.users__role}
          style={{
            backgroundColor: getRoleProperty("color", role),
          }}
        >
          <p className={styles.users__roleText}>
            {role.abbreviation}
          </p>
        </div>
      </div>
    );
  };

  const getRoles1 = (role, usr, index) => {
    return (
      <div
        key={role._id + role.description}
        className={styles.users__roleContainer}
        style={{
          zIndex: usr.roles.length - index,
          marginLeft: index ? (smScreen || mdScreen ? "-6px" : "-8px") : 0,
        }}
        onMouseEnter={(e) => handleDisplayUser1(e, role)}
        onMouseLeave={() => setContextMenu(null)}
      >
        <div
          className={styles.users__role}
          style={{
            backgroundColor: getRoleProperty("color", role),
          }}
        >
          <p className={styles.users__roleText}>
            {role.abbreviation}
          </p>
        </div>
      </div>
    );
  };

  const getRolesMobile2 = (role, usr, listItems) => {
    return (
      <div
        key={role._id + role.description}
        onClick={(e) => handleDisplayUser2(e, listItems)}
        style={{
          marginLeft: smScreen || mdScreen ? "-6px" : "-8px",
          position: "relative",
          width: "100%",
          height: smScreen || mdScreen ? "27px" : "40px",
        }}
      >
        <div className={styles.users__moreRoles}>
          <p className={styles.users__roleText}>+{usr.roles.length - 3}</p>
        </div>
      </div>
    );
  };

  const getRoles2 = (role, usr, listItems) => {
    return (
      <div
        key={role._id + role.description}
        onMouseEnter={(e) => handleDisplayUser2(e, listItems)}
        onMouseLeave={() => setContextMenu(null)}
        style={{
          marginLeft: smScreen || mdScreen ? "-6px" : "-8px",
          position: "relative",
          width: "100%",
          height: smScreen || mdScreen ? "27px" : "40px",
        }}
      >
        <div className={styles.users__moreRoles}>
          <p className={styles.users__roleText}>+{usr.roles.length - 3}</p>
        </div>
      </div>
    );
  };

  const showRoles = (usr) => {

    return (
      usr.roles.length &&
      usr.roles.map((role, index) => {        
        role.type = toCamelCase(role.type) // For backwards compatibility
        role.description = t(role.type, {ns: "roles"})
        role.abbreviation = t(`${role.type}Abbr`, {ns: "roles"})
        role.additionalInfo = t(`${role.type}Info`, {ns: "roles"})

        let zIndex = 5;
        if (index < 3) {
          return smScreen
            ? getRolesMobile1(role, usr, index)
            : getRoles1(role, usr, index);
        } else if (index === 3) {
          let listItems = [];
          for (let i = 3; i < usr.roles.length; i++) {
            listItems.push(usr.roles[i]);
          }
          return smScreen
            ? getRolesMobile2(role, usr, listItems)
            : getRoles2(role, usr, listItems);
        }
      })
    );
  };

  return (
    <>
      <Outlet />
      {error && (
        <div className={styles.users__errorBannerContainer}>
          <ErrorBanner message={error} onClose={() => setError(null)} />
        </div>
      )}

      {locationPathname === "/settings/users" && (
        <>
          {!smScreen && <Spacer space={mdScreen ? 20 : 70} unit={"px"} />}
          <div
            className={styles.users__row}
            style={{ justifyContent: "space-between" }}
          >
            <div className={styles.users__searchBarContainer}>
              <SearchBar
                placeholder={
                  smScreen ? t("searchPlaceholderMobile") : t("searchBarText")
                }
                onSearch={handleSearch}
                focus={true}
              />
            </div>

            {RoleCheckService.canInviteUsers(user) && (
              <div className={styles.users__createButtonContainer}>
                <CreateButton
                  onClick={handleCreate}
                  isEnabled={true}
                  labelName={t("addButtonLabel")}
                />
              </div>
            )}
          </div>
          {!smScreen && <Spacer space={mdScreen ? 50 : 100} unit={"px"} />}

          {/* Generation of Data Table Headings for Large Screen */}

          {!smScreen && (
            <ul className={styles.users__tableHeader}>
              <li className={`${styles.users__nameHeader}`}>{t("col0")}</li>
              <li className={`${styles.users__rolesHeader}`}>{t("col1")}</li>
              <li className={`${styles.users__defaultSiteHeader}`}>
                {"Default Site"}
              </li>
              <li className={`${styles.users__emailHeader}`}>{t("col2")}</li>
              <li className={`${styles.users__phoneNumberHeader}`}>
                {t("col3")}
              </li>
              {lgScreen && (
                <li className={styles.users__actionsHeading}>{t("col4")}</li>
              )}
            </ul>
          )}

          <Spacer space={smScreen ? 0 : 20} unit={"px"} />
          {showPasswordResetModal &&
          <Modal title="Reset Password" open={true} onOk={()=>{
            const user = {...showPasswordResetModal};
            if(user.password) {
              updateUser(user);
            }
            setShowPasswordResetModal(null);
          }} onCancel={()=>{setShowPasswordResetModal(null)}}>
            <p>Reset {showPasswordResetModal ? showPasswordResetModal.username : ''}'s password?</p>
            <Input.Password defaultValue={''}
                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                            placeholder="Enter password" onChange={(e)=>{
              const user = {...showPasswordResetModal}
              user.password = e.currentTarget.value;
              setShowPasswordResetModal(user);
            }}/>
          </Modal>
          }

          {/* Generation of Data Table */}

          <div
            className={
              !smScreen
                ? styles.users__scroll
                : styles.users__dataCardsContainer
            }
          >
            {roleList.length > 0 &&
              users.length > 0 &&
              users
                .filter((o) =>
                  filterText
                    ? o.firstName.toUpperCase().includes(filterText) ||
                      o.lastName.toUpperCase().includes(filterText)
                    : true
                )
                .map((usr, index) => {
                  // Note that usr.profilePic is an Id, not an object
                  let userProfilePic = getUserProfilePic(usr.profilePic);

                  let userProfileForDisplay =
                    userProfilePic && userProfilePic !== profilePic ? (
                      <img
                        className={styles.users__profilePic}
                        src={
                          userProfilePic.mimeType
                            ? `data:image/${userProfilePic.mimeType};base64,${userProfilePic.base64EncodedString}`
                            : userProfilePic.base64EncodedString
                        }
                        alt={"logo"}
                      />
                    ) : userProfilePic && userProfilePic === profilePic ? (
                      <img
                        className={styles.users__profilePic}
                        src={
                          profilePic.mimeType
                            ? `data:image/${profilePic.mimeType};base64,${profilePic.base64EncodedString}`
                            : profilePic.base64EncodedString
                        }
                        alt={"logo"}
                      />
                    ) : (
                      <PicFrameEmpty className={styles.users__profilePic} />
                    );

                  return !smScreen ? (
                    <ul
                      key={usr._id}
                      className={
                        usr.isEnabled
                          ? styles.users__listItem
                          : `${styles.users__listItem} ${styles["users__listItem--lightGray"]}`
                      }
                      style={{
                        zIndex: users.length - index,
                      }}
                    >
                      <li className={`${styles.users__profilePicData}`}>
                        {userProfileForDisplay}
                      </li>

                      <li className={`${styles.users__nameData}`}>
                        {usr.firstName} {usr.lastName}
                      </li>

                      <li
                        className={`${styles.users__row} ${styles.users__rolesData} ${styles.users__overflowHidden}`}
                      >
                        {showRoles(usr)}
                      </li>

                      <li className={`${styles.users__defaultSiteData}`}>
                        {usr?.defaultSite?.name || "---"}
                      </li>

                      <li className={`${styles.users__emailData}`}>
                        {usr.emailAddress}
                      </li>
                      <li className={`${styles.users__phoneNumberData}`}>
                        {usr.phoneNumbers?.length > 0
                          ? formatPhoneNumberIntl(usr.phoneNumbers[0].number)
                          : ""}
                      </li>
                      {RoleCheckService.canInviteUsers(user) && (
                        <li
                          className={styles.users__actionsContainer}
                          onClick={(e) => {
                            if (modal) {
                              setModal(false);
                            }
                            setShowPopup(usr);
                            setUserId(usr._id);
                          }}
                        >
                          {mdScreen ? (
                            <MoreActionsIconMobile />
                          ) : (
                            <MoreActionsIcon />
                          )}
                          {showPopup === usr && (
                            <div className={styles.users__popupContainer}>
                              <Popup
                                menuItems={getActionMenu(usr)}
                                onAction={(action) => {
                                  setShowPopup(null);
                                  handleAction(action, usr);
                                }}
                                onClose={() => setShowPopup(null)}
                                rightJustify={true}
                              />
                            </div>
                          )}
                        </li>
                      )}
                      {showPopup === usr && modal && (
                        <MyModal
                          onClose={() => {
                            setModal(false);
                          }}
                          top={1}
                          message={t("modalMessage")}
                          buttonLabelName={t("closeButtonLabel")}
                          title={t("resendInviteTitle")}
                        />
                      )}
                    </ul>
                  ) : (
                    <UsersDataCard
                      key={usr._id}
                      onHideContextMenu={handleHideContextMenu}
                      data={{
                        nameHeading: t("col0"),
                        rolesHeading: t("col1"),
                        emailHeading: t("col2"),
                        phoneNumberHeading: t("col3"),
                        actionsHeading: t("col4"),
                        defaultSiteHeading: "Default Site",
                        name: `${usr.firstName} ${usr.lastName}`,
                        roles: showRoles(usr),
                        email: usr.emailAddress,
                        phoneNumber:
                          usr.phoneNumbers?.length > 0
                            ? formatPhoneNumberIntl(usr.phoneNumbers[0].number)
                            : "",
                        actions: (
                          <div
                            className={styles.users__actionsContainer}
                            onClick={(e) => {
                              setShowPopup(usr);
                            }}
                          >
                            <MoreActionsIconMobile />
                            {showPopup === usr && (
                              <div className={styles.users__popupContainer}>
                                <Popup
                                  menuItems={getActionMenu(usr)}
                                  onAction={(action) => {
                                    setShowPopup(null);
                                    handleAction(action, usr);
                                  }}
                                  onClose={() => setShowPopup(null)}
                                  rightJustify={true}
                                />
                              </div>
                            )}
                          </div>
                        ),

                        userProfilePic: userProfileForDisplay,
                        defaultSite: usr?.defaultSite
                          ? usr.defaultSite[0]?.name
                          : "",
                      }}
                    />
                  );
                })}
          </div>
        </>
      )}

      {contextMenu && (
        <ContextMenu
          onSelect={contextMenu.onSelect}
          listItems={contextMenu.listItems}
          top={contextMenu.top}
          left={contextMenu.left}
          isRightAligned={contextMenu.isRightAligned}
          maxHeight={contextMenu.maxHeight}
          onClose={() => setContextMenu(null)}
          width={smScreen ? "110px" : "auto"}
          smScreen={true}
        />
      )}
    </>
  );
};

export default Users;
