import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  Fragment,
} from "react";
import Loader from "react-loader-spinner";
import EV54DashboardMobileView from "./MobileViews/EV54DashboardMobileView";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import EV54DashboardController from "../EV54Dashboard/EV54DashboardController";

import ScriptsByHourGraph from "./graphs/ScriptsByHourGraph";
import ScriptTypeGraph from "./graphs/ScriptTypeGraph";
import CanisterUtilizationGraph from "./graphs/CanisterUtilizationGraph";
import EV54AlarmsTable from "./SubViews/EV54AlarmsTable";
import EV54CanisterDetailsTable from "./SubViews/EV54CanisterDetailsTable";
import EV54InventoryTable from "./SubViews/EV54InventoryTable";
import BarGraph from "../sharedGraphs/BarGraph";
import { ScreenContext } from "../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./EV54Dashboard.module.css";
import stylesMobile from "./MobileViews/EV54DashboardMobileView.module.css";

const soonToExpireThreshold = 90;

const EV54Dashboard = ({ props }) => {
  const {
    user,
    selectedSite,
    dashboardView,
    windowHeight,
    dashboardReturnStateLocal,
    makeDashboardReturnStateLocalNull,
    onShowDropdowns,
    viewWidth,
  } = props;

  const { t } = useTranslation("truscriptDashboard")

  const [fetchingScriptsByHour, setFetchingScriptsByHour] = useState(false);
  const [fetchingScriptType, setFetchingScriptType] = useState(false);
  const [fetchingCanisterUtilization, setFetchingCanisterUtilization] =
    useState(false);
  const [fetchingAlarms, setFetchingAlarms] = useState(false);
  const [fetchingInventory, setFetchingInventory] = useState(false);
  const [selectedGraph, setSelectedGraph] = useState(null);
  const [SelectedGraphView, setSelectedGraphView] = useState(null);
  const [scriptsByHourGraphData, setScriptsByHourGraphData] = useState([]);
  const [scriptsByHourRollingAverage, setScriptsByHourRollingAverage] =
    useState(null);
  const [scriptTypesGraphData, setScriptTypesGraphData] = useState([]);
  const [canisterUtilizationGraphData, setCanisterUtilizationGraphData] =
    useState([]);
  const [alarmsGraphData, setAlarmsGraphData] = useState([]);
  const [inventoryGraphData, setInventoryGraphData] = useState([]);
  const [inventoryTableData, setInventoryTableData] = useState([]);
  const viewHeight = (+windowHeight - 200).toString() + "px";
  const stopDate = new Date();
  const inventoryStartDate = new Date();
  inventoryStartDate.setDate(inventoryStartDate.getDate() - 4); // 5 days total

  const alarmsStartDate = new Date();
  alarmsStartDate.setDate(alarmsStartDate.getDate() - 6); // 7 days total

  const _controllerRef = useRef(EV54DashboardController);

  const navigate = useNavigate();

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const getDateRange = (dayCount) => {
    const dt = new Date();
    dt.setHours(23, 59, 59); // set always to the end of TODAY
    const stopDate = new Date(dt.getTime());
    dt.setDate(dt.getDate() - dayCount);
    const startDate = new Date(dt.getTime());
    return { startDate, stopDate };
  };

  useEffect(() => {
    selectedGraph ? onShowDropdowns(false) : onShowDropdowns(true);
  }, [selectedGraph]);

  useEffect(() => {
    setScriptsByHourGraphData([]);
    setScriptsByHourRollingAverage(null);
    setScriptTypesGraphData([]);
    setCanisterUtilizationGraphData([]);
    setAlarmsGraphData([]);
    setInventoryGraphData([]);
    setInventoryTableData([]);
  }, [selectedSite]);

  useEffect(() => {
    // Used after navigation return from Devices to EV54CanisterDetailsTable
    if (
      dashboardReturnStateLocal &&
      dashboardReturnStateLocal.source === "EV54CanisterDetailsTable" &&
      canisterUtilizationGraphData.length
    ) {
      setSelectedGraph(dashboardReturnStateLocal?.selectedGraph);
      makeDashboardReturnStateLocalNull(null);
    }
  }, [canisterUtilizationGraphData]);

  useEffect(() => {
    // Used after navigation return from Devices to EV54CanisterDetailsTable
    if (
      dashboardReturnStateLocal &&
      dashboardReturnStateLocal.source === "EV54InventoryTable" &&
      inventoryTableData.length
    ) {
      setSelectedGraph(dashboardReturnStateLocal?.selectedGraph);
      makeDashboardReturnStateLocalNull(null);
    }
  }, [inventoryTableData]);

  /***********************************************************************  
  Implement graph-data fetching methods here
  ***********************************************************************/
  useEffect(() => {
    // Get initial data for Scripts by Hour graph
    const { startDate, stopDate } = getDateRange(1);

    const utcStartDate = startDate.toUTCString();
    const utcStopDate = stopDate.toUTCString();

    setFetchingScriptsByHour(true);
    _controllerRef.current
      .getEV54ScriptsPerHourData(
        user,
        selectedSite,
        null,
        utcStartDate,
        utcStopDate
      )
      .then((data) => {
        const getScriptsForHour = (hour, prevDayResults) => {
          return prevDayResults.filter((o) => {
            const dt = new Date(o.processedOn);
            return dt.getHours() === hour;
          });
        };

        let hourCountData = [];
        for (let hour = 0; hour < 24; hour++) {
          const temp = getScriptsForHour(hour, data.results.prevDayResults);
          hourCountData.push({
            hour: hour,
            count: temp.length,
          });
        }

        setScriptsByHourGraphData(
          hourCountData.map((result, index) => ({
            x:
              index < 12
                ? `${result.hour > 0 ? result.hour : 12} AM`
                : `${result.hour > 12 ? result.hour - 12 : 12} PM`,
            y: result.count,
          }))
        );

        setScriptsByHourRollingAverage(
          data?.results?.rollingAverage ? data.results.rollingAverage : 0
        );

        setFetchingScriptsByHour(false);
      })
      .catch((error) => {
        console.log(error);
        setFetchingScriptsByHour(false);
      });
  }, [selectedSite]);

  useEffect(() => {
    // Get initial data for Script Type graph
    const { startDate, stopDate } = getDateRange(1);
    const utcStartDate = startDate.toUTCString();
    const utcStopDate = stopDate.toUTCString();

    setFetchingScriptType(true);
    _controllerRef.current
      .getEV54ScriptTypes(user, selectedSite, null, utcStartDate, utcStopDate)
      .then((results) => {
        // console.log("results: ", results);

        let highNumScripts = 0;
        let lowNumScripts = 0;
        let statNumScripts = 0;
        let waiterNumScripts = 0;

        results.results.forEach((result) => {
          if (result.scriptType === "HIGH") {
            highNumScripts += 1;
          } else if (result.scriptType === "LOW") {
            lowNumScripts += 1;
          } else if (result.scriptType === "STAT") {
            statNumScripts += 1;
          } else if (result.scriptType === "WAITER") {
            waiterNumScripts += 1;
          }
        });
        setScriptTypesGraphData([
          { x: t("highLabel"), y: highNumScripts },
          { x: t("lowLabel"), y: lowNumScripts },
          { x: t("statLabel"), y: statNumScripts },
          { x: t("waiterLabel"), y: waiterNumScripts },
        ]);

        setFetchingScriptType(false);
      })
      .catch((error) => {
        console.log(error);
        setFetchingScriptType(false);
      });
  }, [selectedSite]);

  useEffect(() => {
    // Get initial data for Canister Utilization graph
    setFetchingCanisterUtilization(true);
    _controllerRef.current
      .getEV54CanisterUtilization(user, selectedSite, null)
      .then((results) => {
        // console.log("results: ", results);
        let tempCanisterUtilizationGraphData =
          results.results.scriptsPerCanister.map((result) => ({
            x: result.canister.name,
            y: result.count,
            y1: results.results.averageScriptsPerCanister,
            device: result.device, // stock location
            canister: result.canister,
            product: result.product,
          }));

        function compare(a, b) {
          const countA = a.y;
          const countB = b.y;

          let comparison = 0;
          if (countA > countB) {
            comparison = 1;
          } else if (countA < countB) {
            comparison = -1;
          }
          return comparison;
        }

        tempCanisterUtilizationGraphData.sort(compare);
        setCanisterUtilizationGraphData(tempCanisterUtilizationGraphData);
        setFetchingCanisterUtilization(false);
      })

      .catch((error) => {
        console.log(error);
        setFetchingCanisterUtilization(false);
      });
  }, [selectedSite]);

  /************ Get data for Alarms graph ***********/
  useEffect(() => {
    setFetchingAlarms(true);
    _controllerRef.current
      .getEV54AlarmHistory(user, selectedSite, alarmsStartDate, stopDate)
      .then((results) => {
        // console.log("results: ", results);
        const alarmsData = results.map((result) => {
          return { x: result.error, y: result.count };
        });
        setAlarmsGraphData(alarmsData);
        setFetchingAlarms(false);
      })
      .catch((error) => {
        console.log(error);
        setFetchingAlarms(false);
      });
  }, [user, selectedSite]);

  useEffect(() => {
    // Get initial data for Inventory graph
    setFetchingInventory(true);
    _controllerRef.current
      .getEV54Inventory(user, selectedSite)
      .then((results) => {
        // console.log("results: ", results);
        let belowMin = 0;
        let expired = 0;
        let overMax = 0;
        let soonToExpire = 0;
        let today = new Date();
        let soonToExpireDate = new Date(today);
        soonToExpireDate.setDate(
          soonToExpireDate.getDate() + soonToExpireThreshold
        );

        results.results.forEach((result) => {
          if (result.quantity < result.min) belowMin++;
          if (result.quantity > result.max) overMax++;
          const isExpired = Date.parse(result.expiration) <= today;
          if (isExpired) expired++;
          if (!isExpired && Date.parse(result.expiration) <= soonToExpireDate)
            soonToExpire++;
        });
        setInventoryGraphData([
          { x: t("belowLabel"), y: belowMin },
          { x: t("overLabel"), y: overMax },
          { x: t("expiredLabel"), y: expired },
          { x: t("soonToExpireLabel"), y: soonToExpire },
        ]);

        function compare(a, b) {
          const canisterA = Number(a.canister.name.split("-")[2]);
          const canisterB = Number(b.canister.name.split("-")[2]);

          let comparison = 0;
          if (canisterA > canisterB) {
            comparison = 1;
          } else if (canisterA < canisterB) {
            comparison = -1;
          }
          return comparison;
        }

        let tempTableDataSorted = results.results.filter(
          (result) =>
            result.quantity < result.min || result.quantity > result.max
        );
        tempTableDataSorted.sort(compare);

        setInventoryTableData(tempTableDataSorted);
        setFetchingInventory(false);
      })
      .catch((error) => {
        console.log(error);
        setFetchingInventory(false);
      });
  }, [selectedSite]);
  /***********************************************************************/

  useEffect(() => {
    switch (selectedGraph) {
      case 1:
        setSelectedGraphView();
        navigate("/reports", {
          state: {
            returnURL: "/dashboard",
            reportName: "Production",
            dataFromDashboard: {
              returnURL: "/dashboard",
              returnView: dashboardView,
              reportName: "Production",
              selectedSite:
                typeof selectedSite === "object" ? selectedSite : null,
            },
          },
        });
        break;
      case 2:
        setSelectedGraphView();
        navigate("/reports", {
          state: {
            returnURL: "/dashboard",
            reportName: "Production",
            dataFromDashboard: {
              returnURL: "/dashboard",
              returnView: dashboardView,
              reportName: "Production",
              selectedSite:
                typeof selectedSite === "object" ? selectedSite : null,
            },
          },
        });
        break;
      case 3:
        setSelectedGraphView(
          <EV54CanisterDetailsTable
            tableData={canisterUtilizationGraphData}
            goBackHandler={goBackHandler}
            selectedSite={!selectedSite ? "All Sites" : selectedSite}
            selectedGraph={selectedGraph}
            windowHeight={windowHeight}
          />
        );
        break;
      case 4:
        setSelectedGraphView(
          <EV54AlarmsTable
            goBackHandler={goBackHandler}
            selectedSite={!selectedSite ? "All Sites" : selectedSite}
            deviceName={selectedGraph?.deviceName}
            _controllerRef={_controllerRef}
            user={user}
            startDate={alarmsStartDate}
            stopDate={stopDate}
            windowHeight={windowHeight}
          />
        );
        break;
      case 5:
        setSelectedGraphView(
          <EV54InventoryTable
            goBackHandler={goBackHandler}
            selectedSite={!selectedSite ? "All Sites" : selectedSite}
            selectedGraph={selectedGraph}
            windowHeight={windowHeight}
            inventoryTableData={inventoryTableData}
          />
        );
        break;
      default:
        return null;
    }
  }, [selectedGraph, selectedSite]);

  const goBackHandler = () => {
    setSelectedGraph(null);
  };

  const handleGraphClick = (graphId) => {
    setSelectedGraph(graphId);
  };

  const loader = (
    <div
      className={
        smScreen
          ? stylesMobile.EV54DashboardMobile__loader
          : styles.EV54Dashboard__loader
      }
    >
      <Loader type="Rings" color="#089BAB" height={110} width={110} />
      <p>{t("fetching")}</p>
    </div>
  );

  const ScriptsByHourGraphContainer = (
    <div
      key={1}
      className={
        smScreen
          ? `${stylesMobile.EV54Dashboard__graphContainerMobile} ${stylesMobile["EV54Dashboard__graphContainerMobile--paddingBottom"]}`
          : `${styles.EV54Dashboard__graphContainerMobile} ${styles["EV54Dashboard__graphContainer--paddingBottom"]}`
      }
      onClick={!fetchingScriptsByHour ? () => handleGraphClick(1) : null}
    >
      {fetchingScriptsByHour && loader}
      <ScriptsByHourGraph
        graphData={scriptsByHourGraphData}
        benchmarkValue={scriptsByHourRollingAverage}
        graphId="scriptsByHourGraph1"
        graphTitle={t("hourlyTitle")}
        palette={["#318ce7", "#e32636"]}
        viewWidth={viewWidth}
        primarySeriesLabel={t("filledLabel")}
        secondarySeriesLabel={t("benchmarkLabel")}
      />
    </div>
  );

  const ScriptTypeGraphContainer = (
    <div
      key={2}
      className={
        smScreen
          ? `${stylesMobile.EV54Dashboard__graphContainerMobile} ${stylesMobile["EV54Dashboard__graphContainerMobile--paddingBottom"]}`
          : `${styles.EV54Dashboard__graphContainerMobile} ${styles["EV54Dashboard__graphContainer--paddingBottom"]}`
      }
      onClick={!fetchingScriptType ? () => handleGraphClick(2) : null}
    >
      {fetchingScriptType && loader}
      <ScriptTypeGraph
        graphData={scriptTypesGraphData}
        graphId="scriptTypeGraph1"
        graphTitle={t("typesTitle")}
        palette={["#318ce7", "#FC8A17", "#C0C0C0", "#ffdf00"]}
        viewWidth={viewWidth}
      />
    </div>
  );
  const CanisterUtilizationGraphContainer = (
    <div
      key={3}
      className={
        smScreen
          ? `${stylesMobile.EV54Dashboard__graphContainerMobile} ${stylesMobile["EV54Dashboard__graphContainerMobile--paddingBottom"]}`
          : `${styles.EV54Dashboard__graphContainerMobile} ${styles["EV54Dashboard__graphContainer--paddingBottom"]}`
      }
      onClick={!fetchingCanisterUtilization ? () => handleGraphClick(3) : null}
    >
      {fetchingCanisterUtilization && loader}
      <CanisterUtilizationGraph
        graphData={canisterUtilizationGraphData.slice(0, 6)}
        graphId="canisterUtilizationGraph"
        graphTitle={t("canisterTitle")}
        palette={["#318ce7", "#e32636"]}
        viewWidth={viewWidth}
        primarySeriesLabel={t("quantityLabel")}
        secondarySeriesLabel={t("averageLabel")}
      />
    </div>
  );
  const AlarmsGraphContainer = (
    <div
      key={4}
      className={
        smScreen
          ? `${stylesMobile.EV54Dashboard__graphContainerMobile} ${stylesMobile["EV54Dashboard__graphContainerMobile--paddingBottom"]}`
          : `${styles.EV54Dashboard__graphContainerMobile} ${styles["EV54Dashboard__graphContainer--paddingBottom"]}`
      }
      onClick={!fetchingAlarms ? () => handleGraphClick(4) : null}
    >
      {fetchingAlarms && loader}
      <BarGraph
        graphData={alarmsGraphData}
        graphId="alarmsGraphContainer1"
        graphTitle={t("alarmsTitle")}
        palette={["#318ce7"]}
        columnsName=""
        viewWidth={viewWidth}
      />
    </div>
  );
  const InventoryGraphContainer = (
    <div
      key={5}
      className={
        smScreen
          ? `${stylesMobile.EV54Dashboard__graphContainerMobile} ${stylesMobile["EV54Dashboard__graphContainerMobile--paddingBottom"]}`
          : `${styles.EV54Dashboard__graphContainerMobile} ${styles["EV54Dashboard__graphContainer--paddingBottom"]}`
      }
      onClick={!fetchingInventory ? () => handleGraphClick(5) : null}
    >
      {fetchingInventory && loader}
      <BarGraph
        graphData={inventoryGraphData}
        graphId="inventoryGraphContainer1"
        graphTitle={t("inventoryTitle")}
        palette={["#318ce7"]}
        columnsName=""
        viewWidth={viewWidth}
      />
    </div>
  );
  const Graphs = [
    ScriptsByHourGraphContainer,
    ScriptTypeGraphContainer,
    CanisterUtilizationGraphContainer,
    AlarmsGraphContainer,
    InventoryGraphContainer,
  ];

  return (
    <Fragment>
      {!selectedGraph && !smScreen && (
        <div
          style={{ height: viewHeight }}
          className={styles.PillSortDashboard__viewContainer}
        >
          <div
            style={{ height: viewHeight }}
            className={styles.EV54Dashboard__graphsGroup}
          >
            {Graphs}
          </div>
        </div>
      )}
      {!selectedGraph && smScreen && (
        <div className={stylesMobile.EV54Dashboard__graphsGroupMobile}>
          <EV54DashboardMobileView Graphs={Graphs} />
        </div>
      )}

      {selectedGraph && SelectedGraphView}
    </Fragment>
  );
};

export default EV54Dashboard;
