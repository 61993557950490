import React, { useEffect, useRef, useContext } from "react";
import StockLocationController from "../../../../controllers/StockLocationController";
import Button from "../../../../components/Button";
import styles from "./CreateStockedItems.module.css";
import { useTranslation } from "react-i18next";

const CreateStockedItems = ({
  secondProductScan,
  currentStatusObj,
  handleCurrentStatusObj,
  handleStockedItemObjs,
  stockedItemObjs,
  setBarcodesToBeAssociated,
  setScannedBarcodes,
  setProductScannedLot,
  setProductScannedExpDate,
  site,
  user,
  stockLocationScanned,
  setProductScanned,
  setScannedProducts,
  setStockedItems,
  setGetStockedItemsComplete,
  setStockedItemsQty,
  setInputFieldQty,
  setStockLocations,
  setSortedStockLocations,
  setStockLocationScanned,
  setProductRescanned,
  setQtyError,
  setQtyErrorArray,
  setPullStock,
  setPlaceStock,
  setScannedStockedItems,
  setScannedItemIndex,
  setScannedStockedItemsQuantities,
  setUpdatedScannedStockedItems,
  setEnforceInputFocus,
  setPreviousProductScanned,
  setProductAssociationFound,
  setProductAssociationNotFound,
  setProductNotFound,
  setProductBarcode,
  setAssociateBarcodeWithProduct,
  setProductsSearchText,
  setSelectedProductForAssoc,
  setUserCredentials,
  setNewAssociationComplete,
  setAuthorizationFailure,
  onSetErrorHandler,
  setProductRescannedLot,
  setProductRescannedExpDate,
}) => {
  const { t } = useTranslation("stock")

  const _controller = useRef(null);

  useEffect(() => {
    _controller.current = new StockLocationController(user, site);
  }, []);

  // addStockedItem
  const callback = (error, stockedItem) => {
    if (!error) {
      // console.log("Added stocked item:", stockedItem);
    } else {
      console.log("error:", error);
    }
  };

  const resetReceiveState = () => {
    handleCurrentStatusObj({
      barcode: "",
      product: "",
      lotNumber: "",
      expiration: "",
    });
    handleStockedItemObjs([]);
    setBarcodesToBeAssociated([]);
    setScannedBarcodes([]);
    setProductScannedLot("");
    setProductScannedExpDate("");
    setProductScanned("");
    setScannedProducts([]);
    setStockedItems("");
    setGetStockedItemsComplete(false);
    setStockedItemsQty(0);
    setInputFieldQty(0);
    setStockLocations([]);
    setSortedStockLocations([]);
    setStockLocationScanned("");
    setProductRescanned("");
    setQtyError("");
    setQtyErrorArray([]);
    setPullStock(false);
    setPlaceStock(false);
    setScannedStockedItems([]);
    setScannedItemIndex(0);
    setScannedStockedItemsQuantities([]);
    setUpdatedScannedStockedItems([]);
    setEnforceInputFocus(true);
    setPreviousProductScanned("");
    setProductAssociationFound(false);
    setProductAssociationNotFound(true);
    setProductNotFound(false);
    setProductBarcode("");
    setAssociateBarcodeWithProduct(false);
    setProductsSearchText("");
    setSelectedProductForAssoc("");
    setUserCredentials("");
    setNewAssociationComplete(false);
    setAuthorizationFailure(false);
    onSetErrorHandler("");
    setScannedStockedItemsQuantities([]);
    setProductRescannedLot("");
    setProductRescannedExpDate("");
  };

  const onClickHandler = () => {
    let productAssociationFound;

    const productAssociationCheck = (id) => {
      for (
        let i = 0;
        i < stockLocationScanned.productAssociations.length;
        i++
      ) {
        if (
          stockLocationScanned.productAssociations[i].product.packageId === id
        ) {
          return true;
        }
      }
      return false;
    };

    let updatedProductAssociations = [
      ...stockLocationScanned.productAssociations,
    ];
    let productsToBeAssociated = []; // with scanned stock location

    stockedItemObjs.forEach((stockedItemObj, index) => {
      if (
        !isNaN(stockedItemObj.totalQty) &&
        parseInt(stockedItemObj.totalQty) !== 0
      ) {
        const multiples =
          parseInt(stockedItemObj.totalQty) /
          stockedItemObj.product.packageQuantity;

        for (let i = 0; i < multiples; i++) {
          const stockedItem = {
            quantity: stockedItemObj.product.packageQuantity,
            account: user.account,
            site: site,
            product: stockedItemObj.product,
            stockLocation: stockLocationScanned,
            deviceCompatability:
              stockLocationScanned && stockLocationScanned.type.canDispense
                ? stockLocationScanned.type.type
                : null,
            packageDescription: stockedItemObj.product.packageDescription,
            barcode: stockedItemObj.barcode,
            expiration: stockedItemObj.expiration,
            lotNumber: stockedItemObj.lotNumber,
          };

          // console.log("stockedItem to add", stockedItem);

          _controller.current.addStockedItem(stockedItem, callback);
        }

        // Check to see if a product association is found

        const inProductsToBeAssociated = () => {
          let found = false;

          productsToBeAssociated.forEach((product) => {
            if (product.packageId === stockedItemObj.product.packageId) {
              found = true;
            }
          });
          return found;
        };

        productAssociationFound =
          inProductsToBeAssociated() ||
          productAssociationCheck(stockedItemObj.product.packageId);

        if (!productAssociationFound) {
          const newProductAssociation = {
            min: 0,
            max: 0,
            product: stockedItemObj.product,
          };

          productsToBeAssociated.push(stockedItemObj.product);

          updatedProductAssociations = [
            ...updatedProductAssociations,
            newProductAssociation,
          ];
          // create product association here
          let stockLocation = {
            ...stockLocationScanned,
          };
          // update stock location
          stockLocation.productAssociations = updatedProductAssociations;

          const callback2 = (error, stockLocation) => {
            if (!error) {
            } else {
              console.log("error", error);
            }
          };
          _controller.current.updateStockLocation(stockLocation, callback2);
        }
      }
      resetReceiveState();
    });
  };

  return (
    <div className={styles.buttonContainer}>
      <Button
        labelName={t("doneButtonLabel")}
        isPrimary={true}
        isDisabled={
          secondProductScan ||
          (!currentStatusObj.product &&
            !currentStatusObj.barcode &&
            !currentStatusObj.lotNumber &&
            !currentStatusObj.expiration)
            ? false
            : true
        }
        onClick={onClickHandler}
        minWidth={213}
      />
    </div>
  );
};

export default CreateStockedItems;
