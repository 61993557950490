import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as BackArrowMobile } from "../../../assets/back-icon-mobile.svg";
import { ReactComponent as CloseIcon } from "../../../assets/x.svg";
import Button from "../../../components/Button";
import ErrorBanner from "../../../components/ErrorBanner";
import InputCustomType from "../../../components/InputCustomType/InputCustomType";
import Spacer from "../../../components/Spacer";
import { ScreenContext } from "../../../contexts/ScreenContext";
import styles from "./AdjustQuantity.module.css";

const AdjustQuantity = ({
  pendDisOrderControllerRef,
  selectedItem,
  onCloseAdjustQuantityModal,
}) => {
  const { t, i18n } = useTranslation("pendingDispensingOrder");

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const originalQuantity =
    selectedItem && selectedItem.qtyNeeded
      ? selectedItem.qtyNeeded.toString()
      : "";

  const [quantity, setQuantity] = useState(originalQuantity);
  const [userError, setUserError] = useState("");

  const handleQuantity = (quantityEntry) => {
    setQuantity(quantityEntry);
  };

  const handleSubmit = () => {
    // Verify correct data format
    let error;
    if (!quantity) {
      error = t("missingQuantityError");
    } else if (Number(quantity) <= 0) {
      error = t("zeroQuantityError");
    }

    if (!error) {
      setUserError("");
      let modifiedDispensingOrderItem = { ...selectedItem };
      modifiedDispensingOrderItem.qtyNeeded = Number(quantity);

      pendDisOrderControllerRef.updateQuantity(
        selectedItem,
        modifiedDispensingOrderItem
      );
      onCloseAdjustQuantityModal(true);
    } else {
      setUserError(error);
    }
  };

  // en is English; es is Spanish; fr is French;
  const languageDetected = i18n.language;
  let packageIdClass;
  let defaultDrugNameClass;
  let qtyNeededClass;

  let packageId;
  let productDefaultName;

  try {
    packageId = selectedItem.product.packageId;
  } catch (tryError) {
    packageId = "";
  }

  try {
    productDefaultName = selectedItem.product.defaultName;
  } catch (tryError) {
    productDefaultName = "";
  }

  switch (languageDetected) {
    case "en":
      packageIdClass = "AdjustQuantity__basicInfo--packageIdEn";
      defaultDrugNameClass = "AdjustQuantity__basicInfo--defaultNameEn";
      qtyNeededClass = "AdjustQuantity__basicInfo--qtyNeededEn";
      break;
    case "es":
      packageIdClass = "AdjustQuantity__basicInfo--packageIdEs";
      defaultDrugNameClass = "AdjustQuantity__basicInfo--defaultNameEs";
      qtyNeededClass = "AdjustQuantity__basicInfo--qtyNeededEs";
      break;
    case "fr":
      packageIdClass = "AdjustQuantity__basicInfo--packageIdFr";
      defaultDrugNameClass = "AdjustQuantity__basicInfo--defaultNameFr";
      qtyNeededClass = "AdjustQuantity__basicInfo--qtyNeededFr";
      break;
    default:
      packageIdClass = "AdjustQuantity__basicInfo--packageIdEn";
      defaultDrugNameClass = "AdjustQuantity__basicInfo--defaultNameEn";
      qtyNeededClass = "AdjustQuantity__basicInfo--qtyNeededEn";
  }

  const goBackHandler = () => {
    // onCloseAdjustQuantityModal();
  };

  return (
    <div className={styles.AdjustQuantity__overlay}>
      <div className={styles.AdjustQuantity__view}>
        <div className={styles.AdjustQuantity__container}>
          <div className={styles.AdjustQuantity__formCloseContainer}>
            {!smScreen && (
              <CloseIcon
                fill={"#121A2D"}
                style={{ cursor: "pointer" }}
                onClick={() => onCloseAdjustQuantityModal(false)}
              />
            )}
          </div>

          {smScreen && (
            <section className={styles.AdjustQuantity__header}>
              <div
                className={styles.AdjustQuantity__backArrow}
                onClick={goBackHandler}
              >
                <BackArrowMobile
                  className={styles.AdjustQuantity__backArrowIcon}
                />
                <p className={styles.AdjustQuantity__goBackTitle}>
                  {t("goBackTitle")}
                </p>
              </div>
              <h1 className={styles.AdjustQuantity__title}>
                {t("adjustQuantityTitle")}
              </h1>
            </section>
          )}

          {!smScreen && (
            <section className={styles.AdjustQuantity__header}>
              <p className={styles.AdjustQuantity__title}>
                {t("adjustQuantityTitle")}
              </p>
            </section>
          )}
          {userError && (
            <div className={styles.AdjustQuantity__errorBannerContainer}>
              <ErrorBanner
                message={userError}
                onClose={() => setUserError("")}
              />
            </div>
          )}

          <section className={styles.AdjustQuantity__productSelectionContainer}>
            <p
              className={[
                styles["AdjustQuantity__basicInfo"],
                styles["AdjustQuantity__basicInfo--id"],
                styles[packageIdClass],
              ].join(" ")}
            >
              {`${t("originalPackageIdLabel")}: `}
              <span>{packageId}</span>
            </p>
            <p
              className={[
                styles["AdjustQuantity__basicInfo"],
                styles["AdjustQuantity__basicInfo--drugName"],
                styles[defaultDrugNameClass],
              ].join(" ")}
            >
              {smScreen ? "" : `${t("defaultDrugNameLabel")}: `}
              <span>{productDefaultName}</span>
            </p>
            <p
              className={[
                styles["AdjustQuantity__basicInfo"],
                styles["AdjustQuantity__basicInfo--qtyNeeded"],
                styles[qtyNeededClass],
              ].join(" ")}
            >
              {`${t("originalQuantityNeededLabel")}: `}
              <span>{selectedItem.qtyNeeded}</span>
            </p>
          </section>

          <section className={styles.AdjustQuantity__quantityInputSection}>
            <p className={styles.AdjustQuantity__quantityInputHeading}>
              {t("adjustQuantityInputLabel")}
            </p>

            <div className={styles.AdjustQuantity__quantityInputContainer}>
              <InputCustomType
                userInput={quantity}
                onHandleUserInput={handleQuantity}
                customType="whole number"
                enforceFocus={true}
                maxValue={999999}
                inputFields={{
                  type: "text",
                  value: quantity,
                }}
              />
            </div>
          </section>

          <section className={styles.AdjustQuantity__footer}>
            <Button
              labelName={t("cancelButtonLabel")}
              isPrimary={false}
              isDisabled={false}
              onClick={() => onCloseAdjustQuantityModal(false)}
              minWidth={smScreen ? 122 : null}
            />
            <Spacer space={20} unit={"px"} />
            <Button
              labelName={t("changeQuantityButtonLabel")}
              isPrimary={true}
              isDisabled={false}
              onClick={handleSubmit}
              minWidth={smScreen ? 122 : 213}
            />
          </section>
        </div>
      </div>
    </div>
  );
};

export default AdjustQuantity;
