import React, { useEffect, useState, Fragment } from "react";
import BarcodeGenerator from "../../../../components/BarcodeGenerator";
import { Svg, Rect } from "@react-pdf/renderer";
import GeneratePDF from "./GeneratePDF";
import styles from "./SummaryBagReportPDF.module.css";
import { useTranslation } from "react-i18next";

const PDF_SIZE_FACTOR = 0.85;

const SummaryBagReportPDF = ({
  selectedViewData,
  selectionRange,
  onShowPDFViewer,
  onSelectedBatchIndex,
  barcodeIndex,
  symbology,
  mainHeadings,
  subHeadings,
  country,
}) => {
  const { t } = useTranslation("reportViewer")

  const [SFBarcodes, setSFBarcodes] = useState([]);
  const [barcodeStringSVGS, setBarcodeStringSVGS] = useState([]);
  // const [Barcode, setBarcode] = useState(null);
  const [Barcodes, setBarcodes] = useState([]);

  // get barcodeContainerStyles
  let barcodeContainerStyles;
  let widthmm;
  let heightmm;

  switch (symbology) {
    case "Code128A":
      barcodeContainerStyles = styles.DataMatrixContainer;
      widthmm = 21.3;
      heightmm = 16;
      break;
    case "Code128B":
      barcodeContainerStyles = styles.Code128BContainer;
      widthmm = 21.3;
      heightmm = 16;
      break;
    case "Code39":
      barcodeContainerStyles = styles.Code39Container;
      widthmm = 21.3;
      heightmm = 16;
      break;
    case "DataMatrix":
      barcodeContainerStyles = styles.DataMatrixContainer;
      widthmm = 24.6;
      heightmm = 18.5;

      break;
    case "QRBarcode":
      barcodeContainerStyles = styles.DataMatrixContainer;
      widthmm = 24.6;
      heightmm = 18.5;
      break;
    default:
    // code block
  }

  let width = (1.2 * widthmm * 3.7795275591).toString() + "px";
  let height;

  if (symbology === "Code39") {
    height = (((0.8 * 24) / 32) * widthmm * 3.7795275591).toString() + "px";
  } else {
    height = (((0.62 * 24) / 32) * widthmm * 3.7795275591).toString() + "px";
  }

  useEffect(() => {
    // let start = Date.now();
    let tempSFBarcodes = [];
    selectedViewData.data.forEach((row, index) => {
      tempSFBarcodes.push(
        <BarcodeGenerator
          key={`svgBarcode-${index}`}
          id={`svgBarcode-${index}`}
          symbology={symbology}
          value={row[barcodeIndex]}
          widthmm={widthmm}
          heightmm={heightmm}
          text=" "
        />
      );
    });
    setSFBarcodes(tempSFBarcodes);
    // let timeTaken = Date.now() - start;
    // console.log(
    //   "Total time taken to generate SF barcodes : " +
    //     timeTaken +
    //     " milliseconds"
    // );
  }, [selectedViewData]);

  useEffect(() => {
    // let start = Date.now();
    if (SFBarcodes.length && !barcodeStringSVGS.length) {
      let svgs = document.querySelectorAll("[id^=svgBarcode]");
      let stringSVGS = [];
      for (let i = 0; i < svgs.length; i++) {
        if (svgs[i]) {
          stringSVGS.push(svgs[i].outerHTML);
        }
      }
      let filteredStringSVGS = stringSVGS.filter((svg) => {
        return svg.startsWith("<svg");
      });
      setBarcodeStringSVGS(filteredStringSVGS);
    }
    // let timeTaken = Date.now() - start;
    // console.log(
    //   "Total time taken to generate BarcodeStringSVGS : " +
    //     timeTaken +
    //     " milliseconds"
    // );
  }, [SFBarcodes]);

  useEffect(() => {
    // let start = Date.now();
    if (barcodeStringSVGS.length) {
      let tempBarcodes = [];
      barcodeStringSVGS.forEach((barcodeStringSVG, index) => {
        tempBarcodes.push(getBarcode(index));
      });
      setBarcodes(tempBarcodes);
    }
    // let timeTaken = Date.now() - start;
    // console.log(
    //   "Total time taken to generate Barcodes : " + timeTaken + " milliseconds"
    // );
  }, [barcodeStringSVGS]);

  const getBarcode = (index) => {
    const barcodeSVGSplitArray = barcodeStringSVGS[index]
      .split(">")
      .filter((item) => {
        return (
          !item.includes("</rect") &&
          !item.includes("<text") &&
          !item.includes("</text") &&
          !item.includes("<svg") &&
          !item.includes("</svg") &&
          item
        );
      });
    const resultArray = barcodeSVGSplitArray
      .map(function (item) {
        return item.replace("<rect", "");
      })
      .map((item) => {
        return item.replace('style="shape-rendering: crispEdges"', "").trim();
      });

    const id = 1;

    let TempBarcode = (
      <Svg
        key={id}
        id={id}
        x={0}
        top={0}
        style={{
          display: "flex",
          alignItems: "flex-start",
          maxHeight: height,
          height: height,
          width: width,
          overflow: "hidden",
          background: "transparent",
          padding: 0,
          paddingBottom: "5px",
          margin: 0,
          textAlign: "center",
          boxSizing: "border-box",
          marginLeft: "-8px",
        }}
      >
        {resultArray.map((item, index) => {
          const xValueStartIndex = item.indexOf("x=") + 3;
          const yValueStartIndex = item.indexOf("y=") + 3;
          const widthValueStartIndex = item.indexOf("width=") + 7;
          const heightValueStartIndex = item.indexOf("height=") + 8;
          const fillValueStartIndex = item.indexOf("fill=") + 6;

          const xValueEndIndex = yValueStartIndex - 6;
          const yValueEndIndex = widthValueStartIndex - 10;
          const widthValueEndIndex = heightValueStartIndex - 11;
          const heightValueEndIndex = fillValueStartIndex - 9;
          const fillValueEndIndex = item.length - 2;

          const xValue = (
            parseFloat(item.substring(xValueStartIndex, xValueEndIndex + 1)) *
            PDF_SIZE_FACTOR
          ).toString();

          const yValue = (
            parseFloat(item.substring(yValueStartIndex, yValueEndIndex + 1)) *
            PDF_SIZE_FACTOR
          ).toString();
          const widthValue = (
            parseFloat(
              item.substring(widthValueStartIndex, widthValueEndIndex + 1)
            ) * PDF_SIZE_FACTOR
          ).toString();
          const heightValue = (
            parseFloat(
              item.substring(heightValueStartIndex, heightValueEndIndex + 1)
            ) * PDF_SIZE_FACTOR
          ).toString();
          const fillValue = item.substring(
            fillValueStartIndex,
            fillValueEndIndex + 1
          );

          return (
            <Rect
              key={`${index}`}
              width={`${widthValue}`}
              height={`${heightValue}`}
              x={`${xValue}`}
              y={`${yValue}`}
              fill={`${fillValue}`}
              style={{
                padding: 0,
                margin: 0,
              }}
            ></Rect>
          );
        })}
      </Svg>
    );
    return TempBarcode;
  };

  if (!Barcodes.length) {
    return <div className={styles.domBarcodes}>{SFBarcodes}</div>;
  } else {
    // Use of setTimeout here prevents the warning: "Cannot update a component (`ReportViewer`) while rendering a different component (`SummaryBagReportPDF`)"
    setTimeout(() => {
      onShowPDFViewer(true);
    }, 0);
  }
  return (
    <GeneratePDF
      selectedViewData={selectedViewData}
      onShowPDFViewer={onShowPDFViewer}
      onSelectedBatchIndex={onSelectedBatchIndex}
      selectionRange={selectionRange}
      Barcodes={Barcodes}
      mainHeadings={mainHeadings}
      subHeadings={subHeadings}
      reportTitle={t("pdfSummaryBagTitle")}
      symbology={symbology}
      country={country}
    />
  );
};

export default SummaryBagReportPDF;
