import React from "react";
import { Modal } from "antd";
import Button from "../../components/Button";

import styles from "./TrayCheckFinishedModal.module.css";
import { useTranslation } from "react-i18next";

const TrayCheckFinishedModal = ({
  open,
  onCancel,
  onTotalCell,
  tray,
  trayRange,
}) => {
  const { t } = useTranslation("trayCheck");

  return (
    <Modal
      open={open}
      width={450}
      onCancel={onCancel}
      classNames={{
        content: styles.TrayCheckFinishedModalBackground,
        header: styles.TrayCheckFinishedModalBackground,
      }}
      title={t("checkFinishedTitle")}
      footer={
        <div className={styles.TrayCheckFinishedModalFooter}>
          <Button labelName={t("totalButtonLabel")} onClick={onTotalCell} />
          <Button labelName={t("okButtonLabel")} isPrimary onClick={onCancel} />
        </div>
      }
    >
      <div className={styles.TrayCheckFinishedModalContainer}>
        <div className={styles.TrayCheckFinishedRow}>
          <span>{t("orderLabel")}:</span>
          <span className={styles.TrayCheckFinishedData}>{tray.rfid}</span>
        </div>
        <div className={styles.TrayCheckFinishedRow}>
          <span>{t("batchNameLabel")}:</span>
          <span className={styles.TrayCheckFinishedData}>
            {tray.account.companyName}
          </span>
        </div>
        <div className={styles.TrayCheckFinishedRow}>
          <span>{t("trayIdLabel")}:</span>
          <span className={styles.TrayCheckFinishedData}>
            {tray.assignedTrayId}
          </span>
        </div>
        <div className={styles.TrayCheckFinishedRow}>
          <span>{t("traySequenceLabel")}:</span>
          <span
            className={styles.TrayCheckFinishedData}
          >{`${tray.rangeId} ${t("ofLabel")} ${trayRange}`}</span>
        </div>
        <div className={styles.TrayCheckFinishedRow}>
          <span>{t("trayStatusLabel")}:</span>
          <span className={styles.TrayCheckFinishedData}>
            {tray.slots.some((slot) =>
              slot.drugs.some(
                (drug) => drug.approvalType === "corrected approved"
              )
            )
              ? t("correctedApprovedLabel")
              : t("approvedLabel")}
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default TrayCheckFinishedModal;
