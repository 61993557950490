import React, { useEffect, useState, useContext, useRef } from "react";
import styles from "./Devices.module.css";
//import AlarmDateTime from "../../components/AlarmDateTime/AlarmDateTime";
import TabBar from "../../components/TabBar";
import RoleCheckService from "../../services/RoleCheckService";
import ErrorBanner from "../../components/ErrorBanner";
import StockLocationService from "../../services/StockLocationService";
import Accordion from "../../components/Accordion/Accordion";
import EV54Device from "./EV54Device/EV54Device";
import TruPakDevice from "./TruPakDevice/TruPakDevice";
import VBMDevice from "./VBMDevice/VBMDevice";
import { useMediaQuery } from "react-responsive";
import { ScreenContext } from "../../contexts/ScreenContext";
import { InventoryContext } from "../../contexts/InventoryContext";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Devices = ({ user, site, stockLocation, dataFromDashboard }) => {
  const { t } = useTranslation("devices")

  const { state } = useLocation();

  const [tabs, setTabs] = useState([]);
  const [error, setError] = useState("");
  const [devices, setDevices] = useState([]);
  const [userHasASite, setUserHasASite] = useState(false);
  const [hideNoSiteMessage, setHideNoSiteMessage] = useState(true);
  const [resetTabSelectionToZero, setResetTabSelectionToZero] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);

  const { selectedSite, updateSelectedSite, updateSelectedDevice } =
    useContext(InventoryContext);
  const { handleShowSmScreenHeader, smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);
  const mounted = useRef(false);

  useEffect(() => {
    if (state && state.device && devices.length > 0) {
      const foundDevice = devices.find((d) => d.name === state.device);
      if (foundDevice) {
        updateSelectedDevice(foundDevice);
        window.history.replaceState({ ...state, device: null }, "");
      }
    }
  }, [state, devices]);

  // If mobile view, show mobile header (navbar hamburger, alarm bell, user profile photo)
  useEffect(() => {
    smScreen && handleShowSmScreenHeader(true);
  }, [smScreen]);

  // Get the pharmacy sites that this user has access rights to
  useEffect(() => {
    mounted.current = true;
    RoleCheckService.getAccessibleSites(user)
      .then((sites) => {
        if (mounted.current) {
          // tab = {index, text}
          sites.sort((a, b) => {
            if (a.name.toUpperCase() < b.name.toUpperCase()) {
              return -1;
            }
            if (b.name.toUpperCase() < a.name.toUpperCase()) {
              return 1;
            }
            return 0;
          });
          if (sites.length > 0) {
            setUserHasASite(true);
            let tabIndex = 0;
            const tabMap = sites.map((site, index) => {
              if (selectedSite && site._id === selectedSite._id) {
                tabIndex = index;
              }
              return {
                index: index,
                site: site,
                text: site.name,
              };
            });
            setTabs(tabMap);
            setSelectedTab(tabIndex);
            onSelectedTab(0, tabMap[tabIndex]);
          } else {
            setHideNoSiteMessage(false);
          }
        }
      })
      .catch((error) => {
        setError(error);
      });

    return () => {
      mounted.current = false;
    };
  }, [user, stockLocation]);

  const onSelectedTab = (index, tab) => {
    StockLocationService.getDispensableLocations(user, tab.site)
      .then((stockLocations) => {
        setDevices(stockLocations);
        updateSelectedSite(tab.site);
      })
      .catch((error) => {
        setError(error);
      });
  };

  let Header = (
    <>
      {!smScreen && (
        <div className={styles.Devices__headerContainer}>
          <h1 className={styles.Devices__header}>{t("title")}</h1>
          {/*<AlarmDateTime />*/}
        </div>
      )}
      {smScreen && <h1 className={styles.Devices__title}>{t("title")}</h1>}
      {error && (
        <>
          <div className={styles.Devices__row}>
            <ErrorBanner message={error} onClose={() => setError("")} />
          </div>
          <br />
        </>
      )}
    </>
  );

  if (userHasASite) {
    return (
      <div className={styles.Devices__container}>
        {Header}
        <div className={styles.Devices__row} key={`tab-${selectedTab}`}>
          <TabBar
            onSelectedTab={(index, tab) => onSelectedTab(index, tab)}
            tabs={tabs}
            site={site}
            resetTabSelectionToZero={resetTabSelectionToZero}
            dynamicSelectedIndex={selectedTab}
          />
        </div>
        <div className={styles.Devices__devices}>
          {devices.map((device, index) => {
            if (device.type.description === "EV-54") {
              return (
                <EV54Device
                  key={device._id}
                  user={user}
                  device={device}
                  dataFromDashboard={dataFromDashboard}
                />
              );
            } else if (device.type.description === "VBM") {
              return <><VBMDevice key={device._id} user={user} device={device} /><br/></>;
            } else if (device.type.description === "Pouch Packager") {
              return (
                <TruPakDevice
                  key={device._id}
                  user={user}
                  site={selectedSite}
                  device={device}
                  dataFromDashboard={dataFromDashboard}
                />
              );
            }
          })}
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.Devices__container}>
        {Header}

        {!hideNoSiteMessage && (
          <div className={styles.Devices__noSitesMessage}>
            <p>{t("noSiteAssigned")}</p>
          </div>
        )}
      </div>
    );
  }
};

export default Devices;
