import React, { useState, useEffect, useContext } from "react";
import TopicCard from "./TopicCard";
import HelpVideos from "./Subviews/HelpVideos/HelpVideos";
import QuickGuides from "./Subviews/QuickGuides";
import OnlineTraining from "./Subviews/OnlineTraining";
import Support from "./Subviews/Support";
import Onboarding from "./Subviews/Onboarding";
import Announcements from "./Subviews/Announcements";
import SFDropDownList from "../../components/SFDropDownList/SFDropDownList";
import SearchBar from "../../components/SearchBar";
import { useMediaQuery } from "react-responsive";
import { ScreenContext } from "../../contexts/ScreenContext";
import styles from "./Help.module.css";
import { useTranslation } from "react-i18next";

const Help = ({ user, navToggle }) => {
  const { t } = useTranslation("help");

  const [pageTitle, setPageTitle] = useState(t("title"));
  const [SelectedView, setSelectedView] = useState("");
  const [selectedViewIndex, setSelectedViewIndex] = useState(-1);
  const [showHeaderUtilities, setShowHeaderUtilities] = useState(true);
  const [selectedDevice, setSelectedDevice] = useState("All Devices");
  const [filterText, setFilterText] = useState("");

  const { handleShowSmScreenHeader, smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const cardTitles = [
    t("helpTitle"),
    t("quickTitle"),
    t("onlineTitle"),
    t("supportTitle"),
    t("onboardingTitle"),
    t("announcementsTitle"),
  ];

  const dropdownList = [
    { Value: 1, Item: "All Devices", displayName: t("allDevicesValue") },
    { Value: 2, Item: "TruCard", displayName: "TruCard" },
    { Value: 3, Item: "TruCheck", displayName: "TruCheck" },
    { Value: 4, Item: "TruPak", displayName: "TruPak" },
    { Value: 5, Item: "TruScript", displayName: "TruScript" },
    { Value: 6, Item: "TruSort", displayName: "TruSort" },
  ];

  const handleShowHeaderUtilities = (boolVal) => {
    setShowHeaderUtilities(boolVal);
  };

  // If mobile view, show mobile header (navbar hamburger, alarm bell, user profile photo)
  useEffect(() => {
    smScreen && handleShowSmScreenHeader(true);
  }, [smScreen, handleShowSmScreenHeader]);

  useEffect(() => {
    setPageTitle(t("title"));
    setSelectedView("");
    setSelectedViewIndex(-1);
    setShowHeaderUtilities(false);
    setSelectedDevice("All Devices");
    setFilterText("");
  }, [navToggle]);

  useEffect(() => {
    switch (selectedViewIndex) {
      case 0:
        setSelectedView(
          <HelpVideos
            selectedDevice={selectedDevice}
            filterText={filterText}
            onPageTitle={handlePageTitle}
            onGoBackToHelpView={handleGoBackToHelpView}
            onShowHeaderUtilities={handleShowHeaderUtilities}
          />
        );
        setShowHeaderUtilities(true);
        break;
      case 1:
        setSelectedView(
          <QuickGuides
            selectedDevice={selectedDevice}
            filterText={filterText}
            onGoBackToHelpView={handleGoBackToHelpView}
          />
        );
        setShowHeaderUtilities(true);
        break;
      case 2:
        setSelectedView(
          <OnlineTraining
            user={user}
            selectedDevice={selectedDevice}
            filterText={filterText}
            onPageTitle={handlePageTitle}
            onGoBackToHelpView={handleGoBackToHelpView}
          />
        );
        setShowHeaderUtilities(true);
        break;
      case 3:
        setSelectedView(
          <Support
            user={user}
            selectedDevice={selectedDevice}
            filterText={filterText}
            onPageTitle={handlePageTitle}
            onGoBackToHelpView={handleGoBackToHelpView}
          />
        );
        setShowHeaderUtilities(true);
        break;
      case 4:
        setSelectedView(
          <Onboarding
            user={user}
            selectedDevice={selectedDevice}
            filterText={filterText}
            onPageTitle={handlePageTitle}
            onGoBackToHelpView={handleGoBackToHelpView}
          />
        );
        setShowHeaderUtilities(true);
        break;
      case 5:
        setSelectedView(
          <Announcements
            user={user}
            selectedDevice={selectedDevice}
            filterText={filterText}
            onPageTitle={handlePageTitle}
            onGoBackToHelpView={handleGoBackToHelpView}
          />
        );
        setShowHeaderUtilities(true);
        break;
      default:
        setSelectedView("");
        setShowHeaderUtilities(false);
    }
  }, [selectedDevice, selectedViewIndex, filterText]);

  const handleSelectedDevice = (deviceName, deviceValue) => {
    setSelectedDevice(deviceName);
  };

  const handlePageTitle = (title) => {
    setPageTitle(title);
  };

  const handleHelpOutlet = (viewIndex, cardTitle) => {
    setPageTitle(cardTitle);
    setSelectedViewIndex(viewIndex);
  };

  const handleGoBackToHelpView = () => {
    setPageTitle(t("title"));
    setSelectedView("");
    setSelectedViewIndex(-1);
    setShowHeaderUtilities(false);
    setSelectedDevice("All Devices");
    setFilterText("");
  };

  const handleSearch = (searchText) => {
    setFilterText(searchText.toUpperCase());
  };

  const LargeHeaderUtilities = (
    <div className={styles.help__headerUtilities}>
      <div className={styles.help__searchBarContainer}>
        <SearchBar placeholder={t("searchPlaceholder")} onSearch={handleSearch} focus={true} />
      </div>
      <div className={styles.help__dropdownContainer}>
        <SFDropDownList
          disabled={true}
          id={"Dashboard2_1"}
          label={t("selectDeviceLabel")}
          defaultValue={selectedDevice || "All Devices"}
          placeHolder={t("selectDevicePlaceholder")}
          dropDownList={dropdownList}
          onSelection={handleSelectedDevice}
          display={"displayName"}
        />
      </div>
    </div>
  );

  const LargeView = (
    <div className={styles.help__pageContainer}>
      {
        <header>
          <h1 className={styles.help__pageTitle}>{pageTitle}</h1>
          {showHeaderUtilities && LargeHeaderUtilities}
        </header>
      }
      {!SelectedView ? (
        <ul className={styles.help__helpList}>
          {cardTitles.map((title, index) => (
            <li key={title}>
              <TopicCard
                index={index}
                cardTitle={title}
                onHelpOutlet={handleHelpOutlet}
              />
            </li>
          ))}
        </ul>
      ) : (
        SelectedView
      )}
    </div>
  );

  const MobileHeaderUtilities = (
    <div className={styles.help__headerUtilities}>
      <div className={styles.help__dropdownContainer}>
        <SFDropDownList
          disabled={true}
          id={"Dashboard2_1"}
          label={null}
          defaultValue={selectedDevice || "All Devices"}
          placeHolder={t("selectDevicePlaceholder")}
          dropDownList={dropdownList}
          onSelection={handleSelectedDevice}
          display={"displayName"}
        />
      </div>
    </div>
  );

  const MobileView = (
    <div className={styles.help__pageContainer}>
      <header>
        {!showHeaderUtilities && (
          <h1 className={styles.help__pageTitle}>{pageTitle}</h1>
        )}
        {showHeaderUtilities && MobileHeaderUtilities && (
          <div className={styles.help__searchBarContainer}>
            <SearchBar
              placeholder={t("searchPlaceholder")}
              onSearch={handleSearch}
              focus={true}
            />
          </div>
        )}
      </header>
      {showHeaderUtilities && MobileHeaderUtilities}
      {!SelectedView ? (
        <ul className={styles.help__helpList}>
          {cardTitles.map((title, index) => (
            <li key={title}>
              <TopicCard
                index={index}
                cardTitle={title}
                onHelpOutlet={handleHelpOutlet}
              />
            </li>
          ))}
        </ul>
      ) : (
        SelectedView
      )}
    </div>
  );

  return smScreen ? MobileView : LargeView;
};

export default Help;
