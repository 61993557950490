import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import style from "./DatePickerComponent.module.css";
import i18n from "../../i18nextConf";
import { enUS, frCA, es } from "date-fns/locale";

const DatePickerComponent = ({
  startDate,
  onChange,
  label,
  alternateFormat = false,
  showMonthYearPicker = false,
  country = null,
  showTimePicker = false,
}) => {
  const [currentDate, setCurrentDate] = useState(startDate);

  const locale =
    {
      fr: frCA,
      en: enUS,
      es: es,
    }[i18n.language] || enUS;

  const dateFormat =
    {
      Canada: "dd/MM/yyyy",
      "United States": "MM/dd/yyyy",
    }[country] || "MM/dd/yyyy";

  const handleDateTimeChange = (date) => {
    setCurrentDate(date);
    if (onChange) {
      onChange(date);
    }
  };

  const CustomTimeInput = ({ date, value, onChange }) => (
    <input
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      style={{ border: "solid 1px pink" }}
    />
  );

  return (
    <div
      className={
        !alternateFormat
          ? style.DatePickerComponent__wrapper
          : `${style.DatePickerComponent__wrapper} ${style["DatePickerComponent__wrapper--alternateFormat"]}`
      }
    >
      {!alternateFormat && <label>{label}</label>}
      {showTimePicker ? (
        <DatePicker
          selected={currentDate}
          onChange={handleDateTimeChange}
          preventOpenOnFocus={true}
          showMonthYearPicker={showMonthYearPicker}
          locale={locale}
          dateFormat={dateFormat + " HH:mm"}
          timeFormat="HH:mm"
          timeIntervals={15}
          showTimeSelect
          customTimeInput={<CustomTimeInput />}
          timeCaption={i18n.t("timeLabel", {ns: "datePicker"})}
        />
      ) : (
        <DatePicker
          preventOpenOnFocus={true}
          selected={startDate}
          onChange={(date) => onChange(date)}
          showMonthYearPicker={showMonthYearPicker}
          locale={locale}
          dateFormat={dateFormat}
        />
      )}
    </div>
  );
};

export default DatePickerComponent;