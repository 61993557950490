import React, { useState, useEffect, useContext } from "react";
import chevronDownIcon from "../assets/cheveron-down.svg";
import CheckboxMultiLine from "./CheckboxMultiLine";
import Spacer from "./Spacer";
import roleDeleteButton from "../assets/role-delete-btn.svg";
import { useKeyPress } from "./InputHooks";
import { ScreenContext } from "../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./DropdownMultiSelectInput.module.css";

const DropdownMultiSelectInput = ({
  labelName,
  listElements, // array of {type: ,description: , additionalInfo:}
  selectedItems,
  validationText,
  onAddItem,
  onRemoveItem,
  width,
  height,
  display,
}) => {
  const [open, setOpen] = useState(false);

  const elements = document.getElementsByClassName("expandingBox");
  const rect = elements.length > 0 ? elements[0].getBoundingClientRect() : null;

  const escapePressed = useKeyPress("Escape");

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  useEffect(() => {
    if (escapePressed) {
      setOpen(false);
    }
  }, [escapePressed]);

  const listItemContainerStyle = {
    display: "flex",
    borderBottom: "1px solid #E7E7E8",
    marginLeft: "20px",
    marginRight: "20px",
    width: "90%",
  };

  const controlGroupStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: selectedItems.length > 0 ? "auto" : "38px",
    width: smScreen ? "auto" : "100%",
    borderBottom: "1px solid var(--black)",
    padding: "3px",
  };

  const handleSelection = (selectedItem, isOn) => {
    if (isOn) {
      onAddItem(selectedItem);
    } else {
      onRemoveItem(selectedItem);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideThisComponentClick);

    return () => {
      document.removeEventListener("click", handleOutsideThisComponentClick);
    };
  });

  const handleOutsideThisComponentClick = () => {
    // This function only runs when an area outside of this component is clicked due to handleThisCompoentClick (below).
    setOpen(false);
  };

  const handleInsideThisComponentClick = (event) => {
    // Stops the mouse-click event from bubbling up; this prevents handleClick from getting called unless a click takes place outside of this component.
    event.stopPropagation();
  };

  const handleKeyUp = (event) => {
    const code = event.keyCode || event.which;
    if (code === 9) {
      setOpen(false);
    }
  };

  return (
    <div
      className={styles.controlGroup}
      onClick={handleInsideThisComponentClick}
      onKeyUp={handleKeyUp}
    >
      <label className={styles.controlLabel}>{labelName}</label>
      <div
        className={styles.controlGroupStyle}
        style={{
          height: selectedItems.length > 0 ? "auto" : "38px",
          width: smScreen ? "auto" : "100%",
        }}
      >
        <div
          className={styles.expandingBox}
          style={{ width: width || "400px" }}
        >
          {selectedItems.length > 0 &&
            selectedItems.map((selectedItem, index) => {
              let description = selectedItem[display] || selectedItem.description;

              // convert old device name to new device name
              if (
                description.toUpperCase() === "EV-54" ||
                description.toUpperCase() === "EV54"
              ) {
                description = "TruScript";
              } else if (description.toUpperCase() === "PILLSORT") {
                description = "TruSort";
              } else if (description.toUpperCase() === "VBM") {
                description = "TruCard";
              } else if (description.toUpperCase() === "PROUD") {
                description = "TruPak";
              } else if (description.toUpperCase() === "MDM") {
                description = "TruCheck";
              }
              return (
                <div key={index}>
                  <div className={styles.itemStyle}>
                    <p className={styles.itemTextStyle}>{description}</p>
                    <button
                      className={styles.itemDeleteButtonStyle}
                      onClick={() => onRemoveItem(selectedItem)}
                    >
                      <img src={roleDeleteButton} alt={"role delete button"} />
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
        <button
          className={`${styles.selectButton} controlButton`}
          onClick={() => setOpen(!open)}
        >
          <img src={chevronDownIcon} alt={"dropdown button"} />
        </button>
      </div>
      {open && (
        <div
          className={styles.popupStyle}
          style={{
            maxHeight: height || "380px",
            width: smScreen ? "auto" : "100%",
            top: rect?.bottom || 0,
            left: rect?.left || 0,
            right: rect?.right || 0,
          }}
        >
          {listElements.length > 0 &&
            listElements.map((listElement, index) => {
              return (
                <div key={index}>
                  <Spacer space={20} unit={"px"} />
                  <section style={listItemContainerStyle}>
                    <CheckboxMultiLine
                      isOn={selectedItems.some(
                        (o) => o.type === listElement.type
                      )}
                      labelName={listElement[display] || listElement.description}
                      description={listElement.additionalInfo}
                      onCheck={(isOn) => {
                        handleSelection(listElement, isOn);
                      }}
                    />
                  </section>
                </div>
              );
            })}
        </div>
      )}

      {validationText && (
        <label className={"controlValidationLabel"}>{validationText}</label>
      )}
    </div>
  );
};

export default DropdownMultiSelectInput;
