// for SyncFusion dropdown
import i18n from "../../../i18nextConf";

const getCanisterDataMappingDropdownList = () => {
  return [
    {
      Item: "Default Value",
      Value: "",
      displayName: i18n.t("Default Value", {ns: "canisterData"}),
    },
    {
      Item: "Barcode",
      Value: "stockLocation.barcode",
      displayName: i18n.t("Barcode", {ns: "canisterData"}),
    },
    {
      Item: "Control Level",
      Value: "productAssociations[0].product.controlLevel.type",
      displayName: i18n.t("Control Level", {ns: "canisterData"}),
    },
    {
      Item: "Description",
      Value: "productAssociations[0].product.comments",
      displayName: i18n.t("Description", {ns: "canisterData"}),
    },
    {
      Item: "Dosage Form",
      Value: "productAssociations[0].product.dosageForm.type",
      displayName: i18n.t("Dosage Form", {ns: "canisterData"}),
    },
    {
      Item: "Legend",
      Value: "productAssociations[0].product.legend",
      displayName: i18n.t("Legend", {ns: "canisterData"}),
    },
    {
      Item: "Manufacturer Abbreviation",
      Value: "productAssociations[0].product.abbrName",
      displayName: i18n.t("Manufacturer Abbreviation", {ns: "canisterData"}),
    },
    {
      Item: "Manufacturer Name",
      Value: "productAssociations[0].product.manufacturer.fullName",
      displayName: i18n.t("Manufacturer Name", {ns: "canisterData"}),
    },
    {
      Item: "Motor Base",
      Value: "stockLocation.devicePosition",
      displayName: i18n.t("Motor Base", {ns: "canisterData"}),
    },
    {
      Item: "Name",
      Value: "stockLocation.name",
      displayName: i18n.t("Name", {ns: "canisterData"}),
    },
    {
      Item: "Package Id",
      Value: "productAssociations[0].product.packageId",
      displayName: i18n.t("Package Id", {ns: "canisterData"}),
    },
    {
      Item: "Primary Name",
      Value: "productAssociations[0].product.primaryName",
      displayName: i18n.t("Primary Name", {ns: "canisterData"}),
    },
    {
      Item: "Product Id",
      Value: "productAssociations[0].product.productId",
      displayName: i18n.t("Product Id", {ns: "canisterData"}),
    },
    {
      Item: "Secondary Name",
      Value: "productAssociations[0].product.secondaryName",
      displayName: i18n.t("Secondary Name", {ns: "canisterData"}),
    },
    {
      Item: "Strength",
      Value: "productAssociations[0].product.strength",
      displayName: i18n.t("Strength", {ns: "canisterData"}),
    },
    {
      Item: "TallMan Name",
      Value: "productAssociations[0].product.tallManName",
      displayName: i18n.t("TallMan Name", {ns: "canisterData"}),
    },
    {
      Item: "Tertiary Name",
      Value: "productAssociations[0].product.tertiaryName",
      displayName: i18n.t("Tertiary Name", {ns: "canisterData"}),
    },
  ].sort((a, b) => a.displayName.localeCompare(b.displayName));
};

export default getCanisterDataMappingDropdownList;
