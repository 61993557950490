import React from "react";
import styles from "./PickListMainHeadingByProduct.module.css";
import { useTranslation } from "react-i18next";

const PickListMainHeadingByProduct = () => {
  const { t } = useTranslation("pickList");

  return (
    <div className={styles.pickList__mainHeading}>
      <p
        className={[
          styles["pickList__mainColumnName"],
          styles["pickList__mainColumnName--item1"],
        ].join(" ")}
      >
        {t("packageIdCol")}
      </p>
      <p
        className={[
          styles["pickList__mainColumnName"],
          styles["pickList__mainColumnName--item2"],
        ].join(" ")}
      >
        {t("productDescriptionCol")}
      </p>
      <p
        className={[
          styles["pickList__mainColumnName"],
          styles["pickList__mainColumnName--item3"],
        ].join(" ")}
      >
        {t("lotCol")}
      </p>
      <p
        className={[
          styles["pickList__mainColumnName"],
          styles["pickList__mainColumnName--item4"],
        ].join(" ")}
      >
        {t("expCol")}
      </p>
      <p
        className={[
          styles["pickList__mainColumnName"],
          styles["pickList__mainColumnName--item5"],
        ].join(" ")}
      >
        {t("needCol")}
      </p>
    </div>
  );
};

export default PickListMainHeadingByProduct;
