import React, { useState, useEffect, useRef, useContext } from "react";
import stylesAll from "./view.module.css";
import InventoryTransferPullLocationViewController from "./InventoryTransferPullLocationViewController";
import StockLocationsController from "../../../controllers/StockLocationsController";
import InventoryTransferPullProductView from "./InventoryTransferPullProductView";
import { isCanister } from "../../../functions/utilities.js";
import ErrorBanner from "../../../components/ErrorBanner";
import Header from "../commonUI/Header";
import Modal from "../commonUI/ModalTransparent";
import { ScreenContext } from "../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

const InventoryTransferPullLocationView = ({
  onStateBack,
  user,
  site,
  onHideModal,
  onInputValue,
}) => {
  const { t } = useTranslation("stock");

  const [scannedStockLocation, setScannedStockLocation] = useState(null);
  const [scannedStockLocationReady, setScannedStockLocationReady] =
    useState(false);
  const [unfilteredStockedItems, setUnfilteredStockedItems] = useState([]);
  const [activateSmartDetermination, setActivateSmartDetermination] =
    useState(false);

  const [smartProduct, setSmartProduct] = useState(null);
  const [smartCanister, setSmartCanister] = useState(false);

  const [activateErrorModal, setActivateErrorModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const _controller = useRef(null);
  const _stockLocationsController = useRef(null);

  const stateBackHandler = () => {
    setScannedStockLocation(null);
    setScannedStockLocationReady(false);
    setUnfilteredStockedItems([]);
    setSmartProduct(null);
    setSmartCanister(false);
  };

  const stateBackHandlerDone = () => {
    setScannedStockLocation(null);
    setScannedStockLocationReady(false);
    setUnfilteredStockedItems([]);
    setActivateSmartDetermination(false);
    setSmartProduct(null);
    setSmartCanister(false);
    setActivateErrorModal(false);
    setErrorMsg("");
  };

  const backArrowHandler = () => {
    onStateBack("");
    setScannedStockLocation(null);
    setScannedStockLocationReady(false);
    setUnfilteredStockedItems([]);
    setActivateSmartDetermination(false);
    setSmartProduct(null);
    setSmartCanister(false);
    setActivateErrorModal(false);
    setErrorMsg("");
  };

  const handleCloseErrorModal = () => {
    setErrorMsg("");
    setActivateErrorModal(false);
  };

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  useEffect(() => {
    _controller.current = new InventoryTransferPullLocationViewController(
      user,
      site
    );
    _stockLocationsController.current = new StockLocationsController(
      user,
      site
    );
  }, []);

  useEffect(() => {
    if (scannedStockLocation) {
      const callback = (error, stockedItems) => {
        const isASmartCanister = isCanister(scannedStockLocation);
        if (
          !error &&
          stockedItems &&
          (isASmartCanister || stockedItems.length >= 1)
        ) {
          setUnfilteredStockedItems(stockedItems);
          setScannedStockLocationReady(true);

          if (isASmartCanister) {
            if (stockedItems && stockedItems.length > 0) {
              setSmartProduct(stockedItems[0].product);
            } else {
              setSmartProduct(null);
            }
            setSmartCanister(true);
          }
        } else if (stockedItems.length === 0) {
          setErrorMsg(
            t("noStockedItemError")
          );
          setActivateErrorModal(true);
        } else {
          console.log("error:", error);
        }
      };
      _controller.current.getStockedItems(scannedStockLocation, callback);
    }
  }, [scannedStockLocation]);

  const inputKeyUpHandler = (e) => {
    if (e.which === 13) {
      let barcode = e.target.value;
      const callback = (error, stockLocation) => {
        if (!error && stockLocation) {
          if (stockLocation.isEnabled) {
            setScannedStockLocation(stockLocation);
          } else {
            setErrorMsg(t("disabledStockLocationError"));
            setActivateErrorModal(true);
          }
        } else {
          setErrorMsg(t("noStockLocationError"));
          setActivateErrorModal(true);
          console.log("error:", error);
        }
      };
      _controller.current.getStockLocationByBarcode(barcode, callback);
    }
  };

  const ErrorModal = () => {
    return (
      <div className={stylesAll.errorBannerContainer}>
        <ErrorBanner message={errorMsg} onClose={handleCloseErrorModal} />
      </div>
    );
  };

  if (!scannedStockLocationReady) {
    return (
      <div
        className={
          activateSmartDetermination
            ? stylesAll["container--showBody"]
            : stylesAll.container
        }
      >
        {activateErrorModal && <ErrorModal />}
        {activateErrorModal && <Modal />}
        <Header
          headerTitle={t("pullTitle")}
          backArrowHandler={backArrowHandler}
          useInput={activateSmartDetermination ? false : true}
          showFullBottomBorderRadius={true}
          onHideModal={onHideModal}
          inputFields={{
            onKeyUp: inputKeyUpHandler,
            placeholder: smScreen
              ? t("scanPullPlaceholderMobile")
              : t("scanPullPlaceholder"),
            type: "text",
            disabled: `${errorMsg}`,
          }}
          inputExtras={{
            enforceFocus: true,
          }}
          onInputValue={onInputValue}
        />
      </div>
    );
  } else {
    return (
      <InventoryTransferPullProductView
        onStateBack={stateBackHandler}
        onStateBackDone={stateBackHandlerDone}
        user={user}
        site={site}
        unfilteredStockedItems={unfilteredStockedItems}
        scannedStockLocation={scannedStockLocation}
        onHideModal={onHideModal}
        smartProduct={smartProduct}
        smartCanister={smartCanister}
      />
    );
  }
};

export default InventoryTransferPullLocationView;
