import React, { Fragment, useEffect, useState, useContext } from "react";
import ErrorBanner from "../../../components/ErrorBanner";
import Loader from "react-loader-spinner";
import { DialogComponent } from "@syncfusion/ej2-react-popups";

// mobile
import { capitalize } from "../../../functions/utilities";
import Checkbox from "../../../components/Checkbox";
import { ReactComponent as BackArrowMobile } from "../../../assets/back-icon-mobile.svg";
import { ScreenContext } from "../../../contexts/ScreenContext";
import styles from "./DispensingOrdersMobileView.module.css";
import { useTranslation } from "react-i18next";

const DispensingOrdersMobileView = ({
  sites,
  error,
  onError,
  getSiteHeader,
  dialogRef,
  dispensingOrderQueueHeaderGroup,
  getPendingColumn,
  getRoutedColumn,
  getPackagedColumn,
  getPackagingMethodWidget,
  getDeviceCoverageWidget,
  productFieldControllerRef,
  selectedSiteIndex,
  fetchingPending,
  pendingDispensingOrders,
  pendingDOSelection,
  onPendingDOSelection,
  selectAllPendingOrders,
  onSelectAllPendingOrders,
  orderDetailsDialog,
  onOrderDetailsDialog,
  showDeviceRouteSelector,
  getDeviceRouteSelector,
  showGetDeviceModal,
  selectDeviceComponent,
  showPicklistModal,
  getPickList,
}) => {
  const { t } = useTranslation("dispensingOrders")

  const { handleShowSmScreenHeader } = useContext(ScreenContext);
  const [siteIsSelected, setSiteIsSelected] = useState(false);
  const [activeMobileScreen, setActiveMobileScreen] = useState({
    index: 0,
    title: [t("selectPharmacyHeader"), t("pendingHeader"), t("routedHeader"), t("packagedHeader"), t("verifiedHeader")],
  });

  useEffect(() => {
    handleShowSmScreenHeader(true);
  }, [showPicklistModal]);

  useEffect(() => {
    if (activeMobileScreen.index === 0) {
      setSiteIsSelected(false);
    }
  }, [activeMobileScreen.index]);

  const handleVerifySelectionClicked = () => {
    setSiteIsSelected(true);
    handleIncrementActiveScreenIndex();
  };

  const handleIncrementActiveScreenIndex = () => {
    setActiveMobileScreen((prevState) => {
      let newIndex;
      if (prevState.index < prevState.title.length - 1) {
        newIndex = prevState.index + 1;
        return { ...prevState, index: newIndex };
      } else {
        return { ...prevState, index: 1 };
      }
    });
  };

  const handleDecrementActiveScreenIndex = () => {
    setActiveMobileScreen((prevState) => {
      let newIndex;
      if (prevState.index > 1) {
        newIndex = prevState.index - 1;
        return { ...prevState, index: newIndex };
      } else {
        return { ...prevState, index: 4 };
      }
    });
  };

  const handleTitleClick = () => {
    setActiveMobileScreen({
      index: 0,
      title: [t("selectPharmacyHeader"), t("pendingHeader"), t("routedHeader"), t("packagedHeader"), t("verifiedHeader")],
    });
    setSiteIsSelected(false);
  };

  // PAGE RENDERING
  if (showDeviceRouteSelector) {
    return getDeviceRouteSelector();
  } else if (showGetDeviceModal) {
    return selectDeviceComponent();
  } else if (showPicklistModal) {
    return getPickList();
  }

  return (
    <Fragment>
      <div className={styles.view}>
        <div className={styles.header}>
          {
            <h1 className={styles.title} onClick={handleTitleClick}>
              {t("title")}
              {sites.length > 0 &&
                (selectedSiteIndex || selectedSiteIndex === 0) &&
                activeMobileScreen.index !== 0 && (
                  <span>
                    &nbsp;&ndash;&nbsp;
                    {capitalize(sites[selectedSiteIndex].name)}
                  </span>
                )}
            </h1>
          }
        </div>

        {error && (
          <div className={styles.errorBannerContainer}>
            <ErrorBanner message={error} onClose={() => onError(null)} />
          </div>
        )}

        <main id="main" className={styles.main}>
          {!siteIsSelected && (
            <h1 className={styles.selectPharmacy}>{t("selectPharmacyHeader")}</h1>
          )}
          <div className={!siteIsSelected ? styles.sites : styles.noDisplay}>
            {sites.map((site, index) => { 
              return (
                <div key={site._id} onClick={handleVerifySelectionClicked}>
                  {getSiteHeader(site, index)}
                </div>
              );
            })}
          </div>

          {siteIsSelected && (
            <div className={styles.processModeHeader}>
              <BackArrowMobile
                className={styles.backArrowMobile}
                onClick={handleDecrementActiveScreenIndex}
              />

              <div
                style={{
                  display: "flex",
                  justifyContent: "flex start",
                  alignItems: "center",
                }}
              >
                {activeMobileScreen.index === 1 && (
                  <Checkbox
                    labelName={""}
                    isOn={selectAllPendingOrders}
                    onCheck={(isOn) => {
                      if (!isOn) {
                        onPendingDOSelection([]);
                      } else {
                        let arr = [];
                        for (
                          let i = 0;
                          i < pendingDispensingOrders.length;
                          i++
                        ) {
                          arr.push(pendingDispensingOrders[i]);
                        }
                        onPendingDOSelection(arr);
                      }
                      onSelectAllPendingOrders(isOn);
                    }}
                  />
                )}

                <p>
                  {activeMobileScreen &&
                    activeMobileScreen.title[activeMobileScreen.index]}
                </p>
                {fetchingPending && (
                  <Loader type="Rings" color="#089BAB" height={50} width={50} />
                )}
              </div>

              <BackArrowMobile
                className={[
                  styles["rotate180Degrees"],
                  styles["backArrowMobile"],
                ].join(" ")}
                onClick={() => handleIncrementActiveScreenIndex()}
              />
            </div>
          )}

          {/* This component allows a user to filter what orders they see by specifying the package methods */}
          <div
            style={{ display: "flex", justifyContent: "space-between" }}
          ></div>

          {selectedSiteIndex !== null && (
            <div
              className={
                !(activeMobileScreen.index === 0)
                  ? styles.siteDispensingOrderDetails
                  : styles.noDisplay
              }
            >
              <div
                className={
                  activeMobileScreen.index === 1 ||
                  activeMobileScreen.index === 2 ||
                  activeMobileScreen.index === 3
                    ? styles.dispensingOrderQueue
                    : styles.noDisplay
                }
              >
                <div className={styles.errorBannerContainer}>
                  {error && (
                    <ErrorBanner message={error} onClose={() => onError("")} />
                  )}
                </div>
                <div className={styles.packagingMethodWidgetContainer}>
                  {getPackagingMethodWidget()}
                  {activeMobileScreen.index === 1 &&
                    dispensingOrderQueueHeaderGroup("pending")}
                </div>
                {pendingDOSelection.length > 0 && getDeviceCoverageWidget()}
                <div className={styles.siteDispensingOrderDetails}>
                  {activeMobileScreen.index === 1 && getPendingColumn()}
                  {activeMobileScreen.index === 2 && getRoutedColumn()}
                  {activeMobileScreen.index === 3 && getPackagedColumn()}
                </div>

                <DialogComponent
                  width="400px"
                  target={"#main"}
                  isModal={true}
                  position={{ X: "center", Y: "center" }}
                  visible={orderDetailsDialog}
                  ref={(dialog) => {
                    dialogRef.current = dialog;
                  }}
                  buttons={[
                    {
                      click: () => {
                        onOrderDetailsDialog(null);
                        dialogRef.current.hide();
                      },
                      buttonModel: {
                        isPrimary: true,
                        content: t("closeButtonLabel"),
                        cssClass: "custom-dialog-button",
                      },
                    },
                  ]}
                  close={() => {
                    onOrderDetailsDialog(null);
                  }}
                >
                  <div>
                    <div className="dialog-content">
                      <br />
                      <div className="dialogText">
                        <b>{orderDetailsDialog?.patientInfo.name}</b>
                      </div>
                      <br />
                      <div className="dialogText">
                        {productFieldControllerRef.current.getDefaultName(
                          orderDetailsDialog?.product
                        )}
                      </div>
                      <br />
                      <div className="dialogText">
                        {t("qtyLabel")}: {orderDetailsDialog?.qtyNeeded}
                      </div>
                    </div>
                  </div>
                </DialogComponent>
              </div>
            </div>
          )}
        </main>
      </div>
    </Fragment>
  );
};

export default DispensingOrdersMobileView;
