import React, { useEffect, useRef, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import css from "./SuperAdminOrderGeneration.module.css";
import { InboxOutlined } from "@ant-design/icons";
import { Space, Select, Radio, Button, message, Upload } from "antd";
import StockLocationService from "../../../services/StockLocationService";
import { ReactComponent as BackButton } from "../../../assets/back_icon.svg";
import styles from "../Pending/PendingDispensingOrder.module.css";
import DispensingOrderService from "../../../services/DispensingOrderService";
import ApiService from "../../../services/ApiService";
import OrderGenerator from "../OrderGenerator/OrderGenerator";

const SuperAdminOrderGeneration = ({ user, site }) => {
  const navigate = useNavigate();
  const { Dragger } = Upload;

  const [stockLocations, setStockLocations] = useState([]);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [generationType, setGenerationType] = useState("");

  let url = "";
  if (selectedDevice) {
    url = `${ApiService.BASE_URL}/accounts/${
      user.account._id || user.account
    }/sites/${site._id}/stock-locations/${
      selectedDevice._id
    }/order-generation/csv`;
  }

  const props = {
    name: "file",
    multiple: false,
    action: url,
    onChange(info) {
      console.log(props.action);
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  useEffect(() => {
    StockLocationService.getDevices(user, site).then((data, user) => {
      data.sort((a, b) => {
        if (a.name < b.Name) {
          return -1;
        }
        if (b.name < a.name) {
          return 1;
        }
        return 0;
      });
      setStockLocations(data);
    });
  }, [user]);

  const handleStockLocationChange = (value, option) => {
    setSelectedDevice(stockLocations.find((o) => o._id === value));
  };

  const handleOption = (e) => {
    setGenerationType(e.target.value);
  };

  const handleGenerationType = (value) => {
    setGenerationType(value);
  };

  const getType = (type) => {
    switch (type) {
      case "VIAL_FILLER":
        return "TruScript";
      case "POUCH_PACKAGER":
        return "TruPak";
      case "BLISTER_PACKAGER":
        return "TruCard";
      default:
        return "Unknown";
    }
  };

  const generate = () => {
    DispensingOrderService.generate(user, site, selectedDevice, generationType)
      .then(() => {
        alert(
          `A test batch for ${selectedDevice.name} has been generated successfully.`
        );
      })
      .catch((err) => {
        alert(
          `A test batch for ${selectedDevice.name} has failed to generate.`
        );
      });
  };

  if (generationType === "orderGenerator") {
    return (
      <OrderGenerator
        onGenerationType={handleGenerationType}
        user={user}
        site={site}
        selectedDevice={selectedDevice}
      />
    );
  } else {
    return (
      <div className={css.column}>
        <div className={css.row}>
          <BackButton
            className={styles.PendingDispensingOrder__button}
            onClick={() => {
              navigate(-1);
            }}
          />
          <h1>{site.name} Order Generation</h1>
        </div>
        <br />
        <br />
        <label>Select device</label>
        <Select
          style={{ width: 350 }}
          onChange={handleStockLocationChange}
          options={stockLocations.map((sl) => {
            return { value: sl._id, label: sl.name };
          })}
        />
        <br />
        <br />
        {selectedDevice && (
          <div className={css.column}>
            <h2>
              {selectedDevice.name} - {getType(selectedDevice.type.type)}
            </h2>
            <br />
            <Radio.Group onChange={handleOption} value={generationType}>
              {selectedDevice.type.type === "POUCH_PACKAGER" && (
                <Radio value={"orderGenerator"}>Order generator</Radio>
              )}
              <Radio value={"allAssignedCanisters"}>
                All assigned canisters
              </Radio>
              <Radio value={"csv"}>CSV</Radio>
            </Radio.Group>

            <br />
            {generationType === "csv" && (
              <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload. Strictly prohibited from
                  uploading company data or other banned files.
                </p>
              </Dragger>
            )}
            <br />
            <br />
            {generationType !== "csv" && (
              <Button
                onClick={generate}
                disabled={!generationType}
                className={css.primaryButton}
              >
                Generate
              </Button>
            )}
          </div>
        )}
      </div>
    );
  }
};

export default SuperAdminOrderGeneration;
