import React, { useState, useEffect, useContext, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import TimeField from "react-simple-timefield";
import { ReactComponent as CloseIcon } from "../../assets/x.svg";
import ErrorBanner from "../../components/ErrorBanner";
import Spacer from "../../components/Spacer";
import Button from "../../components/Button";
import Checkbox from "../../components/Checkbox";
import { ReactComponent as BackArrowMobile } from "../../assets/back-icon-mobile.svg";
import FacilityService from "../../services/FacilityService";
import { useTranslation } from "react-i18next";
import RoleCheckService from "../../services/RoleCheckService";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./DeliverySchedule.module.css";

const DeliverySchedule = ({ user, facility }) => {
  const { t } = useTranslation("deliverySchedule");
  const navigate = useNavigate();

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const black = "#121A2D";
  const defaultTime = "13:00";

  const timeStyle = {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "27px",
    borderTop: "none",
    borderRight: "none",
    borderLeft: "none",
    borderBottom: `1px solid ${black}`,
    width: "90%",
    outline: "none",
  };

  const [error, setError] = useState("");
  const [timeVals, setTimeVals] = useState(null);
  const [days, setDays] = useState(null);

  useEffect(() => {
    let tempTimeVals = [];
    let tempDays = [];
    for (let i = 0; i < 7; i++) {
      let daySchedule = facility.deliverySchedule?.find(
        (o) => o.dayOfWeek === i
      );
      if (daySchedule) {
        tempTimeVals.push(
          daySchedule.hour.toString() + ":" + daySchedule.minute.toString()
        );
      } else {
        tempTimeVals.push(defaultTime);
      }
      tempDays.push(!!daySchedule);
    }

    setDays(tempDays);
    setTimeVals(tempTimeVals);
  }, [facility]);

  const handleClose = () => {
    navigate(-1);
  };

  const handleDaySelection = (isOn, dayOfWeek) => {
    if (RoleCheckService.createDeliverySchedule(user)) {
      let copyArr = [...days];
      copyArr[dayOfWeek] = isOn;
      setDays(copyArr);
    }
  };

  const onChange = (dayOfWeek, val) => {
    let copyArr = [...timeVals];
    copyArr[dayOfWeek] = val;
    setTimeVals(copyArr);
  };

  const handleUpdate = () => {
    let deliverySchedule = [];
    for (let i = 0; i < 7; i++) {
      if (days[i]) {
        let timeParts = timeVals[i].split(":");
        deliverySchedule.push({
          dayOfWeek: i,
          hour: timeParts[0],
          minute: timeParts[1],
        });
      }
    }
    facility.deliverySchedule = deliverySchedule;
    FacilityService.update(user, facility, false)
      .then(() => handleClose())
      .catch((error) => setError(error));
  };

  const DesktopView = (
    <div className={styles.DeliverySchedule__overlay}>
      <section className={styles.DeliverySchedule__view}>
        <section className={styles.DeliverySchedule__header}>
          <p className={styles.DeliverySchedule__title}>{`${facility.name} ${t(
            "title"
          )}`}</p>
          <div className={styles.DeliverySchedule__closeIcon}>
            <CloseIcon
              fill={"#121A2D"}
              style={{ cursor: "pointer" }}
              onClick={handleClose}
            />
          </div>
        </section>

        {days && timeVals && (
          <section className={styles.DeliverySchedule__body}>
            {error && (
              <>
                <ErrorBanner
                  message={error}
                  handleClose={() => setError(null)}
                />
                <Spacer space={10} unit={"px"} />
              </>
            )}

            <Spacer space={50} unit={"px"} />
            <div className={styles.DeliverySchedule__dayBoxesContainer}>
              <div className={styles.DeliverySchedule__dayBox}>
                <Checkbox
                  labelName={t("sundayLabel")}
                  onCheck={(isOn) => {
                    handleDaySelection(isOn, 0);
                  }}
                  isOn={days[0]}
                />
                <Spacer space={20} unit={"px"} />
                <TimeField
                  value={days[0] ? timeVals[0] : ""}
                  onChange={(event, value) => {
                    onChange(0, value);
                  }}
                  style={timeStyle}
                  disabled={!days[0]}
                />
              </div>
              <div className={styles.DeliverySchedule__dayBox}>
                <Checkbox
                  labelName={t("mondayLabel")}
                  onCheck={(isOn) => handleDaySelection(isOn, 1)}
                  isOn={days[1]}
                />
                <Spacer space={20} unit={"px"} />
                <TimeField
                  value={days[1] ? timeVals[1] : ""}
                  onChange={(event, value) => {
                    onChange(1, value);
                  }}
                  style={timeStyle}
                  disabled={!days[1]}
                />
              </div>
              <div className={styles.DeliverySchedule__dayBox}>
                <Checkbox
                  labelName={t("tuesdayLabel")}
                  onCheck={(isOn) => handleDaySelection(isOn, 2)}
                  isOn={days[2]}
                />
                <Spacer space={20} unit={"px"} />
                <TimeField
                  value={days[2] ? timeVals[2] : ""}
                  onChange={(event, value) => {
                    onChange(2, value);
                  }}
                  style={timeStyle}
                  disabled={!days[2]}
                />
              </div>
              <div className={styles.DeliverySchedule__dayBox}>
                <Checkbox
                  labelName={t("wednesdayLabel")}
                  onCheck={(isOn) => handleDaySelection(isOn, 3)}
                  isOn={days[3]}
                />
                <Spacer space={20} unit={"px"} />
                <TimeField
                  value={days[3] ? timeVals[3] : ""}
                  onChange={(event, value) => {
                    onChange(3, value);
                  }}
                  style={timeStyle}
                  disabled={!days[3]}
                />
              </div>
              <div className={styles.DeliverySchedule__dayBox}>
                <Checkbox
                  labelName={t("thursdayLabel")}
                  onCheck={(isOn) => handleDaySelection(isOn, 4)}
                  isOn={days[4]}
                />
                <Spacer space={20} unit={"px"} />
                <TimeField
                  value={days[4] ? timeVals[4] : ""}
                  onChange={(event, value) => {
                    onChange(4, value);
                  }}
                  style={timeStyle}
                  disabled={!days[4]}
                />
              </div>
              <div className={styles.DeliverySchedule__dayBox}>
                <Checkbox
                  labelName={t("fridayLabel")}
                  onCheck={(isOn) => handleDaySelection(isOn, 5)}
                  isOn={days[5]}
                />
                <Spacer space={20} unit={"px"} />
                <TimeField
                  value={days[5] ? timeVals[5] : ""}
                  onChange={(event, value) => {
                    onChange(5, value);
                  }}
                  style={timeStyle}
                  disabled={!days[5]}
                />
              </div>
              <div className={styles.DeliverySchedule__dayBox}>
                <Checkbox
                  labelName={t("saturdayLabel")}
                  onCheck={(isOn) => handleDaySelection(isOn, 6)}
                  isOn={days[6]}
                />
                <Spacer space={20} unit={"px"} />
                <TimeField
                  value={days[6] ? timeVals[6] : ""}
                  onChange={(event, value) => {
                    onChange(6, value);
                  }}
                  style={timeStyle}
                  disabled={!days[6]}
                />
              </div>
            </div>
          </section>
        )}
        <section className={styles.DeliverySchedule__footer}>
          {RoleCheckService.createDeliverySchedule(user) && (
            <Button
              labelName={t("buttonLabel")}
              isPrimary={true}
              isDisabled={false}
              onClick={handleUpdate}
            />
          )}
        </section>
      </section>
    </div>
  );

  const MobileView = (
    <Fragment>
      <section className={styles.DeliverySchedule__view}>
        <section className={styles.DeliverySchedule__header}>
          <div
            className={styles.DeliverySchedule__backArrow}
            onClick={handleClose}
          >
            <BackArrowMobile
              className={styles.DeliverySchedule__backArrowIcon}
            />
            <p className={styles.DeliverySchedule__goBackTitle}>{t("backButtonLabel")}</p>
          </div>
          <h2 className={styles.DeliverySchedule__title}>
            {t("title")}{" "}
            <span className={styles.DeliverySchedule__lineBreak}>
              {facility.name}
            </span>
          </h2>
        </section>
        {/* <section className={styles.DeliverySchedule__header}>
          <p className={styles.DeliverySchedule__title}>{`${facility.name} ${t(
            "title"
          )}`}</p>
        </section> */}

        {days && timeVals && (
          <section className={styles.DeliverySchedule__body}>
            {error && (
              <>
                <ErrorBanner
                  message={error}
                  handleClose={() => setError(null)}
                />
                <Spacer space={10} unit={"px"} />
              </>
            )}
            <div className={styles.DeliverySchedule__dayBoxesContainer}>
              <div className={styles.DeliverySchedule__dayBox}>
                <Checkbox
                  labelName={t("sundayLabel")}
                  onCheck={(isOn) => {
                    handleDaySelection(isOn, 0);
                  }}
                  isOn={days[0]}
                />
                <Spacer space={20} unit={"px"} />
                <TimeField
                  value={days[0] ? timeVals[0] : ""}
                  onChange={(event, value) => {
                    onChange(0, value);
                  }}
                  style={timeStyle}
                  disabled={!days[0]}
                />
              </div>
              <div className={styles.DeliverySchedule__dayBox}>
                <Checkbox
                  labelName={t("mondayLabel")}
                  onCheck={(isOn) => handleDaySelection(isOn, 1)}
                  isOn={days[1]}
                />
                <Spacer space={20} unit={"px"} />
                <TimeField
                  value={days[1] ? timeVals[1] : ""}
                  onChange={(event, value) => {
                    onChange(1, value);
                  }}
                  style={timeStyle}
                  disabled={!days[1]}
                />
              </div>
              <div className={styles.DeliverySchedule__dayBox}>
                <Checkbox
                  labelName={t("tuesdayLabel")}
                  onCheck={(isOn) => handleDaySelection(isOn, 2)}
                  isOn={days[2]}
                />
                <Spacer space={20} unit={"px"} />
                <TimeField
                  value={days[2] ? timeVals[2] : ""}
                  onChange={(event, value) => {
                    onChange(2, value);
                  }}
                  style={timeStyle}
                  disabled={!days[2]}
                />
              </div>
              <div className={styles.DeliverySchedule__dayBox}>
                <Checkbox
                  labelName={t("wednesdayLabel")}
                  onCheck={(isOn) => handleDaySelection(isOn, 3)}
                  isOn={days[3]}
                />
                <Spacer space={20} unit={"px"} />
                <TimeField
                  value={days[3] ? timeVals[3] : ""}
                  onChange={(event, value) => {
                    onChange(3, value);
                  }}
                  style={timeStyle}
                  disabled={!days[3]}
                />
              </div>
              <div className={styles.DeliverySchedule__dayBox}>
                <Checkbox
                  labelName={t("thursdayLabel")}
                  onCheck={(isOn) => handleDaySelection(isOn, 4)}
                  isOn={days[4]}
                />
                <Spacer space={20} unit={"px"} />
                <TimeField
                  value={days[4] ? timeVals[4] : ""}
                  onChange={(event, value) => {
                    onChange(4, value);
                  }}
                  style={timeStyle}
                  disabled={!days[4]}
                />
              </div>
              <div className={styles.DeliverySchedule__dayBox}>
                <Checkbox
                  labelName={t("fridayLabel")}
                  onCheck={(isOn) => handleDaySelection(isOn, 5)}
                  isOn={days[5]}
                />
                <Spacer space={20} unit={"px"} />
                <TimeField
                  value={days[5] ? timeVals[5] : ""}
                  onChange={(event, value) => {
                    onChange(5, value);
                  }}
                  style={timeStyle}
                  disabled={!days[5]}
                />
              </div>
              <div className={styles.DeliverySchedule__dayBox}>
                <Checkbox
                  labelName={t("saturdayLabel")}
                  onCheck={(isOn) => handleDaySelection(isOn, 6)}
                  isOn={days[6]}
                />
                <Spacer space={20} unit={"px"} />
                <TimeField
                  value={days[6] ? timeVals[6] : ""}
                  onChange={(event, value) => {
                    onChange(6, value);
                  }}
                  style={timeStyle}
                  disabled={!days[6]}
                />
              </div>
            </div>
          </section>
        )}
        <section className={styles.DeliverySchedule__footer}>
          <Button
            labelName={t("cancelButtonLabel")}
            isPrimary={false}
            isDisabled={false}
            onClick={handleClose}
          />

          <Spacer space={20} unit={"px"} />

          {RoleCheckService.createDeliverySchedule(user) && (
            <Button
              labelName={t("buttonLabel")}
              isPrimary={true}
              isDisabled={false}
              onClick={handleUpdate}
            />
          )}
        </section>
      </section>
    </Fragment>
  );

  return smScreen ? MobileView : DesktopView;
};

export default DeliverySchedule;
