import { Col, Row, Table } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as MoreActionsIcon } from "../../assets/more-actions.svg";
import CreateButton from "../../components/CreateButton";
import ErrorBanner from "../../components/ErrorBanner";
import Popup from "../../components/PopupMenu/Popup";
import SearchBar from "../../components/SearchBar";
import WaitIndicator from "../../components/WaitIndicator";
import { ScreenContext } from "../../contexts/ScreenContext";
import ProductService from "../../services/ProductService";
import RoleCheckService from "../../services/RoleCheckService";
import styles from "../Product/Products.module.css";

const TherapeuticEquivalencies = ({ user, refresh }) => {
  const { t } = useTranslation("therapeuticEquivalents");

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const location = useLocation();
  const navigate = useNavigate();

  const actionListItems = [
    {
      type: "edit",
      description: t("editButtonLabel"),
    },
    {
      type: "remove",
      description: t("removeButtonLabel"),
    },
  ];

  const [filterText, setFilterText] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [locationPathname, setLocationPathname] = useState("");
  const [data, setData] = useState([]);
  const [showPopup, setShowPopup] = useState(null);

  useEffect(() => {
    setLocationPathname(location.pathname);
  }, [location.pathname]);

  useEffect(async () => {
    try {
      setLoading(true);
      let list = await ProductService.getTherapeuticEquivalencies(user);
      list = list.sort((a, b) => {
        if (a.teCode < b.teCode) {
          return -1;
        }
        if (b.teCode < a.teCode) {
          return 1;
        }
        return 0;
      });
      setData(list);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [user, refresh]);

  const handleSearch = (searchText) => {
    searchText = searchText.trim();
    setFilterText(searchText);
  };

  const handleCreate = () => {
    navigate("create");
  };

  const handleAction = (action, row) => {
    switch (action.type) {
      case "edit":
        navigate("create", { state: { teCode: row.teCode } });
        break;
      case "remove":
        // TBD
        break;
      default:
        break;
    }
  };

  if (loading) {
    return <WaitIndicator message={t("fetching")} />;
  } else {
    return (
      <>
        <Outlet />
        {error && (
          <div className={styles.Products__errorBannerContainer}>
            <ErrorBanner message={error} onClose={() => setError(null)} />
          </div>
        )}
        {(locationPathname === "/settings/therapeuticEquivalencies" ||
          locationPathname === "/inventory/substitutions") && (
          <Row>
            {!smScreen && <Col span={24} style={{ height: "70px" }}></Col>}
            <Col span={smScreen ? 24 : 12}>
              <SearchBar
                placeholder={t("searchPlaceholder")}
                onSearch={handleSearch}
                parentFilterText={filterText}
                focus={true}
              />
            </Col>
            <Col span={smScreen ? 24 : 12}>
              <Row justify="end">
                <Col>
                  {RoleCheckService.canCreateProduct(user) && (
                    <CreateButton
                      onClick={handleCreate}
                      isEnabled={true}
                      labelName={t("createButtonLabel")}
                    />
                  )}
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Table
                columns={[
                  {
                    title: t("col0"),
                    render: (row) => row.teCode,
                  },
                  {
                    title: t("col1"),
                    render: (row) => row.productCount,
                  },
                  {
                    title: t("col2"),
                    render: (row) => {
                      if (RoleCheckService.canCreateProduct(user)) {
                        return (
                          <li className={styles.Products__actionsContainer}>
                            <div
                              className={styles.Products__actionsIconContainer}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setShowPopup(row);
                              }}
                            >
                              <MoreActionsIcon />
                              {showPopup === row && (
                                <div
                                  className={styles.Products__popupContainer}
                                >
                                  <Popup
                                    menuItems={actionListItems}
                                    rightJustify={true}
                                    onClose={() => setShowPopup(null)}
                                    onAction={(action) => {
                                      handleAction(action, row);
                                      setShowPopup(null);
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </li>
                        );
                      } else {
                        return "";
                      }
                    },
                    width: 100,
                  },
                ]}
                rowKey={(record) => record._id}
                dataSource={data.filter((o) =>
                  filterText
                    ? o.teCode.toUpperCase().includes(filterText.toUpperCase())
                    : true
                )}
                pagination={false}
                scroll={{ y: window.innerHeight - 300 }}
              />
            </Col>
          </Row>
        )}
      </>
    );
  }
};

export default TherapeuticEquivalencies;
