import React, { useEffect, useContext } from "react";
import { ScreenContext } from "../../../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import StockLocationCard from "./StockLocationCard.js";
import styles from "./StockLocations.module.css";
import { useTranslation } from "react-i18next";

const StockLocations = ({
  user,
  site,
  productScanned,
  stockedItems,
  stockLocations,
  sortedStockLocations,
  setSortedStockLocations,
  onSetErrorHandler = { onSetErrorHandler },
}) => {
  const { t } = useTranslation("stock")

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  useEffect(() => {
    if (stockedItems.length === stockLocations.length) {
      let tempStockLocations = [...stockLocations];
      tempStockLocations.map((tempStockLocation, index) => {
        for (let i = 0; i < stockedItems.length; i++) {
          if (stockedItems[i].stockLocIndex === index) {
            tempStockLocation.totalProductQty = stockedItems[i].totalQty;
          }
        }
      });

      function quantityCompare(location1, location2) {
        /* Sort by need - highest need first */
        return (
          location2.productAssociations[0].min -
          location2.totalProductQty -
          (location1.productAssociations[0].min - location1.totalProductQty)
        );
      }

      const tempStockLocationsWithTotalProductQty = [
        ...tempStockLocations,
      ].sort(quantityCompare);

      setSortedStockLocations(tempStockLocationsWithTotalProductQty);
    }
    return (tempStockLocations, tempStockLocationsWithTotalProductQty) => {
      tempStockLocations = null; //free from memory
      tempStockLocationsWithTotalProductQty = null; // free from memory
    };
  }, [stockedItems, stockLocations]);

  return (
    <section className={styles.locationsDisplay}>
      <span className={styles.locationsDisplay__heading}>{t("locationsLabel")}</span>
      <div
        className={
          smScreen || (stockLocations && stockLocations.length > 2)
            ? [styles["locationsData"], styles["scrollArea"]].join(" ")
            : styles.locationsData
        }
      >
        {sortedStockLocations.map((location, index) => {
          const productAssociations = location.productAssociations;

          // Get the productAssociation that matches the scanned product
          const prodId = productScanned.packageId;
          const filteredProductAssociations = productAssociations.filter(
            (productAssociation) => {
              return prodId === productAssociation.product.packageId;
            }
          );

          const filteredProductAssociation = filteredProductAssociations[0];

          let numBottlesNeeded = (
            filteredProductAssociation.min / productScanned.packageQuantity
          ).toFixed(2);

          return numBottlesNeeded >= 0 ? (
            <StockLocationCard
              key={location._id}
              user={user}
              site={site}
              productScanned={productScanned}
              filteredProductAssociation={filteredProductAssociation}
              numBottlesNeeded={numBottlesNeeded}
              location={location}
            />
          ) : null;
        })}
      </div>
    </section>
  );
};

export default StockLocations;
