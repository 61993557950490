import React from "react";
import { Table, Spin, Dropdown } from "antd";
import { useNavigate } from "react-router-dom";
import { EllipsisOutlined } from "@ant-design/icons";

import styles from "./TrayList.module.css";
import { useTranslation } from "react-i18next";

const TrayListFilteredTable = ({
  site,
  device,
  resetTray,
  deleteTray,
  ordersList,
  restoreData,
  selectedOrder,
  isProcessingAction,
}) => {
  const { t } = useTranslation("trupakTrayList")
  const navigate = useNavigate();

  return (
    <Table
      bordered
      rowKey="_id"
      pagination={false}
      dataSource={ordersList}
      columns={[
        {
          title: t("orderCol"),
          render: (row) => row.dispensingOrder.dispensingOrderId,
        },
        {
          title: t("deviceCol"),
          render: (row) => row.dispensingOrder.routedToName,
        },
        {
          title: t("traySeqCol"),
          render: (row) =>
            `${row.rangeId}${
              selectedOrder && selectedOrder.trayNeeds
                ? ` ${t("ofLabel")} ${selectedOrder.trayNeeds.totalTrays}`
                : ""
            }`,
        },
        {
          title: t("trayCol"),
          render: (row) => row.assignedTrayId,
        },
        {
          align: "center",
          title: t("trayStatusCol"),
          render: (row) => t(row.status, {ns: "trayStatuses"}),
        },
        {
          width: 10,
          align: "center",
          title: t("actionsCol"),
          onCell: (_) => {
            return {
              onClick: (e) => e.stopPropagation(),
            };
          },
          render: (row) =>
            isProcessingAction ? (
              <Spin size="small" />
            ) : (
              <Dropdown
                trigger={["click"]}
                menu={{
                  items: [
                    {
                      key: "reset-option",
                      label: t("resetAction"),
                    },
                    {
                      key: "delete-option",
                      label: t("deleteAction"),
                    },
                  ],
                  onClick: ({ key, domEvent }) => {
                    domEvent.preventDefault();
                    if (key === "reset-option") {
                      resetTray(row);
                    } else if (key === "delete-option") {
                      deleteTray(row);
                    } else if (key === "restore-original") {
                      restoreData();
                    }
                  },
                }}
              >
                <EllipsisOutlined style={{ padding: "5px 10px" }} />
              </Dropdown>
            ),
        },
      ]}
      rowClassName={(row) =>
        row.approved
          ? row.status.toUpperCase() === "CORRECTED APPROVED"
            ? styles.CorrectedApprovedRow
            : styles.ApprovedRow
          : row.slots.some((slot) =>
              slot.drugs.some(
                (drug) =>
                  drug.approvalType &&
                  drug.approvalType.toUpperCase() === "CORRECTED APPROVED"
              )
            )
          ? styles.CorrectedApprovedRow
          : row.status === "Not Filled"
          ? styles.NotFilledRow
          : styles.NeedCheckRow
      }
      onRow={(record, _) => {
        return {
          onClick: (_) => {
            if (
              !record.isLocked &&
              (!record.approved ||
                (record.approved &&
                  record.slots.some((slot) =>
                    slot.drugs.some(
                      (drug) =>
                        drug.approvalType &&
                        drug.approvalType.toUpperCase() === "CORRECTED APPROVED"
                    )
                  )))
            ) {
              navigate(
                `/site/${site._id}/device/${device._id}/order/${record.dispensingOrder._id}/trayCheck/${record._id}`
              );
            }
          },
        };
      }}
    />
  );
};

export default TrayListFilteredTable;
