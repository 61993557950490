import React, {useState} from 'react'
import {Space, Button} from 'antd'
import Search from "antd/es/input/Search";
import ClientFDBService from "../../../services/ClientFDBService";
import ImageService from "../../../services/ImageService";
import ProductService from "../../../services/ProductService";
import { useTranslation } from 'react-i18next';

const FdbLookup = ({user, onAdd}) => {
    const { t } = useTranslation("fdbLookup")

    const [error, setError] = useState('');
    const [fdbPackagedDrug, setFdbPackagedDrug] = useState(null);

    const onSearch = (value, _e, info) => {
        setError('');
        ClientFDBService.getFdbPackagedDrugByNdc(user, value).then(fdbPackagedDrug=>{
            if(fdbPackagedDrug){
                ProductService.getProductByPackageId(user, fdbPackagedDrug.packagedDrugID).then(product=>{
                    if(product){
                        setError(t("errorExists"));
                    } else {
                        setFdbPackagedDrug(fdbPackagedDrug);
                    }
                })
            } else {
                setError(t("errorNotFound"))
            }


        }).catch(error=>{
            setError(error);
        })
    };

    const convert = () => {
        const o = {...fdbPackagedDrug};
        const product = {
            images: [],
            isEnabled: true,
            isPending: false,
            productId: o.packagedDrugID.substring(0, 9),
            packageId: o.packagedDrugID,
            humanReadableId: o.ndcFormatted,
            primaryName: o.packagedDrugDesc,
            tallManName: o.dispensableDrugTallManDesc !== o.dispensableDrugDesc ? o.dispensableDrugTallManDesc : '',
            manufacturer: {
                fullName: o.labelerName,
                abbrName: o.labelerName
            },
            therapeuticEquivalencyCode: o.dispensableGenericID,
            packageDescription: o.packageDesc,
            packageQuantity: o.packageSize,
        }
        onAdd(product)
    }

    return (
        <Space direction={"vertical"}>
            <Search
                enterButton
                placeholder={t("enterNdcLabel")}
                onSearch={onSearch}

            />
            {fdbPackagedDrug &&
                <>
                    <p><b>{t("packageIdLabel")}:</b> {fdbPackagedDrug.ndcFormatted}</p>
                    <p><b>{t("descriptionLabel")}:</b> {fdbPackagedDrug.packagedDrugDesc}</p>
                    <p><b>{t("labelerLabel")}:</b> {fdbPackagedDrug.labelerName}</p>
                    <p><b>{t("substitutionIdLabel")}:</b> {fdbPackagedDrug.dispensableGenericID}</p>
                    <p><b>{t("packageLabel")}:</b> {fdbPackagedDrug.packageSize} {fdbPackagedDrug.packageDesc}</p>
                    <Button onClick={convert} type={'primary'}>{t("addProductLabel")}</Button>
                </>

            }
            {error &&
                <p>{error}</p>
            }
        </Space>
    )
}

export default FdbLookup;