import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { ScreenContext } from "../contexts/ScreenContext";
import AddessService from "../services/AddessService";
import Checkbox from "./Checkbox";
import DropdownInput from "./DropDownInput/DropdownInput";
import Spacer from "./Spacer";
import TextInput from "./TextInput";

const Address = ({
  address,
  onChange,
  showSameAsShipping,
  onSameAsShipping,
  errors,
}) => {
  const { t, i18n } = useTranslation("address");

  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState(null);
  const [addr, setAddr] = useState(address ?? {});

  const { smallScreen, mediumScreen, largeScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);
  const mdScreen = useMediaQuery(mediumScreen);
  const lgScreen = useMediaQuery(largeScreen);

  useEffect(() => {
    AddessService.getCountries()
      .then((result) => {
        let transformedCountries = result.countries
          .map((country) => {
            return {
              type: country.code,
              description: country.country,
              displayName: t(country.country, { ns: "countries" }),
              states: country.states.map((state) => {
                return {
                  type: state,
                  description: state,
                  displayName:
                    country.country === "Canada"
                      ? t(state, { ns: `states/${country.country}` })
                      : state,
                };
              }),
            };
          })
          .sort((a, b) => {
            if (
              a.displayName.normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
              b.displayName.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            ) {
              return -1;
            }
            if (
              a.displayName.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
              b.displayName.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            ) {
              return 1;
            }
            return 0;
          });

        transformedCountries.find(o => o.type === "CA").states.sort((a, b) => {
          if (
            a.displayName.normalize("NFD").replace(/[\u0300-\u036f]/g, "") <
            b.displayName.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          ) {
            return -1;
          }
          if (
            a.displayName.normalize("NFD").replace(/[\u0300-\u036f]/g, "") >
            b.displayName.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          ) {
            return 1;
          }
          return 0;
        });
          
        setCountries(transformedCountries);
        if (!!address?.country) {
          setCountry(transformedCountries.find((o) => o.description === address?.country));
        } else {
          setCountry(transformedCountries.find((o) => o.type === "US"));
        }
        
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setAddr(address ?? {});
  }, [address]);

  return (
    <section className={"column"}>
      {showSameAsShipping && (
        <>
          <Spacer space={10} unit={"px"} />
          <section className={"row"}>
            <Checkbox
              labelName={t("sameAsShippingLabel")}
              isOn={addr ? addr.sameAsShipping : false}
              onCheck={(isOn) => {
                onSameAsShipping(isOn);
              }}
            />
          </section>
          <Spacer space={20} unit={"px"} />
        </>
      )}
      <section className={smScreen ? "column" : "row"}>
        <section className={"column"}>
          <DropdownInput
            listElements={countries}
            labelName={t("countryLabel")}
            isEnabled={true}
            value={addr ? t(addr.country, {ns: "countries"}) || "" : ""}
            placeholder={""}
            validationText={errors ? t(errors.country, {ns: "validation"}) : ""}
            onSelection={(value) => {
              addr.country = value.description;
              setCountry(value);
              onChange(addr);
            }}
            display={"displayName"}
          />
          <TextInput
            labelName={t("street1Label")}
            isEnabled={true}
            value={addr ? addr.street1 || "" : ""}
            placeholder={""}
            validationText={errors ? t(errors.street1, {ns: "validation"}) : ""}
            type={"text"}
            onChange={(value) => {
              addr.street1 = value;
              onChange(addr);
            }}
            focus={true}
          />
          <TextInput
            labelName={t("street2Label")}
            isEnabled={true}
            value={addr ? addr.street2 || "" : ""}
            placeholder={""}
            validationText={""}
            type={"text"}
            onChange={(value) => {
              addr.street2 = value;
              onChange(addr);
            }}
            focus={true}
          />
        </section>
        {lgScreen && <Spacer space={10} unit={"%"} />}
        {mdScreen && <Spacer space={20} unit={"px"} />}
        <section className={"column"}>
          <TextInput
            labelName={t("cityLabel")}
            isEnabled={true}
            value={addr ? addr.city || "" : ""}
            placeholder={""}
            validationText={errors ? t(errors.city, {ns: "validation"}) : ""}
            type={"text"}
            onChange={(value) => {
              addr.city = value;
              onChange(addr);
            }}
            focus={true}
          />
          <section className={smScreen || mdScreen ? "column" : "row"}>
            <section className={mdScreen ? "row w-100" : "row w-60"}>
              <DropdownInput
                listElements={country?.states || []}
                labelName={t("stateLabel", {context: addr?.country})}
                isEnabled={true}
                value={
                  !!addr?.state 
                    ? addr?.country === "Canada" 
                      ? t(addr.state, {ns: "states/Canada"})  
                      : addr.state
                    : ""
                }
                placeholder={""}
                validationText={errors ? t(errors.state, {context: addr?.country, ns: "validation"}) : ""}
                onSelection={(value) => {
                  addr.state = value.description;
                  onChange(addr);
                }}
                size={100}
                display={"displayName"}
              />
            </section>
            <section className={mdScreen ? "row w-5" : "row w-10"} />
            <section className={"row w-30"}>
              <TextInput
                labelName={t("zipcodeLabel", {context: addr?.country})}
                isEnabled={true}
                value={addr ? addr.zipcode || "" : ""}
                placeholder={""}
                validationText={errors ? t(errors.zipcode, {context: addr?.country, ns: "validation"}) : ""}
                type={"text"}
                onChange={(value) => {
                  addr.zipcode = value;
                  onChange(addr);
                }}
                focus={true}
              />
            </section>
          </section>
        </section>
      </section>
    </section>
  );
};

export default Address;
