import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const fallbackLng = ['en'];
const availableLanguages = ['en', 'fr', 'es'];
const namespaces = [
    "errors", 
    "dateRange", 
    "validation", 
    "roles", 
    "pharmacyTypes", 
    "siteOrderPriority", 
    //"dosageForms", 
    "controlLevels", 
    "routes", 
    "trayStatuses", 
    "orderPriority",
    "orderFilterTypes",
    "datePicker",
    "months",
    "waitIndicator",
    "stock",
    "stockLocationTypes",
]

i18n
    .use(Backend) // load translations using http (default public/assets/locals/en/translations)
    .use(LanguageDetector) // detect user language
    .use(initReactI18next) // pass the i18n instance to react-i18next.
    .init({
        fallbackLng, // fallback language is english.

        ns: namespaces, // lets us explicitly select these namespaces with translation objects

        detection: {
            checkWhitelist: true, // options for language detection
        },

        debug: false,

        whitelist: availableLanguages,

        interpolation: {
            escapeValue: false, // no need for react. it escapes by default
        }
    });

export default i18n;
