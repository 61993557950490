import React, { useEffect, useRef, useContext } from "react";
import styles from "./CreateProductAccordion.module.css";
import { useTranslation } from "react-i18next";
import Accordion from "../../components/Accordion/Accordion";
import TextInput from "../../components/TextInput";
import ProductController from "../../controllers/ProductController";
import ImageCarousel from "../../components/ImageCarousel";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";

const CreateProductRetailCosts = ({
  isNew,
  p,
  errors,
  onPropertyChange,
  onError,
  onAddImage,
  onDeleteImage,
}) => {
  const { t } = useTranslation("createProductCosts");
  const productControllerRef = useRef(null);

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  useEffect(() => {
    productControllerRef.current = new ProductController();
  }, []);

  return (
    <Accordion title={t("tabRetail")}>
      <div
        className={
          smScreen
            ? styles.CreateProductAccordion__col
            : styles.CreateProductAccordion__row
        }
      >
        <div className={styles.CreateProductAccordion__col}>
          <TextInput
            type={"number"}
            labelName={t("awpBulkLabel")}
            isEnabled={true}
            value={p?.retailAwpBulk || ""}
            validationText={t(errors.retailAwpBulk, {ns: "validation"})}
            focus={false}
            onChange={(val) => {
              onPropertyChange({ retailAwpBulk: parseFloat(val) });
            }}
          />
        </div>
        <div className={styles.CreateProductAccordion__col}>
          <TextInput
            type={"number"}
            labelName={t("awpItemLabel")}
            isEnabled={true}
            value={p?.retailAwpItem || ""}
            validationText={t(errors.retailAwpItem, {ns: "validation"})}
            focus={false}
            onChange={(val) => {
              onPropertyChange({ retailAwpItem: parseFloat(val) });
            }}
          />
        </div>
      </div>
      <div
        className={
          smScreen
            ? styles.CreateProductAccordion__col
            : styles.CreateProductAccordion__row
        }
      >
        <div className={styles.CreateProductAccordion__col}>
          <TextInput
            type={"number"}
            labelName={t("acquisitionBulkLabel")}
            isEnabled={true}
            value={p?.retailAcquisitionBulk || ""}
            validationText={t(errors.retailAcquisitionBulk, {ns: "validation"})}
            focus={false}
            onChange={(val) => {
              onPropertyChange({ retailAcquisitionBulk: parseFloat(val) });
            }}
          />
        </div>
        <div className={styles.CreateProductAccordion__col}>
          <TextInput
            type={"number"}
            labelName={t("acquisitionItemLabel")}
            isEnabled={true}
            value={p?.retailAcquisitionItem || ""}
            validationText={t(errors.retailAcquisitionItem, {ns: "validation"})}
            focus={false}
            onChange={(val) => {
              onPropertyChange({ retailAcquisitionItem: parseFloat(val) });
            }}
          />
        </div>
      </div>
      <div
        className={
          smScreen
            ? styles.CreateProductAccordion__col
            : styles.CreateProductAccordion__row
        }
      >
        <div className={styles.CreateProductAccordion__col}>
          <TextInput
            type={"number"}
            labelName={t("gpoBulkLabel")}
            isEnabled={true}
            value={p?.retailGpoBulk || ""}
            validationText={t(errors.retailGpoBulk, {ns: "validation"})}
            focus={false}
            onChange={(val) => {
              onPropertyChange({ retailGpoBulk: parseFloat(val) });
            }}
          />
        </div>
        <div className={styles.CreateProductAccordion__col}>
          <TextInput
            type={"number"}
            labelName={t("gpoItemLabel")}
            isEnabled={true}
            value={p?.retailGpoItem || ""}
            validationText={t(errors.retailGpoItem, {ns: "validation"})}
            focus={false}
            onChange={(val) => {
              onPropertyChange({ retailGpoItem: parseFloat(val) });
            }}
          />
        </div>
      </div>
      <div
        className={
          smScreen
            ? styles.CreateProductAccordion__col
            : styles.CreateProductAccordion__row
        }
      >
        <div className={styles.CreateProductAccordion__col}>
          <TextInput
            type={"number"}
            labelName={t("340bBulkLabel")}
            isEnabled={true}
            value={p?.retailThree40bBulk || ""}
            validationText={t(errors.retailThree40bBulk, {ns: "validation"})}
            focus={false}
            onChange={(val) => {
              onPropertyChange({ retailThree40bBulk: parseFloat(val) });
            }}
          />
        </div>
        <div className={styles.CreateProductAccordion__col}>
          <TextInput
            type={"number"}
            labelName={t("340bItemLabel")}
            isEnabled={true}
            value={p?.retailThree40bItem || ""}
            validationText={t(errors.retailThree40bItem, {ns: "validation"})}
            focus={false}
            onChange={(val) => {
              onPropertyChange({ retailThree40bItem: parseFloat(val) });
            }}
          />
        </div>
      </div>
    </Accordion>
  );
};

export default CreateProductRetailCosts;
