import React, { useState, useEffect, useContext } from "react";
import InventoryEquivDataCard from "./InventoryEquivDataCard";
import { ReactComponent as DeleteIcon } from "../../assets/delete-circle-bg.svg";
import styles from "./InventoryEquivProductsList.module.css";
import ProductController from "../../controllers/ProductController";
import { useTranslation } from "react-i18next";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";

const InventoryEquivProductsList = ({
  product,
  filteredEquivProducts,
  productEquivalency,
  equivProducts,
  setEquivProducts,
  prodEquivCntrInstance,
}) => {
  const pcRef = new ProductController();

  const maxNumRowsNoScroll = 5;

  const [allowEquivDeleteClick, setAllowEquivDeleteClick] = useState(true);

  const [productIdToDelete, setProductIdToDelete] = useState(-1);

  const { t } = useTranslation("equivalency");

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  useEffect(() => {
    setAllowEquivDeleteClick(true);
    setProductIdToDelete(-1);
  }, [equivProducts]);

  const handleDelete = (e, id) => {
    const equivProductsToDelete = equivProducts.filter(
      (ep) => ep.packageId === id
    );

    const productToRemove = equivProductsToDelete[0];

    const callback = (error, productEquivalency) => {
      if (!error) {
        setEquivProducts(productEquivalency?.products || []);
      } else {
        console.log("error", error);
      }
    };
    prodEquivCntrInstance.removeProductEquivalency(
      productEquivalency,
      product,
      productToRemove,
      callback
    );
  };

  const resolveAfterTimeout = () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve("resolved");
      }, 500);
    });
  };

  const handleDeleteClick = async (e, id) => {
    setAllowEquivDeleteClick(false);
    setProductIdToDelete(id);
    // setToggle(!toggle);
    const result = await resolveAfterTimeout();
    handleDelete(e, id);
  };

  return (
    <div
      className={styles.prodList__scrollArea}
      // style={
      //   filteredEquivProducts.length > maxNumRowsNoScroll
      //     ? { ...{ paddingRight: "10px" } }
      //     : null
      // }
    >
      {filteredEquivProducts
        .filter((p) => p.packageId !== product.packageId)
        .map((prod) =>
          !smScreen ? (
            <div key={prod.packageId} className={styles.prodList__row}>
              <p className={styles.prodList__id}>{prod.packageId}</p>
              <p className={styles.prodList__description}>
                {pcRef.getDefaultName(prod)}
              </p>
              <p className={styles.prodList__manufacturer}>
                {prod.manufacturer.fullName}
              </p>
              <div className={styles.prodList__deleteIconContainer}>
                {prod.packageId !== product.packageId && (
                  <div
                    id={prod.packageId}
                    onClick={(e) => {
                      // console.log('event.target', e.currentTarget.getAttribute('id'));
                      if (allowEquivDeleteClick) {
                        handleDeleteClick(e, prod.packageId);
                      }
                    }}
                  >
                    <DeleteIcon
                      className={
                        +prod.packageId !== +productIdToDelete
                          ? allowEquivDeleteClick
                            ? styles.prodList__deleteIcon
                            : styles.prodList__deleteInProgress
                          : styles.prodList__selectedDeleteIcon
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <InventoryEquivDataCard
              key={prod.packageId}
              data={{
                idHeading: t("col0"),
                descriptionHeading: t("col1"),
                manufacturerHeading: t("col2"),
                actionsHeading: t("col3"),
                id: prod.packageId,
                description: pcRef.getDefaultName(prod),
                manufacturer: prod.manufacturer.fullName,
                actions: (
                  <div
                    id={prod.packageId}
                    onClick={(e) => {
                      // console.log('event.target', e.currentTarget.getAttribute('id'));
                      if (allowEquivDeleteClick) {
                        handleDeleteClick(e, prod.packageId);
                      }
                    }}
                  >
                    {prod.packageId !== product.packageId ? (
                      <DeleteIcon
                        className={
                          +prod.packageId !== +productIdToDelete
                            ? allowEquivDeleteClick
                              ? styles.prodList__deleteIcon
                              : styles.prodList__deleteInProgress
                            : styles.prodList__selectedDeleteIcon
                        }
                      />
                    ) : null}
                  </div>
                ),
              }}
            />
          )
        )}
    </div>
  );
};

export default InventoryEquivProductsList;
