import React, { useContext } from "react";
import { NavbarContext } from "../../../../contexts/NavbarContext";
import InputCustomType from "../../../../components/InputCustomType/InputCustomType";
import SFDropDownList from "../../../../components/SFDropDownList/SFDropDownList";
import { ReactComponent as TrashIcon } from "../../../../assets/trash-icon.svg";
import styles from "./RepeaterProperties.module.css";
import { useTranslation } from "react-i18next";

const RepeaterProperties = ({
  activeNode,
  nodes,
  onNodes,
  onShowDeleteNodeWarning,
  onInputFocused,
  onLabelModificationInProgress,
}) => {
  const { t } = useTranslation("labelEditor");

  const { handleLabelModificationInProgressViaContext } =
    useContext(NavbarContext);

  let tempNodes = [...nodes];

  const wholeNumbers = /^[0-9]+$/;
  const rx = new RegExp(wholeNumbers);
  let paddingValToProcess;

  const handleDataUpdate = (tempNodes) => {
    onNodes([...tempNodes]);
    onLabelModificationInProgress(true);
    handleLabelModificationInProgressViaContext(true);
  };

  const handleXPosition = (xPosition) => {
    tempNodes[activeNode.nodeId].xPosmm = xPosition;
    handleDataUpdate(tempNodes);
  };

  const handleYPosition = (yPosition) => {
    tempNodes[activeNode.nodeId].yPosmm = yPosition;
    handleDataUpdate(tempNodes);
  };

  const handleWidth = (width) => {
    tempNodes[activeNode.nodeId].widthmm = width;
    handleDataUpdate(tempNodes);
  };

  const handleHeight = (height) => {
    tempNodes[activeNode.nodeId].heightmm = height;
    handleDataUpdate(tempNodes);
  };

  const handleTopPadding = (paddingVal) => {
    if (rx.test(paddingVal) || !paddingVal) {
      paddingValToProcess = rx.test(paddingVal) ? parseInt(paddingVal) : "";
      tempNodes[activeNode.nodeId].padding = {
        ...activeNode.padding,
        top: paddingValToProcess,
      };
      handleDataUpdate(tempNodes);
    }
  };

  const handleRightPadding = (paddingVal) => {
    if (rx.test(paddingVal) || !paddingVal) {
      paddingValToProcess = rx.test(paddingVal) ? parseInt(paddingVal) : "";
      tempNodes[activeNode.nodeId].padding = {
        ...activeNode.padding,
        right: paddingValToProcess,
      };
      handleDataUpdate(tempNodes);
    }
  };

  const handleBottomPadding = (paddingVal) => {
    if (rx.test(paddingVal) || !paddingVal) {
      paddingValToProcess = rx.test(paddingVal) ? parseInt(paddingVal) : "";
      tempNodes[activeNode.nodeId].padding = {
        ...activeNode.padding,
        bottom: paddingValToProcess,
      };
      handleDataUpdate(tempNodes);
    }
  };

  const handleLeftPadding = (paddingVal) => {
    if (rx.test(paddingVal) || !paddingVal) {
      paddingValToProcess = rx.test(paddingVal) ? parseInt(paddingVal) : "";
      tempNodes[activeNode.nodeId].padding = {
        ...activeNode.padding,
        left: paddingValToProcess,
      };
      handleDataUpdate(tempNodes);
    }
  };

  const handleRowGap = (gapVal) => {
    tempNodes[activeNode.nodeId].gapmm = gapVal;
    handleDataUpdate(tempNodes);
  };

  // for SyncFusion orientation dropdown
  const dropdownListOrientation = [
    { Item: "Vertical", Value: "Vertical", displayName: t("verticalLabel") },
    { Item: "Horizontal", Value: "Horizontal", displayName: t("horizontalLabel") },
  ];

  // for SyncFusion orientation dropdown
  const handleSelectedOrientation = (
    selectedOrientation,
    selectedOrientationValue
  ) => {
    tempNodes[activeNode.nodeId].orientation = selectedOrientation;
    if (selectedOrientation === "Vertical") {
      // onRepeaterDefaultOrientation("Vertical");
      tempNodes[activeNode.nodeId].orientation = "Vertical";
    } else {
      // onRepeaterDefaultOrientation("Horizontal");
      tempNodes[activeNode.nodeId].orientation = "Horizontal";
    }
    handleDataUpdate(tempNodes);
  };

  const handlePropertyName = (propertyName) => {
    tempNodes[activeNode.nodeId].propertyName = propertyName;
    handleDataUpdate(tempNodes);
  };

  // for SyncFusion repeatCount dropdown
  const dropdownListRepeatCount = [
    { Item: 1, Value: 1 },
    { Item: 2, Value: 2 },
    { Item: 3, Value: 3 },
    { Item: 4, Value: 4 },
    { Item: 5, Value: 5 },
  ];

  // for SyncFusion repeatCount dropdown
  const handleSelectedRepeatCount = (
    selectedRepeatCount,
    selectedRepeatCountValue
  ) => {
    tempNodes[activeNode.nodeId].repeatCount = selectedRepeatCount;
    handleDataUpdate(tempNodes);
  };

  const handleRemoveNode = () => {
    onShowDeleteNodeWarning(true);
  };

  const handleMouseEnter = () => {
    onInputFocused(true);
  };

  const handleStopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div>
      <div className={styles.RepeaterProperties__container}>
        <div
          className={styles.RepeaterProperties__trashIconContainer}
          onClick={handleRemoveNode}
        >
          <TrashIcon />
        </div>

        <div className={styles.RepeaterProperties__topAndRightPaddingContainer}>
          <p>
            {t("paddingLabel")} <span>(px)</span>
          </p>
          <div className={styles.RepeaterProperties__boxesContainer}>
            <div className={styles.RepeaterProperties__topPaddingBoxContainer}>
              <div
                className={styles.RepeaterProperties__inputContainer}
                onClick={handleStopPropagation}
              >
                <InputCustomType
                  userInput={activeNode.padding.top}
                  onHandleUserInput={handleTopPadding}
                  onMouseEnter={handleMouseEnter}
                  customType="positive number"
                  enforceFocus={false}
                  maxValue={100}
                  maxNumOfDecimals={1}
                  inputFields={{
                    type: "text",
                    value: activeNode.padding.top,
                  }}
                />
              </div>
              <p>{t("topLabel")}</p>
            </div>
            <div
              className={styles.RepeaterProperties__rightPaddingBoxContainer}
            >
              <div
                className={styles.RepeaterProperties__inputContainer}
                onClick={handleStopPropagation}
              >
                <InputCustomType
                  userInput={activeNode.padding.right}
                  onHandleUserInput={handleRightPadding}
                  onMouseEnter={handleMouseEnter}
                  customType="positive number"
                  enforceFocus={false}
                  maxValue={100}
                  maxNumOfDecimals={1}
                  inputFields={{
                    type: "text",
                    value: activeNode.padding.right,
                  }}
                />
              </div>
              <p>{t("rightLabel")}</p>
            </div>
          </div>
        </div>

        <div
          className={styles.RepeaterProperties__bottomAndLeftPaddingContainer}
        >
          <p>
            {t("paddingLabel")} <span>(px)</span>
          </p>
          <div className={styles.RepeaterProperties__boxesContainer}>
            <div
              className={styles.RepeaterProperties__bottomPaddingBoxContainer}
            >
              <div
                className={styles.RepeaterProperties__inputContainer}
                onClick={handleStopPropagation}
              >
                <InputCustomType
                  userInput={activeNode.padding.bottom}
                  onHandleUserInput={handleBottomPadding}
                  onMouseEnter={handleMouseEnter}
                  customType="positive number"
                  enforceFocus={false}
                  maxValue={100}
                  maxNumOfDecimals={1}
                  inputFields={{
                    type: "text",
                    value: activeNode.padding.bottom,
                  }}
                />
              </div>
              <p>{t("bottomLabel")}</p>
            </div>
            <div className={styles.RepeaterProperties__leftPaddingBoxContainer}>
              <div
                className={styles.RepeaterProperties__inputContainer}
                onClick={handleStopPropagation}
              >
                <InputCustomType
                  userInput={activeNode.padding.left}
                  onHandleUserInput={handleLeftPadding}
                  onMouseEnter={handleMouseEnter}
                  customType="positive number"
                  enforceFocus={false}
                  maxValue={100}
                  maxNumOfDecimals={1}
                  inputFields={{
                    type: "text",
                    value: activeNode.padding.left,
                  }}
                />
              </div>
              <p>{t("leftLabel")}</p>
            </div>
          </div>
        </div>

        <div className={styles.RepeaterProperties__rowGapContainer}>
          <p>
            {t("rowGapLabel")} <span>(mm)</span>
          </p>
          <div className={styles.RepeaterProperties__rowGapBoxContainer}>
            <div
              className={styles.RepeaterProperties__inputContainer}
              onClick={handleStopPropagation}
            >
              <InputCustomType
                userInput={activeNode.gapmm}
                onHandleUserInput={handleRowGap}
                onMouseEnter={handleMouseEnter}
                customType="positive number"
                enforceFocus={false}
                maxValue={100}
                maxNumOfDecimals={2}
                inputFields={{
                  type: "text",
                  value: activeNode.gapmm,
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.RepeaterProperties__positionContainer}>
          <p>
            {t("positionLabel")} <span>(mm)</span>
          </p>

          <div className={styles.RepeaterProperties__boxesContainer}>
            <div className={styles.RepeaterProperties__xBoxContainer}>
              <div
                className={styles.RepeaterProperties__inputContainer}
                onClick={handleStopPropagation}
              >
                <InputCustomType
                  userInput={activeNode.xPosmm}
                  onHandleUserInput={handleXPosition}
                  onMouseEnter={handleMouseEnter}
                  customType="positive number"
                  enforceFocus={false}
                  maxValue={10000}
                  maxNumOfDecimals={1}
                  inputFields={{
                    type: "text",
                    value: activeNode.xPosmm,
                  }}
                />
              </div>
              <p>x</p>
            </div>
            <div className={styles.RepeaterProperties__yBoxContainer}>
              <div
                className={styles.RepeaterProperties__inputContainer}
                onClick={handleStopPropagation}
              >
                <InputCustomType
                  userInput={activeNode.yPosmm}
                  onHandleUserInput={handleYPosition}
                  onMouseEnter={handleMouseEnter}
                  customType="positive number"
                  enforceFocus={false}
                  maxValue={10000}
                  maxNumOfDecimals={1}
                  inputFields={{
                    type: "text",
                    value: activeNode.yPosmm,
                  }}
                />
              </div>
              <p>y</p>
            </div>
          </div>
        </div>
        <div className={styles.RepeaterProperties__sizeContainer}>
          <p>
            {t("sizeLabel")} <span>(mm)</span>
          </p>
          <div className={styles.RepeaterProperties__boxesContainer}>
            <div className={styles.RepeaterProperties__widthBoxContainer}>
              <div
                className={styles.RepeaterProperties__inputContainer}
                onClick={handleStopPropagation}
              >
                <InputCustomType
                  userInput={activeNode.withmm}
                  onHandleUserInput={handleWidth}
                  onMouseEnter={handleMouseEnter}
                  customType="positive number"
                  enforceFocus={false}
                  maxValue={10000}
                  maxNumOfDecimals={1}
                  inputFields={{
                    type: "text",
                    value: activeNode.widthmm,
                  }}
                />
              </div>
              <p>{t("widthLabel")}</p>
            </div>
            <div className={styles.RepeaterProperties__heightBoxContainer}>
              <div
                className={styles.RepeaterProperties__inputContainer}
                onClick={handleStopPropagation}
              >
                <InputCustomType
                  userInput={activeNode.heightmm}
                  onHandleUserInput={handleHeight}
                  onMouseEnter={handleMouseEnter}
                  customType="positive number"
                  enforceFocus={false}
                  maxValue={10000}
                  maxNumOfDecimals={1}
                  inputFields={{
                    type: "text",
                    value: activeNode.heightmm,
                  }}
                />
              </div>
              <p>{t("heightLabel")}</p>
            </div>
          </div>
        </div>

        <div className={styles.RepeaterProperties__orientationContainer}>
          <SFDropDownList
            id={`${activeNode.id}-orientation`}
            label={t("orientationLabel")}
            defaultValue={activeNode.orientation}
            placeHolder={t("orientationPlaceholder")}
            dropDownList={dropdownListOrientation}
            onSelection={handleSelectedOrientation}
            display={"displayName"}
          />
        </div>

        <div className={styles.RepeaterProperties__repeatCountContainer}>
          <SFDropDownList
            id={`${activeNode.id}-repeatCount`}
            label={t("repeaterCountLabel")}
            defaultValue={activeNode.repeatCount}
            placeHolder={t("repeatCountPlaceholder")}
            dropDownList={dropdownListRepeatCount}
            onSelection={handleSelectedRepeatCount}
          />
        </div>
      </div>
    </div>
  );
};

export default RepeaterProperties;
