import React, { useEffect, useRef, useContext } from "react";
import styles from "./CreateProductAccordion.module.css";
import { useTranslation } from "react-i18next";
import Accordion from "../../components/Accordion/Accordion";
import TextInput from "../../components/TextInput";
import ProductController from "../../controllers/ProductController";
import ImageCarousel from "../../components/ImageCarousel";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import { SearchOutlined } from '@ant-design/icons';
import {Button, Space} from 'antd'


const CreateProductPhysicalAttributes = ({
  isNew,
  p,
  errors,
  onPropertyChange,
  onError,
  onAddImage,
  onDeleteImage, onSearchFDBImages
}) => {
  const { t } = useTranslation("createProductPhysical");
  const productControllerRef = useRef(null);

  const { smallScreen, mediumScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);
  const mdScreen = useMediaQuery(mediumScreen);

  useEffect(() => {
    productControllerRef.current = new ProductController();
  }, []);

  return (
    <Accordion title={t("tab")}>
      <div
        className={
          smScreen
            ? styles.CreateProductAccordion__col
            : styles.CreateProductAccordion__row
        }
      >
        <div className={styles.CreateProductAccordion__col} style={{alignItems: 'start'}}>

            <Space direction={'vertical'}>
                {p.packageId &&
                    <Space direction={'horizontal'}>
                    <Button  onClick={onSearchFDBImages}>
                        {t("pullFdbLabel")}
                    </Button>
                    </Space>
                }

              <ImageCarousel
                labelName={t("selectImagesLabel")}
                onAdd={onAddImage}
                onRemove={onDeleteImage}
                images={p?.images || []}
                onDoubleClick={(image) => {
                  onPropertyChange({ defaultImage: image._id });
                }}
                defaultImage={
                  p?.images ? p.images.find((o) => o._id === p?.defaultImage) : null
                }
              />
            </Space>
        </div>
      </div>
      <div
        className={
          smScreen
            ? styles.CreateProductAccordion__col
            : styles.CreateProductAccordion__row
        }
        style={{ marginTop: "20px" }}
      >
        <div className={styles.CreateProductAssociation__transitionGroup}>
          <div className={styles.CreateProductAccordion__col}>
            <TextInput
              type={"text"}
              labelName={t("pillWeightLabel")}
              isEnabled={true}
              value={p?.averagePillWeight || ""}
              validationText={t(errors.averagePillWeight, {ns: "validation"})}
              focus={false}
              onChange={(val) => {
                onPropertyChange({ averagePillWeight: val });
              }}
            />
          </div>

          <div className={styles.CreateProductAccordion__col}>
            <TextInput
              type={"text"}
              labelName={t("pillColorLabel")}
              isEnabled={true}
              value={p?.pillColor || ""}
              validationText={t(errors.pillColor, {ns: "validation"})}
              focus={false}
              onChange={(val) => {
                onPropertyChange({ pillColor: val });
              }}
            />
          </div>
        </div>

        <div className={styles.CreateProductAssociation__transitionGroup}>
          <div className={styles.CreateProductAccordion__col}>
            <TextInput
              type={"text"}
              labelName={t("sideMarkings1Label")}
              isEnabled={true}
              value={p?.pillSide1Markings || ""}
              validationText={t(errors.pillSide1Markings, {ns: "validation"})}
              focus={false}
              onChange={(val) => {
                onPropertyChange({ pillSide1Markings: val });
              }}
            />
          </div>

          <div className={styles.CreateProductAccordion__col}>
            <TextInput
              type={"text"}
              labelName={t("sideMarkings2Label")}
              isEnabled={true}
              value={p?.pillSide2Markings || ""}
              validationText={t(errors.pillSide2Markings, {ns: "validation"})}
              focus={false}
              onChange={(val) => {
                onPropertyChange({ pillSide2Markings: val });
              }}
            />
          </div>
        </div>
      </div>
      <div
        className={
          smScreen
            ? styles.CreateProductAccordion__col
            : styles.CreateProductAccordion__row
        }
        style={{ marginTop: smScreen || mdScreen ? "0" : "20px" }}
      >
        <div className={styles.CreateProductAccordion__col}>
          <TextInput
            type={"text"}
            labelName={t("packageDescriptionLabel")}
            isEnabled={true}
            value={p?.packageDescription || ""}
            validationText={t(errors.packageDescription, {ns: "validation"})}
            focus={false}
            onChange={(val) => {
              onPropertyChange({ packageDescription: val });
            }}
          />
        </div>
        <div className={styles.CreateProductAccordion__col}>
          <TextInput
            type={"number"}
            labelName={t("packageSizeLabel")}
            isEnabled={true}
            value={p?.packageQuantity || ""}
            validationText={t(errors.packageQuantity, {ns: "validation"})}
            focus={false}
            onChange={(val) => {
              onPropertyChange({ packageQuantity: val });
            }}
          />
        </div>
      </div>
    </Accordion>
  );
};

export default CreateProductPhysicalAttributes;
