export default function validate(values) {
    let errors = {};

    if(!values.oldPassword) {
        errors.oldPassword = 'missingOldPassword';
    }

    if(!values.newPassword) {
        errors.newPassword = "missingNewPassword";
    }

    if(!values.confirmPassword) {
        errors.confirmPassword = "missingConfirmPassword";
    }

    if(values.newPassword !== values.confirmPassword) {
        errors.confirmPassword = "invalidConfirmPassword";
    }

    return errors;
}
