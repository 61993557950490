import React, { Fragment, useState, useEffect, useRef } from "react";
import AssociateProductBarcodeController from "./AssociateProductBarcodeController";
import LoginForm from "./LoginForm";
import NDCProduct from "./NDCProduct";
import Barcode from "./Barcode";
import styles from "./Confirmation.module.css";
import { useTranslation } from "react-i18next";

const Confirmation = ({
  scannedBarcodes,
  user,
  productBarcode,
  selectedProductForAssoc,
  setSelectedProductForAssoc,
  userCredentials,
  setUserCredentials,
  newAssociationComplete,
  setNewAssociationComplete,
  setProductNotFound,
  setAssociateBarcodeWithProduct,
  setProductsSearchText,
  setProductScanned,
  setPreviousProductScanned,
  authorizationFailure,
  setAuthorizationFailure,
  stockLocationScanned,
  setProductRescanned,
  setProductAssociationNotFound,
  setScannedProducts,
  setScannedStockedItemsQuantities,
}) => {
  const { t } = useTranslation("associateProductBarcode");

  const _controller = useRef(null);

  useEffect(() => {
    _controller.current = new AssociateProductBarcodeController(user);
  }, []);

  const associateHandler = () => {
    const successCallback = (result) => {
      if (result) {
        setUserCredentials("Authorized");
        let tempProduct = { ...selectedProductForAssoc };
        tempProduct.barcodeList = [
          ...tempProduct.barcodeList,
          scannedBarcodes[scannedBarcodes.length - 1],
        ];
        _controller.current.updateProduct(tempProduct, callback);
      } else {
        setUserCredentials("Not Authorized");
      }
    };

    const failureCallback = (error) => {
      console.error("Error verifying authorization: " + error);
    };

    // isUserAuthorized returns a promise
    _controller.current
      .isUserAuthorized(user)
      .then(successCallback, failureCallback);
  };

  // updateProduct
  const callback = (error, product) => {
    if (!error) {
      setNewAssociationComplete(true);
      if (!stockLocationScanned) {
        setProductScanned(product);
        setPreviousProductScanned(product);
      } else {
        // Needed in ScanProduct.js;
        product.aliasBarcode = scannedBarcodes[scannedBarcodes.length - 1];

        product.barcode = productBarcode;
        setProductRescanned(product);
        setScannedProducts((prevState) => {
          return [...prevState, product];
        });
        setScannedStockedItemsQuantities((prevState) => {
          return [...prevState, product.packageQuantity];
        });
      }
    } else {
      console.log("error:", error);
    }
  };

  const continueHandler = () => {
    const product = selectedProductForAssoc;
    setProductNotFound(false);
    setAssociateBarcodeWithProduct(false);
    setProductsSearchText("");
    setSelectedProductForAssoc("");
    setUserCredentials("");
    setNewAssociationComplete(false);
    setProductAssociationNotFound(false);
    // setProductAssociationNotFound(false);
  };

  const cancelHandler = () => {
    setSelectedProductForAssoc("");
    setUserCredentials("");
  };

  let productAndBarcodeInfo = (
    <Fragment>
      <div>
        <h2 className={styles.confirmation__heading}>{t("productLabel")} </h2>
        <NDCProduct
          product={selectedProductForAssoc}
          setSelectedProductForAssoc={setSelectedProductForAssoc}
        />
      </div>
      <div>
        <h2 className={styles.confirmation__heading}>{t("barcodeLabel")} </h2>
        <Barcode productBarcode={productBarcode} />
      </div>
    </Fragment>
  );

  let bodyOutput = null;

  if (
    (userCredentials === "" || userCredentials === "Authorized") &&
    !newAssociationComplete
  ) {
    bodyOutput = (
      <div className={styles.confirmation__buttons}>
        <button
          className={[
            styles["confirmation__button"],
            styles["confirmation__button--create"],
          ].join(" ")}
          onClick={associateHandler}
        >
          {t("associateButtonLabel")}
        </button>
        <button
          className={[
            styles["confirmation__button"],
            styles["confirmation__button--cancel"],
          ].join(" ")}
          onClick={cancelHandler}
        >
          {t("cancelButtonLabel")}
        </button>
      </div>
    );
  } else if (userCredentials === "Authorized" && newAssociationComplete) {
    const message = t("associatedMessage", {barcode: productBarcode, product: selectedProductForAssoc.packageId});

    productAndBarcodeInfo = null;

    bodyOutput = (
      <Fragment>
        <div className={styles.confirmation__confirmationMsgBox}>
          <p className={styles.confirmation__confirmationMsg}>{message}</p>

          <button
            className={[
              styles["confirmation__button"],
              styles["confirmation__button--continue"],
            ].join(" ")}
            onClick={continueHandler}
          >
            {t("continueButtonLabel")}
          </button>
        </div>
      </Fragment>
    );
  }

  return (
    <div className={styles.confirmation__container}>
      {productAndBarcodeInfo}
      {bodyOutput}
      {userCredentials === "Not Authorized" ? (
        <LoginForm
          authorizationFailure={authorizationFailure}
          setAuthorizationFailure={setAuthorizationFailure}
          setUserCredentials={setUserCredentials}
        />
      ) : null}
    </div>
  );
};

export default Confirmation;
