import React, { useEffect, useState, useRef, useContext } from "react";
import styles from "./EV54Device.module.css";
import Accordion from "../../../components/Accordion/Accordion";
import EV54DeviceController from "./EV54DeviceController";
import TabBar from "../../../components/TabBar";
import EV54Alarms from "./alarms/EV54Alarms";
import EV54Replenishment from "./replenishment/EV54Replenishment";
import RoleCheckService from "../../../services/RoleCheckService";
import { InventoryContext } from "../../../contexts/InventoryContext";
import WaitIndicator from "../../../components/WaitIndicator";
import { useTranslation } from "react-i18next";

const EV54Device = ({ user, device, dataFromDashboard }) => {
  const { t } = useTranslation("truscriptDevice");

  const { selectedDevice, updateSelectedDevice } = useContext(InventoryContext);

  const [accordionOpen, setAccordionOpen] = useState(
    !!selectedDevice && selectedDevice._id === device._id
  );

  const contRef = useRef(null);
  const [records, setRecords] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [action, setAction] = useState("");

  const getTabs = () => {
    const tabs = [];
    if (RoleCheckService.viewDeviceAlarms(user)) {
      tabs.push({
        index: 0,
        text: t("alarmsTab"),
      });
    }
    if (RoleCheckService.viewDeviceReplenishment(user)) {
      tabs.push({
        index: 1,
        text: t("replenishTab"),
      });
    }
    return tabs;
  };

  const [tabs, setTabs] = useState(getTabs());

  useEffect(() => {
    if (dataFromDashboard) {
      if (dataFromDashboard?.selectedTabIndex) {
        setSelectedTabIndex(dataFromDashboard.selectedTabIndex);
      }
      if (dataFromDashboard?.actionItem)
        setAction(dataFromDashboard.actionItem);
    }
  }, []);

  useEffect(() => {
    if (accordionOpen) updateSelectedDevice(device);
  }, [accordionOpen]);

  useEffect(() => {
    contRef.current = new EV54DeviceController(user, device);
    contRef.current.initializeSocket(handleCommandComplete);
    contRef.current.addActiveAlarmsCallback(handleAlarm);
    contRef.current.addReplenishmentNeedsCallback(
      handleReplenishmentNeeds,
      handleStockNeeds
    );
    contRef.current.getCurrentStockFromServer(device);
    return async () => {
      contRef.current.removeActiveAlarmsCallback(handleAlarm);
      contRef.current.removeReplenishmentNeedsCallback(
        handleReplenishmentNeeds,
        handleStockNeeds
      );
      await contRef.current.releaseSocket();
    };
  }, [user, device, dataFromDashboard]);

  const handleTabClick = (tab) => {
    setSelectedTabIndex(tab.index);
  };
  const handleAlarm = (_) => {
    if (RoleCheckService.viewDeviceAlarms(user)) {
      const alarmCount = contRef.current.getActiveAlarmCount();
      const tempTabs = [...tabs];
      tempTabs[0].badge = alarmCount;
      setTabs(tempTabs);
    }
  };

  const handleReplenishmentNeeds = (_) => {
    if (RoleCheckService.viewDeviceReplenishment(user)) {
      const needsCount = contRef.current.getReplenishmentNeedsCount();
      const tempTabs = [...tabs];
      tempTabs[1].badge = needsCount;
      setTabs(tempTabs);
    }
  };

  const handleStockNeeds = (needs) => {
    // console.log(needs)
  };

  const handleCommandComplete = (ev54Command) => {
    //alert(`The ${ev54Command.commandName} completed ${ev54Command.responseSuccess === 1 ? 'successfully' : 'unsuccessfully'}`);
  };

  const getAccordionState = (boolVal) => {
    setAccordionOpen(boolVal);
  };

  if(!contRef.current){
    return WaitIndicator(t("connecting"))
  }
  return (
    <Accordion
      title={device.name}
      openByDefault={!!dataFromDashboard}
      onGetAccordionState={(val) => getAccordionState(val)}
    >
      {!action && (
        <>
          <div className={styles.EV54Device__header}>
            <div className={styles.EV54Device__tabContainer}>
              <TabBar
                tabs={tabs}
                onSelectedTab={(tabIndex) => {
                  setSelectedTabIndex(tabIndex);
                }}
                dynamicActiveIndex={
                  dataFromDashboard ? dataFromDashboard.selectedTabIndex : null
                }
              />
            </div>
            <div
              className={styles.EV54Device__actionsIconContainer}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowPopup(true);
              }}
            >
            </div>
          </div>
          <br />
          <div className={styles.EV54Device__container}>
            {accordionOpen && RoleCheckService.viewDeviceAlarms(user) &&
              selectedTabIndex === 0 && (
                <EV54Alarms controllerRef={contRef} user={user} />
              )}
            {accordionOpen && RoleCheckService.viewDeviceReplenishment(user) &&
              selectedTabIndex === 1 && (
                <div>
                  <EV54Replenishment
                    controllerRef={contRef}
                    user={user}
                    device={device}
                  />
                </div>
              )}
          </div>
        </>
      )}
    </Accordion>
  );
};

export default EV54Device;
