import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from "../../../assets/x.svg";
import { ReactComponent as BackArrowMobile } from "../../../assets/back-icon-mobile.svg";
import DropdownInput from "../../../components/DropDownInput/DropdownInput";
import Button from "../../../components/Button";
import Spacer from "../../../components/Spacer";
import styles from "./ChangePriority.module.css";
import { ScreenContext } from "../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import { getOrderPriorityList } from "../../../functions/utilities";
import { useTranslation } from "react-i18next";

const ChangePriority = ({
  user,
  pendDisOrderControllerRef,
  dispensingOrderItem,
  onCloseModal,
  handleUpdateTable,
}) => {
  const { t } = useTranslation("pendingDispensingOrder")

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const [orderPriorityList, setOrderPriorityList] = useState([]);

  useEffect(() => {
    const tempOrderPriorityList = getOrderPriorityList().map((item) => ({
      ...item,
      displayName: t(item.description, {ns: "orderPriority"})
    }));
    setOrderPriorityList(tempOrderPriorityList);
  }, []);

  const goBackHandler = () => {
    onCloseModal();
  };

  return (
    <div className={styles.ChangePriority__overlay}>
      <div className={styles.ChangePriority__view}>
        <div className={styles.ChangePriority__container}>
          <div className={styles.ChangePriority__formCloseContainer}>
            {!smScreen && (
              <CloseIcon
                fill={"#121A2D"}
                style={{ cursor: "pointer" }}
                onClick={() => onCloseModal()}
              />
            )}
          </div>

          {smScreen && (
            <section className={styles.ChangePriority__header}>
              <div
                className={styles.ChangePriority__backArrow}
                onClick={goBackHandler}
              >
                <BackArrowMobile
                  className={styles.ChangePriority__backArrowIcon}
                />
                <p className={styles.ChangePriority__goBackTitle}>{t("backButtonLabel")}</p>
              </div>
              <h1 className={styles.ChangePriority__title}>{t("changePriorityTitle")}</h1>
            </section>
          )}

          {!smScreen && (
            <section className={styles.ChangePriority__header}>
              <p className={styles.ChangePriority__title}>{t("changePriorityTitle")}</p>
            </section>
          )}

          <div className={styles.ChangePriority__column}>
            <Spacer space={50} unit={"px"} />
            <DropdownInput
              labelName={t("orderPriorityLabel")}
              isEnabled={true}
              onSelection={(item) => {
                const modifiedDispensingOrderItem = { ...dispensingOrderItem };
                const originalDispensingOrderItem = { ...dispensingOrderItem };
                modifiedDispensingOrderItem.priority = item.description;
                pendDisOrderControllerRef.updateOrderPriority(
                  originalDispensingOrderItem,
                  modifiedDispensingOrderItem
                );
                dispensingOrderItem.priority = item.description;
                onCloseModal();
                handleUpdateTable(true);
              }}
              listElements={orderPriorityList}
              value={t(dispensingOrderItem.priority, {ns: "orderPriority"})}
              display={"displayName"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePriority;
