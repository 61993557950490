import React, { useEffect, useState, useContext, Fragment } from "react";
import Loader from "react-loader-spinner";
import DateRangePickerComponent from "../../../../components/DateRangePickerComponent";
import OptiSortDailyReportCardList from "./MobileViews/OptiSortDailyReport/OptiSortDailyReportCardList";
import Button from "../../../../components/Button";
import Table from "../../../../components/Table/Table";
import { dateSelectionRangeTimeAdjustment } from "../../../../functions/dates";
import { getLocalizedDate } from "../../../../functions/localization";
import PDFReportViewer from "../../../Reports2/PDFReportViewer";
import ReportExcelExporter from "../../../Reports2/ReportExcelExporter";
import { ReactComponent as BackIcon } from "../../../../assets/back_icon.svg";
import backIcon from "../../../../assets/back_icon.svg";
import { ReactComponent as ExcelIcon } from "../../../../assets/excel.svg";
import { ReactComponent as PDFIcon } from "../../../../assets/pdf.svg";
import { ScreenContext } from "../../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./OptiSortDailyReport.module.css";
import { useTranslation } from "react-i18next";

const OptiSortDailyReport = ({
  goBackHandler,
  deviceName,
  user,
  _controllerRef,
  selectedSite,
  windowHeight,
}) => {
  const { t } = useTranslation("trusortOptiSortTable")

  const [reportData, setReportData] = useState([]);
  const [fetchingData, setFetchingData] = useState(false);
  const [showDateTimeRangePicker, setShowDateTimeRangePicker] = useState(false);
  const [selectionRange, setSelectionRange] = useState({});
  const [runReport, setRunReport] = useState(false);
  const [showPDFViewer, setShowPDFViewer] = useState(false);
  const [exportToExcel, setExportToExcel] = useState(false);

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const loader = (
    <div className={styles.OptiSortDailyReport__loader}>
      <Loader type="Rings" color="#089BAB" height={110} width={110} />
      <p>{t("fetching")}</p>
    </div>
  );

  const reportTitle = t("title");

  useEffect(() => {
    const startDate = new Date();
    const endDate = new Date();
    setSelectionRange(dateSelectionRangeTimeAdjustment(startDate, endDate));
  }, []);

  useEffect(() => {
    setReportData([]);
  }, [selectedSite]);

  const tableHeight = (+windowHeight - 280).toString() + "px";

  const handleDateRangeSelect = (ranges) => {
    const startDate = new Date(ranges.selection.startDate);
    const endDate = new Date(ranges.selection.endDate);
    setSelectionRange(dateSelectionRangeTimeAdjustment(startDate, endDate));
    setFetchingData(false);
  };

  const handleRunReport = () => {
    setRunReport(false);
    const tempReportData = [];

    setFetchingData(true);

    _controllerRef.current
      .getProcessHistory(
        user,
        selectedSite,
        selectionRange.startDate,
        selectionRange.endDate,
        true
      )
      .then((results) => {
        setRunReport(true);
        results.forEach((result) => {
          const processedDateObj = new Date(result.processedOn);
          const months = [
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
          ];
          const year = processedDateObj.getFullYear();
          const month = months[processedDateObj.getMonth()];
          const date = processedDateObj.getDate();
          const hour = processedDateObj.getHours();
          const min = processedDateObj.getMinutes();

          const processedDate = `${month}/${date}/${year}, ${hour}:${min}`;

          tempReportData.push([
            result.siteName,
            result.stockLocationName,
            processedDate,
            result.packageId,
            result.productName,
            result.manufacturer,
            result.controlLevel,
            result.itemPrice,
            result.quantity,
            result.totalPrice.toFixed(2),
          ]);
        });
        setReportData(tempReportData);
        setFetchingData(false);
      })
      .catch((error) => {
        setFetchingData(false);
        console.log(error);
      });
  };

  const DateRangePickerGroup = (
    <div className={styles.OptiSortDailyReport__modal}>
      <DateRangePickerComponent
        ranges={[selectionRange]}
        onChange={handleDateRangeSelect}
        rangeColors={["#089BAB"]}
        country={user?.defaultSite?.shippingAddress?.country}
      />
      <br />
      <Button
        labelName={t("continueButtonLabel")}
        isPrimary={true}
        onClick={() => setShowDateTimeRangePicker(false)}
      />
    </div>
  );

  const DateRangeGroup = (
    <div className={styles.OptiSortDailyReport__dateRangeGroup}>
      <p className={styles.OptiSortDailyReport__dateRangeLabel}>
        {t("datePickerLabel")}
      </p>
      <p
        className={styles.OptiSortDailyReport__dateRange}
        onClick={() => setShowDateTimeRangePicker(true)}
      >
        {getLocalizedDate(selectionRange.startDate, user?.defaultSite?.shippingAddress?.country)}{" – "}
        {getLocalizedDate(selectionRange.endDate, user?.defaultSite?.shippingAddress?.country)}
      </p>
    </div>
  );

  const ButtonGroup = (
    <div className={styles.OptiSortDailyReport__buttonGroup}>
      {reportData.length ? (
        <div className={styles.OptiSortDailyReport__fileIconContainer}>
          <PDFIcon
            className={styles.OptiSortDailyReport__fileIcon}
            onClick={() => setShowPDFViewer(true)}
          />
        </div>
      ) : null}
      {reportData.length ? (
        <div className={styles.OptiSortDailyReport__fileIconContainer}>
          <ExcelIcon
            className={styles.OptiSortDailyReport__fileIcon}
            onClick={() => setExportToExcel(true)}
          />
        </div>
      ) : null}

      <Button
        labelName={t("runButtonLabel")}
        minWidth={"123px"}
        isPrimary={true}
        onClick={handleRunReport}
      />
    </div>
  );

  const headings = [
    t("col0"),
    t("col1"),
    t("col2"),
    t("col3"),
    t("col4"),
    t("col5"),
    t("col6"),
    t("col7"),
    t("col8"),
    t("col9")
  ];

  let DesktopView;

  if (!showPDFViewer && !exportToExcel) {
    DesktopView = (
      <Fragment>
        {showDateTimeRangePicker && (
          <div className={styles.OptiSortDailyReport__overlay}></div>
        )}
        <div className={styles.OptiSortDailyReport__mainContainer}>
          {fetchingData && loader}
          {showDateTimeRangePicker && DateRangePickerGroup}
          <div className={styles.OptiSortDailyReport__reportUtility}>
            <div className={styles.OptiSortDailyReport__reportUtilityCol1}>
              <div
                className={styles.OptiSortDailyReport__backButton}
                onClick={goBackHandler}
              >
                <BackIcon />
              </div>
              <p className={styles.OptiSortDailyReport__reportName}>
                {reportTitle}
              </p>
            </div>
            {DateRangeGroup}
            {ButtonGroup}
          </div>
          <div
            style={{ height: tableHeight }}
            className={styles.OptiSortDailyReport__tableContainer}
          >
            <Table
              padScrollBar={true}
              // searchText={searchText}
              headers={headings}
              records={reportData}
            />
          </div>
        </div>
      </Fragment>
    );
  } else if (showPDFViewer) {
    DesktopView = (
      <PDFReportViewer
        user={user}
        title={reportTitle}
        headers={headings}
        data={reportData}
        collectionSequence={[]}
        onBack={() => setShowPDFViewer(false)}
        columnSums={headings.map(() => -1)}
        columnAverages={headings.map(() => -1)}
        selectionRange={selectionRange}
        showAVGRow={false}
        showSUMRow={false}
      />
    );
  } else if (exportToExcel) {
    DesktopView = (
      <ReportExcelExporter
        title={reportTitle}
        headers={headings}
        data={reportData}
        collectionSequence={[]}
        onBack={() => setExportToExcel(false)}
        columnSums={headings.map(() => -1)}
        columnAverages={headings.map(() => -1)}
        selectionRange={selectionRange}
        showAVGRow={false}
        showSUMRow={false}
      />
    );
  }
  const MobileView = (
    <div className={styles.OptiSortDailyReport__mainContainer}>
      {fetchingData && loader}
      {showDateTimeRangePicker && (
        <div className={styles.OptiSortDailyReport__overlay}>
          <div className={styles.OptiSortDailyReport__modal}>
            <DateRangePickerComponent
              ranges={[selectionRange]}
              onChange={handleDateRangeSelect}
              rangeColors={["#089BAB"]}
              country={user?.defaultSite?.shippingAddress?.country}
            />
            <br />
            <Button
              labelName={t("continueButtonLabel")}
              isPrimary={true}
              onClick={() => setShowDateTimeRangePicker(false)}
            />
          </div>
        </div>
      )}
      <div className={styles.OptiSortDailyReport__header}>
        <div className={styles.OptiSortDailyReport__titleGroup}>
          <div className={styles.OptiSortDailyReport__backButton}>
            <img src={backIcon} onClick={goBackHandler} />
          </div>
          <p className={styles.OptiSortDailyReport__reportTitle}>
            {t("title")}
          </p>
        </div>

        <div className={styles.OptiSortDailyReport__dateRangeGroup}>
          <p className={styles.OptiSortDailyReport__dateRangeLabel}>
            {t("datePickerLabel")}
          </p>
          <p
            className={styles.OptiSortDailyReport__dateRange}
            onClick={() => setShowDateTimeRangePicker(true)}
          >
            {selectionRange.startDate?.toLocaleDateString() || ""}{" – "}
            {selectionRange.endDate?.toLocaleDateString() || ""}
          </p>
        </div>
      </div>
      <div className={styles.OptiSortDailyReport__buttonGroup}>
        <div className={styles.OptiSortDailyReport__runReportButtonContainer}>
          <Button
            labelName={t("runButtonLabel")}
            minWidth={"123px"}
            isPrimary={true}
            onClick={handleRunReport}
          />
        </div>
      </div>
      {runReport && (
        <div className={styles.OptiSortDailyReport__cardListContainer}>
          <OptiSortDailyReportCardList
            headings={headings}
            data={reportData}
            fetchingData={fetchingData}
          />
        </div>
      )}
    </div>
  );
  return smScreen ? MobileView : DesktopView;
};

export default OptiSortDailyReport;
