import React from "react";
import styles from "./TrayCheckTags.module.css";
import i18n from "../../i18nextConf";

export const ControlMedTag = () => (
  <div className={styles.ControlMedTag}>
    <h1 className={styles.ControlMedTagText}>{i18n.t("controlTag", {ns: "trayCheck"})}</h1>
  </div>
);

export const PartialMedTag = ({ label }) => (
  <div className={styles.PartialMedTag}>
    <h1 className={styles.PartialMedTagText}>{label}</h1>
  </div>
);
