import React, { useState, useEffect, useContext, Fragment } from "react";
import { ReactComponent as CloseIcon } from "../../assets/x.svg";
import { ReactComponent as BackArrowMobile } from "../../assets/back-icon-mobile.svg";
import Button from "../../components/Button";
import Spacer from "../../components/Spacer";
import { useTranslation } from "react-i18next";
import ErrorBanner from "../../components/ErrorBanner";
import CreateProductCategoryDataCard from "./CreateProductCategoryDataCard";
import { ReactComponent as CreateButton } from "../../assets/create-button-icon.svg";
import { ReactComponent as DeleteButton } from "../../assets/trash-icon-small.svg";
import { ReactComponent as EditButton } from "../../assets/edit-icon-small.svg";
import ContextMenu from "../../components/ContextMenu";
import ProductService from "../../services/ProductService";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./CreateProductCategory.module.css";

const ProductCategories = ({ user, onClose }) => {
  const { t } = useTranslation("productCategories");

  const [error, setError] = useState("");
  const [contextMenu, setContextMenu] = useState(null);
  const [categories, setCategories] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [showEditModal, setShowEditModal] = useState(false);
  const [editCategory, setEditCategory] = useState(null);

  const { smallScreen, mediumScreen, largeScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);
  const mdScreen = useMediaQuery(mediumScreen);
  const lgScreen = useMediaQuery(largeScreen);

  useEffect(() => {
    ProductService.getProductCategories(user).then((results) => {
      setCategories(results);
    });
  }, [user]);

  const handleSearch = (e) => {
    let searchText = e.currentTarget.value;
    setFilterText(searchText);
  };

  const handleCreateCategory = () => {
    let newCategoryName = filterText;
    if (newCategoryName) {
      ProductService.productCategoryExists(user, newCategoryName)
        .then((exists) => {
          if (exists) {
            setError(t("existingCategory", {category: newCategoryName}));
          } else {
            ProductService.addProductCategory(user, newCategoryName)
              .then((pc) => {
                setCategories([...categories, pc]);
                setFilterText("");
                document.querySelector("#searchText").value = "";
              })
              .catch((error) => {
                setError(error);
              });
          }
        })
        .catch((error) => {
          setError(error);
        });
    }
  };

  const handleDeleteCategory = (category) => {
    ProductService.removeProductCategory(user, category)
      .then((pcDoc) => {
        let index = categories.findIndex((o) => o._id === pcDoc._id);
        let list = [...categories];
        list.splice(index, 1);
        setCategories(list);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const handleEditCategory = (category) => {
    setEditCategory({ ...category });
    setShowEditModal(true);
  };

  const handleNameChange = async () => {
    ProductService.productCategoryExists(user, editCategory.name)
      .then((exists) => {
        if (exists) {
          setError(t("existingCategory", {category: editCategory.name}));
        } else {
          ProductService.updateProductCategory(user, editCategory)
            .then((pc) => {
              let copyCategories = [...categories];
              let index = copyCategories.findIndex((o) => o._id === pc._id);
              copyCategories[index] = pc;
              setCategories(copyCategories);
              setShowEditModal(false);
              setEditCategory(null);
            })
            .catch((error) => {
              setShowEditModal(false);
              setEditCategory(null);
              setError(error);
            });
        }
      })
      .catch((error) => {
        setShowEditModal(false);
        setEditCategory(null);
        setError(error);
      });
  };

  // const getActionMenu = (category) => {
  //     let listItems = [
  //
  //     ];
  //
  //     return listItems;
  // }

  // const handleAction = (action, category) =>{
  //     let newCategory = {...category};
  //     switch (action.type) {
  //         case "rename":
  //
  //             break;
  //         case "delete":
  //
  //             break;
  //
  //         default:
  //             break;
  //     }
  // }

  const goBackHandler = () => {
    onClose(categories);
  };

  return (
    <div className={styles.createProductCategory__overlay}>
      {smScreen && error && (
        <div className={styles.createProductCategory__errorBannerContainer}>
          <ErrorBanner message={error} onClose={() => setError(null)} />
        </div>
      )}
      <div className={styles.createProductCategory__view}>
        <div className={styles.createProductCategory__container}>
          {!showEditModal && (
            <Fragment>
              <div className={styles.createProductCategory__formCloseContainer}>
                {!smScreen && (
                  <CloseIcon
                    fill={"#121A2D"}
                    style={{ cursor: "pointer" }}
                    onClick={() => onClose(categories)}
                  />
                )}
              </div>

              {smScreen && (
                <section className={styles.createProductCategory__header}>
                  <div
                    className={styles.createProductCategory__backArrow}
                    onClick={goBackHandler}
                  >
                    <BackArrowMobile
                      className={styles.createProductCategory__backArrowIcon}
                    />
                    <p className={styles.createProductCategory__goBackTitle}>
                      {t("goBackTitle")}
                    </p>
                  </div>
                  <h1 className={styles.createProductCategory__title}>
                    {t("title")}
                  </h1>
                </section>
              )}

              {!smScreen && (
                <div className={styles.createProductCategory__header}>
                  <div className={styles.createProductCategory__title}>
                    {t("title")}
                  </div>
                </div>
              )}

              {!smScreen && error && (
                <div
                  className={styles.createProductCategory__errorBannerContainer}
                >
                  <ErrorBanner message={error} onClose={() => setError(null)} />
                </div>
              )}
              {lgScreen && <Spacer space={70} unit={"px"} />}
              {mdScreen && <Spacer space={35} unit={"px"} />}
              <div className={styles.createProductCategory__row}>
                <input
                  className={styles.createProductCategory__addCategoryInput}
                  id={"searchText"}
                  type={"text"}
                  placeholder={t("placeholderText")}
                  onChange={handleSearch}
                />
                <CreateButton
                  style={{ cursor: "pointer" }}
                  stroke={"#089BAB"}
                  onClick={handleCreateCategory}
                />
              </div>

              {lgScreen && <Spacer space={100} unit={"px"} />}
              {mdScreen && <Spacer space={50} unit={"px"} />}

              {/* Generation of Data Table Headings */}
              {!smScreen && (
                <ul className={styles.createProductCategory__tableHeader}>
                  <li className={`${styles.createProductCategory__name}`}>
                    {t("col0")}
                  </li>
                  {lgScreen && (
                    <li
                      className={styles.createProductCategory__actionsHeading}
                    >
                      {t("col1")}
                    </li>
                  )}
                </ul>
              )}
              <Spacer space={smScreen ? 0 : 20} unit={"px"} />

              {/* Generation of Data Table */}

              <div className={styles.createProductCategory__scroll}>
                {categories.length > 0 &&
                  categories
                    .filter((o) =>
                      filterText
                        ? o.name
                            .toUpperCase()
                            .includes(filterText.toUpperCase())
                        : true
                    )
                    .sort((a, b) => {
                      if (a.name.toUpperCase() < b.name.toUpperCase()) {
                        return -1;
                      }
                      if (a.name.toUpperCase() > b.name.toUpperCase()) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((category) => {
                      return !smScreen ? (
                        <ul
                          className={styles.createProductCategory__listItem}
                          key={category._id}
                        >
                          <li
                            className={`${styles.createProductCategory__name}`}
                          >
                            {category.name}
                          </li>

                          <li
                            className={
                              styles.createProductCategory__actionsContainer
                            }
                          >
                            {/*<div onClick={(e)=>{*/}
                            {/*    let left = e.currentTarget.offsetLeft + e.currentTarget.offsetWidth;*/}
                            {/*    let top = e.currentTarget.offsetTop + e.currentTarget.offsetHeight + 2;*/}
                            {/*    setContextMenu({left: left, top: top, listItems: getActionMenu(category), isRightAligned: true, onSelect: (action)=>{*/}
                            {/*            handleAction(action, category);*/}
                            {/*            setContextMenu(null)*/}
                            {/*        }});*/}
                            {/*}}>*/}
                            <div className={styles.createProductCategory__row}>
                              {category.name?.toLowerCase() !== "drug" && (
                                <>
                                  <EditButton
                                    style={{ width: 20, height: 20 }}
                                    onClick={() => handleEditCategory(category)}
                                  />
                                  <Spacer unit={"px"} space={10} />
                                  <DeleteButton
                                    style={{ width: 20, height: 20 }}
                                    onClick={() =>
                                      handleDeleteCategory(category)
                                    }
                                  />
                                </>
                              )}
                            </div>

                            {/*</div>*/}
                          </li>
                        </ul>
                      ) : (
                        <CreateProductCategoryDataCard
                          key={category._id}
                          data={{
                            categoryNameHeading: t("col0"),
                            actionsHeading: t("col1"),
                            categoryName: category.name,
                            actions: (
                              <div
                                className={styles.createProductCategory__row}
                              >
                                {category.name?.toLowerCase() !== "drug" && (
                                  <>
                                    <EditButton
                                      style={{ width: 20, height: 20 }}
                                      onClick={() =>
                                        handleEditCategory(category)
                                      }
                                    />
                                    <Spacer unit={"px"} space={10} />
                                    <DeleteButton
                                      style={{ width: 20, height: 20 }}
                                      onClick={() =>
                                        handleDeleteCategory(category)
                                      }
                                    />
                                  </>
                                )}
                              </div>
                            ),
                          }}
                        />
                      );
                    })}
              </div>

              <div className={styles.createProductCategory__footer}>
                <Button
                  minWidth={smScreen ? "100%" : null}
                  labelName={t("cancelButtonLabel")}
                  isPrimary={false}
                  isDisabled={false}
                  onClick={() => onClose(categories)}
                />
              </div>
              {contextMenu && (
                <ContextMenu
                  onSelect={contextMenu.onSelect}
                  listItems={contextMenu.listItems}
                  top={contextMenu.top}
                  left={contextMenu.left}
                  isRightAligned={contextMenu.isRightAligned}
                  maxHeight={contextMenu.maxHeight}
                  onClose={() => setContextMenu(null)}
                />
              )}
            </Fragment>
          )}

          {showEditModal && (
            // <div className={styles.createProductCategory__modalOverlay}>
            //   <div className={styles.createProductCategory__modal}>
            <div className={styles.createProductCategory__editModalContainer}>
              <div
                className={`${styles.createProductCategory__header} ${styles["createProductCategory__header--editModal"]}`}
              >
                <div className={styles.createProductCategory__title}>
                  {t("editTitle")}
                </div>

                <div className={styles.createProductCategory__close}>
                  {lgScreen && (
                    <CloseIcon
                      fill={"#121A2D"}
                      style={{ cursor: "pointer" }}
                      onClick={() => onClose(categories)}
                    />
                  )}
                </div>
              </div>

              <input
                className={styles.createProductCategory__addCategoryInput}
                autoFocus={true}
                type={"text"}
                value={editCategory.name}
                onChange={(e) => {
                  let val = e.currentTarget.value;
                  setEditCategory({ ...editCategory, name: val });
                }}
              />
              <div className={styles.createProductCategory__footer}>
                <Button
                  minWidth={smScreen ? 335 : null}
                  labelName={t("cancelButtonLabel")}
                  isPrimary={false}
                  isDisabled={false}
                  onClick={() => {
                    setEditCategory(null);
                    setShowEditModal(false);
                  }}
                />
                <Spacer space={20} unit={"px"} />
                <Button
                  minWidth={smScreen ? 335 : 213}
                  labelName={t("saveCategoryNameButtonLabel")}
                  isPrimary={true}
                  isDisabled={false}
                  onClick={handleNameChange}
                />
              </div>
            </div>
            //   </div>
            // </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductCategories;
