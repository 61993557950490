import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import stylesAll from "./view.module.css";
import styles from "./InventoryTransferView.module.css";
import Header from "../commonUI/Header";
import InventoryTransferPullLocationView from "./InventoryTransferPullLocationView";
import InventoryTransferPlaceLocationView from "../PlaceStock/InventoryTransferPlaceLocationView";
import { ReactComponent as PullIcon } from "../../../assets/arrow-downward.svg";
import { ReactComponent as PlaceIcon } from "../../../assets/checked-box.svg";
import { useMediaQuery } from "react-responsive";
import { ScreenContext } from "../../../contexts/ScreenContext";
import { useTranslation } from "react-i18next";

const InventoryTransferView = ({
  user,
  site,
  setSiteHandler,
  setActiveIcon,
  onHideModal,
  onInputValue,
}) => {
  const { t } = useTranslation("stock");

  const navigate = useNavigate();

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const [transferType, setTransferType] = useState("");

  const stateBackHandler = (transType) => {
    setTransferType(transType);
  };

  const backArrowHandler = () => {
    if (user.sites.length === 1) {
      if (!smScreen) {
        setActiveIcon("Home");
      }
      navigate("/inventory");
      onHideModal();
    } else {
      setTransferType("");
      setSiteHandler("");
    }
  };

  const pullButtonHandler = () => {
    setTransferType("Pull");
  };

  const placeButtonHandler = () => {
    setTransferType("Place");
  };

  if (!transferType) {
    return (
      <div className={stylesAll.container}>
        <Header
          backArrowHandler={backArrowHandler}
          useInput={false}
          headerTitle={t("transferTitle")}
          onHideModal={onHideModal}
          onInputValue={onInputValue}
        />

        <div className={styles.transferSelection__group}>
          <div
            onClick={pullButtonHandler}
            className={styles.transferSelection__item}
          >
            <PullIcon className={styles.transferSelection__icon} />
            <span className={styles.transferSelection__title}>{t("pullButtonLabel")}</span>
          </div>
          <div
            onClick={placeButtonHandler}
            className={styles.transferSelection__item}
          >
            <PlaceIcon className={styles.transferSelection__icon} />
            <span className={styles.transferSelection__title}>{t("placeButtonLabel")}</span>
          </div>
        </div>
      </div>
    );
  } else if (transferType === "Pull") {
    return (
      <InventoryTransferPullLocationView
        onStateBack={stateBackHandler}
        user={user}
        site={site}
        onHideModal={onHideModal}
        onInputValue={onInputValue}
      />
    );
  } else {
    return (
      <InventoryTransferPlaceLocationView
        onStateBack={stateBackHandler}
        user={user}
        site={site}
        onHideModal={onHideModal}
        transferType={transferType}
      />
    );
  }
};

export default InventoryTransferView;
