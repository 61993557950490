import React from "react";
import ProductionReportCard from "./ProductionReportCard";
import styles from "./ProductionReportCardList.module.css";
import { useTranslation } from "react-i18next";

const ProductionReportCardList = ({ headings, data }) => {
  const { t } = useTranslation("reportViewer")

  const headingsObj = {
    facilityName: headings[0],
    packagedOn: headings[1],
    stockLocationName: headings[2],
    packageId: headings[3],
    productName: headings[4],
    manufacturingAbbrName: headings[5],
    productStrength: headings[6],
    patientLastName: headings[7],
    patientFirstName: headings[8],
    rxNumber: headings[9],
    qtyNeeded: headings[10],
    qoh: headings[11],
    orderPriority: headings[12],
  };

  if (data.length) {
    return data.map((dataRow, index) => (
      <div key={index} className={styles.ProductionReportCardList__container}>
        <ProductionReportCard
          headingsObj={headingsObj}
          dataObj={{
            facilityName: dataRow[0],
            packagedOn: dataRow[1],
            stockLocationName: dataRow[2],
            packageId: dataRow[3],
            productName: dataRow[4],
            manufacturingAbbrName: dataRow[5],
            productStrength: dataRow[6],
            patientLastName: dataRow[7],
            patientFirstName: dataRow[8],
            rxNumber: dataRow[9],
            qtyNeeded: dataRow[10],
            qoh: dataRow[11],
            orderPriority: dataRow[12],
          }}
        />
        {index < data.length - 1 && (
          <hr className={styles.ProductionReportCardList__hr} />
        )}
      </div>
    ));
  } else {
    return (
      <div className={styles.ProductionReportCardList__noDataFoundMessage}>
        <p>{t("noDataLabel")}</p>
      </div>
    );
  }
};

export default ProductionReportCardList;
