import React, { useState, useContext } from "react";
import addPhoneBtnImage from "../../assets/add-phone-btn.svg";
import TextInput from "../TextInput";
import { ReactComponent as RemoveIcon } from "../../assets/x.svg";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { useTranslation } from "react-i18next";
import styles from "./PhoneList.module.css";
import Popup from "../PopupMenu/Popup";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import Button from "../../components/Button";
import { ReactComponent as PlusIcon } from "../../assets/add-phone-icon.svg";


const PhoneList = ({
  country,
  phoneNumbers,
  readonly,
  onAdd,
  onRemove,
  onChange,
  errors,
  height,
}) => {
  const { t } = useTranslation("phoneList");

  const countryCode = {
    "United States": "US",
    "Canada": "CA",
  }[country] || "US";

  const [showPopup, setShowPopup] = useState(false);

  const { smallScreen, mediumScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);
  const mdScreen = useMediaQuery(mediumScreen);

  const phoneTypes = [
    { type: "mobile", description: t("mobileDescription") },
    { type: "office", description: t("officeDescription") },
    { type: "facility", description: t("facilityDescription") },
    { type: "main", description: t("mainDescription") },
    { type: "work", description: t("workDescription") },
    { type: "fax", description: t("faxDescription") },
    { type: "department", description: t("departmentDescription") },
    { type: "other", description: t("otherDescription") },
  ];

  const handleTypeSelection = (index, phoneTypeObj) => {
    let phoneNumber = phoneNumbers[index];
    phoneNumber.type = phoneTypeObj;
    onChange(index, phoneNumber);
  };

  const handleNumberChange = (index, number) => {
    let phoneNumber = phoneNumbers[index];
    phoneNumber.number = formatPhoneNumberIntl(number);
    onChange(index, phoneNumber);
  };

  const addPhoneNumber = () => {
    onAdd({
      type: "",
      number: "",
    });
  };

  const removePhoneNumber = (index) => {
    onRemove(index);
  };

  return (
    <div className={styles.PhoneList__container} style={{ height: "100%" }}>
      <div
        className={styles.PhoneList__body}
        style={
          smScreen ? { maxHeight: "100%" } : { maxHeight: height || "250px" }
        }
      >
        {phoneNumbers.map((phoneNumber, index) => {
          return (
            <div
              key={index}
              className={
                smScreen || mdScreen
                  ? styles.PhoneList__column
                  : styles.PhoneList__row
              }
            >
              <TextInput
                value={phoneNumber.type ? phoneNumber.type.description : ""}
                labelName={t("typeLabel")}
                placeholder={""}
                isEnabled={false}
                validationText={errors ? t(errors[index], {ns: "validation"}) : ""}
                focus={false}
                type={"text"}
              />
              <span style={{ width: "10px" }} />
              <TextInput
                value={phoneNumber.number || ""}
                labelName={t("phoneNumberLabel")}
                placeholder={""}
                isEnabled={true}
                validationText={errors ? t(errors[index], {ns: "validation"}) : ""}
                focus={false}
                type={"tel"}
                countryCode={countryCode}
                onChange={(number) => {
                  handleNumberChange(index, number);
                }}
              />
              {!readonly && (
                <div
                  className={styles.PhoneList__remove_button}
                  onClick={() => removePhoneNumber(index)}
                >
                  <RemoveIcon fill={"#121A2D"} />
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className={styles.PhoneList__footer}>
        <div
          className={styles.PhoneList__button}
          onClick={() => setShowPopup(true)}
        >
          <Button
            style={
              smScreen
                ? { width: "210px", height: "40px" }
                : { width: "210px", height: "40px" }
            }
            icon={<PlusIcon />}
            labelName={t("buttonLabel")}
            isGray={true}
            minWidth={smScreen ? 280 : null}
          />
        </div>
        {showPopup && (
          <Popup
            menuItems={phoneTypes}
            onClose={() => setShowPopup(false)}
            rightJustify={true}
            onAction={(listitem) => {
              setShowPopup(false);
              onAdd({
                type: listitem,
                number: "",
              });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default PhoneList;
