import React, { useEffect, useState, useContext, Fragment } from "react";
import SuctionCupLifeCardList from "./MobileViews/SuctionCupLife/SuctionCupLifeCardList";
import Button from "../../../../components/Button";
import Table from "../../../../components/Table/Table";
import Popup from "../../../../components/PopupMenu/Popup";
import backIcon from "../../../../assets/back_icon.svg";
import { ReactComponent as MoreActionsIcon } from "../../../../assets/more-actions.svg";
import { ReactComponent as MoreActionsMobileIcon } from "../../../../assets/more-actions-mobile.svg";
import { ScreenContext } from "../../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./SuctionCupTable.module.css";
import { useTranslation } from "react-i18next";

const SuctionCupTable = ({
  user,
  suctionCupData,
  goBackHandler,
  deviceName,
  windowHeight,
  _controllerRef,
  onUpdateSuctionCupLifeGraphToggle,
}) => {
  const { t } = useTranslation("trusortSuctionCupTable")

  const [items, setItems] = useState([]);
  const [showPopup, setShowPopup] = useState(null);
  const [popupIndex, setPopupIndex] = useState(-1);

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const actionMenu = [
    {
      type: "reset",
      description: t("resetButtonLabel"),
    },
    {
      type: "order",
      description: t("orderButtonLabel"),
    },
  ];

  const handleAction = (action, index) => {
    switch (action.type) {
      case "reset":
        _controllerRef.current
          .resetSuctionCupLife(user, suctionCupData.stockLocation)
          .then((res) => {
            // console.log(res?.results);
            onUpdateSuctionCupLifeGraphToggle();
          })
          .catch((error) => {
            console.log(error);
          });
        break;
      case "order":
        console.log("index: ", index);
        alert("Order");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (true) {
      // get and set suction cup table data here
    }
  }, []);

  useEffect(() => {
    (async () => {
      const tempItems = [];

      try {
        const actions = (
          <div
            className={styles.SuctionCup__actionsContainer}
            onClick={(e) => {
              if (!showPopup) {
                setShowPopup(true);
                setPopupIndex(0);
              }
            }}
          >
            {smScreen ? <MoreActionsMobileIcon /> : <MoreActionsIcon />}
            {showPopup && popupIndex === 0 && (
              <Popup
                menuItems={actionMenu}
                onAction={(action) => {
                  setShowPopup(null);
                  setPopupIndex(-1);
                  handleAction(action, 0);
                }}
                onClose={() => setShowPopup(null)}
                rightJustify={true}
              />
            )}
          </div>
        );

        let year, month, day, lastReset;
        if (
          suctionCupData?.stockLocation?.device?.settings?.suctionCupLastReset
        ) {
          year =
            suctionCupData?.stockLocation?.device?.settings?.suctionCupLastReset.substring(
              0,
              4
            );

          month =
            suctionCupData?.stockLocation?.device?.settings?.suctionCupLastReset.substring(
              5,
              7
            );

          day =
            suctionCupData?.stockLocation?.device?.settings?.suctionCupLastReset.substring(
              8,
              10
            );
          lastReset = `${month}/${day}/${year}`;
        } else {
          lastReset = "--";
        }

        const lifeDays =
          suctionCupData?.stockLocation?.device?.settings?.suctionCupLifeDays;

        let resetBy;

        if (
          suctionCupData?.stockLocation?.device?.settings?.suctionCupLastResetBy
        ) {
          resetBy =
            suctionCupData?.stockLocation.device.settings.suctionCupLastResetBy;
        } else {
          resetBy = "--";
        }

        tempItems.push([
          t("taskValue"),
          lifeDays,
          t("instructionsValue"),
          lastReset,
          resetBy,
          actions,
        ]);
        setItems(tempItems);
      } catch (e) {
        console.log(e.message);
      }
    })();
  }, [showPopup, popupIndex, suctionCupData, smScreen]);

  const tableHeight = (+windowHeight - 200).toString() + "px";

  const headers = [t("col0"), t("col1"), t("col2"), t("col3"), t("col4"), t("col5")];

  const DesktopView = (
    <Fragment>
      <div className={styles.SuctionCup__header}>
        <h1>{t("title")}, {suctionCupData?.stockLocation?.name}</h1>
      </div>
      <div
        className={styles.SuctionCup__mainContainer}
        style={{ height: tableHeight }}
      >
        <div className={styles.SuctionCup__tableContainer}>
          <Table
            padScrollBar={true}
            headers={headers}
            records={items}
            showPopup={showPopup}
            popupIndex={popupIndex}
            tableRowHeight={37}
            scrollAdjustConstant={130}
          />
        </div>
        <div className={styles.SuctionCup__buttonGroup}>
          <Button
            onClick={goBackHandler}
            labelName={t("backButtonLabel")}
            isPrimary={false}
            isDisabled={false}
            minWidth={null}
          />
        </div>
      </div>
    </Fragment>
  );

  const MobileView = (
    <div className={styles.SuctionCup__mainContainer}>
      <div className={styles.SuctionCup__header}>
        <div className={styles.SuctionCup__backButton}>
          <img src={backIcon} onClick={goBackHandler} />
        </div>
        <p>{t("title")}, {suctionCupData?.stockLocation?.name}</p>
      </div>
      {
        <div className={styles.SuctionCup__cardListContainer}>
          <SuctionCupLifeCardList headings={headers} data={items} />
        </div>
      }
    </div>
  );

  return smScreen ? MobileView : DesktopView;
};

export default SuctionCupTable;
