import React from "react";
import EV54CanisterDetailsCard from "./EV54CanisterDetailsCard";
import styles from "./EV54CanisterDetailsCardList.module.css";
import { useTranslation } from "react-i18next";

const EV54CanisterDetailsCardList = ({ headings, data }) => {
  const { t } = useTranslation("truscriptCanisterDetailsTable")

  const headingsObj = {
    deviceName: headings[0],
    canisterNum: headings[1],
    associatedProduct: headings[2],
    numScripts: headings[3],
  };

  if (data.length) {
    return data.map((dataRow, index) => (
      <div
        key={index}
        className={styles.EV54CanisterDetailsCardList__container}
      >
        <EV54CanisterDetailsCard
          headingsObj={headingsObj}
          dataObj={{
            deviceName: dataRow[0],
            canisterNum: dataRow[1],
            associatedProduct: dataRow[2],
            numScripts: dataRow[3],
            actions: dataRow[4],
          }}
        />
        {index < data.length - 1 && (
          <hr className={styles.EV54CanisterDetailsCardList__hr} />
        )}
      </div>
    ));
  } else {
    return (
      <div className={styles.EV54CanisterDetailsCardList__noDataFoundMessage}>
        <p>{t("noData")}</p>
      </div>
    );
  }
};

export default EV54CanisterDetailsCardList;
