import React from "react";
import styles from "./PharmacySitesDataCard.module.css";

const PharmacySitesDataCard = ({ data }) => {
  return (
    <div className={styles.pharmacySitesDataCard__container}>
      <div className={styles.pharmacySitesDataCard__dataList}>
        <div className={styles.pharmacySitesDataCard__rowGroup}>
          <div
            className={`${styles.pharmacySitesDataCard__colGroup} ${styles["pharmacySitesDataCard__colGroup--1"]}`}
          >
            <div className={`${styles["pharmacySitesDataCard__heading"]}`}>
              {data.siteNameHeading}
            </div>
            <div className={`${styles["pharmacySitesDataCard__value"]}`}>
              {data.siteName}
            </div>
          </div>

          <div className={styles.pharmacySitesDataCard__actions}>
            {data.actions}
          </div>
        </div>

        <div className={styles.pharmacySitesDataCard__rowGroup}>
          <div
            className={`${styles.pharmacySitesDataCard__colGroup} ${styles["pharmacySitesDataCard__colGroup--1"]}`}
          >
            <div className={`${styles["pharmacySitesDataCard__heading"]}`}>
              {data.locationHeading}
            </div>
            <div className={`${styles["pharmacySitesDataCard__value"]}`}>
              {data.location}
            </div>
          </div>
        </div>

        <div className={styles.pharmacySitesDataCard__rowGroup}>
          <div
            className={`${styles.pharmacySitesDataCard__colGroup} ${styles["pharmacySitesDataCard__colGroup--1"]} `}
          >
            <div className={`${styles["pharmacySitesDataCard__heading"]}`}>
              {data.typeHeading}
            </div>
            <div className={`${styles["pharmacySitesDataCard__value"]}`}>
              {data.type}
            </div>
          </div>

          <div
            className={`${styles.pharmacySitesDataCard__colGroup} ${styles["pharmacySitesDataCard__colGroup--2"]}`}
          >
            <div className={`${styles["pharmacySitesDataCard__heading"]}`}>
              {data.phoneNumberHeading}
            </div>
            <div className={`${styles["pharmacySitesDataCard__value"]}`}>
              {data.phoneNumber}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PharmacySitesDataCard;
