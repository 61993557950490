import React, { useState, useEffect, Fragment } from "react";
import { getNewProductName } from "../HelpFunctions";
import HelpVideosCard from "./HelpVideosCard";
import VideoView from "./VideoView";
import Button from "../../../../components/Button";
import HelpController from "../../HelpController";
import WaitIndicator from "../../../../components/WaitIndicator";
import styles from "./HelpVideos.module.css";
import { useTranslation } from "react-i18next";

const HelpVideos = ({
  selectedDevice,
  filterText,
  onPageTitle,
  onGoBackToHelpView,
  onShowHeaderUtilities,
}) => {
  const { t } = useTranslation("help");

  const [helpVideosData, setHelpVideosData] = useState([]);
  const [videoUrls, setVideoUrls] = useState([]);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  /************ Get Help Videos List data ***********/
  useEffect(() => {
    setIsLoading(true);
    (async () => {
      const controller = new HelpController();
      const videos = await controller.getHelpVideoList();

      let tempVideoUrls = [];
      for (let i = 0; i < videos.length; i++) {
        const video = await controller.getHelpVideo(videos[i].filename);

        tempVideoUrls.push(video);
        videos[i].urlIndex = i;
      }

      setHelpVideosData(videos);
      setVideoUrls(tempVideoUrls);
      setIsLoading(false);
    })();
  }, []);

  const handleCardClick = (index) => {
    setSelectedCardIndex(index);
    onShowHeaderUtilities(false);
    onPageTitle(helpVideosData[index].title);
  };

  const handleSelectedCardIndex = (val) => {
    setSelectedCardIndex(val);
  };

  const VideoList = (
    <Fragment>
      <ul className={styles.helpVideos__helpVideosList}>
        {helpVideosData
          .filter((obj) => {
            if (
              obj &&
              obj?.title &&
              obj?.type &&
              obj?.filename &&
              obj?.urlIndex >= 0
            ) {
              const type = getNewProductName(obj.type);
              return filterText
                ? (selectedDevice === "All Devices" ||
                    selectedDevice.toUpperCase() === type.toUpperCase()) &&
                    obj.title.toUpperCase().includes(filterText.toUpperCase())
                : selectedDevice === "All Devices" ||
                  selectedDevice.toUpperCase() === type.toUpperCase()
                ? true
                : false;
            } else {
              return false;
            }
          })
          .map((obj) => (
            <li key={obj.filename}>
              <HelpVideosCard
                cardTitle={obj.title}
                machineType={getNewProductName(obj.type)}
                onCardClick={() => handleCardClick(obj.urlIndex)}
              />
            </li>
          ))}
      </ul>
      <div className={styles.helpVideos__buttonGroup}>
        <Button
          onClick={onGoBackToHelpView}
          labelName={t("backButtonLabel")}
          isPrimary={false}
          isDisabled={false}
          minWidth={null}
        />
      </div>
    </Fragment>
  );

  let View;

  if (isLoading) {
    View = <WaitIndicator message={t("collectingVideosWarning")} />;
  } else if (!selectedCardIndex && selectedCardIndex !== 0) {
    View = VideoList;
  } else {
    return (View = (
      <VideoView
        onPageTitle={onPageTitle}
        onShowHeaderUtilities={onShowHeaderUtilities}
        videoUrl={videoUrls[selectedCardIndex]}
        // width="1600px"
        onSelectedCardIndex={handleSelectedCardIndex}
      />
    ));
  }

  return View;
};

export default HelpVideos;
