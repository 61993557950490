import React, { useEffect, useRef, useContext } from "react";
import styles from "./CreateProductAccordion.module.css";
import { useTranslation } from "react-i18next";
import Accordion from "../../components/Accordion/Accordion";
import TextInput from "../../components/TextInput";
import ProductController from "../../controllers/ProductController";
import ImageCarousel from "../../components/ImageCarousel";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";

const CreateProductLTCCosts = ({
  isNew,
  p,
  errors,
  onPropertyChange,
  onError,
  onAddImage,
  onDeleteImage,
}) => {
  const { t } = useTranslation("createProductCosts");
  const productControllerRef = useRef(null);

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  useEffect(() => {
    productControllerRef.current = new ProductController();
  }, []);

  return (
    <Accordion title={t("tabLtc")}>
      <div
        className={
          smScreen
            ? styles.CreateProductAccordion__col
            : styles.CreateProductAccordion__row
        }
      >
        <div className={styles.CreateProductAccordion__col}>
          <TextInput
            type={"number"}
            labelName={t("awpBulkLabel")}
            isEnabled={true}
            value={p?.ltcAwpBulk || ""}
            validationText={t(errors.ltcAwpBulk, {ns: "validation"})}
            focus={false}
            onChange={(val) => {
              onPropertyChange({ ltcAwpBulk: parseFloat(val) });
            }}
          />
        </div>
        <div className={styles.CreateProductAccordion__col}>
          <TextInput
            type={"number"}
            labelName={t("awpItemLabel")}
            isEnabled={true}
            value={p?.ltcAwpItem || ""}
            validationText={t(errors.ltcAwpItem, {ns: "validation"})}
            focus={false}
            onChange={(val) => {
              onPropertyChange({ ltcAwpItem: parseFloat(val) });
            }}
          />
        </div>
      </div>
      <div
        className={
          smScreen
            ? styles.CreateProductAccordion__col
            : styles.CreateProductAccordion__row
        }
      >
        <div className={styles.CreateProductAccordion__col}>
          <TextInput
            type={"number"}
            labelName={t("acquisitionBulkLabel")}
            isEnabled={true}
            value={p?.ltcAcquisitionBulk || ""}
            validationText={t(errors.ltcAcquisitionBulk, {ns: "validation"})}
            focus={false}
            onChange={(val) => {
              onPropertyChange({ ltcAcquisitionBulk: parseFloat(val) });
            }}
          />
        </div>
        <div className={styles.CreateProductAccordion__col}>
          <TextInput
            type={"number"}
            labelName={t("acquisitionItemLabel")}
            isEnabled={true}
            value={p?.ltcAcquisitionItem || ""}
            validationText={t(errors.ltcAcquisitionItem, {ns: "validation"})}
            focus={false}
            onChange={(val) => {
              onPropertyChange({ ltcAcquisitionItem: parseFloat(val) });
            }}
          />
        </div>
      </div>
      <div
        className={
          smScreen
            ? styles.CreateProductAccordion__col
            : styles.CreateProductAccordion__row
        }
      >
        <div className={styles.CreateProductAccordion__col}>
          <TextInput
            type={"number"}
            labelName={t("gpoBulkLabel")}
            isEnabled={true}
            value={p?.ltcGpoBulk || ""}
            validationText={t(errors.ltcGpoBulk, {ns: "validation"})}
            focus={false}
            onChange={(val) => {
              onPropertyChange({ ltcGpoBulk: parseFloat(val) });
            }}
          />
        </div>
        <div className={styles.CreateProductAccordion__col}>
          <TextInput
            type={"number"}
            labelName={t("gpoItemLabel")}
            isEnabled={true}
            value={p?.ltcGpoItem || ""}
            validationText={t(errors.ltcGpoItem, {ns: "validation"})}
            focus={false}
            onChange={(val) => {
              onPropertyChange({ ltcGpoItem: parseFloat(val) });
            }}
          />
        </div>
      </div>
      <div
        className={
          smScreen
            ? styles.CreateProductAccordion__col
            : styles.CreateProductAccordion__row
        }
      >
        <div className={styles.CreateProductAccordion__col}>
          <TextInput
            type={"number"}
            labelName={t("340bBulkLabel")}
            isEnabled={true}
            value={p?.ltcThree40bBulk || ""}
            validationText={t(errors.ltcThree40bBulk, {ns: "validation"})}
            focus={false}
            onChange={(val) => {
              onPropertyChange({ ltcThree40bBulk: parseFloat(val) });
            }}
          />
        </div>
        <div className={styles.CreateProductAccordion__col}>
          <TextInput
            type={"number"}
            labelName={t("340bItemLabel")}
            isEnabled={true}
            value={p?.ltcThree40bItem || ""}
            validationText={t(errors.ltcThree40bItem, {ns: "validation"})}
            focus={false}
            onChange={(val) => {
              onPropertyChange({ ltcThree40bItem: parseFloat(val) });
            }}
          />
        </div>
      </div>
    </Accordion>
  );
};

export default CreateProductLTCCosts;
