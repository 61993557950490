import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useContext,
} from "react";
import StockLocationsController from "../../controllers/StockLocationsController";
import Node from "../../functions/Node";
import { ReactComponent as BackIcon } from "../../assets/back_icon.svg";
import { ReactComponent as BackArrowMobile } from "../../assets/back-icon-mobile.svg";
import ErrorBanner from "../../components/ErrorBanner";
import Spacer from "../../components/Spacer";
import SearchBar from "../../components/SearchBar";
import RoleCheckService from "../../services/RoleCheckService";
import CreateButton from "../../components/CreateButton";
import CreateStockLocation from "./CreateStockLocation";
import StockLocationService from "../../services/StockLocationService";
import { useTranslation } from "react-i18next";
import StockLocationsHeadings from "./StockLocationsHeadings";
import StockLocationsData from "./StockLocationsData";
import StockLocationsCardList from "./mobileViews/StockLocationsCardList";
import ProductAssociation from "./ProductAssociation";
import { useNavigate } from "react-router-dom";
import WaitIndicator from "../../components/WaitIndicator";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./StockLocations.module.css";

const StockLocations = ({ user, site }) => {
  const navigate = useNavigate();

  const { t } = useTranslation("stockLocations");

  const [nodeList, setNodeList] = useState([]);
  const _controller = useRef(null);

  const [productAssociations, setProductAssociations] = useState([]);

  const [contextMenu, setContextMenu] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const green = "#089BAB";
  const black = "#121A2D";

  const ADD_STOCK_LOCATION = 1;
  const EDIT_STOCK_LOCATION = 2;
  const DELETE_STOCK_LOCATION = 3;
  const ADD_CHILD = 4;
  const ADD_PRODUCT = 5;
  const EDIT_PRODUCT = 6;
  const DELETE_PRODUCT = 7;

  const [error, setError] = useState("");
  const [filterText, setFilterText] = useState("");
  const [currentAction, setCurrentAction] = useState(0);
  const [stockLocations, setStockLocations] = useState([]);
  const [selectedStockLocation, setSelectedStockLocation] = useState(null);
  const [parent, setParent] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [name, setName] = useState("");
  const [type, setType] = useState(null);
  const [isControlled, setIsControlled] = useState(false);
  const [barcode, setBarcode] = useState("");
  const [stockLocationsList, setStockLocationsList] = useState([]);
  const [orderPriority, setOrderPriority] = useState("");
  const [active, setActive] = useState(true);
  const [device, setDevice] = useState(null);
  const [tabs, setTabs] = useState([{ index: 0, text: t("tab0") }]);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [APIndex, setAPIndex] = useState(-1);
  const [deviceId, setDeviceId] = useState("");
  const [devicePosition, setDevicePosition] = useState("");
  const [allowAutomaticDispensing, setAllowAutomaticDispensing] =
    useState(false);

  const [toggle, setToggle] = useState(false);

  const { smallScreen, mediumScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);
  const mdScreen = useMediaQuery(mediumScreen);

  useEffect(() => {
    // Hide StockLocations from mobile view.
    if (smScreen) {
      // navigate(-1);
    }
  }, [smScreen]);

  useEffect(() => {
    _controller.current = new StockLocationsController(user, site);
    const callback = (error, stockLocations) => {
      if (!error) {
        /* generate initial stock location node list (root stock locations) */
        const nodes = [];
        stockLocations.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (b.name < a.name) {
            return 1;
          }
          return 0;
        });
        stockLocations.forEach((sl) => {
          const node = new Node(sl);
          nodes.push(node);
        });
        setNodeList(nodes);
      } else {
        console.log("Error", error);
      }
    };

    const getTopData = () => {
      try {
        // Returns only the stockLocations which do not have a parent (root stock locations).  Note 'null' parameter in method parameter list.
        _controller.current.getStockLocations(null, callback);
      } catch (error) {
        console.log("error", error);
      }
    };

    getTopData();
  }, [stockLocations]);

  const handleSetContextMenu = (contextObj) => {
    setContextMenu(contextObj);
  };

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  };

  // const saveProducts = () => {
  //   const callback = (error, products) => {
  //     if (!error) {
  //       // *******************************************************************
  //       // Check to see why productAssociations is set to the products array.
  //       // *******************************************************************
  //       setProductAssociations(products);
  //     } else {
  //       console.log("Error", error);
  //     }
  //   };
  //
  //   async function doSaveData() {
  //     // Get all products for a site.
  //     // Returns a sorted array of Product (by description)
  //     _controller.current.getProducts(callback);
  //   }
  //   doSaveData();
  // };

  useEffect(() => {
    // Get stock locations for a given user and site
    setIsLoading(true);
    StockLocationService.list(user, site)
      .then((stockLocations) => {
        setStockLocations(stockLocations);
        setIsLoading(false);
      })
      .catch((error) => {
        setError(error);
        setIsLoading(false);
      });
  }, [user, site]);

  const handleCreate = () => {
    setName("");
    setBarcode("");
    setCurrentAction(ADD_STOCK_LOCATION);
  };

  const handleCreated = (stockLocation) => {
    setStockLocations((prevState) => {
      return [...prevState, stockLocation];
    });
    setCurrentAction(0);
    setSelectedStockLocation(null);
  };

  const handleSearch = (searchText) => {
    setFilterText(searchText.toUpperCase());
  };

  const filteredNodeList = nodeList.filter((node) => {
    if (
      filterText === "" ||
      node.obj.name.toUpperCase().includes(filterText.toUpperCase()) ||
      node.obj.type.description.toUpperCase().includes(filterText.toUpperCase())
    ) {
      return true;
    } else {
      if (node.isExpanded) {
        node.isExpanded = false;
      }
      return false;
    }
  });

  const handleUpdated = (stockLocation) => {
    setCurrentAction(0);
    setSelectedStockLocation(null);
    let newArray = [...stockLocations];
    let index = newArray.findIndex((o) => o._id === stockLocation._id);
    newArray[index] = stockLocation;
    setStockLocations(newArray);
  };

  const goBackHandler = () => {
    navigate(-1);
  };

  const StockLocationsDesktopView = (
    <div
      className={styles.stockLocations__mainView}
      onClick={() => handleCloseContextMenu()}
    >
      {error && (
        <>
          {mdScreen ? (
            <Spacer space={50} unit={"px"} />
          ) : (
            <Spacer space={70} unit={"px"} />
          )}
          <ErrorBanner message={error} onClose={() => setError(null)} />
          <Spacer space={10} unit={"px"} />
        </>
      )}
      {mdScreen ? (
        <Spacer space={20} unit={"px"} />
      ) : (
        <Spacer space={70} unit={"px"} />
      )}
      <div
        className={`${styles.stockLocations__flexContainer} ${styles["stockLocations__flexContainer--spaceBetween"]}`}
      >
        <SearchBar placeholder={t("searchBarText")} onSearch={handleSearch} />
        {RoleCheckService.createStockLocations(user) && (
          <CreateButton
            onClick={handleCreate}
            isEnabled={true}
            labelName={t("addButtonLabel")}
          />
        )}
      </div>
      {mdScreen ? (
        <Spacer space={50} unit={"px"} />
      ) : (
        <Spacer space={70} unit={"px"} />
      )}
      <div className={styles.stockLocations__flexContainer}>
        <BackIcon
          className={styles.stockLocations__backIcon}
          onClick={() => {
            navigate("/settings");
          }}
        />
        <Spacer space={10} unit={"px"} />
        <p className={styles.stockLocations__title}>
          {site.name}{" "}
          <span className={styles.stocklocations__subtitle}>{t("title")}</span>
        </p>
      </div>
      {!mdScreen && <Spacer space={50} unit={"px"} />}
      <StockLocationsHeadings />
      <StockLocationsData
        site={site}
        user={user}
        nodeList={nodeList}
        setNodeList={setNodeList}
        toggle={toggle}
        setToggle={setToggle}
        filteredNodeList={filteredNodeList}
        setCurrentAction={setCurrentAction}
        EDIT_STOCK_LOCATION={EDIT_STOCK_LOCATION}
        ADD_CHILD={ADD_CHILD}
        ADD_PRODUCT={ADD_PRODUCT}
        EDIT_PRODUCT={EDIT_PRODUCT}
        setSelectedStockLocation={setSelectedStockLocation}
        setParent={setParent}
        setIsSubmitting={setIsSubmitting}
        setError={setError}
        setErrors={setErrors}
        contextMenu={contextMenu}
        onSetContextMenu={handleSetContextMenu}
      />
    </div>
  );

  const StockLocationsMobileView = (
    <div
      onClick={() => handleCloseContextMenu()}
      className={styles.stockLocationsMobile__mainContainer}
    >
      {error && (
        <>
          <Spacer space={70} unit={"px"} />
          <ErrorBanner message={error} onClose={() => setError(null)} />
          <Spacer space={10} unit={"px"} />
        </>
      )}
      <div className={styles.stockLocationsMobile__header}>
        <div className={styles.stockLocationsMobile__backAndSearch}>
          <div
            className={styles.stockLocationsMobile__backArrow}
            onClick={goBackHandler}
          >
            <BackArrowMobile
              className={styles.stockLocationsMobile__backArrowIcon}
            />

            <p className={styles.stockLocationsMobile__goBackTitle}>{t("backButtonLabel")}</p>
          </div>
          <div className={styles.stockLocationsMobile__searchBarContainer}>
            <SearchBar
              placeholder={t("searchBarText")}
              onSearch={handleSearch}
              smFullWidth={true}
            />
          </div>
        </div>
        <p className={styles.stockLocations__title}>
          {site.name}{" "}
          <span className={styles.stocklocations__subtitle}>{t("title")}</span>
        </p>
        {RoleCheckService.createStockLocations(user) && (
          <div className={styles.stockLocationsMobile__createButtonContainer}>
            <CreateButton
              onClick={handleCreate}
              isEnabled={true}
              labelName={t("addButtonLabel")}
            />
          </div>
        )}
      </div>
      <StockLocationsCardList
        site={site}
        user={user}
        nodeList={nodeList}
        setNodeList={setNodeList}
        toggle={toggle}
        setToggle={setToggle}
        filteredNodeList={filteredNodeList}
        setCurrentAction={setCurrentAction}
        EDIT_STOCK_LOCATION={EDIT_STOCK_LOCATION}
        ADD_CHILD={ADD_CHILD}
        ADD_PRODUCT={ADD_PRODUCT}
        EDIT_PRODUCT={EDIT_PRODUCT}
        setSelectedStockLocation={setSelectedStockLocation}
        setParent={setParent}
        setIsSubmitting={setIsSubmitting}
        setError={setError}
        setErrors={setErrors}
        contextMenu={contextMenu}
        onSetContextMenu={handleSetContextMenu}
      />
    </div>
  );

  if (
    currentAction === ADD_STOCK_LOCATION ||
    currentAction === EDIT_STOCK_LOCATION ||
    currentAction === ADD_CHILD
  ) {
    return (
      <div
        className={
          smScreen
            ? styles.stockLocationsMobile__CreateStockLocationContainer
            : styles.stockLocations__overlay
        }
      >
        <CreateStockLocation
          user={user}
          site={site}
          nodeList={nodeList}
          setNodeList={setNodeList}
          selectedStockLocation={selectedStockLocation}
          onClose={() => {
            setCurrentAction(0);
            setSelectedStockLocation(null);
            setParent(null);
          }}
          onCreated={handleCreated}
          onUpdated={handleUpdated}
          currentAction={currentAction}
          ADD_STOCK_LOCATION={ADD_STOCK_LOCATION}
          EDIT_STOCK_LOCATION={EDIT_STOCK_LOCATION}
          DELETE_STOCK_LOCATION={DELETE_STOCK_LOCATION}
          ADD_CHILD={ADD_CHILD}
          parent={parent}
          productAssociations={productAssociations}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          error={error}
          setError={setError}
          errors={errors}
          setErrors={setErrors}
          name={name}
          setName={setName}
          type={type}
          setType={setType}
          isControlled={isControlled}
          setIsControlled={setIsControlled}
          barcode={barcode}
          setBarcode={setBarcode}
          stockLocations={stockLocations}
          setStockLocations={setStockLocations}
          stockLocationsList={stockLocationsList}
          setStockLocationsList={setStockLocationsList}
          orderPriority={orderPriority}
          setOrderPriority={setOrderPriority}
          active={active}
          setActive={setActive}
          device={device}
          setDevice={setDevice}
          tabs={tabs}
          setTabs={setTabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          deviceId={deviceId}
          setDeviceId={setDeviceId}
          devicePosition={devicePosition}
          setDevicePosition={setDevicePosition}
          allowAutomaticDispensing={allowAutomaticDispensing}
          setAllowAutomaticDispensing={setAllowAutomaticDispensing}
        />
      </div>
    );
  } /*else if (currentAction === ADD_PRODUCT || currentAction === EDIT_PRODUCT) {
    return (
      <div className={styles.stockLocations__overlay}>
        <ProductAssociation
          user={user}
          site={site}
          nodeList={nodeList}
          filteredNodeList={filteredNodeList}
          setNodeList={setNodeList}
          selectedStockLocation={selectedStockLocation}
          onClose={() => {
            setCurrentAction(0);
            setSelectedStockLocation(null);
            setParent(null);
          }}
          onCreated={handleCreated}
          onUpdated={handleUpdated}
          currentAction={currentAction}
          ADD_STOCK_LOCATION={ADD_STOCK_LOCATION}
          EDIT_STOCK_LOCATION={EDIT_STOCK_LOCATION}
          DELETE_STOCK_LOCATION={DELETE_STOCK_LOCATION}
          ADD_CHILD={ADD_CHILD}
          ADD_PRODUCT={ADD_PRODUCT}
          EDIT_PRODUCT={EDIT_PRODUCT}
          parent={parent}
          productAssociations={productAssociations}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          error={error}
          setError={setError}
          errors={errors}
          setErrors={setErrors}
          name={name}
          setName={setName}
          type={type}
          setType={setType}
          isControlled={isControlled}
          setIsControlled={setIsControlled}
          barcode={barcode}
          setBarcode={setBarcode}
          stockLocationsList={stockLocationsList}
          setStockLocationsList={setStockLocationsList}
          orderPriority={orderPriority}
          setOrderPriority={setOrderPriority}
          active={active}
          setActive={setActive}
          device={device}
          setDevice={setDevice}
          tabs={tabs}
          setTabs={setTabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          APIndex={APIndex}
          setAPIndex={setAPIndex}
        />
      </div>
    );
  }*/ else if (isLoading) {
    return (
      <WaitIndicator
        message={t("fetching")}
      />
    );
  } else {
    return smScreen ? StockLocationsMobileView : StockLocationsDesktopView;
  }
};

export default StockLocations;
