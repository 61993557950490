import ApiService from "./ApiService";
import axios from "axios";

class OrderGenerationService extends ApiService {
  static generateOrders = async (user, site, stockLocation, orderData) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/stock-locations/${stockLocation._id}/order-generator`;

      let response = await axios.post(
        url,
        {
          user: user,
          site: site,
          stockLocation: stockLocation,
          orderData: orderData,
        },
        rc
      );
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };
}

export default OrderGenerationService;
