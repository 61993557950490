import React, { useEffect, useState, useContext, Fragment } from "react";
import Loader from "react-loader-spinner";
import TruPakAlarmsCardList from "./MobileViews/TruPakAlarms/TruPakAlarmsCardList";
import Button from "../../../../components/Button";
import Table from "../../../../components/Table/Table";
import Popup from "../../../../components/PopupMenu/Popup";
import backIcon from "../../../../assets/back_icon.svg";
import { ReactComponent as MoreActionsIcon } from "../../../../assets/more-actions.svg";
import { ReactComponent as MoreActionsMobileIcon } from "../../../../assets/more-actions-mobile.svg";
import { ScreenContext } from "../../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./TruPakAlarmsTable.module.css";
import { useTranslation } from "react-i18next";

const TruPakAlarmsTable = ({
  goBackHandler,
  _controllerRef,
  user,
  selectedDevices,
  startDate,
  stopDate,
  windowHeight,
}) => {
  const { t } = useTranslation("trupakAlarmsTable")

  const [items, setItems] = useState([]);
  const [showPopup, setShowPopup] = useState(null);
  const [popupIndex, setPopupIndex] = useState(-1);
  const [alarmsData, setAlarmsData] = useState([]);
  const [fetchingAlarmsData, setFetchingAlarmsData] = useState(false);

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const loader = (
    <div className={styles.AlarmsTable__loader}>
      <Loader type="Rings" color="#089BAB" height={110} width={110} />
      <p>{t("fetching")}</p>
    </div>
  );

  const actionMenu = [
    {
      type: "linkToWiki",
      description: t("linkToWikiAction"),
    },
    {
      type: "logTicket",
      description: t("logTicketAction"),
    },
    {
      type: "chat",
      description: t("chatAction"),
    },
  ];

  const handleAction = (action) => {
    switch (action.type) {
      case "linkToWiki":
        alert("Link to Wiki");
        break;
      case "logTicket":
        alert("Order");
        break;
      case "chat":
        alert("Chat");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    async function updateTable() {
      if (selectedDevices?.length) {
        let resultsCombined = [];
        setFetchingAlarmsData(true);

        for (let i = 0; i < selectedDevices.length; i++) {
          const deviceId = selectedDevices[i]._id;
          const site = selectedDevices[i].site;

          await _controllerRef.current
            .getTruPakAlarmHistory(
              user,
              site,
              startDate,
              stopDate,
              true,
              deviceId
            )
            .then((results) => {
              if (results.length) {
                resultsCombined.push(...results);
              }
            })
            .catch((error) => {
              setFetchingAlarmsData(false);
              console.log(error);
            });
        }

        setAlarmsData(resultsCombined);
        setFetchingAlarmsData(false);
      } else {
        setAlarmsData([]);
        setFetchingAlarmsData(false);
      }
    }

    updateTable();
  }, [user, selectedDevices]);

  useEffect(() => {
    (async () => {
      const tempItems = [];

      try {
        alarmsData.forEach((tableRow, index) => {
          const dateString = tableRow.occurredAt;
          const month = dateString.substring(5, 7);
          const day = dateString.substring(8, 10);
          const year = dateString.substring(0, 4);
          const time = dateString.substring(11, 19);
          const actions = (
            <div
              className={styles.TruPakAlarmsTable__actionsContainer}
              onClick={(e) => {
                if (!showPopup) {
                  setShowPopup(true);
                  setPopupIndex(index);
                }
              }}
            >
              {smScreen ? <MoreActionsMobileIcon /> : <MoreActionsIcon />}
              {showPopup && popupIndex === index && (
                <Popup
                  menuItems={actionMenu}
                  onAction={(action) => {
                    setShowPopup(null);
                    setPopupIndex(-1);
                    handleAction(action);
                  }}
                  onClose={() => setShowPopup(null)}
                  rightJustify={true}
                />
              )}
            </div>
          );
          tempItems.push([
            tableRow.error,
            `${month}/${day}/${year}, ${time}`,
            actions,
          ]);
        });
        setItems(tempItems);
      } catch (e) {
        console.log(e.message);
      }
    })();
  }, [showPopup, popupIndex, alarmsData]);

  const tableHeight = (+windowHeight - 200).toString() + "px";

  const headers = [
    t("col0"),
    t("col1"),
    <div className={styles.TruPakAlarmsTable__actionsHeadingContainer}>
      {t("col2")}
    </div>,
  ];

  const DesktopView = (
    <Fragment>
      {fetchingAlarmsData && loader}
      <div className={styles.TruPakAlarmsTable__header}>
        <h1>{t("title")}</h1>
        <p>{t("contact")}: +1 262-729-9202</p>
      </div>
      <div
        style={{ height: tableHeight }}
        className={styles.TruPakAlarmsTable__mainContainer}
      >
        <div className={styles.TruPakAlarmsTable__tableContainer}>
          <Table
            padScrollBar={true}
            headers={headers}
            records={items}
            showPopup={showPopup}
            popupIndex={popupIndex}
            tableRowHeight={37}
            scrollAdjustConstant={160}
          />
        </div>
        <div className={styles.TruPakAlarmsTable__buttonGroup}>
          <Button
            onClick={goBackHandler}
            labelName={t("backButtonLabel")}
            isPrimary={false}
            isDisabled={false}
            minWidth={null}
          />
        </div>
      </div>
    </Fragment>
  );

  const MobileView = (
    <Fragment>
      {fetchingAlarmsData && loader}
      <div className={styles.TruPakAlarmsTable__mainContainer}>
        <div className={styles.TruPakAlarmsTable__header}>
          <div className={styles.TruPakAlarmsTable__backButton}>
            <img src={backIcon} onClick={goBackHandler} />
          </div>
          <p className={styles.TruPakAlarmsTable__reportTitle}>Alarms</p>
        </div>
        {
          <div className={styles.TruPakAlarmsTable__cardListContainer}>
            <TruPakAlarmsCardList
              headings={headers}
              data={items}
              fetchingAlarmsData={fetchingAlarmsData}
            />
          </div>
        }
      </div>
    </Fragment>
  );

  return smScreen ? MobileView : DesktopView;
};

export default TruPakAlarmsTable;
