import StockLocationsController from "../../../controllers/StockLocationsController";
import React, { useState, useEffect, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import Node from "../../../functions/Node";

import StockLocationCard from "./StockLocationCard";
import RoleCheckService from "../../../services/RoleCheckService";
import ProductController from "../../../controllers/ProductController";
import style from "./StockLocationsCardList.module.css";

const StockLocationsCardList = ({
  site,
  user,
  nodeList,
  setNodeList,
  filteredNodeList,
  setCurrentAction,
  EDIT_STOCK_LOCATION,
  ADD_CHILD,
  setSelectedStockLocation,
  setParent,
  setError,
  contextMenu,
  onSetContextMenu,
  toggle,
  setToggle,
}) => {
  const { t } = useTranslation("stockLocations");
  const _controller = useRef(null);
  const pcRef = useRef(null);

  const [expandedNode, setExpandedNode] = useState(null);
  const [filteredNodes, setFilteredNodes] = useState([]);
  const [scrollTopVal, setScrollTopValue] = useState(0);
  const [prevIndex, setPrevIndex] = useState(null);
  const [rowIndex, setRowIndex] = useState(-1);
  const [clickedNode, setClickedNode] = useState(null);

  useEffect(() => {
    var element = document.getElementById("scrollArea");

    element.onscroll = function () {
      setScrollTopValue(element.scrollTop);
    };
  }, []);

  useEffect(() => {
    setFilteredNodes(filteredNodeList);
  }, [filteredNodeList, toggle]);

  useEffect(() => {
    _controller.current = new StockLocationsController(user, site);
    pcRef.current = new ProductController();
  }, []);

  const getActionMenu = (site, stockLocation) => {
    let listItems = [...actionListItems];

    if (
      (stockLocation.children && stockLocation.children.length > 0) ||
      (stockLocation.productAssociations &&
        stockLocation.productAssociations.length > 0)
    ) {
      // Remove "Delete" option
      listItems.splice(5, 1);
    }

    if (!RoleCheckService.deleteStockLocations(user)) {
      let index = listItems.findIndex((o) => o.type === "Delete");
      if (index !== -1) {
        listItems.splice(index, 1);
      }
    }

    if (stockLocation.type.type.includes("CANISTER")) {
      let index = listItems.findIndex((o) => o.type === "Add Child");
      listItems.splice(index, 1);
    }

    if (stockLocation.isEnabled) {
      let index = listItems.findIndex((o) => o.type === "enable");
      listItems.splice(index, 1);
    } else {
      let index;
      index = listItems.findIndex((o) => o.type === "disable");
      if (index !== -1) {
        listItems.splice(index, 1);
      }
      index = listItems.findIndex((o) => o.type === "Add Child");
      if (index !== -1) {
        listItems.splice(index, 1);
      }
      // index = listItems.findIndex((o) => o.type === "Add Product");
      // if (index !== -1) {
      //   listItems.splice(index, 1);
      // }
      index = listItems.findIndex((o) => o.type === "Edit");
      if (index !== -1) {
        listItems.splice(index, 1);
      }
      index = listItems.findIndex((o) => o.type === "Delete");
      if (index !== -1) {
        listItems.splice(index, 1);
      }
    }

    return listItems;
  };

  const handleActionSelection = (action, site, setCurrentAction, node, e) => {
    switch (action.type) {
      case "Add Child":
        if (!node.isExpanded) {
          expand(node);
        }
        setParent(node);
        setSelectedStockLocation({
          obj: {
            name: null,
            type: node.obj.type,
            isControlled: null,
            orderPriority: null,
            isEnabled: true,
            device: null,
          },
        });

        setCurrentAction(ADD_CHILD);
        break;
      // case "Add Product":
      //   setCurrentAction(ADD_PRODUCT);
      //   setParent(node.parent);
      //   setSelectedStockLocation(node);
      //   break;
      case "Edit":
        setCurrentAction(EDIT_STOCK_LOCATION);
        setParent(node.parent);
        setSelectedStockLocation(node);
        break;

      case "Delete":
        if (
          node.obj.children.length > 0 ||
          node.productAssociations.length > 0
        ) {
          setError(
            t("errorExistingChildren")
          );
        } else {
          const stockLocationToDelete = { ...node.obj };

          const currentNodeList = [...nodeList];

          // Get parent node, if exists
          let parentNode = node.parent;

          const callback = (error, stockLocation) => {
            if (!error) {
              // Case where stockLocation does not have a parent
              if (!parentNode) {
                let sLIndex = currentNodeList.findIndex(
                  (node) => node.obj.id === stockLocationToDelete.id
                );
                currentNodeList.splice(sLIndex, 1);
                setNodeList(currentNodeList);
              } else {
                // if stockLocation has a parent

                parentNode.removeChildren(node);

                let parentStockLocation = parentNode.obj;
                let childIndex = parentStockLocation.children.findIndex(
                  (id) => id === stockLocationToDelete.id
                );

                parentStockLocation.children.splice(childIndex, 1);

                const callback2 = (error, stockLocation) => {
                  // Gets numOfChildren, if any, the parent stock location still has after removal of the selected child
                  let numOfChildren = parentNode.obj.children.length;

                  if (numOfChildren >= 1) {
                    collapse(parentNode);
                    expand(parentNode);
                  }
                  setNodeList(currentNodeList);
                  if (!error) {
                    setNodeList(currentNodeList);
                  } else {
                    console.log("error", error);
                  }
                };

                _controller.current.updateStockLocation(
                  parentStockLocation,
                  callback2
                );
              }
            } else {
              console.log("error", error);
            }
          };
          _controller.current.deleteStockLocation(node.obj, callback);
        }

        break;
      case "enable":
        enableStockLocations(node, true);
        break;

      case "disable":
        enableStockLocations(node, false);
        break;
      default:
        break;
    }
  };

  const enableStockLocations = (parent, enabled) => {
    parent.obj.isEnabled = enabled;
    _controller.current.updateStockLocation(parent.obj);
    for (let i = 0; i < parent.children.length; i++) {
      parent.children[i].obj.isEnabled = enabled;
      _controller.current.updateStockLocation(parent.children[i].obj);
    }
  };

  const isAuthorized = () => {
    return RoleCheckService.canCreateSites(user);
  };

  const actionListItems = [
    {
      type: "enable",
      description: t("menuItem0"),
    },
    {
      type: "disable",
      description: t("menuItem5"),
    },
    {
      type: "Add Child",
      description: t("menuItem1"),
    },
    // {
    //   type: "Add Product",
    //   description: t("menuItem2"),
    // },
    {
      type: "Edit",
      description: t("menuItem3"),
    },
    {
      type: "Delete",
      description: t("menuItem4"),
    },
  ];

  const select = (node, nodeObjChildrenLength, e) => {
    if (nodeObjChildrenLength > 0 || node.obj.productAssociations.length > 0) {
      filteredNodes.forEach((nodeItem) => {
        if (
          node.topParentId !== -1 &&
          nodeItem.topParentId !== node.topParentId &&
          nodeItem.isExpanded
        ) {
          collapse(nodeItem);
        }
      });

      if (!node.isExpanded) {
        expand(node);
      } else {
        collapse(node);
      }

      // Force rerendering of page with updates
      // const copy = [...filteredNodes];
      // setFilteredNodes(copy);
      setToggle((prevState) => {
        return !prevState;
      });
    }
  };

  const expand = async (node) => {
    filteredNodes.map((nodeMember) => {
      if (
        nodeMember.isExpanded &&
        node !== nodeMember &&
        node.parent === null
      ) {
        select(nodeMember);
      }
    });

    node.isSelected = !node.isSelected;
    node.isExpanded = !node.isExpanded;

    const callback = async (error, stockLocations) => {
      if (!error) {
        stockLocations.forEach((sl) => {
          let parentContainsChild = false;
          let nodeChildrenArray = node.children;

          for (let i = 0; i < nodeChildrenArray.length; i++) {
            if (nodeChildrenArray[i].objId === sl.id) {
              parentContainsChild = true;
              break;
            }
          }

          // This check is necessary to prevent a double entry upon a rapid double click.
          if (!parentContainsChild) {
            const child = new Node(sl);
            child.parent = node;
            node.addChild(child);
          }
        });

        setFilteredNodes([...filteredNodes]);
      } else {
        console.log("Error", error);
      }
    };

    // Returns all stock locations that are children for a given parent stock location (node.obj).
    _controller.current.getStockLocations(node.obj, callback);
  };

  const handleCurrentAction = (action) => setCurrentAction(action);
  const handleClickedNode = (node) => setClickedNode(node);
  const handleRowIndex = (index) => setRowIndex(index);
  const handlePrevIndex = (index) => setPrevIndex(index);

  const collapse = (node) => {
    node.isSelected = !node.isSelected;
    node.isExpanded = !node.isExpanded;
    node.removeChildren();
  };

  const getNode = (node, index) => {
    let nodeObjChildrenLength = node.obj.children.length;

    if (expandedNode !== node && node.children.length && node.isExpanded) {
      setExpandedNode(node);
    }

    if (expandedNode !== null && expandedNode === node && !node.isExpanded) {
      setExpandedNode(null);
    }
    if (
      !expandedNode ||
      (expandedNode && (node === expandedNode || node.parent === expandedNode))
    ) {
      return (
        <StockLocationCard
          key={node.objId}
          site={site}
          index={indexedDB}
          node={node}
          nodeObjChildrenLength={nodeObjChildrenLength}
          clickedNode={clickedNode}
          onClickedNode={handleClickedNode}
          expandedNode={expandedNode}
          getNode={getNode}
          isAuthorized={isAuthorized}
          rowIndex={rowIndex}
          onRowIndex={handleRowIndex}
          prevIndex={prevIndex}
          onPrevIndex={handlePrevIndex}
          select={select}
          getActionMenu={getActionMenu}
          onActionSelection={handleActionSelection}
          onCurrentAction={handleCurrentAction}
          contextMenu={contextMenu}
          onSetContextMenu={onSetContextMenu}
          lastParentCard={
            !expandedNode && node === filteredNodes[filteredNodes.length - 1]
          }
        />
      );
    }
    return null;
  };

  return (
    <div id="scrollArea" className={style.StockLocationCardList__scrollArea}>
      {filteredNodes.map((node, index) => {
        node.topParentId = index;
        return getNode(node, index);
      })}
    </div>
  );
};

export default StockLocationsCardList;
