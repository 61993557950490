import OrderGeneratorService from "../../../services/OrderGeneratorService";

class OrderGeneratorController {
  generateOrders = async (user, site, stockLocation, orderData) => {
    return await OrderGeneratorService.generateOrders(
      user,
      site,
      stockLocation,
      orderData
    );
  };
}

const instance = new OrderGeneratorController();
export default instance;
