import React, { useState, Fragment } from "react";
import Button from "../../../components/Button";
import styles from "./Support.module.css";
import { useTranslation } from "react-i18next";

const Support = ({
  user,
  selectedDevice,
  filterText,
  onPageTitle,
  onGoBackToHelpView,
}) => {
  const { t } = useTranslation("help");

  return (
    <Fragment>
      {/* <h1>Sanity Check</h1> */}
      <div className={styles.support__buttonGroup}>
        <Button
          onClick={onGoBackToHelpView}
          labelName={t("backButtonLabel")}
          isPrimary={false}
          isDisabled={false}
          minWidth={null}
        />
      </div>
    </Fragment>
  );
};

export default Support;
