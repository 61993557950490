export const TruPakHeaders = [
  "lastName",
  "firstName",
  "packageId",
  "rxNumber",
  "qtyNeeded",
  "adminDate",
  "adminTime",
  "facility",
  "instructions",
  "physician",
  "pharmacist",
  "delete",
];
