import React, { useEffect, useState } from "react";
import { ReactComponent as BackArrowMobile } from "../../../assets/back-icon-mobile.svg";
import TextInput from "../../../components/TextInput";
import ErrorBanner from "../../../components/ErrorBanner";
import DropdownInput from "../../../components/DropDownInput/DropdownInput";
import Button from "../../../components/Button";
import Spacer from "../../../components/Spacer";
import { getOrderPriorityList } from "../../../functions/utilities";
import styles from "./AddRetailScriptMobileView.module.css";
import { useTranslation } from "react-i18next";

const AddRetailScriptMobileView = ({
  site,
  handleCollapse,
  error,
  onError,
  dispensingOrderItem,
  onDispensingOrderItem,
  errors,
  productFieldController,
  products,
  modifiedProducts,
  onErrors,
  isValid,
  onGenerateScript,
}) => {
  const { t } = useTranslation("addRetailScript");
  const [orderPriorityList, setOrderPriorityList] = useState([]);

  useEffect(() => {
    const tempOrderPriorityList = getOrderPriorityList().map((item) => ({
      ...item,
      displayName: t(item.description, {ns: "orderPriority"})
    }));
    setOrderPriorityList(tempOrderPriorityList);
  }, []);

  return (
    <div className={styles.AddRetailScript__container}>
      {error && (
        <div className={styles.AddRetailScript__errorBannerContainer}>
          <ErrorBanner message={error} onClose={() => onError("")} />
        </div>
      )}
      <div className={styles.AddRetailScript__header}>
        <div
          className={styles.AddRetailScript__backArrow}
          onClick={handleCollapse}
        >
          <BackArrowMobile className={styles.AddRetailScript__backArrowIcon} />
          <p className={styles.AddRetailScript__backTitle}>{t("backButtonLabel")}</p>
        </div>
        <h1>
          {site.name} <span>{t("title")}</span>
        </h1>
      </div>
      <div className={styles.AddRetailScript__colGroup}>
        <div className={styles.AddRetailScript__col}>
          <div className={styles.AddRetailScript__row}>
            <TextInput
              isEnabled={true}
              placeholder={""}
              value={dispensingOrderItem.patientInfo?.firstName || ""}
              labelName={t("patientFirstLabel")}
              type={"text"}
              focus={false}
              onChange={(val) => {
                if (val.length === 0) {
                  onErrors((prev) => {
                    return {
                      ...prev,
                      patientFirstName: "missingFirstName",
                    };
                  });
                } else {
                  onErrors((prev) => {
                    return { ...prev, patientFirstName: "" };
                  });
                }
                onDispensingOrderItem((prev) => {
                  const cpy = { ...prev };
                  cpy.patientInfo = { ...cpy.patientInfo, firstName: val };
                  return { ...prev, patientInfo: cpy.patientInfo };
                });
              }}
              validationText={t(errors.patientFirstName, {ns: "validation"})}
            />
          </div>
        </div>

        <div className={styles.AddRetailScript__col}>
          <div className={styles.AddRetailScript__row}>
            <TextInput
              isEnabled={true}
              placeholder={""}
              value={dispensingOrderItem.patientInfo?.lastName || ""}
              labelName={t("patientLastLabel")}
              type={"text"}
              focus={false}
              onChange={(val) => {
                if (val.length === 0) {
                  onErrors((prev) => {
                    return {
                      ...prev,
                      patientLastName: "missingLastName",
                    };
                  });
                } else {
                  onErrors((prev) => {
                    return { ...prev, patientLastName: "" };
                  });
                }
                onDispensingOrderItem((prev) => {
                  const cpy = { ...prev };
                  cpy.patientInfo = { ...cpy.patientInfo, lastName: val };
                  return { ...prev, patientInfo: cpy.patientInfo };
                });
              }}
              validationText={t(errors.patientLastName, {ns: "validation"})}
            />
          </div>
        </div>

        <div className={styles.AddRetailScript__col}>
          <div className={styles.AddRetailScript__row}>
            <TextInput
              isEnabled={true}
              placeholder={t("optionalPlaceholder")}
              value={dispensingOrderItem.patientInfo?.id || ""}
              labelName={t("patientIdLabel")}
              type={"text"}
              focus={false}
              onChange={(val) => {
                onDispensingOrderItem((prev) => {
                  const cpy = { ...prev };
                  cpy.patientInfo = { ...cpy.patientInfo, id: val };
                  return { ...prev, patientInfo: cpy.patientInfo };
                });
              }}
              validationText={""}
            />
          </div>
        </div>

        <div className={styles.AddRetailScript__col}>
          <div className={styles.AddRetailScript__row}>
            <TextInput
              isEnabled={true}
              placeholder={""}
              value={dispensingOrderItem.rxNumber || ""}
              labelName={t("rxNumberLabel")}
              type={"text"}
              focus={true}
              onChange={(val) => {
                if (val.length === 0) {
                  onErrors((prev) => {
                    return {
                      ...prev,
                      rxNumber: "missingRxNumber",
                    };
                  });
                }
                onDispensingOrderItem((prev) => {
                  return { ...prev, rxNumber: val };
                });
              }}
              validationText={t(errors.rxNumber, {ns: "validation"})}
            />
          </div>
        </div>

        <div className={styles.AddRetailScript__col}>
          <div className={styles.AddRetailScript__row}>
            <DropdownInput
              isEnabled={true}
              placeholder={t("selectDrugPlaceholder")}
              validationText={t(errors.product, {ns: "validation"})}
              labelName={t("drugLabel")}
              value={
                dispensingOrderItem.product
                  ? `${productFieldController.getDefaultName(
                      dispensingOrderItem.product
                    )} (${productFieldController.getPackageId(
                      dispensingOrderItem.product
                    )} )`
                  : ""
              }
              listElements={modifiedProducts}
              onSelection={(item) => {
                const p = products.find((o) => o._id === item._id);
                onDispensingOrderItem((prev) => {
                  return { ...prev, product: p };
                });
              }}
            />
          </div>
        </div>

        <div className={styles.AddRetailScript__quantityAndPriorityContainer}>
          <div className={styles.AddRetailScript__quantityInputContainer}>
            <TextInput
              isEnabled={true}
              placeholder={""}
              value={dispensingOrderItem.qtyNeeded}
              labelName={t("quantityLabel")}
              type={"number"}
              focus={false}
              onChange={(val) => {
                let regex = new RegExp("^[1-9][0-9]*$");

                // Check if entered value is a whole number greater than 0, otherwise set val to null.  This allowss the user to delete the value and change it.
                if (regex.test(val)) {
                  val = Number.parseInt(val);
                  onErrors((prev) => {
                    return { ...prev, qtyNeeded: "" };
                  });
                } else {
                  val = null;
                }

                onDispensingOrderItem((prev) => {
                  return {
                    ...prev,
                    qtyNeeded: val ? Number.parseInt(val) : "",
                  };
                });
              }}
              validationText={t(errors.qtyNeeded, {ns: "validation"})}
            />
          </div>
          <div style={{ width: "100%" }}>
            <DropdownInput
              isEnabled={true}
              placeholder={""}
              validationText={t(errors.priority, {ns: "validation"})}
              labelName={t("priorityLabel")}
              value={t(dispensingOrderItem.priority || "LOW", {ns: "orderPriority"})}
              listElements={orderPriorityList}
              display={"displayName"}
              onSelection={(item) => {
                onDispensingOrderItem((prev) => {
                  return { ...prev, priority: item.description };
                });
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.AddRetailScript__footer}>
        <Button
          labelName={t("cancelButtonLabel")}
          isPrimary={false}
          isDisabled={false}
          onClick={handleCollapse}
          minWidth={122}
        />
        <Spacer space={20} unit={"px"} />
        <Button
          labelName={t("generateButtonLabel")}
          isPrimary={true}
          isDisabled={!isValid}
          onClick={onGenerateScript}
          minWidth={122}
        />
      </div>
    </div>
  );
};

export default AddRetailScriptMobileView;
