import React, {useEffect, useState} from 'react';
import ProductStockLocationController from "../ProductStockLocationController";
import {faPlusCircle, faMinusCircle, faChevronLeft, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import style from './AddStockLocation.module.css';
import DropdownInput from "../../../components/DropDownInput/DropdownInput";
import Button from "../../../components/Button";
import { useTranslation } from 'react-i18next';

const AddStockLocation = ({user, sites, product, onClose, stockLocation, onAdd}) => {
    const { t } = useTranslation("addStockLocation")

    const controller = new ProductStockLocationController();

    const [selectedSite, setSelectedSite] = useState(sites[0]);
    const [nodeList, setNodeList] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [selectedStockLocation, setSelectedStockLocation] = useState(null);

    useEffect(()=>{
        (async ()=>{
            const nodes = await controller.getStockLocationNodes(user, selectedSite, product);
            setNodeList(nodes);
            setSelectedStockLocation(null);
        })()
    },[user, selectedSite])

    const renderTree = () => {
        return nodeList.map(node => {
            return (
                <div key={node.objId} >
                    <div className={style.AddStockLocation__listitem_row}
                        onClick={()=>onSelect(node.obj)}
                    >
                        {node.children.length > 0 && <FontAwesomeIcon
                            color={node.isExpanded ? "red" : "green"}
                            icon={node.isExpanded ? faMinusCircle : faPlusCircle}
                            className={style.AddStockLocation__list_icon}
                            onClick={(e) => toggle(e,node)}/>}
                        <p>{node.obj.name}</p>
                    </div>
                    {node.isExpanded && node.children.length > 0 && renderChildren(node, 0)}
                </div>
            )
        })
    }
    const renderChildren = (parent, level) => {
        level++;
        const margin = 30*level;
        return parent.children.map(child => {
            return (
                <div key={child.objId}>
                    <div className={style.AddStockLocation__listitem_row}
                         style={{marginLeft: `${margin}px`}}
                         onClick={()=>onSelect(child.obj)}
                    >
                        {child.children.length > 0 && <FontAwesomeIcon
                            color={child.isExpanded ? "red" : "green"}
                            icon={child.isExpanded ? faMinusCircle : faPlusCircle}
                            className={style.AddStockLocation__list_icon}
                            onClick={(e) => toggle(e,child)}/>}
                        <p>{child.obj.name}</p>
                    </div>
                    {child.isExpanded && child.children.length > 0 && renderChildren(child, level)}
                </div>
            )
        })
    }

    const toggle = (e, node) => {
        e.preventDefault()
        e.stopPropagation();
        node.isExpanded = !node.isExpanded;
        setRefresh(!refresh);
    }

    const onSelect = (stockLocation) => {
        setSelectedStockLocation(stockLocation)
    }

    const onAssociate = async () => {
        await controller.associate(user, product, selectedStockLocation);
        onAdd(selectedStockLocation);
    }

    return (
        <div className={style.AddStockLocation__container}>
            <div className={style.AddStockLocation__title}>
                <FontAwesomeIcon
                    icon={faChevronLeft}
                    color={"#121a2d"}
                    className={style.AddStockLocation__list_icon}
                    onClick={onClose}
                />
                <h1>{stockLocation ? t("editAssociationLabel") : t("newAssociationLabel")}</h1>
            </div>
            <br/>   
            <DropdownInput 
                maxWidth={'400px'} 
                display={'name'} 
                placeholder='' 
                value={selectedSite.name} 
                labelName={t("selectSiteLabel")} 
                isEnabled={true} 
                listElements={sites} 
                onSelection={site => setSelectedSite(site)}
            />
            <br/>
            {!selectedStockLocation &&
                <h2>{t("selectLocationLabel")}</h2>
            }
            {!selectedStockLocation && renderTree()}
            {selectedStockLocation &&
                <>
                    <div className={style.AddStockLocation__stock_location}>
                        <p>{selectedStockLocation.name}</p>
                        <FontAwesomeIcon
                            icon={faTimes}
                            color={"#FFFFFF"}
                            className={style.AddStockLocation__list_icon}
                            onClick={() => setSelectedStockLocation(null)}
                        />
                    </div>
                    <br/>
                    <Button labelName={t("associateButtonLabel")} isPrimary={true} onClick={onAssociate} />
                </>
            }
        </div>
    )
}

export default AddStockLocation;
