import React, { useState, useEffect } from "react";
import { Alert, notification } from "antd";
import { ReactComponent as BackButton } from "../../../assets/back_icon.svg";
import { TruPakHeaders } from "./OrderGeneratorTableHeaders";
import { TruPakInputTypes } from "./OrderGeneratorTableInputTypes";
import { TruPakRelativeColumnWidths } from "./OrderGeneratorRelativeColumnWidths";
import { TruPakMinColumnWidths } from "./OrderGeneratorMinColumnWidths";
import { dataValidityChecksTruPak } from "./OrderGeneratorDataValidationChecks";
import AntSpin from "../../../components/Ant/AntSpin/AntSpin";
import ResizableTable from "./ResizableTable";
import styles from "./OrderGenerator.module.css";

const OrderGenerator = ({ onGenerationType, site, user, selectedDevice }) => {
  const [uploadCSVFileError, setUploadCSVFileError] = useState("");
  const [batchOrderSuccessfullyCreated, setBatchOrderSuccessfullyCreated] =
    useState(false);
  const [generatingBatchOrder, setGeneratingBatchOrder] = useState(false);

  const handleUploadCSVFileError = (errorMsg) => {
    setUploadCSVFileError(errorMsg);
  };

  const handleBatchOrderSuccessfullyCreated = (boolVal) => {
    setBatchOrderSuccessfullyCreated(boolVal);
  };

  const openNotificationWithIcon = (type) => {
    notification[type]({
      message: "Success!",
      description: "Batch order was created.",
      duration: 5,
      placement: "top",
      onClose: () => handleBatchOrderSuccessfullyCreated(false),
    });
  };

  const handleGeneratingBatchOrder = (boolValue) => {
    setGeneratingBatchOrder(boolValue);
  };

  useEffect(() => {
    if (batchOrderSuccessfullyCreated) {
      openNotificationWithIcon("success");
    }
  }, [batchOrderSuccessfullyCreated]);
  return (
    <div className={styles.OrderGenerator__container}>
      <div className={styles.OrderGenerator__backButtonContainer}>
        <BackButton
          onClick={() => {
            onGenerationType("");
          }}
        />
        <h1>{site.name} Order Generator</h1>
        {generatingBatchOrder && (
          <div className={styles.OrderGenerator__spin}>
            <AntSpin
              message="Generating batch order..."
              containerStyle="container-000"
              spinnerStyle="ant-spin-000"
              messageStyle="message-000"
              spinSize="default"
            />
          </div>
        )}
      </div>
      {uploadCSVFileError && (
        <div className={styles.OrderGenerator__alertContainer}>
          <Alert
            message="Unable to populate CSV File data into batch order table"
            description={uploadCSVFileError}
            type="error"
            showIcon
            closable
            onClose={() => setUploadCSVFileError("")}
          />
        </div>
      )}
      <ResizableTable
        site={site}
        user={user}
        selectedDevice={selectedDevice}
        headers={TruPakHeaders}
        inputTypes={TruPakInputTypes}
        startingRelativeColumnWidths={TruPakRelativeColumnWidths}
        minColumnWidths={TruPakMinColumnWidths}
        dataValidationChecks={dataValidityChecksTruPak}
        onUploadCSVFileError={handleUploadCSVFileError}
        onBatchOrderSuccessfullyCreated={handleBatchOrderSuccessfullyCreated}
        onGeneratingBatchOrder={handleGeneratingBatchOrder}
      />
    </div>
  );
};

export default OrderGenerator;
