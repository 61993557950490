import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./InventoryEquivProductsHeadings.module.css";

export const InventoryEquivProductsHeadings = () => {
  const { t } = useTranslation("equivalency");

  return (
    <div className={`${styles.inventoryEquivProductsHeadings__tableHeadings}`}>
      <div
        className={`${styles.inventoryEquivProductsHeadings__ID} ${styles.inventoryEquivProductsHeadings__overflowHidden}`}
      >
        <p className={styles.inventoryEquivProductsHeadings}>{t("col0")}</p>
      </div>
      <div
        className={`${styles.inventoryEquivProductsHeadings__description} ${styles.inventoryEquivProductsHeadings__overflowHidden}`}
      >
        <p className={styles.inventoryEquivProductsHeadings}>{t("col1")}</p>
      </div>
      <div
        className={`${styles.inventoryEquivProductsHeadings__manufacturer} ${styles.inventoryEquivProductsHeadings__overflowHidden}`}
      >
        <p className={styles.inventoryEquivProductsHeadings}>{t("col2")}</p>
      </div>
      <div
        className={`${styles.inventoryEquivProductsHeadings__actions} ${styles.inventoryEquivProductsHeadings__overflowHidden}`}
      >
        <p className={styles.inventoryEquivProductsHeadings}>{t("col3")}</p>
      </div>
    </div>
  );
};
