import React, { useEffect, useRef, useState } from "react";
import styles from "./TrayPickList.module.css";
import { ReactComponent as BackButton } from "../../assets/back_icon.svg";
import ProductFieldController from "../../controllers/ProductFieldController";
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useReactToPrint } from "react-to-print";
import DataMatrix from "react-datamatrix-svg";
import DispensingOrderService from "../../services/DispensingOrderService";
import { useTranslation } from "react-i18next";

const TrayPickList = ({ user, data, onClose }) => {
  const { t } = useTranslation("trayPickList");

  const productFieldController = new ProductFieldController();
  const componentRef = useRef();

  const [trayNeeds, setTrayNeeds] = useState(null);

  useEffect(() => {
    DispensingOrderService.getTrayNeeds(user, data)
      .then((needs) => {
        setTrayNeeds(needs);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [data, user]);

  const getBarcode = (trayNeed) => {
    const bc = `NEWORDER${trayNeed.product.packageId}/${trayNeed.quantity}*TRAY*BATCH*ULTFill`;
    return <DataMatrix msg={bc} dim={50} />;
  };

  const print = useReactToPrint({
    pageStyle: "{padding: '20px'}",
    content: () => componentRef.current,
  });

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div ref={componentRef} className={styles.column}>
      <div className={styles.headerRow}>
        <div className={styles.row}>
          <BackButton className={styles.backButton} onClick={onClose} />
          <h1>{t("title")}</h1>
        </div>
        <FontAwesomeIcon
          icon={faPrint}
          size={"2x"}
          color={"#089BAB"}
          onClick={() => print()}
          style={{ cursor: "pointer" }}
        />
      </div>
      <br />
      <div className={styles.headerRow}>
        <div className={styles.column}>
          <h2>
            <span>{t("batchIdLabel")}:</span> {trayNeeds?.batchId}
          </h2>
          <h2>
            <span>{t("batchNameLabel")}:</span> {trayNeeds?.batchName}
          </h2>
        </div>
      </div>
      <br />
      <div className="tray-picklist-table-wrapper">
        <table>
          <thead>
            <tr>
              <th align={"left"}>{t("idCol")}</th>
              <th align={"left"}>{t("nameCol")}</th>
              <th align={"left"}>{t("manufacturerCol")}</th>
              <th align={"left"}>{t("controlLevelCol")}</th>
              <th align={"left"}>{t("quantityCol")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {trayNeeds &&
              trayNeeds.products
                .sort((p1, p2) => {
                  if (
                    p1.product.primaryName.toUpperCase() <
                    p2.product.primaryName.toUpperCase()
                  ) {
                    return -1;
                  }
                  if (
                    p2.product.primaryName.toUpperCase() <
                    p1.product.primaryName.toUpperCase()
                  ) {
                    return 1;
                  }
                  return 0;
                })
                .map((obj) => {
                  return (
                    <tr key={obj.product._id}>
                      <td>{obj.product.packageId}</td>
                      <td>
                        <div>
                          <p>
                            {productFieldController.getDefaultName(obj.product)}
                          </p>
                          <p className={styles.DescriptionParagraph}>
                            {obj.product.comments}
                          </p>
                        </div>
                      </td>
                      <td>
                        {obj.product.manufacturer?.abbrName ||
                          obj.product.manufacturer?.fullName ||
                          ""}
                      </td>
                      <td>
                        {t(obj.product.controlLevel?.description, {
                          ns: "controlLevels",
                        })}
                      </td>
                      <td>
                        {obj.partialQty === 0
                          ? obj.quantity
                          : `${obj.quantity} (1/${obj.partialQty} ${t(
                              "tabLabel",
                              { count: obj.quantity }
                            )})`}
                      </td>
                      <td>{getBarcode(obj)}</td>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TrayPickList;

