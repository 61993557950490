import React, { useEffect, useState } from "react";
import ImageService from "../../services/ImageService";
import GeneratePDFLabels from "./GeneratePDFLabels";
import BarcodeComponent from "./LabelComponentsForPreviews/BarcodeComponent";
import Rectangle from "./LabelComponentsForPreviews/Rectangle";
import Text from "./LabelComponentsForPreviews/Text";
import Repeater from "./LabelComponentsForPreviews/Repeater";
import Image from "./LabelComponentsForPreviews/Image";
import Pagination from "../../components/Pagination";
import Button from "../../components/Button";
import Spacer from "../../components/Spacer";
import WaitIndicator from "../../components/WaitIndicator";
import CircularStructureStringify from "circular-structure-stringify";
import styles from "./PrintLabelsToPDF.module.css";
import { useTranslation } from "react-i18next";

const PrintLabelsToPDF = ({
  user,
  arrayOfRowsForPrint,
  clickedLabel,
  selectedDeviceCanisters,
  onPrintLabelsToPDF,
  firstDevicePosition,
  lastDevicePosition,
  arrayOfDevicePositions,
  // images,
}) => {
  const { t } = useTranslation("printLabels");

  const [mappedLabels, setMappedLabels] = useState([]);
  const [selectedCanisterPosition, setSelectedCanisterPosition] =
    useState(firstDevicePosition);

  const [generatePDFLabels, setGeneratePDFLabels] = useState(false);

  const [barcodeStringSVGS, setBarcodeStringSVGS] = useState([]);

  const [images, setImages] = useState([]);
  const [readyToDisplayPrintOption, setReadyToDisplayPrintOption] =
    useState(false);

  const canisterPositions = mappedLabels.map((label) =>
    parseInt(label.canisterPosition)
  );

  const arrayContainsObject = (obj, array) => {
    return array.some((elem) => elem === obj);
  };

  // Get drug image
  const getDrugsImages = async () => {
    const tempImages = [];
    for (let i = 0; i < canisterPositions.length; i++) {
      if (
        selectedDeviceCanisters[canisterPositions[i] - 1].productAssociations[0]
          ?.product?.images[0]
      ) {
        const image = await getDrugImage(
          user,
          selectedDeviceCanisters[canisterPositions[i] - 1]
            .productAssociations[0].product.images[0]
        );
        tempImages.push(image.base64EncodedString);
      }
    }
    setImages(tempImages);
    setReadyToDisplayPrintOption(true);
  };

  const getDrugImage = async (user, imageId) => {
    try {
      const image = await ImageService.read(user, imageId);
      return image;
    } catch (error) {
      console.error("Error getting drug image", error);
      return false;
    }
  };

  useEffect(() => {
    if (canisterPositions.length && !images.length) {
      getDrugsImages();
    }
  }, [canisterPositions, images]);

  useEffect(() => {
    if (readyToDisplayPrintOption) {
      let svgs = document.querySelectorAll("[id^=svgBarcode]");
      let stringSVGS = [];
      for (let i = 0; i < svgs.length; i++) {
        if (svgs[i]) {
          stringSVGS.push(svgs[i].outerHTML);
        }
      }
      let filteredStringSVGS = stringSVGS.filter((svg) => {
        return svg.startsWith("<svg");
      });
      setBarcodeStringSVGS(filteredStringSVGS);
    }
  }, [readyToDisplayPrintOption]);

  const getDefaultName = (node) => {
    if (node.type === "text") {
      return node.defaultValue;
    } else {
      switch (node.symbology) {
        case "Code128A":
          return node.defaultValueCode128A;
        case "Code128B":
          return node.defaultValueCode128B;
        case "Code39":
          return node.defaultValueCode39;
        case "DataMatrix":
          return node.defaultValueDataMatrix;
        case "QRBarcode":
          return node.defaultValueQRBarcode;
        default:
          return "";
      }
    }
  };

  const traverseLabelTemplateAndMap = (labelTemplate, parent, canister) => {
    // RECURSIVE CASE
    let newChildNodes = [];

    for (let i = 0; i < labelTemplate["childNodes"]?.length; i++) {
      traverseLabelTemplateAndMap(
        labelTemplate["childNodes"][i],
        labelTemplate,
        canister
      ); // Traverse child labelTemplates
    }

    for (let i = 0; i < labelTemplate["childNodes"]?.length; i++) {
      if (!arrayContainsObject(labelTemplate.childNodes[i], newChildNodes)) {
        let tempChildNode = labelTemplate.childNodes[i];

        if (
          tempChildNode?.mapping &&
          (tempChildNode.type === "text" || tempChildNode.type === "barcode")
        ) {
          let actualName = "";
          switch (tempChildNode.mapping.path) {
            case "":
              actualName = getDefaultName(tempChildNode) || "";
              break;
            case "stockLocation.barcode":
              actualName = canister?.barcode || "";
              break;
            case "productAssociations[0].product.controlLevel.type":
              actualName =
                canister.productAssociations[0]?.product?.controlLevel?.type ||
                "";
              break;
            case "productAssociations[0].product.comments":
              actualName =
                canister.productAssociations[0]?.product?.comments || "";
              break;
            case "productAssociations[0].product.dosageForm.type":
              actualName =
                canister.productAssociations[0]?.product?.dosageForm?.type ||
                "";
              break;
            case "productAssociations[0].product.legend":
              actualName =
                canister.productAssociations[0]?.product?.legend || "";
              break;
            case "productAssociations[0].product.abbrName":
              actualName =
                canister.productAssociations[0]?.product?.abbrName || "";
              break;
            case "productAssociations[0].product.manufacturer.fullName":
              actualName =
                canister.productAssociations[0]?.product?.manufacturer
                  ?.fullName || "";
              break;
            case "stockLocation.devicePosition":
              actualName = canister?.devicePosition || "";
              break;
            case "stockLocation.name":
              actualName = canister?.name || "";
              break;
            case "productAssociations[0].product.packageId":
              actualName =
                canister.productAssociations[0]?.product?.packageId || "";
              break;
            case "productAssociations[0].product.primaryName":
              actualName =
                canister.productAssociations[0]?.product?.primaryName || "";
              break;
            case "productAssociations[0].product.productId":
              actualName =
                canister.productAssociations[0]?.product?.productId || "";
              break;
            case "productAssociations[0].product.secondaryName":
              actualName =
                canister.productAssociations[0]?.product?.secondaryName || "";
              break;
            case "productAssociations[0].product.strength":
              actualName =
                canister.productAssociations[0]?.product?.strength || "";
              break;
            case "productAssociations[0].product.tallManName":
              actualName =
                canister.productAssociations[0]?.product?.tallManName || "";
              break;
            case "productAssociations[0].product.tertiaryName":
              actualName =
                canister.productAssociations[0]?.product?.tertiaryName || "";
              break;
            default:
              actualName = "";
          }

          tempChildNode.value = actualName;
        } else if (
          readyToDisplayPrintOption &&
          tempChildNode.type === "image" &&
          tempChildNode.getImagesFromDatabase &&
          canister.productAssociations[0]?.product?.images?.length
        ) {
          if (canister.name !== canisterName) {
            imageArrayIndex++;
          }
          canisterName = canister.name;
          tempChildNode.ignoreImage = false;
          tempChildNode.base64EncodedString = images[imageArrayIndex];
        } else if (
          tempChildNode.type === "image" &&
          tempChildNode.getImagesFromDatabase &&
          !canister.productAssociations[0]?.product?.images?.length
        ) {
          tempChildNode.ignoreImage = true;
        }
      }
    }

    // BASE CASE;
    return newChildNodes;
  };

  const handlePrevious = () => {
    onPrintLabelsToPDF(false);
  };

  const firstSelectedCanisterIndex = parseInt(
    selectedDeviceCanisters.findIndex((selectedDeviceCanister) => {
      return (
        parseInt(selectedDeviceCanister.devicePosition) ===
        parseInt(firstDevicePosition)
      );
    })
  );

  const lastSelectedCanisterIndex = parseInt(
    selectedDeviceCanisters.findIndex((selectedDeviceCanister) => {
      return (
        parseInt(selectedDeviceCanister.devicePosition) ===
        parseInt(lastDevicePosition)
      );
    })
  );

  /***********************************************************************/
  let selectedCanisters;

  if (arrayOfDevicePositions.length) {
    selectedCanisters = selectedDeviceCanisters.filter((canister) => {
      return arrayOfDevicePositions.includes(canister.devicePosition);
    });
  } else {
    selectedCanisters = selectedDeviceCanisters.slice(
      firstSelectedCanisterIndex,
      lastSelectedCanisterIndex + 1
    );
  }
  /***********************************************************************/

  const buildNodesArray = (node, parent) => {
    // RECURSIVE CASE
    for (let i = 0; i < node["childNodes"]?.length; i++) {
      buildNodesArray(node["childNodes"][i], node); // Traverse child nodes
    }
    if (parent) {
      node.parent = parent;
    }
    // BASE CASE
    return;
  };

  const tempMappedLabels = [];

  let imageArrayIndex = -1;
  let canisterName = "";

  useEffect(() => {
    selectedCanisters.forEach((canister, index) => {
      const newClickedLabelJson = CircularStructureStringify(clickedLabel);
      const newClickedLabel = {
        ...JSON.parse(newClickedLabelJson),
        canisterPosition: canister.devicePosition,
      };
      let labelTemplate = {
        ...newClickedLabel.template,
        index: index,
      };

      traverseLabelTemplateAndMap(labelTemplate, null, canister);
      buildNodesArray(
        newClickedLabel.template,
        newClickedLabel.template.parent
      );
      tempMappedLabels.push(newClickedLabel);
    });

    setMappedLabels(tempMappedLabels);
  }, [readyToDisplayPrintOption]);

  const handleSelectedCanisterPosition = (position) => {
    setSelectedCanisterPosition(position.toString());
  };

  const paginationStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    color: "#121a2d",
    minWidth: "24px",
    paddingLeft: "5px",
    paddingRight: "5px",
    marginLeft: "4px",
    marginRight: "4px",
    textAlign: "center",
    border: "1px solid",
    borderColor: "#00000033",
    cursor: "pointer",
  };

  // listed keys/values override those in paginationStyle when pagination pageValue is active
  const activePaginationStyle = {
    color: "#089bab",
    borderColor: "#089bab",
  };

  const prevNextStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    color: "#121a2d",
    cursor: "pointer",
  };

  // listed keys/values override those in prevNextStyle when pagination pageValue is active
  const prevNextDisabledStyle = {
    color: "#00000033",
    cursor: "default",
  };

  const GetCanvasComponent = (children) => {
    return <div>{children}</div>;
  };

  const getRectangleComponent = (node, children, selectedObjectBorderColor) => {
    return (
      <Rectangle key={node.nodeId} node={node}>
        {children}
      </Rectangle>
    );
  };

  const getBarcodeComponent = (node, children) => {
    return (
      <BarcodeComponent key={node.nodeId} node={node}>
        {children}
      </BarcodeComponent>
    );
  };

  const getTextComponent = (node, children) => {
    return (
      <Text key={node.nodeId} node={node}>
        {children}
      </Text>
    );
  };

  const getRepeaterComponent = (
    node,
    children,
    ghost = false,
    ghostIndex = -1,
    copy = false
  ) => {
    return (
      <Repeater
        key={ghostIndex === -1 ? node.nodeId : `ghost-${ghostIndex}`}
        keyValue={ghostIndex === -1 ? node.nodeId : `ghost-${ghostIndex}`}
        node={node}
        children={children}
        ghost={ghost}
        ghostIndex={ghostIndex}
        copy={copy}
        arrayOfRowsForPrint={arrayOfRowsForPrint}
      >
        {children}
      </Repeater>
    );
  };

  const getImageComponent = (node, children) => {
    return (
      <Image key={node.nodeId} node={node}>
        {children}
      </Image>
    );
  };

  // Traverse node tree recursively, starting with label child nodes

  let nodeIds = [];

  const traverseLabelChildren = (node) => {
    if (node.childNodes.length && readyToDisplayPrintOption) {
      const NodeComponents = node.childNodes.map((childNode) => {
        if (childNode.type === "childRectangle") {
          return getRectangleComponent(
            childNode,
            traverseLabelChildren(childNode)
          );
        }
        if (childNode.type === "barcode") {
          return getBarcodeComponent(
            childNode,
            traverseLabelChildren(childNode)
          );
        } else if (childNode.type === "text") {
          return getTextComponent(childNode, traverseLabelChildren(childNode));
        } else if (childNode.type === "repeater") {
          let nodeFound = false;

          if (nodeIds.indexOf(childNode.nodeId.toString()) !== -1) {
            nodeFound = true;
          }

          let content;
          // Tailor here is using repeater approach for customized label
          if (
            clickedLabel?.customizedLabel &&
            clickedLabel.customizedLabel === "CindyR"
          ) {
            if (clickedLabel.machineType === "TruScript") {
              content = [
                getRepeaterComponent(
                  childNode,
                  traverseLabelChildren(childNode),
                  false,
                  -1,
                  nodeFound
                ),
              ];
            } else {
              content = [];
            }

            for (let i = 0; i < childNode.repeatCount; i++) {
              if (
                (clickedLabel.machineType === "TruCard" &&
                  (i === 1 || i === 4)) ||
                (clickedLabel.machineType === "TruPak" &&
                  (i === 0 || i === 3)) ||
                (clickedLabel.machineType === "TruScript" && i === 2)
              ) {
                content.push(
                  getRepeaterComponent(
                    childNode,
                    traverseLabelChildren(childNode),
                    true,
                    i,
                    true
                  )
                );
              }
            }
          } else {
            content = [
              getRepeaterComponent(
                childNode,
                traverseLabelChildren(childNode),
                false,
                -1,
                nodeFound
              ),
            ];

            for (let i = 0; i < childNode.repeatCount; i++) {
              content.push(
                getRepeaterComponent(
                  childNode,
                  traverseLabelChildren(childNode),
                  true,
                  i,
                  true
                )
              );
            }
          }
          return content;
        } else if (childNode.type === "image" && !childNode.ignoreImage) {
          return getImageComponent(childNode, traverseLabelChildren(childNode));
        }
      });
      return NodeComponents;
    } else {
      return;
    }
  };

  const mappedLabelIndex = mappedLabels.findIndex(
    (mappedLabel) => mappedLabel.canisterPosition === selectedCanisterPosition
  );

  const handleGeneratePDFLabels = (boolVal) => {
    setGeneratePDFLabels(boolVal);
  };

  if (generatePDFLabels && !readyToDisplayPrintOption) {
    return <WaitIndicator message={t("fetching")} />;
  }

  if (generatePDFLabels && readyToDisplayPrintOption) {
    return (
      <GeneratePDFLabels
        arrayOfRowsForPrint={arrayOfRowsForPrint}
        mappedLabels={mappedLabels}
        clickedLabel={clickedLabel}
        onGeneratePDFLabels={handleGeneratePDFLabels}
        barcodeStringSVGS={barcodeStringSVGS}
      />
    );
  }

  return (
    <div className={styles.PrintLabelsToPDF__container}>
      <header>
        <p className={styles.PrintLabelsToPDF__heading}>{t("previewLabel")}</p>
      </header>
      {!readyToDisplayPrintOption && <WaitIndicator message={t("loading")} />}
      <div className={styles.PrintLabelsToPDF__labelPreviewContainer}>
        {GetCanvasComponent(
          // create label and start recursive traversal across all child nodes
          mappedLabels.length && images?.length
            ? [
                getRectangleComponent(
                  mappedLabels[mappedLabelIndex].template,
                  traverseLabelChildren(mappedLabels[mappedLabelIndex].template)
                ),
              ]
            : null
        )}
      </div>
      {readyToDisplayPrintOption && (
        <div className={styles.PrintLabelsToPDF__paginationContainer}>
          <Pagination
            pageValues={canisterPositions}
            onPageClick={(canisterPosition) =>
              handleSelectedCanisterPosition(canisterPosition)
            }
            maxPagesToDisplay={12}
            paginationStyle={paginationStyle}
            activePaginationStyle={activePaginationStyle}
            prevNextStyle={prevNextStyle}
            prevNextDisabledStyle={prevNextDisabledStyle}
          />
          <header>
            <p>{t("positionLabel")}</p>
          </header>
        </div>
      )}
      <div className={styles.PrintLabelsToPDF__buttonsContainer}>
        <Button
          labelName={t("prevButtonLabel")}
          minWidth={"123px"}
          isPrimary={false}
          onClick={handlePrevious}
        />
        <Spacer space={20} unit={"px"} />
        <Button
          labelName={t("generateButtonLabel")}
          isPrimary={true}
          minWidth={"213px"}
          onClick={handleGeneratePDFLabels}
        />
      </div>
    </div>
  );
};

export default PrintLabelsToPDF;
