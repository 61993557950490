import * as React from "react";
import { useContext, useState } from "react";
import { ReactComponent as Square } from "../../../../assets/square.svg";
import { ScreenContext } from "../../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import { getYAxisScale } from "./graphFunctions";
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  Inject,
  LineSeries,
  Legend,
  Category,
  Tooltip,
  Highlight,
} from "@syncfusion/ej2-react-charts";
import styles from "./PouchesPerHourGraph.module.css";
import { useTranslation } from "react-i18next";

const PouchesPerHourGraph = ({
  graphData,
  benchmarkValue,
  graphId,
  graphTitle,
  palette,
  viewWidth,
  timeRange,
  onTimeRange,
  primarySeriesLabel,
  secondarySeriesLabel,
}) => {
  const { t } = useTranslation("trupakDashboard")

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  // Initializations
  let numYScaleValues = 6;
  let yMin = 0; // min data value

  let yMax = 0;
  graphData.forEach((item) => {
    if (item.y > yMax) {
      yMax = item.y;
    }
  });

  if (benchmarkValue > yMax) yMax = Math.ceil(benchmarkValue);

  let incY = getYAxisScale(yMin, yMax, numYScaleValues); // yAxis scale interval

  let benchmarkData = graphData.map((row) => ({ ...row, y: benchmarkValue }));

  const onChartLoad = (args) => {
    let chart = document.getElementById(graphId);
    if (chart) {
      chart.setAttribute("title", "");
    }
  };
  const load = (args) => {
    let selectedTheme = window.location.hash.split("/")[1];
    selectedTheme = selectedTheme ? selectedTheme : "Material";
    args.chart.theme = (
      selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)
    )
      .replace(/-dark/i, "Dark")
      .replace(/contrast/i, "Contrast");
  };

  const handleStopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div className="control-pane">
      <div className="control-section">
        <ChartComponent
          titleStyle={{
            size: "16px",
            fontFamily: "Poppins",
            fontWeight: "Normal",
          }}
          title={graphTitle}
          id={graphId}
          palettes={palette}
          style={
            smScreen
              ? { width: `${viewWidth - 54}px`, height: "288px" }
              : { width: "450px", height: "288px" }
          }
          primaryXAxis={{
            valueType: "Category",
            edgeLabelPlacement: "Shift",
            majorGridLines: { width: 0 },
            labelFormat: "y",
          }}
          load={load.bind(this)}
          primaryYAxis={{
            // title: "Million Metric Tons",
            rangePadding: "None",
            minimum: yMin,
            maximum: yMax,
            interval: incY,
            lineStyle: { width: 0 },
            majorTickLines: { width: 0 },
            minorTickLines: { width: 0 },
          }}
          chartArea={{ border: { width: 0 } }}
          tooltip={{ enable: false }}
          legendSettings={{ enableHighlight: true }}
          loaded={onChartLoad.bind(this)}
        >
          <Inject
            services={[LineSeries, Category, Legend, Tooltip, Highlight]}
          />
          <SeriesCollectionDirective>
            <SeriesDirective
              dataSource={graphData}
              xName="x"
              yName="y"
              name={t("pouchesDispensedLabel")}
              width={3}
              type="Line"
            ></SeriesDirective>
            <SeriesDirective
              dataSource={benchmarkData}
              xName="x"
              yName="y"
              name={t("pouchesBenchmarkLabel")}
              width={3}
              type="Line"
            ></SeriesDirective>
          </SeriesCollectionDirective>
        </ChartComponent>
        <div
          className={styles.timeRangeContainer}
          onClick={handleStopPropagation}
        >
          <div
            className={styles.timeRangeOption}
            onClick={() => onTimeRange("month")}
          >
            <div
              className={
                timeRange === "month"
                  ? `${styles.squareContainer} ${styles["squareContainer--active"]}`
                  : styles.squareContainer
              }
            >
              <Square />
            </div>
            <p>{t("lastMonthLabel")}</p>
          </div>
          <div
            className={styles.timeRangeOption}
            onClick={() => onTimeRange("week")}
          >
            <div
              className={
                timeRange === "week"
                  ? `${styles.squareContainer} ${styles["squareContainer--active"]}`
                  : styles.squareContainer
              }
            >
              <Square />
            </div>
            <p>{t("lastWeekLabel")}</p>
          </div>
          <div
            className={styles.timeRangeOption}
            onClick={() => onTimeRange("day")}
          >
            <div
              className={
                timeRange === "day"
                  ? `${styles.squareContainer} ${styles["squareContainer--active"]}`
                  : styles.squareContainer
              }
            >
              <Square />
            </div>
            <p>{t("lastDayLabel")}</p>
          </div>
          <div
            className={styles.timeRangeOption}
            onClick={() => onTimeRange("12 hours")}
          >
            <div
              className={
                timeRange === "12 hours"
                  ? `${styles.squareContainer} ${styles["squareContainer--active"]}`
                  : styles.squareContainer
              }
            >
              <Square />
            </div>
            <p>{t("last12HoursLabel")}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PouchesPerHourGraph;
