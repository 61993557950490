import React, { useState, useEffect, useRef, useContext } from "react";

import TruPakDashboardController from "../../TruPakDashboard/TruPakDashboardController";
import AlarmsGraph from "../graphs/AlarmsGraph";
import { ScreenContext } from "../../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "../TruPakDashboard.module.css";
import stylesMobile from "../MobileViews/TruPakDashboardMobileView.module.css";
import { useTranslation } from "react-i18next";


const GetAlarmsGraph = ({
  onGraphClick,
  user,
  selectedDevices,
  viewWidth,
  loader,
  startDate,
  stopDate,
}) => {
  const { t } = useTranslation("trupakDashboard");

  const [Graph, setGraph] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [fetchingData, setFetchingData] = useState(false);

  const _controllerRef = useRef(TruPakDashboardController);

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  useEffect(() => {
    if (selectedDevices.length && !fetchingData) {
      setGraph(
        AlarmsGraphContainer(
          "alarmsGraph", // id
          graphData // data for selected site and dispensing device
        )
      );
    }
  }, [graphData, fetchingData, selectedDevices]);

  useEffect(() => {
    if (selectedDevices.length) {
      // Get data for Alarms graph
      if (selectedDevices.length) {
        setGraph([AlarmsGraphContainer(1, [])]);
      }
      async function updateGraph() {
        setFetchingData(true);
        let emptyCanisterCount = 0;
        let partialFillCount = 0;
        let suspiciousCount = 0;
        let overCountCount = 0;
        let motorFailureCount = 0;
        let countSensorCount = 0;
        let chuteSensorUndetected = 0;

        for (let i = 0; i < selectedDevices.length; i++) {
          const deviceId = selectedDevices[i]._id;
          const site = selectedDevices[i].site;

          await _controllerRef.current
            .getTruPakAlarmHistory(
              user,
              site,
              startDate,
              stopDate,
              false,
              deviceId
            )
            .then((results) => {
              const alarmsData = results.map((result) => {
                return { x: result.error, y: result.count };
              });
              alarmsData.forEach((alarm) => {
                if (alarm.x === "EmptyCanister") {
                  emptyCanisterCount += alarm.y;
                } else if (alarm.x === "PartialFill") {
                  partialFillCount += alarm.y;
                } else if (alarm.x === "Suspicious") {
                  suspiciousCount += alarm.y;
                } else if (alarm.x === "OverCount") {
                  overCountCount += alarm.y;
                } else if (alarm.x === "MotorFailure") {
                  motorFailureCount += alarm.y;
                } else if (alarm.x === "CountSensor") {
                  countSensorCount += alarm.y;
                } else if (alarm.x === "ChuteSensorUndetected") {
                  chuteSensorUndetected += alarm.y;
                }
              });
            });
        }

        let data = [];

        if (emptyCanisterCount > 0) {
          data.push({ x: "EmptyCanister", y: emptyCanisterCount });
        }
        if (partialFillCount > 0) {
          data.push({ x: "PartialFill", y: partialFillCount });
        }
        if (suspiciousCount) {
          data.push({ x: "Suspicious", y: suspiciousCount });
        }
        if (overCountCount) {
          data.push({ x: "OverCount", y: overCountCount });
        }
        if (motorFailureCount) {
          data.push({ x: "MotorFailure", y: motorFailureCount });
        }
        if (countSensorCount) {
          data.push({ x: "CountSensor", y: countSensorCount });
        }
        if (chuteSensorUndetected) {
          data.push({
            x: "CountSensorUndetected",
            y: chuteSensorUndetected,
          });
        }

        setGraphData(data);
        setFetchingData(false);
      }
      if (selectedDevices.length) {
        updateGraph();
      } else {
        setGraph([]);
      }
    } else {
      setGraph([]);
    }
  }, [selectedDevices]);

  const AlarmsGraphContainer = (id, data) => {
    return (
      <div
        key={id}
        className={
          smScreen
            ? `${stylesMobile.TruPakDashboard__graphContainerMobile} ${stylesMobile["TruPakDashboard__graphContainerMobile--paddingBottom"]}`
            : `${styles.TruPakDashboard__graphContainerMobile} ${styles["TruPakDashboard__graphContainer--paddingBottom"]}`
        }
        onClick={!fetchingData ? () => onGraphClick(4) : null}
      >
        {fetchingData && loader}
        <AlarmsGraph
          graphData={data}
          graphId={"alarmsGraphNew"}
          graphTitle={t("alarmsTitle")}
          palette={["#318ce7"]}
          columnsName=""
          viewWidth={viewWidth}
        />
      </div>
    );
  };

  return Graph;
};

export default GetAlarmsGraph;
