import React, { useEffect, useState, useContext, useMemo } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { ReactComponent as CloseIcon } from "../../../assets/x.svg";
import { ReactComponent as BackArrowMobile } from "../../../assets/back-icon-mobile.svg";
import { useTranslation } from "react-i18next";
import Button from "../../../components/Button";
import Spacer from "../../../components/Spacer";
import ErrorBanner from "../../../components/ErrorBanner";
import styles from "./AdminDateTime.module.css";
import { ScreenContext } from "../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import DatePickerComponent from "../../../components/DatePickerComponent/DatePickerComponent";


const AdminDateTime = ({
  pendDisOrderControllerRef,
  user,
  selectedItem,
  onCloseAdminDateTimeModal,
}) => {
  const { t, i18n } = useTranslation("pendingDispensingOrder");

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [minute, setMinute] = useState("");
  const [hour, setHour] = useState("");

  const [userError, setUserError] = useState("");
  
  const handleModifiedDatetime = (date) => {
    setHour(date.getHours().toString().padStart(2, "0"));
    setMinute(date.getMinutes().toString().padStart(2, "0"));
    setDay(date.getDate().toString().padStart(2, "0"));
    setMonth((date.getMonth() + 1).toString().padStart(2, "0"));
    setYear(date.getFullYear().toString());
  };

  const originalDate = useMemo(() => {
    const adminTime =
      selectedItem.adminTime.split(":").length === 2
        ? selectedItem.adminTime + ":00"
        : selectedItem.adminTime;
    return selectedItem && selectedItem.adminDate
      ? new Date(`${selectedItem.adminDate}T${adminTime}`)
      : new Date()
  }, [selectedItem]);
  

  useEffect(() => {
     handleModifiedDatetime(originalDate);
  }, [originalDate]);


  // Month in JavaScript is 0-indexed (January is 0, February is 1, etc),
  // but by using 0 as the day it will give the last day of the prior
  // month. So passing in 1 as the month number will return the last day
  // of January, not February
  const daysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  };

  const handleSubmit = () => {
    // Verify correct data format
    let error;
    if (
      !(
        day.length === 2 &&
        month.length === 2 &&
        year.length === 4 &&
        minute.length === 2 &&
        hour.length === 2
      )
    ) {
      error = t("incorrectDateError");
    } else {
      // Creates the current datetime in UTC format.
      let currentDate = new Date();
      currentDate.setSeconds(0);

      // When creating a Date with arguments, the month value is zero-indexed.
      // Also, datetimes created with arguments are local time, not UTC unless a special UTC format is speciifed.
      const modifiedDate = new Date(
        year,
        Number(month) - 1,
        day,
        hour,
        minute,
        0,
        0
      );

      // ERROR CHECKING
      let monthName;
      let maxDays;
      switch (Number(month)) {
        case 1:
          monthName = t("january", {ns: "months"});
          maxDays = daysInMonth(1, year);
          break;
        case 2:
          monthName = t("february", {ns: "months"});
          maxDays = daysInMonth(2, year);
          break;
        case 3:
          monthName = t("march", {ns: "months"});
          maxDays = daysInMonth(3, year);
          break;
        case 4:
          monthName = t("april", {ns: "months"});
          maxDays = daysInMonth(4, year);
          break;
        case 5:
          monthName = t("may", {ns: "months"});
          maxDays = daysInMonth(5, year);
          break;
        case 6:
          monthName = t("june", {ns: "months"});
          maxDays = daysInMonth(6, year);
          break;
        case 7:
          monthName = t("july", {ns: "months"});
          maxDays = daysInMonth(7, year);
          break;
        case 8:
          monthName = t("august", {ns: "months"});
          maxDays = daysInMonth(8, year);
          break;
        case 9:
          monthName = t("september", {ns: "months"});
          maxDays = daysInMonth(9, year);
          break;
        case 10:
          monthName = t("october", {ns: "months"});
          maxDays = daysInMonth(10, year);
          break;
        case 11:
          monthName = t("november", {ns: "months"});
          maxDays = daysInMonth(11, year);
          break;
        case 12:
          monthName = t("december", {ns: "months"});
          maxDays = daysInMonth(12, year);
          break;

        default:
        // code block
      }

      if (day > maxDays && monthName !== t("february", {ns: "months"})) {
        error = t("oobMonthError", {month: monthName, days: maxDays});
      } else if (day > maxDays && monthName === t("february", {ns: "months"})) {
        error = t("oobFebruaryError", {month: monthName, days: maxDays, year: year});
        // Even though 'modifiedDate' is in local-time format and 'currentDate' is in UTC format, this test is valid because the getTime method always returns the time in local format.
      } else if (modifiedDate.getTime() < currentDate.getTime()) {
        error = t("datePastError");
      }
    }
    if (!error) {
      setUserError("");

      let modifiedDispensingOrderItem = { ...selectedItem };
      modifiedDispensingOrderItem.adminDate = `${year}-${month}-${day}`;
      modifiedDispensingOrderItem.adminTime = `${hour}:${minute}:00`;

      pendDisOrderControllerRef.updateAdminDateTime(
        selectedItem,
        modifiedDispensingOrderItem
      );

      onCloseAdminDateTimeModal(true);
    } else {
      setUserError(error);
    }
  };

  // en is English; es is Spanish; fr is French;
  const languageDetected = i18n.language;

  let packageIdClass;
  let defaultDrugNameClass;
  let adminDateClass;
  let adminTimeClass;

  let packageId;
  let productDefaultName;

  try {
    packageId = selectedItem.product.packageId;
  } catch (tryError) {
    packageId = "";
  }

  try {
    productDefaultName = selectedItem.product.defaultName;
  } catch (tryError) {
    productDefaultName = "";
  }

  switch (languageDetected) {
    case "en":
      packageIdClass = "AdminDateTime__basicInfo--packageIdEn";
      defaultDrugNameClass = "AdminDateTime__basicInfo--defaultNameEn";
      adminDateClass = "AdminDateTime__basicInfo--adminDateEn";
      adminTimeClass = "AdminDateTime__basicInfo--adminTimeEn";
      break;
    case "es":
      packageIdClass = "AdminDateTime__basicInfo--packageIdEs";
      defaultDrugNameClass = "AdminDateTime__basicInfo--defaultNameEs";
      adminDateClass = " AdminDateTime__basicInfo--adminDateEs";
      adminTimeClass = "AdminDateTime__basicInfo--adminTimeEs";
      break;
    case "fr":
      packageIdClass = "AdminDateTime__basicInfo--packageIdFr";
      defaultDrugNameClass = "AdminDateTime__basicInfo--defaultNameFr";
      adminDateClass = " AdminDateTime__basicInfo--adminDateFr";
      adminTimeClass = " AdminDateTime__basicInfo--adminTimeFr";
      break;
    default:
      packageIdClass = "AdminDateTime__basicInfo--packageIdEn";
      defaultDrugNameClass = "AdminDateTime__basicInfo--defaultNameEn";
      adminDateClass = "AdminDateTime__basicInfo--adminDateEn";
      adminTimeClass = " AdminDateTime__basicInfo--adminTimeEn";
  }

  const goBackHandler = () => {
    onCloseAdminDateTimeModal(false);
  };

  return (
    <div className={styles.AdminDateTime__overlay}>
      <div className={styles.AdminDateTime__view}>
        <div className={styles.AdminDateTime__container}>
          <div className={styles.AdminDateTime__formCloseContainer}>
            {!smScreen && (
              <CloseIcon
                fill={"#121A2D"}
                style={{ cursor: "pointer" }}
                onClick={() => onCloseAdminDateTimeModal()}
              />
            )}
          </div>

          {smScreen && (
            <section className={styles.AdminDateTime__header}>
              <div
                className={styles.AdminDateTime__backArrow}
                onClick={goBackHandler}
              >
                <BackArrowMobile
                  className={styles.AdminDateTime__backArrowIcon}
                />
                <p className={styles.AdminDateTime__goBackTitle}>
                  {t("goBackTitle")}
                </p>
              </div>
              <h1 className={styles.AdminDateTime__title}>
                {t("adminTimeDateLabel")}
              </h1>
            </section>
          )}

          {!smScreen && (
            <section className={styles.AdminDateTime__header}>
              <p className={styles.AdminDateTime__title}>
                {t("adminTimeDateLabel")}
              </p>
            </section>
          )}

          {userError && (
            <div className={styles.AdminDateTime__errorBannerContainer}>
              <ErrorBanner
                message={userError}
                onClose={() => setUserError("")}
              />
            </div>
          )}

          <section className={styles.AdminDateTime__productSelectionContainer}>
            <p
              className={[
                styles["AdminDateTime__basicInfo"],
                styles["AdminDateTime__basicInfo--id"],
                styles[packageIdClass],
              ].join(" ")}
            >
              {`${t("originalPackageIdLabel")}: `}
              <span>{packageId}</span>
            </p>
            <p
              className={[
                styles["AdminDateTime__basicInfo"],
                styles["AdminDateTime__basicInfo--drugName"],
                styles[defaultDrugNameClass],
              ].join(" ")}
            >
              {smScreen ? "" : `${t("defaultDrugNameLabel")}: `}
              <span className={styles.AdminTimeDate__drugName}>
                {productDefaultName}
              </span>
            </p>
            <p
              className={[
                styles["AdminDateTime__basicInfo"],
                styles["AdminDateTime__basicInfo--date"],
                styles[adminDateClass],
              ].join(" ")}
            >
              {`${t("originalAdminDateLabel")}: `}
              <span>{selectedItem.adminDate}</span>
            </p>
            <p
              className={[
                styles["AdminDateTime__basicInfo"],
                styles["AdminDateTime__basicInfo--time"],
                styles[adminTimeClass],
              ].join(" ")}
            >
              {`${t("originalAdminTimeLabel")}: `}
              <span>{selectedItem?.adminTime?.substring(0, 5)}</span>
            </p>
          </section>

          <div className={styles.adminTimeDate__dateTimeInputsContainer}>
            <DatePickerComponent
              label={t("datetimeLabel")}
              startDate={originalDate}
              onChange={handleModifiedDatetime}
              country={user?.defaultSite?.shippingAddress?.country}
              showTimePicker={true}
            />
          </div>

          <section className={styles.AdminDateTime__footer}>
            <Button
              labelName={t("cancelButtonLabel")}
              isPrimary={false}
              isDisabled={false}
              onClick={() => onCloseAdminDateTimeModal()}
              minWidth={smScreen ? 122 : null}
            />
            <Spacer space={20} unit={"px"} />
            <Button
              labelName={t("changeDatetimeButtonLabel")}
              isPrimary={true}
              isDisabled={false}
              onClick={handleSubmit}
              minWidth={smScreen ? 122 : 213}
            />
          </section>
        </div>
      </div>
    </div>
  );
};

export default AdminDateTime;
