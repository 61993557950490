import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { useMediaQuery } from "react-responsive";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as MoreActionsIconMobile } from "../../assets/more-actions-mobile.svg";
import { ReactComponent as MoreActionsIcon } from "../../assets/more-actions.svg";
import CreateButton from "../../components/CreateButton";
import ErrorBanner from "../../components/ErrorBanner";
import Popup from "../../components/PopupMenu/Popup";
import SearchBar from "../../components/SearchBar";
import Spacer from "../../components/Spacer";
import { ScreenContext } from "../../contexts/ScreenContext";
import FacilityService from "../../services/FacilityService";
import RoleCheckService from "../../services/RoleCheckService";
import DeliverySchedule from "./DeliverySchedule";
import styles from "./Facilities.module.css";
import FacilitiesDataCard from "./FacilitiesDataCard";
import ManageFacilitySublocations from "./ManageFacilitySublocations";

const Facilities = ({ user }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { t } = useTranslation("facilities");

  const actionListItems = [
    {
      type: "deliverySchedule",
      description: t("menuItem0"),
    },
    {
      type: "enable",
      description: t("menuItem1"),
    },
    {
      type: "disable",
      description: t("menuItem2"),
    },
    {
      type: "edit",
      description: t("menuItem3"),
    },
    {
      type: "labelConfiguration",
      description: t("menuItem4"),
    },
  ];

  const [selectedFacility, setSelectedFacility] = useState(null);
  const [error, setError] = useState("");
  const [facilities, setFacilities] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [currentAction, setCurrentAction] = useState(0);
  const [showPopup, setShowPopup] = useState(null);
  const [locationPathname, setLocationPathname] = useState("");

  const { handleShowSmScreenHeader, smallScreen, mediumScreen, largeScreen } =
    useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);
  const mdScreen = useMediaQuery(mediumScreen);
  const lgScreen = useMediaQuery(largeScreen);

  useEffect(() => {
    smScreen && handleShowSmScreenHeader(true);
  }, [smScreen]);

  useEffect(() => {
    setLocationPathname(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    FacilityService.list(user, true)
      .then((facilities) => {
        setFacilities(facilities);
      })
      .catch((error) => {
        setError(error);
      });
  }, [user, location.state]);

  const handleSearch = (searchText) => {
    setFilterText(searchText.toUpperCase());
  };

  const handleCreate = () => {
    navigate("create");
    //setCurrentAction(1);
  };

  const getActionMenu = (facility) => {
    let listItems = [...actionListItems];

    if (!RoleCheckService.viewDeliverySchedule(user)) {
      let index = listItems.findIndex((o) => o.type === "deliverySchedule");
      listItems.splice(index, 1);
    }

    if (!RoleCheckService.canCreateFacilities(user)) {
      let index = listItems.findIndex((o) => o.type === "edit");
      listItems.splice(index, 1);
    }

    if (facility.isEnabled) {
      let index = listItems.findIndex((o) => o.type === "enable");
      listItems.splice(index, 1);
      //return listItems;
    } else if (!facility.isEnabled) {
      let index = listItems.findIndex((o) => o.type === "disable");
      listItems.splice(index, 1);
      //return listItems;
    }

    if (!RoleCheckService.canCreateFacilities(user)) {
      let index = listItems.findIndex((o) => o.type === "enable");
      if (index === -1) {
        index = listItems.findIndex((o) => o.type === "disable");
      }
      listItems.splice(index, 1);
    }

    return listItems;
  };

  const handleAction = (action, facility) => {
    let newFacility = { ...facility };
    switch (action.type) {
      case "enable":
        newFacility.isEnabled = true;
        updateFacility(newFacility);
        break;
      case "disable":
        newFacility.isEnabled = false;
        updateFacility(newFacility);
        break;
      case "edit":
        navigate("create", { state: { facility: facility } });
        //setCurrentAction(1);
        //setSelectedFacility(facility);
        break;
      case "deliverySchedule":
        navigate("deliverySchedule", { state: { facility: facility } });
        // setCurrentAction(3);
        // setSelectedFacility(facility);
        break;
      case "labelConfiguration":
        break;
      case "manageSubLocations":
        setCurrentAction(2);
        setSelectedFacility(facility);
        break;
      default:
        break;
    }
  };

  const updateFacility = (facility) => {
    FacilityService.update(user, facility)
      .then((facilityDoc) => {
        let index = facilities.findIndex((o) => o._id === facilityDoc._id);
        let list = [...facilities];
        list[index] = facilityDoc;
        setFacilities(list);
      })
      .catch((error) => {
        setError(error);
      });
  };

  if (currentAction === 2) {
    return (
      <div className={styles.facilities__overlay}>
        <ManageFacilitySublocations
          user={user}
          rootLocation={selectedFacility}
          onClose={() => {
            setCurrentAction(0);
            setSelectedFacility(null);
          }}
        />
      </div>
    );
  } else if (currentAction === 3) {
    return (
      <div className={styles.facilities__overlay}>
        <DeliverySchedule
          user={user}
          facility={selectedFacility}
          onClose={() => {
            setCurrentAction(0);
            setSelectedFacility(null);
          }}
        />
      </div>
    );
  } else {
    return (
      <>
        <Outlet />
        {error && (
          <div className={styles.facilities__errorBannerContainer}>
            <ErrorBanner message={error} onClose={() => setError(null)} />
          </div>
        )}

        {(locationPathname === "/settings/facilities" ||
          locationPathname === "/inventory/facilities") && (
          <>
            {!smScreen && <Spacer space={mdScreen ? 20 : 70} unit={"px"} />}
            <div
              className={`${styles.facilities__row} ${styles["facilities__row--spaceBetween"]}`}
            >
              <div className={styles.facilities__searchBarContainer}>
                <SearchBar
                  placeholder={
                    smScreen
                      ? t("searchPlaceholderMobile")
                      : t("searchPlaceholder")
                  }
                  onSearch={handleSearch}
                  focus={true}
                />
              </div>

              {RoleCheckService.canCreateFacilities(user) && (
                <div className={styles.facilities__createButtonContainer}>
                  <CreateButton
                    onClick={handleCreate}
                    isEnabled={true}
                    labelName={t("createButtonLabel")}
                  />
                </div>
              )}
            </div>
            {!smScreen && <Spacer space={mdScreen ? 50 : 100} unit={"px"} />}

            {/* Generation of Data Table Headings for Large Screen */}

            {!smScreen && (
              <ul className={styles.facilities__tableHeader}>
                <li className={`${styles.facilities__facilityName}`}>
                  {t("col1")}
                </li>
                <li className={`${styles.facilities__type}`}>{t("col2")}</li>
                <li className={`${styles.facilities__contactName}`}>
                  {t("col3")}
                </li>

                <li className={`${styles.facilities__email}`}>{t("col4")}</li>
                <li className={`${styles.facilities__phoneNumber}`}>
                  {t("col5")}
                </li>
                {lgScreen && (
                  <li className={styles.facilities__actionsHeading}>
                    {t("col6")}
                  </li>
                )}
              </ul>
            )}

            <Spacer space={smScreen ? 0 : 20} unit={"px"} />

            {/* Generation of Data Table */}

            <div
              className={
                !smScreen
                  ? styles.facilities__scroll
                  : styles.facilities__dataCardsContainer
              }
            >
              {facilities.length > 0 &&
                facilities
                  .filter(
                    (o) =>
                      RoleCheckService.viewFacilitySettings(user) &&
                      (filterText
                        ? o.name.toUpperCase().includes(filterText) ||
                          (o.primaryContact
                            ? o.primaryContact.name
                                .toUpperCase()
                                .includes(filterText)
                            : false)
                        : true)
                  )
                  .map((facility, index) => {
                    return !smScreen ? (
                      <ul
                        key={facility._id}
                        className={
                          facility.isEnabled
                            ? `${styles.facilities__listItem}`
                            : `${styles.facilities__listItem} ${styles["facilities__listItem--siteEnabled"]}`
                        }
                        style={{
                          zIndex: facilities.length - index,
                        }}
                      >
                        <li className={`${styles["facilities__facilityName"]}`}>
                          {facility.name}
                        </li>
                        <li className={`${styles["facilities__type"]}`}>
                          {facility.packagingMethod?.description || ""}
                        </li>
                        <li className={`${styles["facilities__contactName"]}`}>
                          {facility.primaryContact
                            ? facility.primaryContact.name || ""
                            : ""}
                        </li>
                        <li className={`${styles["facilities__email"]}`}>
                          {facility.primaryContact
                            ? facility.primaryContact.emailAddress || ""
                            : ""}
                        </li>
                        <li className={`${styles["facilities__phoneNumber"]}`}>
                          {facility.phoneNumbers.length > 0
                            ? formatPhoneNumberIntl(
                                facility.phoneNumbers[0].number
                              )
                            : ""}
                        </li>

                        <li
                          className={styles.facilities__actionsContainer}
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setShowPopup(facility);
                          }}
                        >
                          {mdScreen ? (
                            <MoreActionsIconMobile />
                          ) : (
                            <MoreActionsIcon />
                          )}

                          {showPopup === facility && (
                            <div className={styles.facilities__popupContainer}>
                              <Popup
                                menuItems={getActionMenu(facility)}
                                rightJustify={true}
                                onClose={() => setShowPopup(null)}
                                onAction={(action) => {
                                  handleAction(action, facility);
                                  setShowPopup(null);
                                }}
                              />
                            </div>
                          )}
                        </li>
                      </ul>
                    ) : (
                      <FacilitiesDataCard
                        key={facility._id}
                        data={{
                          facilityNameHeading: t("col1"),
                          typeHeading: t("col2"),
                          contactNameHeading: t("col3"),
                          emailHeading: t("col4"),
                          phoneNumberHeading: t("col5"),
                          actionsHeading: t("col6"),
                          facilityName: facility.name,
                          type: facility.packagingMethod?.description || "",
                          contactName: facility.primaryContact
                            ? facility.primaryContact.name || ""
                            : "",
                          email: facility.primaryContact
                            ? facility.primaryContact.emailAddress || ""
                            : "",
                          phoneNumber:
                            facility.phoneNumbers.length > 0
                              ? formatPhoneNumberIntl(
                                  facility.phoneNumbers[0].number
                                )
                              : "",
                          actions: (
                            <div
                              className={styles.facilities__actions}
                              onClick={(e) => {
                                setShowPopup(facility);
                              }}
                            >
                              <div
                                className={styles.facilities__actionsContainer}
                              >
                                <MoreActionsIconMobile />

                                {showPopup === facility && (
                                  <div
                                    className={
                                      styles.facilities__popupContainer
                                    }
                                  >
                                    <Popup
                                      menuItems={getActionMenu(facility)}
                                      onAction={(action) => {
                                        setShowPopup(null);
                                        handleAction(action, facility);
                                      }}
                                      onClose={() => setShowPopup(null)}
                                      rightJustify={true}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          ),
                        }}
                      />
                    );
                  })}
            </div>
          </>
        )}
      </>
    );
  }
};

export default Facilities;
