import React, { useState, useEffect, useRef, useContext } from "react";
import {
  getTimeRangeDay,
  getTimeRange12Hours,
  getTimeRangeWeek,
  getTimeRangeMonth,
} from "../../../../functions/dates";
import TruPakDashboardController from "../../TruPakDashboard/TruPakDashboardController";
import BatchesPerDayGraph from "../graphs/BatchesPerDayGraph";
import { ScreenContext } from "../../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "../TruPakDashboard.module.css";
import stylesMobile from "../MobileViews/TruPakDashboardMobileView.module.css";
import { useTranslation } from "react-i18next";

const TruPakDashboard = ({
  user,
  selectedDevices,
  selectedSites,
  viewWidth,
  loader,
}) => {
  const { t } = useTranslation("trupakDashboard");

  const [timeRange, setTimeRange] = useState("month");
  const [startDate, setStartDate] = useState(null);
  const [stopDate, setStopDate] = useState(null);
  const [Graph, setGraph] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [fetchingData, setFetchingData] = useState(false);

  const _controllerRef = useRef(TruPakDashboardController);

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  useEffect(() => {
    if (selectedDevices.length && !fetchingData) {
      setGraph(
        BatchesPerDayGraphContainer(
          "batchesPerDayGraph", // id
          graphData // data for selected site and dispensing device
        )
      );
    }
  }, [graphData, fetchingData, selectedDevices]);

  useEffect(() => {
    let dateRangeObject;
    if (timeRange === "12 hours") {
      dateRangeObject = getTimeRange12Hours();
    } else if (timeRange === "day") {
      dateRangeObject = getTimeRangeDay();
    } else if (timeRange === "week") {
      dateRangeObject = getTimeRangeWeek();
    } else {
      dateRangeObject = getTimeRangeMonth();
    }

    setStartDate(dateRangeObject.startDate);
    setStopDate(dateRangeObject.stopDate);
  }, [timeRange]);

  // Batches Per Day graph-data fetching methods
  useEffect(() => {
    if (startDate && stopDate && selectedDevices.length) {
      // Get data for Pouches Per Hour graph
      const utcStartDate = startDate.toUTCString();
      const utcStopDate = stopDate.toUTCString();

      if (selectedSites.length && selectedDevices.length) {
        setGraph([BatchesPerDayGraphContainer(1, [], 0, null, true)]);
      }

      async function updateGraph() {
        setFetchingData(true);
        let batchesPerDay = {
          Sun: 0,
          Mon: 0,
          Tues: 0,
          Wed: 0,
          Thurs: 0,
          Fri: 0,
          Sat: 0,
        };

        for (let i = 0; i < selectedDevices.length; i++) {
          const device = selectedDevices[i];
          const site = selectedDevices[i].site;

          await _controllerRef.current
            .getTruPakBatchesVsPackagedOnData(
              user,
              site,
              device,
              utcStartDate,
              utcStopDate
            )
            .then((data) => {
              const batchesLookup =
                data.results?.truPakBatchesVsPackagedOnData?.batches;

              if (batchesLookup) {
                for (const [batch, packagedOn] of Object.entries(
                  batchesLookup
                )) {
                  const dt = new Date(packagedOn); // UTC
                  const dayOfWeek = dt.getDay(); // Local time

                  switch (dayOfWeek) {
                    case 0:
                      batchesPerDay["Sun"] += 1;
                      break;
                    case 1:
                      batchesPerDay["Mon"] += 1;
                      break;
                    case 2:
                      batchesPerDay["Tues"] += 1;
                      break;
                    case 3:
                      batchesPerDay["Wed"] += 1;
                      break;
                    case 4:
                      batchesPerDay["Thurs"] += 1;
                      break;
                    case 5:
                      batchesPerDay["Fri"] += 1;
                      break;
                    case 6:
                      batchesPerDay["Sat"] += 1;
                      break;

                    default:
                    // code block
                  }
                }
              }
            });
        }

        const graphData = [
          { x: "Sun", y: batchesPerDay["Sun"] },
          { x: "Mon", y: batchesPerDay["Mon"] },
          { x: "Tues", y: batchesPerDay["Tues"] },
          { x: "Wed", y: batchesPerDay["Wed"] },
          { x: "Thurs", y: batchesPerDay["Thurs"] },
          { x: "Fri", y: batchesPerDay["Fri"] },
          { x: "Sat", y: batchesPerDay["Sat"] },
        ];

        setGraphData(graphData);
        setFetchingData(false);
      }

      if (selectedDevices.length) {
        updateGraph();
      } else {
        setGraph([]);
      }
    } else {
      setGraph([]);
    }
  }, [selectedSites, startDate, stopDate, selectedDevices]);

  const handleTimeRangeBatchesPerDay = (stringVal) => {
    setTimeRange(stringVal);
  };

  const BatchesPerDayGraphContainer = (id, data) => {
    let hours;
    if (startDate && stopDate) {
      const Difference_In_Time = stopDate.getTime() - startDate.getTime();

      hours = Math.round(Difference_In_Time / (1000 * 3600));
    }

    let totalNumPouches = 0;

    if (data.length) {
      data.forEach((item) => (totalNumPouches += item.y));
    }

    let batchesPerSundayBenchmark = 0;
    let batchesPerMondayBenchmark = 0;
    let batchesPerTuesdayBenchmark = 0;
    let batchesPerWednesdayBenchmark = 0;
    let batchesPerThursdayBenchmark = 0;
    let batchesPerFridayBenchmark = 0;
    let batchesPerSaturdayBenchmark = 0;
    selectedDevices.forEach((device) => {
      if (device?.device && device.device?.settings) {
        if (device.device.settings?.batchesPerSundayBenchmark) {
          batchesPerSundayBenchmark += Number(
            device.device.settings.batchesPerSundayBenchmark
          );
        }
        if (device.device.settings?.batchesPerMondayBenchmark) {
          batchesPerMondayBenchmark += Number(
            device.device.settings.batchesPerMondayBenchmark
          );
        }
        if (device.device.settings?.batchesPerTuesdayBenchmark) {
          batchesPerTuesdayBenchmark += Number(
            device.device.settings.batchesPerTuesdayBenchmark
          );
        }
        if (device.device.settings?.batchesPerWednesdayBenchmark) {
          batchesPerWednesdayBenchmark += Number(
            device.device.settings.batchesPerWednesdayBenchmark
          );
        }
        if (device.device.settings?.batchesPerThursdayBenchmark) {
          batchesPerThursdayBenchmark += Number(
            device.device.settings.batchesPerThursdayBenchmark
          );
        }
        if (device.device.settings?.batchesPerFridayBenchmark) {
          batchesPerFridayBenchmark += Number(
            device.device.settings.batchesPerFridayBenchmark
          );
        }
        if (device.device.settings?.batchesPerSaturdayBenchmark) {
          batchesPerSaturdayBenchmark += Number(
            device.device.settings.batchesPerSaturdayBenchmark
          );
        }
      }
    });

    const benchmarkData = [
      { x: "Sun", y: batchesPerSundayBenchmark },
      { x: "Mon", y: batchesPerMondayBenchmark },
      { x: "Tues", y: batchesPerTuesdayBenchmark },
      { x: "Wed", y: batchesPerWednesdayBenchmark },
      { x: "Thurs", y: batchesPerThursdayBenchmark },
      { x: "Fri", y: batchesPerFridayBenchmark },
      { x: "Sat", y: batchesPerSaturdayBenchmark },
    ];

    let maxBenchmarkValue = 0;
    benchmarkData.forEach((item) => {
      if (item.y > maxBenchmarkValue) {
        maxBenchmarkValue = item.y;
      }
    });

    return (
      <div
        key={id}
        className={
          smScreen
            ? `${stylesMobile.TruPakDashboard__graphContainerMobile} ${stylesMobile["TruPakDashboard__graphContainerMobile--paddingBottom"]}`
            : `${styles.TruPakDashboard__graphContainerMobile} ${styles["TruPakDashboard__graphContainer--paddingBottom"]}`
        }
        // onClick={!fetchingData ? () => handleGraphClick(1) : null}
      >
        {fetchingData && loader}
        <BatchesPerDayGraph
          graphData={data}
          benchmarkData={benchmarkData}
          maxBenchmarkValue={maxBenchmarkValue}
          graphId={`batchesPerDayGraph${id}`}
          graphTitle={t("batchesTitle")}
          palette={["#318ce7", "#E68000"]}
          viewWidth={viewWidth}
          timeRange={timeRange}
          onTimeRange={handleTimeRangeBatchesPerDay}
        />
      </div>
    );
  };

  return Graph;
};

export default TruPakDashboard;
