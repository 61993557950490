import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import styles from "./InventoryEquiv.module.css";
import { ReactComponent as BackIcon } from "../../assets/back_icon.svg";
import { ReactComponent as BackArrowMobile } from "../../assets/back-icon-mobile.svg";
import { ReactComponent as SVGCreateButtonIcon } from "../../assets/create-button-icon.svg";
import SearchBar from "../../components/SearchBar";
import { InventoryEquivProductsHeadings } from "./InventoryEquivProductsHeadings.js";
import InventoryEquivProductsList from "./InventoryEquivProductsList";
import InventoryAddEquiv from "./InventoryAddEquiv";
import ProductEquivalencyController from "../../controllers/ProductEquivalencyController";
import ProductController from "../../controllers/ProductController";
import { useNavigate } from "react-router-dom";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";

const InventoryEquiv = ({ user, product: product }) => {
  const navigate = useNavigate();

  const [equivProducts, setEquivProducts] = useState([]);
  const [productEquivalency, setProductEquivalency] = useState({});
  const [toggle, setToggle] = useState(false);
  const [addEquivModalActive, setAddEquivModalActive] = useState("");
  const [filterText, setFilterText] = useState("");

  const prodEquivCntrInstance = new ProductEquivalencyController(user);
  const pcRef = new ProductController(user);

  const callback = (error, productEquivalency) => {
    if (!error) {
      if (productEquivalency) {
        setProductEquivalency(productEquivalency);
        setEquivProducts(productEquivalency.products);
      }
    } else {
      console.log("error", error);
    }
  };

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  useEffect(() => {
    prodEquivCntrInstance.getProductEquivalency(product, callback);
  }, [toggle]);

  const goBackHandler = () => {
    navigate(-1);
  };

  const filteredEquivProducts = equivProducts.filter((prod) => {
    if (
      // filter out primary product
      (prod.packageId !== product.packageId &&
        (prod.packageId
          .toString()
          .toUpperCase()
          .includes(filterText.toUpperCase()) ||
          pcRef.getDefaultName(prod).includes(filterText.toUpperCase()) ||
          prod.manufacturer.fullName
            .toUpperCase()
            .includes(filterText.toUpperCase()))) ||
      prod.manufacturer.abbrName
        .toUpperCase()
        .includes(filterText.toUpperCase())
    ) {
      return true;
    } else {
      return false;
    }
  });

  const { t } = useTranslation("equivalency");

  const handleSearch = (searchText) => {
    setFilterText(searchText.toUpperCase());
  };

  const handleAddEquivalencyClick = () => {
    setAddEquivModalActive(true);
  };

  const handleBack = () => {
    if (!addEquivModalActive) {
      navigate(-1);
    } else {
      setAddEquivModalActive(false);
    }
  };

  const productDescriptionGroup = (
    <div className={styles.invEquiv__descriptionGroup}>
      {smScreen && (
        <p className={styles.invEquiv__description}>
          {pcRef.getDefaultName(product)}
        </p>
      )}

      <div className={styles.invEquiv__IDContainer}>
        <div className={styles.invEquiv__IDGroup}>
          <span className={styles.invEquiv__IDKey}>ID: </span>
          <span className={styles.invEquiv__ID}>{product.packageId}</span>
        </div>
        <div className={styles.invEquiv__manufacturerGroup}>
          <span className={styles.invEquiv__manufacturerKey}>
            {t("col2") + ": "}
          </span>
          <span className={styles.invEquiv__manufacturerName}>
            {product.manufacturer.fullName || product.manufacturer.abbrName}
          </span>
        </div>
      </div>
    </div>
  );

  const addEquivalencyGroup = (
    <div className={styles.invEquiv__addEquivalencyGroup}>
      <SVGCreateButtonIcon
        className={styles.invEquiv__createBtn}
        onClick={() => {
          handleAddEquivalencyClick(product.packageId);
        }}
      />
      <span className={styles.invEquiv__addEquivalency}>
        {t("addEquivalencyLabel")}
      </span>
    </div>
  );

  const smallScreenHeader = (
    <section className={styles.invEquiv__header}>
      <div className={styles.invEquiv__backArrow} onClick={goBackHandler}>
        <BackArrowMobile className={styles.invEquiv__backArrowIcon} />

        <p className={styles.invEquiv__goBackTitle}>{`Back`}</p>
      </div>
      <div className={styles.invEquiv__searchBarContainer}>
        <SearchBar
          placeholder={t("searchPlaceholderSmall")}
          onSearch={handleSearch}
          smFullWidth={true}
        />
      </div>
      <div className={styles.invEquiv__breakToNewRow}></div>
      <p className={styles.invEquiv__title}>{`Equivalency`}</p>
    </section>
  );

  if (!addEquivModalActive) {
    return (
      <div className={styles.invEquiv__main}>
        {smScreen && smallScreenHeader}
        <section className={styles.invEquiv__nonScrollContent}>
          {!smScreen && (
            <div className={styles.invEquiv__header}>
              <div className={styles.invEquiv__subHeader}>
                <BackIcon
                  className={styles.invEquiv__backIcon}
                  onClick={handleBack}
                />
                <p className={styles.invEquiv__descriptionHeading}>
                  <span className={styles.invEquiv__description}>
                    {pcRef.getDefaultName(product)}
                  </span>
                  <span className={styles.invEquiv__equivalency}>
                    {" " + t("equivalencyLabel")}
                  </span>
                </p>
              </div>
            </div>
          )}

          {smScreen && addEquivalencyGroup}
          {productDescriptionGroup}

          {!smScreen && (
            <Fragment>
              <br />
              <div className={styles.invEquiv__searchBarContainer}>
                <div className={styles.invEquiv__searchBar}>
                  <SearchBar
                    placeholder={t("searchPlaceholder")}
                    onSearch={handleSearch}
                    smFullWidth={true}
                  />
                </div>
                {addEquivalencyGroup}
              </div>

              <InventoryEquivProductsHeadings />
            </Fragment>
          )}
        </section>

        <InventoryEquivProductsList
          product={product}
          filteredEquivProducts={filteredEquivProducts}
          productEquivalency={productEquivalency}
          equivProducts={equivProducts}
          setEquivProducts={setEquivProducts}
          //selectedStockItemMongoId={selectedStockItemMongoId}
          prodEquivCntrInstance={prodEquivCntrInstance}
        />
      </div>
    );
  } else {
    return (
      <InventoryAddEquiv
        product={product}
        toggle={toggle}
        setToggle={setToggle}
        setAddEquivModalActive={setAddEquivModalActive}
        productEquivalency={productEquivalency}
        setProductEquivalency={setProductEquivalency}
        equivProducts={equivProducts}
        setEquivProducts={setEquivProducts}
        prodEquivCntrInstance={prodEquivCntrInstance}
      />
    );
  }
};

export default InventoryEquiv;
