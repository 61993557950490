import React from "react";
import styles from "./AssociateProductBarcode.module.css";
import { useTranslation } from "react-i18next";

const AssociateProductBarcode = ({
  scannedBarcodes,
  setScannedBarcodes,
  productBarcode,
  setProductBarcode,
  setProductNotFound,
  setAssociateBarcodeWithProduct,
}) => {
  const { t } = useTranslation("associateProductBarcode")

  const cancelHandler = () => {
    setProductNotFound(false);
    setProductBarcode("");
    let tempArray = [...scannedBarcodes];
    tempArray.pop();
    setScannedBarcodes(tempArray);
  };

  const associateHandler = () => {
    setAssociateBarcodeWithProduct(true);
  };

  return (
    <div className={styles.associateProductBarcode}>
      <p className={styles.associateProductBarcode__message}>
        {t("associateProductPrefix")}{" "}
        <span className={styles.associateProductBarcode__messageStyling}>
          {scannedBarcodes[scannedBarcodes.length - 1]}
        </span>{" "}
        {t("associateProductSuffix")}
      </p>
      <div className={styles.associateProductBarcode__buttons}>
        <button
          className={[
            styles["associateProductBarcode__button"],
            styles["associateProductBarcode__button--create"],
          ].join(" ")}
          onClick={associateHandler}
        >
          {t("yesButtonLabel")}
        </button>
        <button
          className={[
            styles["associateProductBarcode__button"],
            styles["associateProductBarcode__button--cancel"],
          ].join(" ")}
          onClick={cancelHandler}
        >
          {t("cancelButtonLabel")}
        </button>
      </div>
    </div>
  );
};

export default AssociateProductBarcode;
