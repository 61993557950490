import React, { useContext } from "react";
import { ReactComponent as CloseIcon } from "../assets/x.svg";
import { ScreenContext } from "../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import { getLocalizedError } from "../functions/localization";

const ErrorBanner = ({ message, onClose }) => {
  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  let containerStyle = {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "76px",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#d9534f",
    opacity: "0.9",
    overflow: "no-content",
  };

  if (smScreen) {
    containerStyle.height = "66px";
  }

  let messageContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    minHeight: "76px",
    height: "auto",
    justifyContent: "flex-start",
    alignItems: "center",
  };

  if (smScreen) {
    messageContainer.minHeight = "66px";
  }

  let messageStyle = {
    fontSize: "18px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.45",
    letterSpacing: "normal",
    color: "white",
    marginLeft: "25px",
  };

  if (smScreen) {
    messageStyle.fontSize = "16px";
  }

  let iconStyle = {
    marginRight: "25px",
    color: "white",
    cursor: "pointer",
  };

  if (smScreen) {
    iconStyle.marginRight = "20px";
  }

  return (
    <section style={containerStyle}>
      <section style={messageContainer}>
        <p style={messageStyle}>{getLocalizedError(message)}</p>
      </section>
      <CloseIcon
        fill={"white"}
        stroke={"white"}
        style={iconStyle}
        onClick={onClose}
      />
    </section>
  );
};

export default ErrorBanner;
