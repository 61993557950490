import React, { useState, useEffect, useContext } from "react";
import FullBackdropWithModal from "../FullBackdropWithModal/FullBackdropWithModal";
import { ReactComponent as BackArrow } from "../../assets/mobile-back-arrow.svg";
import { ReactComponent as CloseModalIcon } from "../../assets/x.svg";
import { ReactComponent as BackArrowMobile } from "../../assets/back-icon-mobile.svg";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import PharmacyService from "../../services/PharmacyService";
import styles from "./SelectSiteWithDevice.module.css";
import { useTranslation } from "react-i18next";

const SelectSiteWithDevice = ({
  user,
  onHandleSetCurrentAction,
  onHandleSetSelectedSite,
  onClose,
  title,
}) => {
  const { t } = useTranslation("selectSite")

  const { handleShowSmScreenHeader, smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  let maxItemsNoScroll;
  maxItemsNoScroll = !smScreen ? 3 : 4;

  const [sitesWithDevices, setSitesWithDevices] = useState([]);

  useEffect(() => {
    (async () => {
      const sites = await PharmacyService.list(user);
      const tempSitesWithDevices = sites.filter(
        (site) => site.devices.length > 0
      );
      tempSitesWithDevices.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (b.name < a.name) {
          return 1;
        }
        return 0;
      });
      setSitesWithDevices(tempSitesWithDevices);
    })();
  }, [user]);

  const handleGoBack = () => {
    onHandleSetCurrentAction(0);
    handleShowSmScreenHeader(true);
    onClose();
  };

  const Header = smScreen ? (
    <section className={styles.selectSiteWithDevice__headingGroup}>
      <div
        className={styles.selectSiteWithDevice__backArrow}
        onClick={handleGoBack}
      >
        <BackArrowMobile
          className={styles.selectSiteWithDevice__backArrow__icon}
        />
        <p className={styles.selectSiteWithDevice__goBackTitle}>{t("backButtonLabel")}</p>
      </div>
      <p className={styles.selectSiteWithDevice__title}>{title}</p>
    </section>
  ) : (
    <div className={styles.selectSiteWithDevice__header}>
      <CloseModalIcon
        className={styles.selectSiteWithDevice__closeIcon}
        onClick={() => {
          handleGoBack();
        }}
      />
      <BackArrow
        className={styles.selectSiteWithDevice__backArrow}
        onClick={() => {
          handleGoBack();
        }}
      />
      <p className={styles.selectSiteWithDevice__headerTitle}>{title}</p>
    </div>
  );

  if (sitesWithDevices && sitesWithDevices.length > 0) {
    return (
      <FullBackdropWithModal
        modalClassName={styles.selectSiteWithDevice__modal}
      >
        <div className={styles.selectSiteWithDevice__container}>
          {Header}

          {sitesWithDevices && sitesWithDevices.length > 0 && (
            <div className={styles.selectSiteWithDevice}>
              <h2 className={styles.selectSiteWithDevice__heading}>
                {sitesWithDevices && sitesWithDevices.length > 0
                  ? t("selectSiteHeader")
                  : null}
              </h2>
              <div
                className={
                  sitesWithDevices && sitesWithDevices.length > maxItemsNoScroll
                    ? styles["selectSiteWithDevice__scrollArea"]
                    : null
                }
              >
                <ul
                  className={
                    sitesWithDevices &&
                    sitesWithDevices.length > maxItemsNoScroll
                      ? `${styles.selectSiteWithDevice__list} ${styles["selectSiteWithDevice__list--withScroll"]}`
                      : styles["selectSiteWithDevice__list"]
                  }
                >
                  {sitesWithDevices.map((site, index) => {
                    return (
                      <li
                        key={site._id}
                        className={
                          !(sitesWithDevices.length > maxItemsNoScroll)
                            ? styles.selectSiteWithDevice__item
                            : `${styles.selectSiteWithDevice__item} ${styles["selectSiteWithDevice__item--withScroll"]}`
                        }
                        onClick={() => {
                          onHandleSetSelectedSite(site);
                        }}
                      >
                        {site.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          )}
        </div>
      </FullBackdropWithModal>
    );
  } else {
    return (
      <FullBackdropWithModal
        modalClassName={styles.selectSiteWithDevice__modal}
      >
        <div className={styles.selectSiteWithDevice__noSitesFound}>
          <p>{t("noSitesLabel")}</p>
        </div>
      </FullBackdropWithModal>
    );
  }
};

export default SelectSiteWithDevice;
