import React, {
  useState,
  useEffect,
  useReducer,
  useContext,
  Fragment,
} from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/Button";
import Spacer from "../../../components/Spacer";
import SwitchInput from "../../../components/SwitchInput";
import TextInput from "../../../components/TextInput";
import PharmacyService from "../../../services/PharmacyService";
import DropdownMultiSelectInput from "../../../components/DropdownMultiSelectInput";
import SFDropDownList from "../../../components/SFDropDownList/SFDropDownList";
import Report2Collections from "../Report2Collections/Report2Collections";
import { useMediaQuery } from "react-responsive";
import { ScreenContext } from "../../../contexts/ScreenContext";
import ev54CanisterManagement_reportObj from "../CustomizedReports/reportObjects/ev54CanisterManagement_reportObj";
import replenishment_reportObj from "../CustomizedReports/reportObjects/replenishment_reportObj";
import refillReportObject from "../CustomizedReports/RefillReportForHealthDirect/refillReportObjectCustom";
import trayCheckSummaryReportObj from "../CustomizedReports/reportObjects/trayCheckSummaryReportObj";
import styles from "./Report2Header.module.css";

const Report2Header = ({
  reportObj,
  subscribeToReport,
  handleReportObj,
  user,
  onClose,
  onUpdated,
  onSaveData,
  handleFromHeaderPage,
  handleNotFromHeaderPage,
  onIsNotNew,
  editMode,
  onEditMode,
  checkIfReportNameExists,
  originalReportObj,
  previewButton,
  ReportViewerForPreview,
  isPreview,
  collectionFieldDictionary,
}) => {
  const { t } = useTranslation("createReport");

  const [returnToHeader, setReturnToHeader] = useState(false);

  const [errors, setErrors] = useState("");
  const [name, setName] = useState("");
  const [siteList, setSiteList] = useState([]);
  const [sites, setSites] = useState([]);
  const [selectedSites, changeSelectedSites] = useReducer(
    (array, { type, value }) => {
      switch (type) {
        case "add":
          if (!array.some((o) => o.type === value.type)) {
            return [...array, value];
          }
          return array;
        case "remove":
          return array.filter((o) => o.type !== value.type);
        default:
          return array;
      }
    },
    []
  );
  const [collectionsObj, setCollectionsObj] = useState("");
  const [activateCollectionsPage, setActivateCollectionsPage] = useState(false);
  const [resetAppState, setResetAppState] = useState(false);
  const [customizedReport, setCustomizedReport] = useState(null);

  const { mediumScreen } = useContext(ScreenContext);
  const mdScreen = useMediaQuery(mediumScreen);

  useEffect(() => {
    if (editMode) {
      if (reportObj?.title) {
        setName(reportObj.title);
      }
    }
  }, []);

  useEffect(() => {
    if (reportObj?.sites) {
      let mappedSites = reportObj.sites.map((site) => {
        return {
          type: site.name,
          description: site.name,
        };
      });

      mappedSites.forEach((mappedSite) => {
        changeSelectedSites({ type: "add", value: mappedSite });
      });
    }
  }, [reportObj]);

  useEffect(() => {
    if (siteList && siteList.length && selectedSites && selectedSites.length) {
      let tempReportObjSites = [];
      siteList.forEach((siteFromList, siteFromListIndex) => {
        selectedSites.forEach((selectedSite) => {
          if (selectedSite.description === siteFromList.description) {
            tempReportObjSites.push(sites[siteFromListIndex]);
          }
        });
      });
      handleReportObj({ ...reportObj, sites: tempReportObjSites });
    }
  }, [siteList, selectedSites]);

  useEffect(() => {
    checkIfReportNameExists(reportObj.title) &&
    originalReportObj.title !== reportObj.title
      ? setErrors({ ...errors, name: t("reportExists", {ns: "validation"}) })
      : setErrors({ ...errors, name: "" });
  }, [reportObj]);

  useEffect(() => {
    if (resetAppState) {
      onClose();
    }
  }, [resetAppState]);

  useEffect(() => {
    PharmacyService.list(user).then((results) => {
      setSites(results);
      let mappedSites = results.map((result) => {
        return {
          type: result.name,
          description: result.name,
        };
      });
      setSiteList(mappedSites);
    });
  }, [user]);

  useEffect(() => {
    setCustomizedReport(customizedReportDropdownList[0].Item);
  }, []);

  useEffect(() => {
    if (!editMode && reportObj.customizedReport) {
      onSaveData();
    }
  }, [editMode, reportObj]);

  const handleSelectedCustomizedReport = (report) => {
    setCustomizedReport(report);
  };

  const customizedReportDropdownList = [
    { Item: "EV54 Canister Management", Value: "EV54_CANISTER_MANAGEMENT", displayName: t("EV54 Canister Management", {ns:"customReports"}) },
    { Item: "Refill Report", Value: "Refill Report", displayName: t("Refill Report", {ns:"customReports"}) },
    { Item: "Replenishment", Value: "Replenishment", displayName: t("Replenishment", {ns:"customReports"}) },
    { Item: "Summary Bag Report", Value: "Summary_BAG_REPORT", displayName: t("Summary Bag Report", {ns:"customReports"}) },
    { Item: "Tray Check Summary", Value: "TrayCheckSummary", displayName: t("Tray Check Summary", {ns:"customReports"}) },
  ];

  let CustomizedReportValueSelection = (
    <div className={styles.customizedReportValueSelectionContainer}>
      <SFDropDownList
        disabled={true}
        id={"customizedReportValueSelection"}
        label={t("customReportLabel")}
        defaultValue={reportObj?.customizedReport || customizedReport}
        placeHolder={t("customReportPlaceholder")}
        dropDownList={customizedReportDropdownList}
        onSelection={handleSelectedCustomizedReport}
        display={"displayName"}
      />
    </div>
  );

  const handleReturnToHeader = (bool) => {
    setReturnToHeader(bool);
  };

  const handleResetAppState = () => {
    setResetAppState(true);
  };

  const handleAddSite = (site) => {
    changeSelectedSites({ type: "add", value: site });
  };

  const handleRemoveSite = (site) => {
    changeSelectedSites({ type: "remove", value: site });
  };

  const handleNextPage = () => {
    const tempObj = {
      title: name,
      collections: [],
      fields: [],
      functionFields: [],
      functionLookups: {},
    };
    setCollectionsObj(tempObj);
    handleActivateCollectionsPage();
    handleReturnToHeader(false);
  };

  const handleSubscribeToReport = () => {
    let tempReportObj;

    switch (customizedReport) {
      case customizedReportDropdownList[0].Item:
        tempReportObj = {
          ...ev54CanisterManagement_reportObj,
          ignoreDateRange: false,
        };
        break;
      case customizedReportDropdownList[1].Item:
        tempReportObj = { ...refillReportObject };
        break;
      case customizedReportDropdownList[2].Item:
        tempReportObj = { ...replenishment_reportObj };
        break;
      case customizedReportDropdownList[3].Item:
        tempReportObj = {
          title: "",
          isEnterprise: false,
          isStandard: false,
          ignoreDateRange: false,
          collections: [],
          fields: [],
          functionFields: [],
          sites: [],
          groupedField: "No grouping",
          showCustomerReport: false,
          customizedReport: "Summary Bag Report",
          functionLookups: {},
        };
        break;
      case customizedReportDropdownList[4].Item:
        tempReportObj = { ...trayCheckSummaryReportObj };
        break;
      default:
      // code block
    }

    tempReportObj.title = reportObj.title;
    tempReportObj.isEnterprise = reportObj.isEnterprise;
    tempReportObj.sites = reportObj.sites;
    tempReportObj.customizedReport = customizedReport;
    tempReportObj.showCustomReport = reportObj.showCustomReport;

    handleReportObj(tempReportObj);
  };

  const handleActivateCollectionsPage = () => {
    setActivateCollectionsPage(true);
  };

  const handleDeactivateCollectionsPage = () => {
    setActivateCollectionsPage(false);
  };

  const EditSaveButtonGroup = editMode && (
    <Fragment>
      <Spacer space={20} unit={"px"} />
      <Button
        onClick={() => onSaveData()}
        labelName={t("saveCloseButtonLabel")}
        isPrimary={true}
        isDisabled={
          !errors.name &&
          reportObj.title &&
          (reportObj.collections.length > 0 || reportObj?.customizedReport) &&
          (reportObj.fields.length > 0 || reportObj?.customizedReport) &&
          (reportObj.isEnterprise || selectedSites.length > 0)
            ? false
            : true
        }
        minWidth={mdScreen ? null : 213}
      />
    </Fragment>
  );

  if (
    editMode &&
    isPreview &&
    !activateCollectionsPage &&
    reportObj.collections.length > 0 &&
    reportObj.fields.length > 0
  ) {
    return ReportViewerForPreview;
  }

  let reportHeading = t("createTitle");

  if (editMode && !reportObj?.customizedReport) {
    reportHeading = t("editTitle");
  } else if (editMode && reportObj?.customizedReport) {
    reportHeading = t("editCustomTitle")
  } else if (subscribeToReport) {
    reportHeading = t("subscribeTitle");
  }

  if (!activateCollectionsPage) {
    return (
      <div className={styles.Report2Header__view}>
        <div className={styles.Report2Header__leftPane}>
          <div className={styles.Report2Header__header}>
            <p className={styles.Report2Header__titleLeft}>{reportHeading}</p>

            {editMode && reportObj.fields.length > 0 && (
              <div className={styles.Report2Header__previewButtonContainer}>
                {previewButton}
              </div>
            )}
          </div>
          <Spacer space={30} unit={"px"} />

          {(subscribeToReport || reportObj?.customizedReport) &&
            CustomizedReportValueSelection}
          {(subscribeToReport || reportObj?.customizedReport) && (
            <Spacer space={30} unit={"px"} />
          )}

          <TextInput
            value={reportObj.title}
            labelName={t("reportNameLabel")}
            focus={true}
            isEnabled={true}
            onChange={(val) => {
              setName(val);
              handleReportObj({ ...reportObj, title: val });
            }}
            placeholder={""}
            type={"text"}
            validationText={t(errors.name, {ns: "validation"})}
          />
          <Spacer space={30} unit={"px"} />

          <SwitchInput
            labelName={t("accountReportLabel")}
            isOn={reportObj.showCustomReport}
            onToggle={(isOn) => {
              handleReportObj({ ...reportObj, showCustomReport: isOn });
            }}
            labelLeft={true}
          />
          <Spacer space={30} unit={"px"} />
          <SwitchInput
            labelName={t("enterpriseSwitchLabel")}
            isOn={reportObj.isEnterprise}
            onToggle={(isOn) => {
              handleReportObj({ ...reportObj, isEnterprise: isOn });
            }}
            labelLeft={true}
          />
          {!subscribeToReport && !reportObj?.customizedReport && (
            <Spacer space={30} unit={"px"} />
          )}
          {!subscribeToReport && !reportObj?.customizedReport && (
            <SwitchInput
              labelName={t("ignoreDateRangeSwitchLabel")}
              isOn={reportObj.ignoreDateRange}
              onToggle={(isOn) => {
                handleReportObj({ ...reportObj, ignoreDateRange: isOn });
              }}
              labelLeft={true}
            />
          )}
          <Spacer space={30} unit={"px"} />
          {!reportObj.isEnterprise && (
            <DropdownMultiSelectInput
              labelName={selectedSites.length > 0 ? t("label7") : t("label8")}
              validationText={t(errors.sites, {ns: "validation"})}
              listElements={siteList}
              selectedItems={selectedSites}
              onAddItem={handleAddSite}
              onRemoveItem={handleRemoveSite}
              width={"200px"}
            />
          )}
          <Spacer space={80} unit={"px"} />
          <div className={styles.Report2Header__buttonGroup}>
            <Button
              onClick={() => {
                handleFromHeaderPage();
                onEditMode(false);
                onClose();
              }}
              labelName={t("prevButtonLabel")}
              isPrimary={false}
              isDisabled={false}
              minWidth={null}
            />
            <Spacer space={20} unit={"px"} />
            {!subscribeToReport && !reportObj.customizedReport && (
              <Button
                onClick={() => handleNextPage(true)}
                labelName={t("nextButtonLabel")}
                isPrimary={true}
                isDisabled={
                  !errors.name &&
                  reportObj.title &&
                  (reportObj.isEnterprise || selectedSites.length > 0)
                    ? false
                    : true
                }
                minWidth={mdScreen ? null : 213}
              />
            )}
            {subscribeToReport && (
              <Button
                onClick={handleSubscribeToReport}
                labelName={t("saveButtonLabel")}
                isPrimary={true}
                isDisabled={
                  !errors.name &&
                  reportObj.title &&
                  (reportObj.isEnterprise || selectedSites.length > 0)
                    ? false
                    : true
                }
                minWidth={mdScreen ? null : 213}
              />
            )}
            {EditSaveButtonGroup}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <Report2Collections
        handleReturnToHeader={handleReturnToHeader}
        handleResetAppState={handleResetAppState}
        user={user}
        reportObj={reportObj}
        handleReportObj={handleReportObj}
        collectionsObj={collectionsObj}
        handleCollectionsObj={setCollectionsObj}
        handleDeactivateCollectionsPage={handleDeactivateCollectionsPage}
        onUpdated={onUpdated}
        onSaveData={onSaveData}
        handleNotFromHeaderPage={handleNotFromHeaderPage}
        onIsNotNew={onIsNotNew}
        EditSaveButtonGroup={EditSaveButtonGroup}
        editMode={editMode}
        previewButton={previewButton}
        ReportViewerForPreview={ReportViewerForPreview}
        isPreview={isPreview}
        collectionFieldDictionary={collectionFieldDictionary}
      />
    );
  }
};

export default Report2Header;
