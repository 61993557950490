import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import AddRetailScriptLargeView from "./AddRetailScriptLargeView";
import AddRetailScriptMobileView from "./AddRetailScriptMobileView";
import FacilityService from "../../../services/FacilityService";
import DispensingOrderService from "../../../services/DispensingOrderService";
import { v4 as uuidv4 } from "uuid";
import ProductService from "../../../services/ProductService";
import ProductFieldController from "../../../controllers/ProductFieldController";
import { ScreenContext } from "../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

const AddRetailScript = ({ user, site, selectedPackagingMethods }) => {
  const { t } = useTranslation("addRetailScript")

  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [dispensingOrder, setDispensingOrder] = useState(null);
  //const [dispensingOrderItems, setDispensingOrderItems] = useState([]);
  const [dispensingOrderItem, setDispensingOrderItem] = useState({
    qtyNeeded: 1,
    priority: "LOW",
  });
  const [errors, setErrors] = useState({});
  const [products, setProducts] = useState([]);
  const [modifiedProducts, setModifiedProducts] = useState([]);

  const productFieldController = new ProductFieldController();

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  useEffect(() => {
    FacilityService.list(user, false)
      .then((facilities) => {
        const f = facilities.find((o) => o.name.toUpperCase() === "RETAIL");
        if (!f) {
          setError(t("noRetailFacilityError"));
        } else {
          setDispensingOrder({
            account: user.account,
            site: site,
            facility: f,
            dispensingOrderId: uuidv4(),
            type: "retail",
          });
        }
      })
      .catch((error) => {
        setError(error);
      });
  }, [user]);

  useEffect(() => {
    ProductService.getEV54Products(user)
      .then((products) => {
        let modifiedProducts = [];
        for (let i = 0; i < products.length; i++) {
          let product = products[i];
          let modifiedProduct = {
            _id: product._id,
            sortKey: productFieldController.getDefaultName(product),
            description: `${productFieldController.getDefaultName(
              product
            )} (${productFieldController.getPackageId(product)} )`,
          };
          modifiedProducts.push(modifiedProduct);
        }
        setProducts(products);
        setModifiedProducts(
          modifiedProducts.sort((a, b) => {
            if (a.sortKey < b.sortKey) {
              return -1;
            }
            if (b.sortKey < a.sortKey) {
              return 1;
            }
            return 0;
          })
        );
      })

      .catch((error) => setError(error));
  }, [user]);

  const handleCollapse = () => {
    navigate('/dispensing-orders', {
      state: {
        selectedSite: site,
        selectedPackagingMethods: selectedPackagingMethods
      }});
  };

  const handleError = (err) => {
    setError(err);
  };

  const handleErrors = (errs) => {
    setErrors(errs);
  };

  const handleDispensingOrderItem = (item) => {
    setDispensingOrderItem(item);
  };

  const updateName = () => {
    const lastName = dispensingOrderItem.patientInfo?.lastName || "";
    const firstName = dispensingOrderItem.patientInfo.firstName || "";
    dispensingOrderItem.patientInfo[
      "name"
    ] = `${lastName.toUpperCase()}, ${firstName.toUpperCase()}`;
  };

  const rxIsActive = async (rxNumber) => {
    return await DispensingOrderService.rxNumberIsActive(user, rxNumber);
  };

  const handleGenerateScript = async () => {
    try {
      if (await rxIsActive(dispensingOrderItem.rxNumber)) {
        setError(t("rxNumberExistsError", {rxNumber: dispensingOrderItem.rxNumber}));
        return;
      }

      if (isValid()) {
        if (
          !dispensingOrderItem.product.ev54ChuteLimit ||
          dispensingOrderItem.product.ev54ChuteLimit === 0
        ) {
          setError(t("chuteLimitUndefindedError"));
          return;
        }

        if (
          dispensingOrderItem.product.ev54ChuteLimit > 0 &&
          dispensingOrderItem.product.ev54ChuteLimit <
            dispensingOrderItem.qtyNeeded
        ) {
          await handleVialSplitting();
        } else {
          updateName();
          const d = new Date();
          dispensingOrderItem.adminDate = formatDate(d);
          dispensingOrderItem.adminTime = formatTime(d);
          if (!dispensingOrderItem.priority) {
            dispensingOrderItem.priority = "LOW";
          }
          dispensingOrderItem.dispensingOrder =
            await DispensingOrderService.create(
              user,
              site,
              dispensingOrder.facility,
              dispensingOrder
            );
          dispensingOrderItem.account = dispensingOrder.account;
          dispensingOrderItem.site = dispensingOrder.site;
          dispensingOrderItem.facility = dispensingOrder.facility;
          dispensingOrderItem.state = "pending";
          await DispensingOrderService.addDispensingOrderItem(
            user,
            dispensingOrderItem
          );
        }
        handleCollapse();
      } else {
        setError(t("invalidDataError"));
      }
    } catch (e) {
      setError(e);
    }
  };

  const handleVialSplitting = async () => {
    let remainingNeed = dispensingOrderItem.qtyNeeded;
    let vialCapacity = dispensingOrderItem.product.ev54ChuteLimit;
    let rxIndex = 0;
    const facility = dispensingOrder.facility;

    const d = new Date();
    const patientInfo = {
      lastName: dispensingOrderItem.patientInfo?.lastName || "",
      firstName: dispensingOrderItem.patientInfo?.firstName || "",
      id: dispensingOrderItem.patientInfo?.id || "",
    };
    patientInfo.name = `${patientInfo.lastName.toUpperCase()}, ${patientInfo.firstName.toUpperCase()}`;
    const rxNumber = dispensingOrderItem.rxNumber;
    const priority = dispensingOrderItem.priority || "LOW";
    const product = dispensingOrderItem.product;

    while (remainingNeed > 0) {
      dispensingOrder.dispensingOrderId = uuidv4();
      const dispOrd = await DispensingOrderService.create(
        user,
        site,
        dispensingOrder.facility,
        dispensingOrder
      );
      rxIndex++;
      let qty = remainingNeed >= vialCapacity ? vialCapacity : remainingNeed;
      remainingNeed -= qty;
      const doi = {
        adminDate: formatDate(d),
        adminTime: formatTime(d),
        patientInfo: patientInfo,
        rxNumber: `${rxNumber}-SPLIT-${rxIndex}`,
        priority: priority.toUpperCase(),
        qtyNeeded: qty,
        dispensingOrder: dispOrd,
        account: dispOrd.account,
        site: site,
        facility: facility,
        state: "pending",
        product: product,
      };

      await DispensingOrderService.addDispensingOrderItem(user, doi);
    }
  };

  const padTo2Digits = (num) => {
    return num.toString().padStart(2, "0");
  };

  const formatDate = (date) => {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  };

  const formatTime = (date) => {
    return [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds()),
    ].join(":");
  };

  const isValid = () => {
    if (!dispensingOrderItem.patientInfo) {
      dispensingOrderItem.patientInfo = {
        firstName: " ",
        lastName: " ",
        name: " ",
      };
    }

    return (
      dispensingOrderItem.rxNumber &&
      dispensingOrderItem.patientInfo?.firstName &&
      dispensingOrderItem.patientInfo?.lastName &&
      dispensingOrderItem.product &&
      dispensingOrderItem.qtyNeeded > 0
    );
  };

  return smScreen ? (
    <AddRetailScriptMobileView
      site={site}
      handleCollapse={handleCollapse}
      error={error}
      onError={handleError}
      dispensingOrderItem={dispensingOrderItem}
      onDispensingOrderItem={handleDispensingOrderItem}
      errors={errors}
      productFieldController={productFieldController}
      products={products}
      modifiedProducts={modifiedProducts}
      onErrors={handleErrors}
      isValid={isValid}
      onGenerateScript={handleGenerateScript}
    />
  ) : (
    <AddRetailScriptLargeView
      site={site}
      handleCollapse={handleCollapse}
      error={error}
      onError={handleError}
      dispensingOrderItem={dispensingOrderItem}
      onDispensingOrderItem={handleDispensingOrderItem}
      errors={errors}
      productFieldController={productFieldController}
      products={products}
      modifiedProducts={modifiedProducts}
      onErrors={handleErrors}
      isValid={isValid}
      onGenerateScript={handleGenerateScript}
    />
  );
};

export default AddRetailScript;
