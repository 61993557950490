import React, { useState } from "react";
import styles from "./InventoryIconMenu.module.css";
import { ReactComponent as InventoryIcon } from "../../assets/receive-icon.svg";
import { ReactComponent as ReceiveIcon } from "../../assets/receive-icon.svg";
import { ReactComponent as TransferIcon } from "../../assets/transfer-icon.svg";
import { ReactComponent as ProfileIcon } from "../../assets/profile-icon.svg";
import { useTranslation } from "react-i18next";

const InventoryIconMenu = ({
  onReceiveOnClickHandler,
  onTransferOnClickHandler,
}) => {
  const { t } = useTranslation("items")

  const activeIcon = "Home";

  return (
    <section className={styles.inventoryIconMenu}>
      <div
        className={styles.inventoryIconMenu__icon}
        // onClick={iconClickHandler}
      >
        <InventoryIcon
          className={
            activeIcon === "Home"
              ? styles["inventoryIconMenu__fill--active"]
              : styles.inventoryIconMenu__fill
          }
        />
        <span
          className={
            activeIcon === "Home"
              ? [
                  styles["inventoryIconMenu__title"],
                  styles["inventoryIconMenu__title--active"],
                ].join(" ")
              : styles.inventoryIconMenu__title
          }
        >
          {t("inventoryButtonLabel")}
        </span>
      </div>

      <div
        className={styles.inventoryIconMenu__icon}
        onClick={onReceiveOnClickHandler}
      >
        <ReceiveIcon
          className={
            activeIcon === "Receive"
              ? styles["inventoryIconMenu__fill--active"]
              : styles.inventoryIconMenu__fill
          }
        />
        <span
          className={
            activeIcon === "Receive"
              ? [
                  styles["inventoryIconMenu__title"],
                  styles["inventoryIconMenu__title--active"],
                ].join(" ")
              : styles.inventoryIconMenu__title
          }
        >
          {t("receiveButtonLabel")}
        </span>
      </div>

      <div
        className={styles.inventoryIconMenu__icon}
        onClick={onTransferOnClickHandler}
      >
        <TransferIcon
          className={
            activeIcon === "Transfer"
              ? styles["inventoryIconMenu__stroke--active"]
              : styles.inventoryIconMenu__stroke
          }
        />
        <span
          className={
            activeIcon === "Transfer"
              ? [
                  styles["inventoryIconMenu__title"],
                  styles["inventoryIconMenu__title--active"],
                ].join(" ")
              : styles.inventoryIconMenu__title
          }
        >
          {t("transferButtonLabel")}
        </span>
      </div>
    </section>
  );
};

export default InventoryIconMenu;
