import React from "react";
import styles from "./PickListSubHeadingByProduct.module.css";
import { useTranslation } from "react-i18next";

const PickListSubHeadingByProduct = () => {
  const { t } = useTranslation("pickList");

  return (
    <div className={styles.pickList__subHeading}>
      <p
        className={[
          styles["pickList__subColumnName"],
          styles["pickList__subColumnName--item1"],
        ].join(" ")}
      >
        {t("locationTypeCol")}
      </p>
      <p
        className={[
          styles["pickList__subColumnName"],
          styles["pickList__subColumnName--item2"],
        ].join(" ")}
      >
        {t("locationNameCol")}
      </p>
      <p
        className={[
          styles["pickList__subColumnName"],
          styles["pickList__subColumnName--item3"],
        ].join(" ")}
      >
        {t("barcodeCol")}
      </p>
      <p
        className={[
          styles["pickList__subColumnName"],
          styles["pickList__subColumnName--item4"],
        ].join(" ")}
      >
        {t("qtyCol")}
      </p>
    </div>
  );
};

export default PickListSubHeadingByProduct;
