import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  Fragment,
} from "react";
import Button from "../../../components/Button";
import CreateButton from "../../../components/CreateButton";
import Spacer from "../../../components/Spacer";
import DatePickerComponent from "../../../components/DatePickerComponent/DatePickerComponent";
import ToggleSwitch from "../../../components/ToggleSwitch/ToggleSwitch";
import { ReactComponent as TrashIcon } from "../../../assets/trash-icon.svg";
import { ReactComponent as Subset } from "../../../assets/subset.svg";
import { ReactComponent as NotSubset } from "../../../assets/not_subset.svg";
import Report2Functions from "../Report2Functions/Report2Functions";
import { getPreferredFieldName } from "../lookups/getPreferredFieldName";
import { useMediaQuery } from "react-responsive";
import { ScreenContext } from "../../../contexts/ScreenContext";
import styles from "./Report2Calcs.module.css";
import { useTranslation } from "react-i18next";

const Report2Calcs = ({
  reportObj,
  handleReportObj,
  user,
  handleDeactivateCalcsPage,
  booleanDateIntegerOrStringFound,
  onSaveReport,
  editMode,
  previewButton,
  ReportViewerForPreview,
  isPreview,
  collectionFieldDictionary,
}) => {
  const { t } = useTranslation("reportCalcs")

  const [showPopups, setShowPopups] = useState([]);
  const [queryRequirementsMet, setQueryRequirementsMet] = useState([]);
  const [queryValueBlank, setQueryValueBlank] = useState(true);
  const dataRef = useRef({});
  const [activateFunctionsPage, setActivateFunctionsPage] = useState(false);
  const [twoOrMoreNumberFields, setTwoOrMoreNumberFields] = useState(false);

  const { mediumScreen } = useContext(ScreenContext);
  const mdScreen = useMediaQuery(mediumScreen);

  const today = new Date();

  const querySymbolLookup = {
    "LIKE s%": "s%",
    "LIKE %s": "%s",
    INCLUDES: <Subset className={styles.Report2Calcs__includesSVG} />,
    EXCLUDES: <NotSubset className={styles.Report2Calcs__notIncludesSVG} />,
    "EQUALS TO": "=",
    "DOES NOT EQUAL TO": "<>",
    "GREATER THAN": ">",
    "GREATER THAN OR EQUALS TO": ">=",
    "LESS THAN": "<",
    "LESS THAN OR EQUALS TO": "<=",
    "EQUALS TRUE": "true",
    "EQUALS FALSE": "false",
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutsidePopupZone);

    return () => {
      document.removeEventListener("click", handleClickOutsidePopupZone);
    };
  });

  useEffect(() => {
    let tempShowPopups = [];
    reportObj.fields.forEach((field) => {
      tempShowPopups.push({
        show: false,
        type: field.dataType,
      });
    });
    setShowPopups(tempShowPopups);
  }, []);

  useEffect(() => {
    showPopups.length && checkIfQueryValueIsBlank();
  }, [showPopups]);

  useEffect(() => {
    let foundLength = 0;

    for (let i = 0; i < reportObj.fields.length; i++) {
      if (reportObj.fields[i].dataType === "Number") {
        foundLength++;
        if (foundLength === 2) {
          break;
        }
      }
    }

    foundLength === 2
      ? setTwoOrMoreNumberFields(true)
      : setTwoOrMoreNumberFields(false);
  }, [reportObj]);

  useEffect(() => {
    showPopups.length && checkIfQueryValueIsBlank();
  }, [showPopups, reportObj]);

  const handleClickOutsidePopupZone = () => {
    // Automatically closes all popups if a click is made outside of the popup zone.
    let tempArray = [...showPopups];
    tempArray.forEach((popup) => {
      popup.show = false;
    });
    tempArray.length && setShowPopups(tempArray);
  };

  const handleClickInsidePopupZone = (event) => {
    // Stops the mouse-click event from bubbling up. If a click is made inside of the popup zone, this prevents a doucment click from calling handleClickOutsidePopupZone.
    event.stopPropagation();
  };

  const getDateStringForReportObj = (fullYear, month, day) => {
    if (month.length === 1) {
      month = "0" + month;
    }

    if (day.length === 1) {
      day = "0" + day;
    }

    return `${fullYear}-${month}-${day}`;
  };

  const getQuerySelectedStatus = (index, operation) => {
    let queryLength = reportObj.fields[index].query.length;

    let duplicate = false;
    for (let i = 0; i < queryLength; ++i) {
      if (reportObj.fields[index].query[i].operation === operation) {
        duplicate = true;
        break;
      }
    }
    return duplicate;
  };

  const handleActivateFunctionsPage = () => {
    setActivateFunctionsPage(true);
  };

  const handleDeactivateFunctionsPage = () => {
    setActivateFunctionsPage(false);
  };

  const handleSetQueryOperator = (event, index, operation) => {
    handleClickInsidePopupZone(event);

    let queryValue = "";

    if (operation === "EQUALS TRUE") {
      queryValue = true;
    } else if (operation === "EQUALS FALSE") {
      queryValue = false;
    }

    if (!getQuerySelectedStatus(index, operation)) {
      let tempObj = { ...reportObj };
      let tempArray = tempObj.fields[index].query;
      let dataType = tempObj.fields[index].dataType;

      let fullYear, month, day;
      let startDate;

      if (dataType) {
        fullYear = today.getFullYear().toString();
        month = (today.getMonth() + 1).toString();
        day = today.getDate().toString();

        startDate = getDateStringForReportObj(fullYear, month, day);
      }

      if (dataType.toLowerCase() === "date") {
        queryValue = startDate;
      }

      tempArray.push({
        operation: operation,
        value: queryValue,
      });

      handleReportObj(tempObj);
    }
  };

  const handleRemoveQuery = (index, queryIndex) => {
    let tempObj = { ...reportObj };
    tempObj.fields[index].query.splice(queryIndex, 1);
    handleReportObj(tempObj);
  };

  const handleSelectDate = (date, index, queryIndex) => {
    let fullYear = date.getFullYear().toString();
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();

    const selectedDate = getDateStringForReportObj(fullYear, month, day);

    let tempObj = { ...reportObj };
    tempObj.fields[index].query[queryIndex].value = selectedDate;
    handleReportObj(tempObj);

    dataRef.current.expirationDateTime = date;
  };

  const handleSum = (index) => {
    let tempObj = { ...reportObj };
    if (reportObj.fields[index].sum === false) {
      tempObj.fields[index].sum = true;
    } else {
      tempObj.fields[index].sum = false;
    }
    handleReportObj(tempObj);
  };

  const handleAverage = (index) => {
    let tempObj = { ...reportObj };
    if (reportObj.fields[index].average === false) {
      tempObj.fields[index].average = true;
    } else {
      tempObj.fields[index].average = false;
    }
    handleReportObj(tempObj);
  };

  const updateComparisonString = (e, index, field, queryIndex) => {
    let tempObj = { ...reportObj };
    tempObj.fields[index].query[queryIndex].value =
      e.target.value === "" ? "" : e.target.value;
    handleReportObj(tempObj);
  };

  const updateComparisonNumber = (e, index, field, queryIndex) => {
    let tempObj = { ...reportObj };
    tempObj.fields[index].query[queryIndex].value =
      e.target.value === "" ? "" : Number(e.target.value);
    handleReportObj(tempObj);
  };

  const CardContent = (index, field) => {
    const hasIcon =
      field.dataType === "String" ||
      field.dataType === "Boolean" ||
      field.dataType === "Number" ||
      field.dataType === "Date";

    let fieldQueries = null;

    if (field.dataType === "String") {
      fieldQueries = field?.query.map((query, queryIndex) => {
        return (
          <div
            key={queryIndex}
            className={styles.Report2Calcs__CardFieldQueriesContainer}
          >
            <div className={styles.Report2Calcs__CardFieldQueriesInputGroup}>
              <p className={styles.Report2Calcs__CardFieldQueries}>
                {querySymbolLookup[query.operation]}
              </p>
              <input
                className={`${styles.Report2Calcs__input} ${styles["Report2Calcs__input--string"]}`}
                onChange={(e) =>
                  updateComparisonString(e, index, field, queryIndex)
                }
                value={reportObj.fields[index].query[queryIndex].value}
                type="text"
                placeholder={t("textPlaceholder")}
                autoFocus
              ></input>

              <div className={styles.Report2Calcs__trashIconContainer}>
                <TrashIcon
                  onClick={() => handleRemoveQuery(index, queryIndex)}
                />
              </div>
            </div>
          </div>
        );
      });
    } else if (field.dataType === "Boolean") {
      fieldQueries = field.query.map((query, queryIndex) => {
        return (
          <div
            key={queryIndex}
            className={styles.Report2Calcs__CardFieldQueriesContainer}
          >
            <div className={styles.Report2Calcs__CardFieldQueriesInputGroup}>
              <p
                className={`${styles["Report2Calcs__CardFieldQueries"]} ${styles["Report2Calcs__CardFieldQueries--boolean"]}`}
              >
                {querySymbolLookup[query.operation]}
              </p>

              <div className={styles.Report2Calcs__trashIconContainer}>
                <TrashIcon
                  onClick={() => handleRemoveQuery(index, queryIndex)}
                />
              </div>
            </div>
          </div>
        );
      });
    } else if (field.dataType === "Number") {
      fieldQueries = field.query.map((query, queryIndex) => {
        return (
          <div
            key={queryIndex}
            className={styles.Report2Calcs__CardFieldQueriesContainer}
          >
            <div className={styles.Report2Calcs__CardFieldQueriesInputGroup}>
              <p className={styles.Report2Calcs__CardFieldQueries}>
                {querySymbolLookup[query.operation]}
              </p>
              <input
                className={`${styles.Report2Calcs__input} ${styles["Report2Calcs__input--number"]}`}
                onChange={(e) =>
                  updateComparisonNumber(e, index, field, queryIndex)
                }
                value={
                  reportObj.fields[index].query[queryIndex].value >= 0
                    ? reportObj.fields[index].query[queryIndex].value
                    : 0
                }
                type="number"
                placeholder={t("numberPlaceholder")}
                autoFocus
              ></input>

              <div className={styles.Report2Calcs__trashIconContainer}>
                <TrashIcon
                  onClick={() => handleRemoveQuery(index, queryIndex)}
                />
              </div>
            </div>
          </div>
        );
      });
    } else if (field.dataType === "Date") {
      fieldQueries = field.query.map((query, queryIndex) => {
        let queryDate = reportObj.fields[index].query[queryIndex].value;
        let dateArray;
        let queryDateFormattedForDatePicker;
        if (queryDate) {
          dateArray = queryDate.split("-");
          queryDateFormattedForDatePicker = `${dateArray[1]}-${dateArray[2]}-${dateArray[0]}`;
        }
        return (
          <div
            key={queryIndex}
            className={styles.Report2Calcs__CardFieldQueriesContainer}
          >
            <div className={styles.Report2Calcs__CardFieldQueriesInputGroup}>
              <p className={styles.Report2Calcs__CardFieldQueries}>
                {querySymbolLookup[query.operation]}
              </p>

              <DatePickerComponent
                startDate={
                  !queryDate ? today : new Date(queryDateFormattedForDatePicker)
                }
                onChange={(date) => {
                  handleSelectDate(date, index, queryIndex);
                }}
                alternateFormat={true}
                country={user?.defaultSite?.shippingAddress?.country}
              />
              <span className={styles.Report2Calcs__clickDateMsg}>
                {t("changeDateLabel")}
              </span>

              <div className={styles.Report2Calcs__trashIconContainer}>
                <TrashIcon
                  onClick={() => handleRemoveQuery(index, queryIndex)}
                />
              </div>
            </div>
          </div>
        );
      });
    }

    return (
      <React.Fragment>
        <div
          className={
            hasIcon
              ? `${styles.Report2Calcs__CardContentTitleGroup} ${styles["Report2Calcs__CardContentTitleGroup--hasIcon"]}`
              : `${styles.Report2Calcs__CardContentTitleGroup} ${styles["Report2Calcs__CardContentTitleGroup--noIcon"]}`
          }
        >
          <p
            className={styles.Report2Calcs__cardFieldCollectionAndName}
            // >{`${field.collection}: ${field.name}`}</p>
          >
            {getPreferredFieldName(
              field.collection,
              field.name,
              true
            )}
          </p>
          {hasIcon && (
            <div className={styles.Report2Calcs__popupGroup}>
              <div className={styles.Report2Calcs__createButtonContainer}>
                <CreateButton
                  onClick={() => {
                    handleShowPopup(index);
                  }}
                  isEnabled={true}
                  labelName={""}
                  buttonNoFocus={true}
                />
              </div>
              <div
                className={
                  queryRequirementsMet[index]
                    ? `${styles.Report2Calcs__queryWarning} ${styles["Report2Calcs__queryWarning--none"]}`
                    : styles.Report2Calcs__queryWarning
                }
              ></div>
            </div>
          )}
        </div>
        {field.dataType === "Number" && (
          <div className={styles.Report2Calcs__toggleSwitchesContainer}>
            <div className={styles.Report2Calcs__toggleSwitchGroup}>
              <p className={styles.Report2Calcs__toggleSwitchLabel}>{t("sumLabel")}</p>
              <ToggleSwitch
                id={`toggle-${index.toString()}-0`}
                onChangeHandler={() => {
                  handleSum(index);
                }}
                checkState={reportObj.fields[index].sum}
              />
            </div>
            <div className={styles.Report2Calcs__toggleSwitchGroup}>
              <p className={styles.Report2Calcs__toggleSwitchLabel}>{t("averageLabel")}</p>
              <ToggleSwitch
                id={`toggle-${index.toString()}-1`}
                onChangeHandler={() => {
                  handleAverage(index);
                }}
                checkState={reportObj.fields[index].average}
              />
            </div>
          </div>
        )}
        {fieldQueries}
      </React.Fragment>
    );
  };

  const getOperationsCard = (index, field) => {
    let PopupContent = null;

    if (showPopups[index]?.show) {
      if (
        field.dataType === "String" ||
        field.dataType === "Number" ||
        field.dataType === "Date"
      ) {
        PopupContent = (
          <div className={styles.Report2Calcs__contentGroup}>
            <h2
              className={`${styles.Report2Calcs__contentRow} ${styles["Report2Calcs__contentRow--header"]}`}
            >
              {t(`${field.dataType.toLowerCase()}Label`)}
            </h2>

            {field.dataType === "String" && (
              <div
                className={styles.Report2Calcs__contentRow}
                onClick={(event) =>
                  handleSetQueryOperator(event, index, "LIKE s%")
                }
              >
                {`${t("startsWithLabel", {context: field.dataType.toLowerCase()})} (${querySymbolLookup["LIKE s%"]})`}
              </div>
            )}

            {field.dataType === "String" && (
              <div
                className={styles.Report2Calcs__contentRow}
                onClick={(event) =>
                  handleSetQueryOperator(event, index, "LIKE %s")
                }
              >
                {`${t("endsWithLabel", {context: field.dataType.toLowerCase()})} (${querySymbolLookup["LIKE %s"]})`}
              </div>
            )}

            {field.dataType === "String" && (
              <div
                className={styles.Report2Calcs__contentRow}
                onClick={(event) =>
                  handleSetQueryOperator(event, index, "INCLUDES")
                }
              >
                {t("includesLabel", {context: field.dataType.toLowerCase()})} (
                {<Subset className={styles.Report2Calcs__includesSVG} />})
              </div>
            )}

            {field.dataType === "String" && (
              <div
                className={styles.Report2Calcs__contentRow}
                onClick={(event) =>
                  handleSetQueryOperator(event, index, "EXCLUDES")
                }
              >
                {t("notIncludesLabel", {context: field.dataType.toLowerCase()})} (
                {<NotSubset className={styles.Report2Calcs__notIncludesSVG} />})
              </div>
            )}

            <div
              className={styles.Report2Calcs__contentRow}
              onClick={(event) =>
                handleSetQueryOperator(event, index, "EQUALS TO")
              }
            >
              {`${t("equalsLabel", {context: field.dataType.toLowerCase()})} (${querySymbolLookup["EQUALS TO"]})`}
            </div>

            <div
              className={styles.Report2Calcs__contentRow}
              onClick={(event) =>
                handleSetQueryOperator(event, index, "DOES NOT EQUAL TO")
              }
            >
              {`${t("notEqualsLabel", {context: field.dataType.toLowerCase()})} (${querySymbolLookup["DOES NOT EQUAL TO"]})`}
            </div>

            <div
              className={styles.Report2Calcs__contentRow}
              onClick={(event) =>
                handleSetQueryOperator(event, index, "GREATER THAN")
              }
            >
              {`${t("greaterLabel", {context: field.dataType.toLowerCase()})} (${querySymbolLookup["GREATER THAN"]})`}
            </div>

            <div
              className={styles.Report2Calcs__contentRow}
              onClick={(event) =>
                handleSetQueryOperator(
                  event,
                  index,
                  "GREATER THAN OR EQUALS TO"
                )
              }
            >
              {`${t("greaterOrEqualsLabel", {context: field.dataType.toLowerCase()})} (${querySymbolLookup["GREATER THAN OR EQUALS TO"]})`}
            </div>

            <div
              className={styles.Report2Calcs__contentRow}
              onClick={(event) =>
                handleSetQueryOperator(event, index, "LESS THAN")
              }
            >
              {`${t("lessLabel", {context: field.dataType.toLowerCase()})} (${querySymbolLookup["LESS THAN"]})`}
            </div>

            <div
              className={styles.Report2Calcs__contentRow}
              onClick={(event) =>
                handleSetQueryOperator(event, index, "LESS THAN OR EQUALS TO")
              }
            >
              {`${t("lessOrEqualsLabel", {context: field.dataType.toLowerCase()})} (${querySymbolLookup["LESS THAN OR EQUALS TO"]})`}
            </div>
          </div>
        );
      } else if (field.dataType === "Boolean") {
        PopupContent = (
          <div className={styles.Report2Calcs__contentGroup}>
            <h2
              className={`${styles.Report2Calcs__contentRow} ${styles["Report2Calcs__contentRow--header"]}`}
            >
              {t("booleanLabel")}
            </h2>
            <div
              className={styles.Report2Calcs__contentRow}
              onClick={(event) =>
                handleSetQueryOperator(event, index, "EQUALS TRUE")
              }
            >
              {`${t("trueLabel")} (${querySymbolLookup["EQUALS TRUE"]})`}
            </div>
            <div
              className={styles.Report2Calcs__contentRow}
              onClick={(event) =>
                handleSetQueryOperator(event, index, "EQUALS FALSE")
              }
            >
              {`${t("falseLabel")} (${querySymbolLookup["EQUALS FALSE"]})`}
            </div>
          </div>
        );
      }
    }

    return (
      <div
        className={
          showPopups[index]?.show
            ? styles.Report2Calcs__operationsCard
            : `${styles["Report2Calcs__operationsCard--hide"]}`
        }
      >
        {PopupContent}
      </div>
    );
  };

  const handleShowPopup = (index) => {
    let tempArray = [...showPopups];
    if (tempArray[index].show !== true) {
      tempArray.forEach((item, forEachIndex) => {
        if (forEachIndex !== index) item.show = false;
      });
      tempArray[index].show = true;
    } else {
      tempArray[index].show = false;
    }
    setShowPopups(tempArray);
  };

  const Cards = reportObj?.fields.map((field, index) => {
    return (
      <div
        key={index}
        className={styles.Report2Calcs__cardsGroup}
        onClick={(event) => handleClickInsidePopupZone(event)}
      >
        {getOperationsCard(index, field)}
        <div className={styles.Report2Calcs__card}>
          {CardContent(index, field)}
        </div>
      </div>
    );
  });

  const checkIfQueryValueIsBlank = () => {
    let blankFound = false;
    const dataTypes = ["String", "Number", "Boolean", "Date"];
    let tempQueryRequirementsMet = [...queryRequirementsMet];

    for (let i = 0; i < reportObj.fields.length; i++) {
      tempQueryRequirementsMet[i] = true;
      if (
        dataTypes.includes(reportObj.fields[i].dataType) &&
        reportObj.fields[i].dataType !== "Number" &&
        reportObj.fields[i].dataType !== "String" &&
        reportObj.fields[i].dataType !== "Date" &&
        reportObj.fields[i].query.length === 0
      ) {
        blankFound = true;
        tempQueryRequirementsMet[i] = false;
      }
      // if (
      //   dataTypes.includes(reportObj.fields[i].dataType) &&
      //   reportObj.fields[i].dataType === "Number" &&
      //   !(reportObj.fields[i].sum || reportObj.fields[i].average) &&
      //   reportObj.fields[i].query.length !== 0
      // ) {
      //   blankFound = true;
      //   tempQueryRequirementsMet[i] = false;
      // }
      if (reportObj.fields[i].dataType !== "Boolean") {
        for (let j = 0; j < reportObj.fields[i].query.length; j++) {
          if (!reportObj.fields[i].query[j].value) {
            blankFound = true;
            tempQueryRequirementsMet[i] = false;
          }
        }
      }
    }
    if (blankFound) {
      setQueryValueBlank(true);
    } else {
      setQueryValueBlank(false);
    }
    setQueryRequirementsMet(tempQueryRequirementsMet);
  };

  if (editMode && isPreview && !activateFunctionsPage) {
    return ReportViewerForPreview;
  }

  if (!activateFunctionsPage) {
    return (
      <div className={styles.Report2Calcs__pageContainer}>
        <div className={styles.Report2Calcs__headerContainer}>
          <h1 className={styles.Report2Calcs__header}>{t("title")}</h1>
          {editMode && (
            <div className={styles.Report2Calcs__previewButtonContainer}>
              {previewButton}
            </div>
          )}
        </div>
        <div className={styles.Report2Calcs__mainContainer}>{Cards}</div>
        <div className={styles.Report2Calcs__buttonGroup}>
          <Button
            onClick={handleDeactivateCalcsPage}
            labelName={t("prevButtonLabel")}
            isPrimary={false}
            isDisabled={false}
            minWidth={null}
          />
          <Spacer space={20} unit={"px"} />
          {!editMode && (
            <Button
              onClick={
                twoOrMoreNumberFields || reportObj?.functionFields?.length > 0
                  ? handleActivateFunctionsPage
                  : onSaveReport
              }
              labelName={
                twoOrMoreNumberFields || reportObj?.functionFields?.length > 0
                  ? t("nextButtonLabel")
                  : t("saveCloseButtonLabel")
              }
              isPrimary={true}
              isDisabled={
                !queryValueBlank &&
                reportObj.title &&
                reportObj.collections.length > 0 &&
                reportObj.fields.length > 0
                  ? false
                  : true
              }
              minWidth={mdScreen ? null : 213}
            />
          )}

          {editMode &&
            (twoOrMoreNumberFields ||
              reportObj?.functionFields?.length > 0) && (
              <Button
                onClick={handleActivateFunctionsPage}
                labelName={t("nextButtonLabel")}
                isPrimary={true}
                isDisabled={
                  !queryValueBlank &&
                  reportObj.title &&
                  reportObj.collections.length > 0 &&
                  reportObj.fields.length > 0
                    ? false
                    : true
                }
                minWidth={mdScreen ? null : 213}
              />
            )}

          {(editMode || !booleanDateIntegerOrStringFound) &&
            reportObj.collections.length > 0 &&
            reportObj.fields.length > 0 && (
              <Fragment>
                <Spacer space={20} unit={"px"} />
                <Button
                  onClick={onSaveReport}
                  labelName={t("saveCloseButtonLabel")}
                  isPrimary={true}
                  isDisabled={
                    !queryValueBlank &&
                    reportObj.title &&
                    reportObj.collections.length > 0 &&
                    reportObj.fields.length > 0
                      ? false
                      : true
                  }
                  minWidth={mdScreen ? null : 213}
                />
              </Fragment>
            )}
        </div>
      </div>
    );
  } else {
    return (
      <Report2Functions
        reportObj={reportObj}
        handleReportObj={handleReportObj}
        handleDeactivateFunctionsPage={handleDeactivateFunctionsPage}
        booleanDateIntegerOrStringFound={booleanDateIntegerOrStringFound}
        onSaveReport={onSaveReport}
        editMode={editMode}
        previewButton={previewButton}
        ReportViewerForPreview={ReportViewerForPreview}
        isPreview={isPreview}
        collectionFieldDictionary={collectionFieldDictionary}
      />
    );
  }
};

export default Report2Calcs;
