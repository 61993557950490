import { Radio } from "antd";
import "./AntRadioGroup.css";
import { useTranslation } from "react-i18next";

/* 
  Example of use
    <AntRadioGroup
      values={[1, 2]}
      labels={["Variable Widths", "Equal Widths"]}
      radioGroupClass="ant-radioGroup-000"
      onChange={onChangeRadio}
      value={radioValue}
    />

    Notes
    (1) "values" must be an array and should be equal in length to "labels".
    (2) "labels" must be an array and should be equal in length to "values".
*/

const AntRadioGroup = ({
  values,
  labels,
  radioGroupClass = "ant-radioGroup-000",
  onChange,
  value,
}) => {
  const { t } = useTranslation("antd");

  const Radios = values.map((value, index) => {
    return (
      <Radio key={value} value={value}>
        {index + 1 <= labels.length ? labels[index] : `${t("optionLabel")} ${index + 1}`}
      </Radio>
    );
  });
  return (
    <div className={radioGroupClass}>
      <Radio.Group onChange={onChange} value={value}>
        {Radios}
      </Radio.Group>
    </div>
  );
};

export default AntRadioGroup;
