import React from "react";
import {
  DateRangePicker,
  defaultStaticRanges,
  defaultInputRanges,
} from "react-date-range";
import { useTranslation } from "react-i18next";
import { enUS, frCA, es } from "date-fns/locale";

const DateRangePickerComponent = (props) => {
  const { country, ...defaultProps } = props;

  const { t, i18n } = useTranslation("dateRange");

  const locale = {
    "fr": frCA,
    "en": enUS,
    "es": es
  }[i18n.language.substring(0, 2)] || enUS

  const dateFormat = {
    "Canada": "dd/MM/yyyy",
    "United States": "MM/dd/yyyy"
  }[country] || "MM/dd/yyyy"

  const staticRanges = defaultStaticRanges.map((range) => ({
    ...range,
    label: t(range.label),
  }));

  const inputRanges = defaultInputRanges.map((range) => ({
    ...range,
    label: t(range.label),
  }));

  const localeProps = {
    locale: locale,
    staticRanges: staticRanges,
    inputRanges: inputRanges,
    dateDisplayFormat: dateFormat
  }

  return (
    <DateRangePicker {...localeProps} {...defaultProps} />
  );
};

export default DateRangePickerComponent;
