import React, { Fragment } from "react";
import { ReactComponent as PrinterIcon } from "../../../../assets/print.svg";
import styles from "./Table.module.css";
import { useTranslation } from "react-i18next";

const TableMain = ({
  showBatch,
  showHeadings,
  batch,
  batchIndex,
  onPrintBatch,
  patientName,
  patientId,
  facility,
  room,
  packagedOnFormatted,
}) => {
  const { t } = useTranslation("reportViewer")

  return (
    <Fragment>
      {!showBatch && showHeadings && (
        <div className={styles.patientDivider}> </div>
      )}
      {showHeadings && (
        <ul className={`${styles.mainData}`}>
          {showBatch && (
            <li>
              <ul className={`${styles.batchRow} ${styles.fontStyles}`}>
                <li className={styles.largeFont}>
                  <span className={styles.bold500}>{t("batchLabel")}:</span>
                  {` ${batch}`}
                </li>

                <li className={`${styles.packagedOn} ${styles.largeFont}`}>
                  <span className={styles.bold500}>{t("packagedOnLabel")}:</span>
                  {` ${packagedOnFormatted}`}
                </li>

                <li className={styles.printerIconContainer}>
                  <PrinterIcon onClick={() => onPrintBatch(batchIndex)} />
                </li>
              </ul>
            </li>
          )}

          <li>
            <ul className={`${styles.mainDataLiUl} ${styles.fontStyles}`}>
              <li className={styles.patientName}>
                <span className={styles.bold500}>{t("patientNameLabel")}:</span>
                {` ${patientName}`}
              </li>
              <li className={styles.patientId}>
                <span className={styles.bold500}>{t("patientIdLabel")}:</span>
                {` ${patientId}`}
              </li>
              <li className={styles.facility}>
                <span className={styles.bold500}>{t("facilityLabel")}:</span>
                {` ${facility}`}
              </li>
              <li className={styles.room}>
                <span className={styles.bold500}>{t("roomLabel")}:</span>
                {` ${room}`}
              </li>
            </ul>
          </li>
        </ul>
      )}
    </Fragment>
  );
};

export default TableMain;
