export default function validate(values) {
    let errors = {};

    if(!values.firstName) {
        errors.firstName = 'missingFirstName'
    }

    if(!values.lastName) {
        errors.lastName = 'missingLastName'
    }

    return errors;
}
