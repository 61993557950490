import { convertCompilerOptionsFromJson } from "typescript";

const alphanumericData = new RegExp(/^[a-z0-9]+$/i);

// No leading or ending white space; no consecutive white spaces
const alphanumericDataWithSingleSpaces = new RegExp(
  /^[a-z0-9]+([-_\s]{1}[a-z0-9]+)*$/i
);

// 0, 1, 2, ...
const positiveWholeNumbers = new RegExp(/^0*[1-9]\d*$/);

// 0.5, 1, 1.5, 2, 2.5, etc.
const positiveWholeNumbersAndHalves = new RegExp(
  /^[+]?([0-9]+(?:[\.][5]*)?|\.[0-9]+)$/
);

// Any number 0 or greater
const positiveDecimalsIncludingZero = new RegExp(
  /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/
);

// YYYY-MM-DD
const date_YYYYMMDD = new RegExp(
  /[1-9][0-9][0-9]{2}-([0][1-9]|[1][0-2])-([1-2][0-9]|[0][1-9]|[3][0-1])/
);

// 14:22
const time_HHDD = new RegExp(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/);

export const dataValidityChecksTruPak = (key, value, validPackageIds) => {
  switch (key) {
    case "lastName":
      const check =
        value === "" ? false : alphanumericDataWithSingleSpaces.test(value);
      return check;
    case "firstName":
      return value === ""
        ? false
        : alphanumericDataWithSingleSpaces.test(value);
    case "packageId":
      if (
        validPackageIds &&
        validPackageIds.constructor === Array &&
        validPackageIds.length
      ) {
        return value === "" ? false : validPackageIds.includes(value);
      }
      return value === "" ? false : alphanumericData.test(value);
    case "rxNumber":
      return value === "" ? false : alphanumericData.test(value);
    case "qtyNeeded":
      return value === "" ||
        value === "0" ||
        value === "0." ||
        (value.length > 1 && value.substring(0) === 0)
        ? false
        : positiveDecimalsIncludingZero.test(Number(value));
    case "adminDate":
      return value === "" ? false : date_YYYYMMDD.test(value);
    case "adminTime":
      return value === "" ? false : time_HHDD.test(value);
    case "facility":
      return value === ""
        ? false
        : alphanumericDataWithSingleSpaces.test(value);
    case "instructions":
      return value === "" || alphanumericDataWithSingleSpaces.test(value);
    case "physician":
      return value === "" || alphanumericDataWithSingleSpaces.test(value);
    case "pharmacist":
      return value === "" || alphanumericDataWithSingleSpaces.test(value);
    default:
      return false;
  }
};
