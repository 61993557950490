import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as MagnifyingGlass } from "../../../../../assets/magnifying-glass.svg";
import DatePickerComponent from "../../../../../components/DatePickerComponent/DatePickerComponent";
import Button from "../../../../../components/Button";
import ProductController from "../../../../../controllers/ProductController";
import Input from "./Input";
import styles from "./ScanInput.module.css";
import { useTranslation } from "react-i18next";

const ScanProduct = ({
  setSecondProductScan,
  currentStatusObj,
  handleCurrentStatusObj,
  stockedItemObjs,
  handleStockedItemObjs,
  setPage,
  productBarcode,
  setProductBarcode,
  barcodesToBeAssociated,
  setBarcodesToBeAssociated,
  scannedBarcodes,
  setScannedBarcodes,
  setLotNumbers,
  setExpirations,
  user,
  inputFieldQty,
  setInputFieldQty,
  productRescanned,
  setProductRescanned,
  setProductRescannedLot,
  setProductRescannedExpDate,
  scannedProducts,
  setScannedProducts,
  enforceInputFocus,
  setEnforceInputFocus,
  scannedItemIndex,
  setScannedItemIndex,
  scannedStockedItemsQuantities,
  setScannedStockedItemsQuantities,
  setProductAssociationNotFound,
  stockLocationScanned,
  productAssociationNotFound,
  productNotFound,
  setProductNotFound,
  onSetErrorHandler,
  errorMsg = { errorMsg },
}) => {
  const { t } = useTranslation("stock")

  const [value, setValue] = useState("");
  const placeHolder = t("scanProductPlaceholder");
  const placeHolderLot = t("lotNumberPlaceholder");
  const _productController = useRef(null);
  const inputElement = useRef(null);
  const [scannedBarcode, setScannedBarcode] = useState("");
  const [productRescannedEntryComplete, setProductRescannedEntryComplete] =
    useState(false);
  const [
    productRescannedLotEntryComplete,
    setProductRescannedLotEntryComplete,
  ] = useState(false);

  const dataRef = useRef({});
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [expirationDateTime, setExpirationDateTime] = useState(tomorrow);
  const [tomorrowDateTime, setTomorrowDateTime] = useState("");
  const [disableContinueButton, setDisableContinueButton] = useState(false);

  const EPSILON = 10000; // milliseconds constant for time comparison

  let productAssociationFound = false;

  let scannedBarcodeInstant = "";

  useEffect(() => {
    setPage(6);
    _productController.current = new ProductController(user);
    if (inputElement.current) {
      inputElement.current.focus();
    }
    setValue("");
    setProductBarcode("");
    setProductRescanned("");
    handleCurrentStatusObj({
      barcode: "",
      product: "",
      lotNumber: "",
      expiration: "",
    });
  }, []);

  useEffect(() => {
    setSecondProductScan(true);
  }, []);

  useEffect(() => {
    if (!productRescanned) {
      let tempCurrentStatusObj = { ...currentStatusObj };
      tempCurrentStatusObj.product = productRescanned;
      handleCurrentStatusObj(tempCurrentStatusObj);
    }
  }, [productBarcode]);

  useEffect(() => {
    let tempCurrentStatusObj = { ...currentStatusObj };
    if (
      tempCurrentStatusObj.barcode &&
      tempCurrentStatusObj.product &&
      tempCurrentStatusObj.expiration &&
      tempCurrentStatusObj.lotNumber
    ) {
      let targetIndex = -1;
      let tempStockedItemObjs = [...stockedItemObjs];
      let i = 0;

      for (i; i < stockedItemObjs.length; i++) {
        if (
          tempStockedItemObjs[i].product.packageId ===
            tempCurrentStatusObj.product.packageId &&
          tempStockedItemObjs[i].expiration ===
            tempCurrentStatusObj.expiration &&
          tempStockedItemObjs[i].lotNumber === tempCurrentStatusObj.lotNumber
        ) {
          targetIndex = i;
          break;
        }
      }

      if (targetIndex === -1) {
        tempCurrentStatusObj.totalQty =
          tempCurrentStatusObj.product.packageQuantity;
        tempStockedItemObjs.push(tempCurrentStatusObj);
      } else {
        tempStockedItemObjs[i].totalQty =
          +tempStockedItemObjs[i].totalQty +
          currentStatusObj.product.packageQuantity;
      }

      handleStockedItemObjs(tempStockedItemObjs);
      handleCurrentStatusObj({
        barcode: "",
        expiration: "",
        lotNumber: "",
        product: "",
      });
    }
  }, [currentStatusObj, stockedItemObjs]);

  useEffect(() => {
    if (scannedItemIndex !== 0 && inputFieldQty !== NaN && inputFieldQty > 0) {
      setValue("");
      setInputFieldQty(parseInt(productRescanned.packageQuantity));

      productRescanned.aliasBarcode = scannedBarcode;
      productRescanned.barcode = productBarcode;

      setScannedProducts((prevState) => {
        return [...prevState, productRescanned];
      });
      setScannedStockedItemsQuantities((prevState) => {
        return [...prevState, productRescanned.packageQuantity];
      });
    }
  }, [scannedItemIndex]);

  useEffect(() => {
    if (productRescanned) {
      setInputFieldQty(parseInt(productRescanned.packageQuantity));
      let tempCurrentStatusObj = { ...currentStatusObj };
      tempCurrentStatusObj.product = productRescanned;
      handleCurrentStatusObj(tempCurrentStatusObj);
    }
  }, [productRescanned]);

  useEffect(() => {
    if (scannedBarcodes.length === 0) {
      setScannedBarcode(productBarcode);
      scannedBarcodeInstant = productBarcode;
      setScannedBarcodes((prevState) => {
        return [...prevState, productBarcode];
      });
      _productController.current.getProductByBarcode(productBarcode, callback);
    }
  }, [scannedBarcodes.length]);

  useEffect(() => {
    if (expirationDateTime && !tomorrowDateTime) {
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      setTomorrowDateTime(tomorrow);
    }
  }, [expirationDateTime]);

  useEffect(() => {
    if (!tomorrowDateTime) {
      setTomorrowDateTime(expirationDateTime);
    }
  }, [expirationDateTime]);

  useEffect(() => {
    try {
      // This is float comparison, thus the need for the EPSILON constant.
      if (
        expirationDateTime.getTime() + EPSILON >=
        tomorrowDateTime.getTime()
      ) {
        setDisableContinueButton(false);
      } else {
        setDisableContinueButton(true);
      }
    } catch {
      // do nothing
    }
  }, [expirationDateTime, tomorrowDateTime]);

  // getProductByBarCode
  const callback = (error, product) => {
    let repeat = false;

    if (product) {
      setProductRescanned(product);

      for (let i = 0; i < scannedProducts.length; i++) {
        if (
          (scannedBarcodeInstant !== product.packageId &&
            scannedProducts[i].aliasBarcode &&
            scannedProducts[i].aliasBarcode === scannedBarcodeInstant) ||
          (scannedBarcodeInstant === product.packageId &&
            scannedProducts[i].packageId === product.packageId &&
            (!scannedProducts[i].aliasBarcode ||
              scannedProducts[i].aliasBarcode === product.packageId))
        ) {
          repeat = true;

          let tempArray = [...scannedStockedItemsQuantities];
          tempArray[i] += scannedProducts[i].packageQuantity;
          setScannedStockedItemsQuantities(tempArray);
          setScannedItemIndex(i);

          break;
        }
      }
      if (!repeat) {
        if (!product.packageQuantity) {
          onSetErrorHandler(
            t("noPackageQuantityError")
          );
        } else {
          let controlledProduct = true;

          let controlLevelExists = true;

          try {
            if (product.controlLevel.type) {
            }
          } catch {
            controlLevelExists = false;
          }

          // Check if the scanned product is not controlled
          if (!controlLevelExists || product.controlLevel.type === 0) {
            controlledProduct = false;
          }

          if (!controlledProduct || stockLocationScanned.isControlled) {
            productAssociationFound =
              barcodesToBeAssociated.includes(scannedBarcode) ||
              productAssociationCheck(scannedBarcode);

            if (!productAssociationFound) {
              setBarcodesToBeAssociated((prevState) => {
                return [...prevState, scannedBarcode];
              });
            }

            setScannedItemIndex((prevState) => {
              return prevState + 1;
            });
            setProductAssociationNotFound(false);
          } else {
            onSetErrorHandler(
              t("associationNotAllowedError", {locationName: stockLocationScanned.name})
            );

            setInputFieldQty(0);
            setProductRescanned("");

            let tempCurrentStatusObj = currentStatusObj;
            tempCurrentStatusObj.product = "";
            handleCurrentStatusObj(tempCurrentStatusObj);
          }
        }
      }
    } else {
      setProductNotFound(true);
      // console.log("error:", error);
    }
    setValue("");
  };

  const changeScanInputHandler = (e) => {
    setValue(e.target.value);
  };

  const productAssociationCheck = (barcode) => {
    let association = false;

    for (let i = 0; i < stockLocationScanned.productAssociations.length; i++) {
      if (
        stockLocationScanned.productAssociations[i].product.packageId ===
        barcode
      ) {
        association = true;
        break;
      }
    }
    return association;
  };

  const onBlurHandler = () => {
    inputElement.current.focus();
  };

  const onKeyUpHandler = (e) => {
    if (e.which === 13 && e.target.value) {
      setScannedBarcode(e.target.value);
      setProductBarcode(e.target.value);
      scannedBarcodeInstant = e.target.value;
      setValue("");
      setProductRescannedEntryComplete(true);

      let tempCurrentStatusObj = { ...currentStatusObj };
      tempCurrentStatusObj.product = productRescanned;
      tempCurrentStatusObj.barcode = e.target.value;
      handleCurrentStatusObj(tempCurrentStatusObj);

      const response = _productController.current.getProductByBarcode(
        e.target.value,
        callback
      );
      if (response) {
        setScannedBarcodes((prevState) => {
          return [...prevState, e.target.value];
        });
        setProductBarcode(e.target.value);

        setSecondProductScan(false);
      } else {
        onSetErrorHandler(t("productRetrievalError"));
      }
    }
  };

  const onKeyUpHandlerLot = (e) => {
    if (e.which === 13 && e.target.value) {
      setProductRescannedLot(e.target.value);
      setLotNumbers((prevState) => {
        return [...prevState, e.target.value];
      });
      setValue("");
      setProductRescannedLotEntryComplete(true);

      let tempCurrentStatusObj = { ...currentStatusObj };
      tempCurrentStatusObj.lotNumber = e.target.value;
      handleCurrentStatusObj(tempCurrentStatusObj);
    }
  };

  const onKeyUpHandlerExpDate = () => {
    if (expirationDateTime.getTime() + EPSILON >= tomorrowDateTime.getTime()) {
      let fullYear = expirationDateTime.getFullYear().toString();
      let month = parseInt(expirationDateTime.getMonth());
      let day = expirationDateTime.getDate().toString();

      month = month + 1;

      if (month.length === 1) {
        month = month + 1;
      }

      month = month.toString();

      if (month.length === 1) {
        month = "0" + month;
      }

      if (day.length === 1) {
        day = "0" + day;
      }

      setExpirations((prevState) => {
        return [...prevState, `${fullYear}-${month}-${day}`];
      });

      setProductRescannedExpDate(`${fullYear}-${month}-${day}`);

      setProductRescannedEntryComplete(false);
      setProductRescannedLotEntryComplete(false);
      setValue("");

      scannedBarcodeInstant = scannedBarcode;

      let tempCurrentStatusObj = { ...currentStatusObj };
      tempCurrentStatusObj.expiration = `${fullYear}-${month}-${day}`;
      handleCurrentStatusObj(tempCurrentStatusObj);
    }
  };

  if (
    !(stockLocationScanned && productRescanned && productAssociationNotFound)
  ) {
    if (!productNotFound && !productRescannedEntryComplete) {
      return (
        <section className={styles.scanInput__container}>
          <MagnifyingGlass className={styles.scanInput__magnifyingGlassIcon} />
          <Input
            className={styles.scanInput__field}
            onChangeHandler={changeScanInputHandler}
            onKeyUpHandler={onKeyUpHandler}
            onBlurHandler={onBlurHandler}
            value={value}
            placeHolder={placeHolder}
            enforceInputFocus={enforceInputFocus}
            setEnforceInputFocus={setEnforceInputFocus}
            errorMsg={errorMsg}
          />
        </section>
      );
    } else if (!productRescannedLotEntryComplete) {
      return (
        <section className={styles.scanInput__container}>
          <MagnifyingGlass className={styles.scanInput__magnifyingGlassIcon} />
          <Input
            className={styles.scanInput__field}
            onChangeHandler={changeScanInputHandler}
            onKeyUpHandler={onKeyUpHandlerLot}
            onBlurHandler={onBlurHandler}
            value={value}
            placeHolder={placeHolderLot}
            enforceInputFocus={enforceInputFocus}
            setEnforceInputFocus={setEnforceInputFocus}
            errorMsg={errorMsg}
          />
        </section>
      );
    } else {
      return (
        <section
          className={`${styles.scanInput__container} ${styles["scanInput__container--white"]}`}
        >
          <DatePickerComponent
            label={t("expirationDateLabel")}
            startDate={expirationDateTime}
            onChange={(date) => {
              setExpirationDateTime(date);
              dataRef.current.expirationDateTime = date;
            }}
          />
          <div className={styles.scanInput__datePickerButton}>
            <Button
              labelName={t("continueButtonLabel")}
              isPrimary={true}
              isDisabled={disableContinueButton}
              onClick={onKeyUpHandlerExpDate}
              minWidth={150}
            />
          </div>
        </section>
      );
    }
  } else {
    return null;
  }
};

export default ScanProduct;
