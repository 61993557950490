import React, { useState, useEffect, useContext, Fragment } from "react";
import Button from "../../../components/Button";
import Spacer from "../../../components/Spacer";
import SFDropDownList from "../../../components/SFDropDownList/SFDropDownList";
import Report2Calcs from "../Report2Calcs/Report2Calcs";
import { useMediaQuery } from "react-responsive";
import { ScreenContext } from "../../../contexts/ScreenContext";
import styles from "./Report2SelectGroupField.module.css";
import { useTranslation } from "react-i18next";
import { getPreferredFieldName } from "../lookups/getPreferredFieldName";

const Report2SelectGroupField = ({
  reportObj,
  handleReportObj,
  user,
  handleDeactivateSelectGroupPage,
  booleanDateIntegerOrStringFound,
  onSaveReport,
  editMode,
  previewButton,
  ReportViewerForPreview,
  isPreview,
  collectionFieldDictionary,
}) => {
  const { t } = useTranslation("reportFields")

  const [activateCalcsPage, setActivateCalcsPage] = useState(false);

  const { mediumScreen } = useContext(ScreenContext);
  const mdScreen = useMediaQuery(mediumScreen);

  let fieldList = reportObj.fields.map((field, index) => {
    return { 
      Item: `${field.collection}:${field.name}`, 
      Value: index + 2,
      displayName: getPreferredFieldName(field.collection, field.name, true)
    };
  });

  fieldList.unshift({ 
    Item: "No grouping", 
    Value: 1,
    displayName: "Aucun groupement"
  });

  useEffect(() => {
    const foundIndex = reportObj.fields.findIndex((field) => {
      return `${field.collection}:${field.name}` === reportObj.groupedField;
    });
    if (foundIndex === -1) {
      handleReportObj({ ...reportObj, groupedField: "No grouping" });
    }
  }, []);

  const goBackHandler = () => {
    handleDeactivateSelectGroupPage();
  };

  const handleActivateCalcsPage = () => {
    setActivateCalcsPage(true);
  };

  const handleDeactivateCalcsPage = () => {
    setActivateCalcsPage(false);
  };

  const handleSelectedFieldGrouping = (field, value) => {
    handleReportObj({ ...reportObj, groupedField: field });
  };

  if (editMode && isPreview && !activateCalcsPage) {
    return ReportViewerForPreview;
  }

  if (!activateCalcsPage) {
    return (
      <div className={styles.Report2SelectGroupField__pageContainer}>
        <div className={styles.Report2SelectGroupField__collectionsContainer}>
          <div className={styles.Report2SelectGroupField__headerContainer}>
            <h1 className={styles.Report2SelectGroupField__header}>
              {t("groupTitle")}
            </h1>
            {editMode && (
              <div
                className={
                  styles.Report2SelectGroupField__previewButtonContainer
                }
              >
                {previewButton}
              </div>
            )}
          </div>

          <div className={styles.Report2SelectGroupField__dropdownContainer}>
            <SFDropDownList
              id={"Report2SelectGroupField"}
              label={t("fieldGroupLabel")}
              defaultValue={reportObj.groupedField}
              placeHolder={t("fieldGroupPlaceholder")}
              dropDownList={fieldList}
              onSelection={handleSelectedFieldGrouping}              
              display={"displayName"}
            />
          </div>

          <div className={styles.Report2SelectGroupField__buttonGroup}>
            <Button
              onClick={goBackHandler}
              labelName={t("prevButtonLabel")}
              isPrimary={false}
              isDisabled={false}
              minWidth={null}
            />
            <Spacer space={20} unit={"px"} />
            {booleanDateIntegerOrStringFound && (
              <Button
                onClick={handleActivateCalcsPage}
                labelName={t("nextButtonLabel")}
                isPrimary={true}
                isDisabled={reportObj.collections.length === 0 ? true : false}
                minWidth={mdScreen ? null : 213}
              />
            )}

            {(editMode || !booleanDateIntegerOrStringFound) && (
              <Fragment>
                <Spacer space={20} unit={"px"} />
                <Button
                  onClick={onSaveReport}
                  labelName={t("saveCloseButtonLabel")}
                  isPrimary={true}
                  isDisabled={
                    reportObj.title &&
                    reportObj.collections.length > 0 &&
                    reportObj.fields.length > 0
                      ? false
                      : true
                  }
                  minWidth={mdScreen ? null : 213}
                />
              </Fragment>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <Report2Calcs
        reportObj={reportObj}
        handleReportObj={handleReportObj}
        user={user}
        handleDeactivateCalcsPage={handleDeactivateCalcsPage}
        booleanDateIntegerOrStringFound={booleanDateIntegerOrStringFound}
        onSaveReport={onSaveReport}
        editMode={editMode}
        previewButton={previewButton}
        ReportViewerForPreview={ReportViewerForPreview}
        isPreview={isPreview}
        collectionFieldDictionary={collectionFieldDictionary}
      />
    );
  }
};

export default Report2SelectGroupField;
