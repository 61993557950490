import React, { useState, useEffect, useContext } from "react";
import Popup from "reactjs-popup";
import FullBackdropWithModal from "../FullBackdropWithModal/FullBackdropWithModal";
import cheveronDownIcon from "../../assets/cheveron-down.svg";
import { ReactComponent as CloseModalIcon } from "../../assets/x.svg";
import { ReactComponent as BackArrowMobile } from "../../assets/back-icon-mobile.svg";
import Button from "../../components/Button";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./SelectDevice.module.css";
import { useTranslation } from "react-i18next";

const SelectDevice = ({
  devices,
  onHandleSetSelectedDevice,
  onHandleCloseSelectDeviceModal,
  onHandleGoBack,
  buttonTitle,
  onHandleProcessing,
}) => {
  const { t } = useTranslation("selectDevice");

  const [selectedDevice, setSelectedDevice] = useState("");

  const setRef = (element) => {
    popup = element;
  };

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);
  const labelName = t("selectDeviceLabel");
  const placeholder = t("selectDevicePlaceholder");

  let popup = null;

  let View;

  if (smScreen) {
    // Mobile View
    View = (
      <div className={styles.selectDevice__container}>
        <section className={styles.selectDevice__headingGroup}>
          <div
            className={styles.selectDevice__backArrow}
            onClick={() => {
              setSelectedDevice("");
              onHandleGoBack();
            }}
          >
            <BackArrowMobile className={styles.selectDevice__backArrow__icon} />
            <p className={styles.selectDevice__goBackTitle}>{t("backButtonLabel")}</p>
          </div>
          <p className={styles.selectDevice__title}>{t("selectDeviceLabel")}</p>
        </section>

        <div className={(styles.controlGroup, styles.dropdown)}>
          <div className={styles.controlButtonInputGroup}>
            <input
              className={styles.controlInput}
              type={"text"}
              placeholder={placeholder}
              disabled={false}
              value={selectedDevice.name || ""}
              readOnly={true}
              onClick={(e) => e.preventDefault()}
            />
            <Popup
              trigger={
                <button className={styles.controlButton}>
                  <img src={cheveronDownIcon} alt={"dropdown button"} />
                </button>
              }
              on={"click"}
              ref={setRef}
              position="bottom right"
              closeOnDocumentClick={true}
              arrow={true}
            >
              <div className={styles.controlButtonPopup}>
                {devices.length > 0 &&
                  devices.map((device, index) => {
                    return (
                      <p
                        className={styles.controlButtonPopupListItem}
                        key={index}
                        onClick={() => {
                          onHandleSetSelectedDevice(device);
                          setSelectedDevice(device);
                          popup.close();
                        }}
                      >
                        {devices[index].name}
                      </p>
                    );
                  })}
              </div>
            </Popup>
          </div>
          <div className={styles.controlBorder} />
        </div>

        <div className={styles.selectDevice__buttonContainer}>
          <Button
            isDisabled={selectedDevice ? false : true}
            isPrimary={true}
            onClick={onHandleProcessing}
            labelName={buttonTitle || t("processButtonLabel")}
          />
        </div>
      </div>
    );
  } else {
    // Large View
    View = (
      <FullBackdropWithModal modalClassName={styles.selectDevice__modal}>
        <div className={styles.selectDevice__container}>
          <CloseModalIcon
            className={styles.selectDevice__closeModalIcon}
            onClick={onHandleCloseSelectDeviceModal}
          />

          <div className={(styles.controlGroup, styles.dropdown)}>
            <label className={styles.selectDevice__heading}>{labelName}</label>
            <div className={styles.controlButtonInputGroup}>
              <input
                className={styles.controlInput}
                type={"text"}
                placeholder={placeholder}
                disabled={false}
                value={selectedDevice.name || ""}
                readOnly={true}
                onClick={(e) => e.preventDefault()}
              />
              <Popup
                trigger={
                  <button className={styles.controlButton}>
                    <img src={cheveronDownIcon} alt={"dropdown button"} />
                  </button>
                }
                on={"click"}
                ref={setRef}
                position="bottom right"
                closeOnDocumentClick={true}
                arrow={true}
              >
                <div className={styles.controlButtonPopup}>
                  {devices.length > 0 &&
                    devices.map((device, index) => {
                      return (
                        <p
                          className={styles.controlButtonPopupListItem}
                          key={index}
                          onClick={() => {
                            onHandleSetSelectedDevice(device);
                            setSelectedDevice(device);
                            popup.close();
                          }}
                        >
                          {devices[index].name}
                        </p>
                      );
                    })}
                </div>
              </Popup>
            </div>
            <div className={styles.controlBorder} />
          </div>

          <div className={styles.selectDevice__buttonContainer}>
            <Button
              isDisabled={selectedDevice ? false : true}
              isPrimary={true}
              onClick={onHandleProcessing}
              labelName={buttonTitle || t("processButtonLabel")}
            />
          </div>
        </div>
      </FullBackdropWithModal>
    );
  }

  return View;
};

export default SelectDevice;
