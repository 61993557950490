import React, { useContext, useState, useEffect } from "react";
import { ScreenContext } from "../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./PackagingMethodFilter.module.css";
import { useTranslation } from "react-i18next";

const PackagingMethodFilter = ({
  packagingMethodsLookup,
  handlePackagingMethodsLookup,
  selectedSite,
  packagingMethods,
  selectedPackagingMethods,
  onChange,
  siteIsSelected,
}) => {
  const { t } = useTranslation("packagingMethods")

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  // const [packagingMethodsLookup, setPackagingMethodsLookUp] = useState({});

  useEffect(() => {
    let validDevices = selectedSite?.devices.map((device) => {
      return device.type.type;
    });

    let tempPackagingMethodsLookup = {};
    let description;

    if (validDevices?.length) {
      packagingMethods.forEach((method) => {
        description = method.description;
        tempPackagingMethodsLookup[description] = false;

        if (validDevices.indexOf(method.stockLocationType) > -1) {
          tempPackagingMethodsLookup[description] = true;
        }
      });
    } else {
      packagingMethods.forEach((method) => {
        description = method.description;
        tempPackagingMethodsLookup[description] = false;
      });
    }
    handlePackagingMethodsLookup(tempPackagingMethodsLookup);
  }, [selectedSite, packagingMethods]);

  const handleChange = (packagingMethod) => {
    let temp = [...selectedPackagingMethods];
    if (
      selectedPackagingMethods.some((o) => {
        return o.type === packagingMethod.type;
      })
    ) {
      let index = selectedPackagingMethods.findIndex(
        (o) => o.type === packagingMethod.type
      );
      temp.splice(index, 1);
    } else {
      temp.push(packagingMethod);
    }
    onChange(temp);
  };

  let tempPackagingMethods = packagingMethods || [];

  return (
    <div
      className={
        !smScreen || (smScreen && siteIsSelected)
          ? styles.PackagingMethod__row
          : styles.PackagingMethod__noDisplay
      }
    >
      {tempPackagingMethods
        .filter((packagingMethod) => {
          return packagingMethodsLookup[packagingMethod.description];
        })

        .map((packagingMethod) => {
          if (
            packagingMethods &&
            selectedPackagingMethods.some((o) => {
              return o.type === packagingMethod.type;
            })
          ) {
            return (
              <div
                key={packagingMethod.type}
                className={`${styles.PackagingMethod__container} ${styles.PackagingMethod__container_selected}`}
                onClick={() => handleChange(packagingMethod)}
              >
                <h1>{t(packagingMethod.type)}</h1>
              </div>
            );
          }
          return (
            <div
              key={packagingMethod.type}
              className={styles.PackagingMethod__container}
              onClick={() => handleChange(packagingMethod)}
            >
              <h1>{t(packagingMethod.type)}</h1>
            </div>
          );
        })}
    </div>
  );
};

export default PackagingMethodFilter;
