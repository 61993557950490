import React from "react";
import EV54InventoryCard from "./EV54InventoryCard";
import styles from "./EV54InventoryCardList.module.css";
import { useTranslation } from "react-i18next";

const EV54InventoryCardList = ({ headings, data }) => {
  const { t } = useTranslation("truscriptInventoryTable")

  const headingsObj = {
    device: headings[0],
    canister: headings[1],
    product: headings[2],
    min: headings[3],
    max: headings[4],
    currentQty: headings[5],
  };

  if (data.length) {
    return data.map((dataRow, index) => (
      <div key={index} className={styles.EV54InventoryCardList__container}>
        <EV54InventoryCard
          headingsObj={headingsObj}
          dataObj={{
            device: dataRow[0],
            canister: dataRow[1],
            product: dataRow[2],
            min: dataRow[3],
            max: dataRow[4],
            currentQty: dataRow[5],
            actions: dataRow[6],
          }}
        />
        {index < data.length - 1 && (
          <hr className={styles.EV54InventoryCardList__hr} />
        )}
      </div>
    ));
  } else {
    return (
      <div className={styles.EV54InventoryCardList__noDataFoundMessage}>
        <p>{t("noData")}</p>
      </div>
    );
  }
};

export default EV54InventoryCardList;
