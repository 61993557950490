import React from "react";
import styles from "./StockedItemsQty.module.css";
import StockedItemsQtyInput from "./stockedItemsQtyInput/StockedItemsQtyInput";
import { useTranslation } from "react-i18next";

const StockedItemsQty = ({
  enforceInputFocus,
  setEnforceInputFocus,
  mapIndex,
  stockedItemObj,
  stockedItemObjs,
  handleStockedItemObjs,
  setInputFieldQty,
  setActiveStockedItemQty,
}) => {
  const { t } = useTranslation("stock")

  return (
    <div className={styles.numStockedItems}>
      <StockedItemsQtyInput
        enforceInputFocus={enforceInputFocus}
        setEnforceInputFocus={setEnforceInputFocus}
        mapIndex={mapIndex}
        stockedItemObj={stockedItemObj}
        stockedItemObjs={stockedItemObjs}
        handleStockedItemObjs={handleStockedItemObjs}
        setInputFieldQty={setInputFieldQty}
        setActiveStockedItemQty={setActiveStockedItemQty}
      />

      {stockedItemObj.product.packageQuantity ? (
        <span className={styles.numContainers}>
          {stockedItemObj.totalQty || stockedItemObj.totalQty === 0
            ? `(${(
                parseInt(stockedItemObj.totalQty) /
                parseInt(stockedItemObj.product.packageQuantity)
              ).toFixed(1)} ${
                t("itemsLabel", {
                  count: parseInt(stockedItemObj.totalQty) /
                         parseInt(stockedItemObj.product.packageQuantity)
                })
              })`
            : `0 ${t("itemsLabel", {count: 0})}`}
        </span>
      ) : null}
    </div>
  );
};

export default StockedItemsQty;
