import React from 'react';
import backgroundView from "../../assets/Background.jpg";
import closeIcon from "../../assets/x.svg";
import {useNavigate, useLocation} from 'react-router-dom';
import {useTranslation} from "react-i18next";

const CreateUserErrorView = (props) => {
    const location = useLocation();
    const { t, i18n } = useTranslation('createUser');

    const user = location.state.user;
    const accountSecurity = location.state.accountSecurity;
    const info = accountSecurity.type === 'EMAIL' ?
        t('emailErrorInstructions') :
        t('usernameErrorInstructions')

    const containerStyle = {
        backgroundImage: `url(${backgroundView})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'norepeat',
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
        width: 100+'vw',
        height: 100+'vh',
    }

    const formViewStyle = {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto 0',
        width: '586px',
        height: '424px',
        backgroundColor: 'white',
        border: '1px solid white',
        borderRadius: '20px'
    }

    const headerStyle = {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: '20px',
        marginRight: '20px'
    }

    const formBodyStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    }

    const titleStyle = {
        margin: 0,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '22px',
        lineHeight: '27px',
        color: '#121A2D',
        textAlign: 'center'
    }

    const emailStyle = {
        margin: 0,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '15px',
        lineHeight: '30px',
        color: '#089BAB',
        textAlign: 'center'
    }

    const infoStyle = {
        margin: 0,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '13px',
        lineHeight: '22px',
        color: '#121A2D',
        textAlign: 'center',
        maxWidth: '350px'
    }

    const buttonStyle = {
        cursor: 'pointer',
        minHeight: 48+'px',
        width: 213+'px',
        outline: 'none',
        border: 'none',
        borderRadius: 10+'px',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 14+'px',
        lineHeight: 21+'px',
        textAlign: 'center',
        backgroundColor: 'var(--green)',
        color: 'white'
    }

    const navigate = useNavigate();

    const handleContinue = (e) => {
        e.preventDefault();
        navigate(-1);
    }

    return (
      <section style={containerStyle}>
        <section style={formViewStyle}>
          <section style={headerStyle}>
            <img src={closeIcon} alt={"close"} />
          </section>
          <section style={formBodyStyle}>
            <p style={titleStyle}>
              {accountSecurity.type === "EMAIL"
                ? t("emailExistsError")
                : t("usernameExistsError")}
            </p>
            <p style={emailStyle}>
              {accountSecurity.type === "EMAIL"
                ? user.emailAddress
                : user.username}
            </p>
            <p style={infoStyle}>{info}</p>
            <button style={buttonStyle} onClick={handleContinue}>
              {t("buttonLabel")}
            </button>
          </section>
        </section>
      </section>
    );
}

export default CreateUserErrorView;
