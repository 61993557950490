import React, { useState, useEffect, useContext } from "react";
import Button from "../../../components/Button";
import { ReactComponent as TrashIcon } from "../../../assets/trash-icon.svg";
import { ReactComponent as FunctionIcon } from "../../../assets/function-icon.svg";
import ToggleSwitch from "../../../components/ToggleSwitch/ToggleSwitch";
import Spacer from "../../../components/Spacer";
import FunctionModal from "../FunctionModal/FunctionModal";
import { getPreferredFieldName } from "../lookups/getPreferredFieldName";
import { useIsVerticalOverflow } from "../../../customHooks/useIsVerticalOverflow";
import { useMediaQuery } from "react-responsive";
import { ScreenContext } from "../../../contexts/ScreenContext";
import styles from "./Report2Functions.module.css";
import { useTranslation } from "react-i18next";

const Report2Functions = ({
  reportObj,
  handleReportObj,
  handleDeactivateFunctionsPage,
  onSaveReport,
  editMode,
  previewButton,
  ReportViewerForPreview,
  isPreview,
  collectionFieldDictionary,
}) => {
  const { t } = useTranslation("reportFunctions");

  const [showFunctionIcon, setShowFunctionIcon] = useState(false);
  const [showFunctionBuilder, setShowFunctionBuilder] = useState(false);
  const [operands, setOperands] = useState([]);
  const [fieldsIndexesToSwap, setFieldsIndexesToSwap] = useState([]);

  const { mediumScreen } = useContext(ScreenContext);
  const mdScreen = useMediaQuery(mediumScreen);

  const ref = React.useRef();

  const isOverflow = useIsVerticalOverflow(ref, (isOverflowFromCallback) => {
    // console.log(isOverflowFromCallback);
  });

  useEffect(() => {
    if (operands.length === 2) {
      setShowFunctionIcon(true);
    } else {
      setShowFunctionIcon(false);
    }
  }, [operands]);

  const handleSelectedOperand = (field) => {
    let tempOperands = [...operands];
    if (operands.includes(field)) {
      const index = operands.findIndex((object) => {
        return (
          object.name === field.name && object.collection === field.collection
        );
      });
      if (index !== -1) {
        tempOperands.splice(index, 1);
        setOperands(tempOperands);
      }
    } else {
      if (operands.length < 2) {
        tempOperands.push(field);
        setOperands(tempOperands);
      }
    }
  };

  useEffect(() => {
    let tempFunctionLookups = { ...reportObj.functionLookups };
    let tempReportObj = { ...reportObj };

    if (
      reportObj?.functionFields?.length === 1 &&
      Object.keys(reportObj.functionLookups).length === 0
    ) {
      const key = reportObj.functionFields[0].name;
      const value = `(${reportObj.functionFields[0].operands[0].collection}.${reportObj.functionFields[0].operands[0].name} ${reportObj.functionFields[0].operator.description} ${reportObj.functionFields[0].operands[0].collection}.${reportObj.functionFields[0].operands[1].name})`;
      tempFunctionLookups[key] = value;
      tempReportObj.functionLookups = tempFunctionLookups;
      handleReportObj(tempReportObj);
    } else if (
      reportObj.functionFields.length > 1 &&
      Object.keys(reportObj.functionLookups).length ===
        reportObj.functionFields.length - 1
    ) {
      const index = reportObj.functionFields.length - 1;
      const key = reportObj.functionFields[index].name;

      let operand1 = "";
      let operand2 = "";
      if (
        reportObj.functionFields[index].operands[0].collection === "function"
      ) {
        operand1 =
          reportObj.functionLookups[
            reportObj.functionFields[index].operands[0].name
          ];
      } else {
        operand1 = `${reportObj.functionFields[index].operands[0].collection}.${reportObj.functionFields[index].operands[0].name}`;
      }
      if (
        reportObj.functionFields[index].operands[1].collection === "function"
      ) {
        operand2 =
          reportObj.functionLookups[
            reportObj.functionFields[index].operands[1].name
          ];
      } else {
        operand2 = `${reportObj.functionFields[index].operands[1].collection}.${reportObj.functionFields[index].operands[1].name}`;
      }

      const value = `(${operand1} ${reportObj.functionFields[index].operator.description} ${operand2})`;

      tempFunctionLookups[key] = value;

      tempReportObj.functionLookups = tempFunctionLookups;

      handleReportObj(tempReportObj);
    }
  }, [reportObj, handleReportObj]);

  const getFieldTag = (fieldNameIndex, operandIndex) => {
    return reportObj.fields.find(
      (field) =>
        field.name ===
          reportObj.functionFields[fieldNameIndex]?.operands[operandIndex]
            .name &&
        field.collection ===
          reportObj.functionFields[fieldNameIndex]?.operands[operandIndex]
            .collection
    )?.tag;
  };

  const createFunction = (operator, name) => {
    let newField = {
      average: false,
      collection: "function",
      dataType: "Number",
      name: name,
      query: [],
      sum: false,
      operator: operator,
      operands: [
        {
          name: operands[0].name,
          collection: operands[0].collection,
        },
        {
          name: operands[1].name,
          collection: operands[1].collection,
        },
      ],
    };

    let tempReportObj = { ...reportObj };
    tempReportObj.functionFields = [
      ...reportObj.functionFields,
      { ...newField },
    ];
    handleReportObj(tempReportObj);

    setOperands([]);
    setShowFunctionIcon(false);
    setShowFunctionBuilder(false);
  };

  const handleGetIndex = (fieldName) => {
    let index = -1;
    for (let i = 0; i < reportObj.functionFields.length; i++) {
      if (fieldName === reportObj.functionFields[i].name) {
        index = i;
        break;
      }
    }
    return index;
  };

  const checkIfFunctionIsReferenced = (functionName) => {
    let referenced = false;
    for (let i = 0; i < reportObj.functionFields.length; i++) {
      if (
        reportObj.functionFields[i].collection === "function" &&
        functionName !== reportObj.functionFields[i].name
      ) {
        if (
          functionName === reportObj.functionFields[i].operands[0].name ||
          functionName === reportObj.functionFields[i].operands[1].name
        ) {
          referenced = true;
          break;
        }
      }
    }
    return referenced;
  };

  const handleRemoveFunction = (event, fieldNameIndex, functionsFieldIndex) => {
    event.stopPropagation();

    let tempReportObjFunctionFields = [...reportObj.functionFields];
    tempReportObjFunctionFields.splice(fieldNameIndex, 1);

    let tempReportObjFunctionLookups = { ...reportObj.functionLookups };

    delete tempReportObjFunctionLookups[
      reportObj.functionFields[fieldNameIndex].name
    ];

    let tempReportObj = { ...reportObj };
    tempReportObj.functionFields = tempReportObjFunctionFields;
    tempReportObj.functionLookups = tempReportObjFunctionLookups;
    handleReportObj(tempReportObj);
  };

  const handleCloseFunctionBuilder = () => {
    setShowFunctionBuilder(false);
  };

  const handleOnDragStart = (functionsFieldIndex) => {
    let tempArray = [];
    tempArray.push(functionsFieldIndex);
    setFieldsIndexesToSwap(tempArray);
  };

  const handleOnDragEnter = (functionsFieldIndex) => {
    let tempArray = [...fieldsIndexesToSwap];
    tempArray[1] = functionsFieldIndex;
    setFieldsIndexesToSwap(tempArray);
  };

  const handleOnDroppedFunction = () => {
    if (fieldsIndexesToSwap.length === 2) {
      const field1 = reportObj.functionFields[fieldsIndexesToSwap[0]];
      const field2 = reportObj.functionFields[fieldsIndexesToSwap[1]];
      let tempArray = [...reportObj.functionFields];
      tempArray[fieldsIndexesToSwap[0]] = field2;
      tempArray[fieldsIndexesToSwap[1]] = field1;

      let tempReportObj = { ...reportObj };
      tempReportObj.functionFields = tempArray;
      handleReportObj(tempReportObj);
    }
  };

  const handleOnDroppedField = () => {
    if (fieldsIndexesToSwap.length === 2) {
      const field1 = reportObj.fields[fieldsIndexesToSwap[0]];
      const field2 = reportObj.fields[fieldsIndexesToSwap[1]];
      let tempArray = [...reportObj.fields];
      tempArray[fieldsIndexesToSwap[0]] = field2;
      tempArray[fieldsIndexesToSwap[1]] = field1;

      let tempReportObj = { ...reportObj };
      tempReportObj.fields = tempArray;
      handleReportObj(tempReportObj);
    }
  };

  const handleSum = (index) => {
    let tempObj = { ...reportObj };
    if (reportObj.functionFields[index].sum === false) {
      tempObj.functionFields[index].sum = true;
    } else {
      tempObj.functionFields[index].sum = false;
    }
    handleReportObj(tempObj);
  };

  const handleAverage = (index) => {
    let tempObj = { ...reportObj };
    if (reportObj.functionFields[index].average === false) {
      tempObj.functionFields[index].average = true;
    } else {
      tempObj.functionFields[index].average = false;
    }
    handleReportObj(tempObj);
  };

  if (editMode && isPreview) {
    return ReportViewerForPreview;
  }

  return (
    <div className={styles.Report2Functions__pageContainer}>
      <div className={styles.Report2Functions__headerContainer}>
        <h1 className={styles.Report2Functions__header}>{t("title")}</h1>

        {showFunctionIcon ? (
          <div className={styles.Report2Functions__functionIconContainer}>
            <FunctionIcon
              style={{ maxWidth: "40px", cursor: "pointer" }}
              onClick={(e) => {
                setShowFunctionBuilder(true);
              }}
            />
          </div>
        ) : (
          <p className={styles.Report2Functions__selectStatement}>
            {t("selectLabel")}
          </p>
        )}
        {editMode && (
          <div className={styles.Report2Functions__previewButtonContainer}>
            {previewButton}
          </div>
        )}
      </div>

      <div className={styles.Report2Function__scroll} ref={ref}>
        <div className={styles.Report2Functions__functionsContainer}>
          <ul
            className={
              isOverflow
                ? styles.Report2Functions__functionFieldsContainer
                : `${styles.Report2Functions__functionFieldsContainer} ${styles["Report2Functions__functionFieldsContainer--noOverflow"]}`
            }
          >
            <h2 className={styles.Report2Functions__functionFieldsHeading}>
              {t("fieldsLabel")}
            </h2>
            {/* <div
            className={
              showFunctionBuilder
                ? styles.Report2Functions__operationsCard
                : `${styles["Report2Functions__operationsCard--hide"]}`
            }
          >
            {
              <FunctionModal
                reportObj={reportObj}
                operands={operands}
                onDone={createFunction}
                onClose={handleCloseFunctionBuilder}
              />
            }
          </div> */}
            {reportObj.fields.map((field, functionsFieldIndex) => {
              return (
                field.collection !== "function" &&
                field.dataType === "Number" && (
                  <li
                    key={`${field.collection}-${field.name}`}
                    className={
                      operands.includes(field)
                        ? `${styles.Report2Functions__functionFieldsAll} ${styles["Report2Functions__functionFieldsAll--showMouseCursor"]} ${styles["Report2Functions__functionFieldsAll--selected"]}`
                        : operands.length < 2
                        ? `${styles.Report2Functions__functionFieldsAll} ${styles["Report2Functions__functionFieldsAll--showMouseCursor"]}`
                        : styles.Report2Functions__functionFieldsAll
                    }
                    onClick={() => handleSelectedOperand(field)}
                    draggable={true}
                    onDragOver={(e) => {
                      e.preventDefault();
                    }}
                    onDragStart={() => {
                      handleOnDragStart(functionsFieldIndex);
                    }}
                    onDragEnter={() => {
                      handleOnDragEnter(functionsFieldIndex);
                    }}
                    onDrop={handleOnDroppedField}
                  >
                    <div
                      className={styles.Report2Functions__functionsContainer}
                    >
                      <div className={styles.Report2Functions__functionFields}>
                        {field.collection !== "function" &&
                          getPreferredFieldName(
                            field.collection,
                            field.name,
                            true,
                            false
                          )}
                      </div>
                      <div className={styles.Report2Functions__functionTag}>
                        {field.collection !== "function" && field.tag}
                      </div>
                    </div>
                  </li>
                )
              );
            })}
          </ul>

          <ul
            className={
              isOverflow
                ? styles.Report2Functions__functionFieldsContainer
                : `${styles.Report2Functions__functionFieldsContainer} ${styles["Report2Functions__functionFieldsContainer--noOverflow"]}`
            }
          >
            <h2 className={styles.Report2Functions__functionFieldsHeading}>
              {t("functionsLabel")}
            </h2>
            <div
              className={
                showFunctionBuilder
                  ? styles.Report2Functions__operationsCard
                  : `${styles["Report2Functions__operationsCard--hide"]}`
              }
            >
              {
                <FunctionModal
                  reportObj={reportObj}
                  operands={operands}
                  onDone={createFunction}
                  onClose={handleCloseFunctionBuilder}
                />
              }
            </div>
            {reportObj.functionFields.map((field, functionsFieldIndex) => {
              let fieldNameIndex = -1;
              if (field?.collection === "function") {
                fieldNameIndex = handleGetIndex(field.name);
              }

              return (
                field?.collection === "function" && (
                  <li
                    key={`${field.collection}-${field.name}`}
                    className={
                      operands.includes(field)
                        ? `${styles.Report2Functions__functionFieldsAll} ${styles["Report2Functions__functionFieldsAll--showMouseCursor"]} ${styles["Report2Functions__functionFieldsAll--function"]} ${styles["Report2Functions__functionFieldsAll--selected"]}`
                        : operands.length < 2
                        ? `${styles.Report2Functions__functionFieldsAll} ${styles["Report2Functions__functionFieldsAll--function"]} ${styles["Report2Functions__functionFieldsAll--showMouseCursor"]}`
                        : styles.Report2Functions__functionFieldsAll
                    }
                    onClick={() => handleSelectedOperand(field)}
                    draggable={true}
                    onDragOver={(e) => {
                      e.preventDefault();
                    }}
                    onDragStart={() => {
                      handleOnDragStart(functionsFieldIndex);
                    }}
                    onDragEnter={() => {
                      handleOnDragEnter(functionsFieldIndex);
                    }}
                    onDrop={handleOnDroppedFunction}
                  >
                    <div
                      className={styles.Report2Functions__functionFieldsGroup}
                    >
                      <div
                        className={`${styles.Report2Functions__functionFields} ${styles["Report2Functions__functionFields--createdFunctions"]}`}
                      >
                        {field.collection === "function" &&
                          `${field.name} = ${
                            reportObj.functionFields[fieldNameIndex]
                              ?.operands[0].collection !== "function"
                              ? getFieldTag(fieldNameIndex, 0)
                              : reportObj.functionFields[fieldNameIndex]
                                  ?.operands[0].name
                          } ${
                            reportObj.functionFields[fieldNameIndex]?.operator
                              .description
                          } ${
                            reportObj.functionFields[fieldNameIndex]
                              ?.operands[1].collection !== "function"
                              ? getFieldTag(fieldNameIndex, 1)
                              : reportObj.functionFields[fieldNameIndex]
                                  ?.operands[1].name
                          }`}
                      </div>

                      {field.collection === "function" &&
                        !checkIfFunctionIsReferenced(field.name) && (
                          <div
                            className={
                              styles.Report2Functions__trashIconContainer
                            }
                            onClick={(event) =>
                              handleRemoveFunction(
                                event,
                                fieldNameIndex,
                                functionsFieldIndex
                              )
                            }
                          >
                            <TrashIcon />
                          </div>
                        )}
                    </div>

                    <div
                      className={
                        styles.Report2Functions__toggleSwitchesContainer
                      }
                    >
                      <div
                        className={styles.Report2Functions__toggleSwitchGroup}
                      >
                        <p
                          className={styles.Report2Functions__toggleSwitchLabel}
                        >
                          {t("sumLabel")}
                        </p>
                        <ToggleSwitch
                          id={`sum_${field.name}`}
                          onChangeHandler={() => {
                            handleSum(fieldNameIndex);
                          }}
                          checkState={
                            reportObj.functionFields[fieldNameIndex].sum
                          }
                        />
                      </div>
                      <div
                        className={styles.Report2Functions__toggleSwitchGroup}
                      >
                        <p
                          className={styles.Report2Functions__toggleSwitchLabel}
                        >
                          {t("averageLabel")}
                        </p>
                        <ToggleSwitch
                          id={`average_${field.name}`}
                          onChangeHandler={() => {
                            handleAverage(fieldNameIndex);
                          }}
                          checkState={
                            reportObj.functionFields[fieldNameIndex].average
                          }
                        />
                      </div>
                    </div>
                  </li>
                )
              );
            })}
          </ul>
        </div>
      </div>

      <div className={styles.Report2Functions__buttonGroup}>
        <Button
          onClick={handleDeactivateFunctionsPage}
          labelName={t("prevButtonLabel")}
          isPrimary={false}
          isDisabled={false}
          minWidth={null}
        />
        <Spacer space={20} unit={"px"} />
        {reportObj.collections.length > 0 && reportObj.fields.length > 0 && (
          <Button
            onClick={onSaveReport}
            labelName={t("saveCloseButtonLabel")}
            isPrimary={true}
            isDisabled={
              !showFunctionIcon &&
              reportObj.title &&
              reportObj.collections.length > 0 &&
              reportObj.fields.length > 0
                ? false
                : true
            }
            minWidth={mdScreen ? null : 213}
          />
        )}
      </div>
    </div>
  );
};

export default Report2Functions;
