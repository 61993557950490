import React, { useState, useContext } from "react";
import ErrorBannerMobile from "../../../../components/ErrorBannerMobile";
import CardFlexCol from "../../../commonUI/CardFlexCol";
import GetSite from "./getSite/GetSite";
import ScannedProductDisplay from "./scannedProductDisplay/ScannedProductDisplay";
import StockLocations from "./stockLocations/StockLocations";
import StockLocationScanned from "./stockLocationScanned/StockLocationScanned";
import StockedItemsDisplayList from "./stockedItemsDisplay/StockedItemsDisplayList";
import CreateStockedItems from "./CreateStockedItems";
import ProductAssociationMessage from "./stockLocations/ProductAssociationMessage";
import AssociateProductBarcode from "../../../AssociateProductBarcode/AssociateProductBarcode";
import NDCProductList from "../../../AssociateProductBarcode/NDCProductList";
import Confirmation from "../../../AssociateProductBarcode/Confirmation";
import { ScreenContext } from "../../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./StockBody.module.css";

const StockBody = ({
  secondProductScan,
  currentStatusObj,
  handleCurrentStatusObj,
  stockedItemObjs,
  handleStockedItemObjs,
  productRescannedEntryComplete,
  setPage,
  barcodesToBeAssociated,
  setBarcodesToBeAssociated,
  scannedBarcodes,
  setScannedBarcodes,
  activeIcon,
  setActiveIcon,
  site,
  setSite,
  user,
  productScanned,
  setProductScanned,
  productScannedLot,
  setProductScannedLot,
  productScannedExpDate,
  setProductScannedExpDate,
  setPreviousProductScanned,
  stockedItems,
  setStockedItems,
  getStockedItemsComplete,
  setGetStockedItemsComplete,
  setStockedItemsQty,
  setInputFieldQty,
  stockedItemsList,
  stockLocations,
  setStockLocations,
  sortedStockLocations,
  setSortedStockLocations,
  stockLocationScanned,
  setStockLocationScanned,
  productRescanned,
  setProductRescanned,
  productRescannedLot,
  setProductRescannedLot,
  productRescannedExpDate,
  setProductRescannedExpDate,
  qtyError,
  setQtyError,
  setQtyErrorArray,
  setPullStock,
  setPlaceStock,
  setScannedStockedItems,
  scannedStockedItemsQuantities,
  setScannedStockedItemsQuantities,
  setScannedItemIndex,
  setUpdatedScannedStockedItems,
  setNeededMeds,
  setEnforceInputFocus,
  setScannedProducts,
  scannedProducts,
  setProductAssociationFound,
  productAssociationNotFound,
  setProductAssociationNotFound,
  productNotFound,
  setProductNotFound,
  productBarcode,
  setProductBarcode,
  associateBarcodeWithProduct,
  setAssociateBarcodeWithProduct,
  productsSearchText,
  setProductsSearchText,
  selectedProductForAssoc,
  setSelectedProductForAssoc,
  userCredentials,
  setUserCredentials,
  newAssociationComplete,
  setNewAssociationComplete,
  authorizationFailure,
  setAuthorizationFailure,
  updatedStockLocations,
  handleUpdatedStockLocations,
  onSetErrorHandler,
}) => {
  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  return (
    <section className={styles.stockBody}>
      {qtyError && (
        <CardFlexCol className={styles.stockBody__errorMsg}>
          <ErrorBannerMobile
            message={qtyError}
            onClose={() => setQtyError(null)}
          />
        </CardFlexCol>
      )}

      {activeIcon === "Receive" && !site && (
        <GetSite
          setPage={setPage}
          site={site}
          setSite={setSite}
          user={user}
          activeIcon={activeIcon}
          setActiveIcon={setActiveIcon}
        />
      )}

      {activeIcon === "Receive" &&
        productNotFound &&
        !associateBarcodeWithProduct && (
          <AssociateProductBarcode
            scannedBarcodes={scannedBarcodes}
            setScannedBarcodes={setScannedBarcodes}
            barcodesToBeAssociated={barcodesToBeAssociated}
            setBarcodesToBeAssociated={setBarcodesToBeAssociated}
            productBarcode={productBarcode}
            setProductBarcode={setProductBarcode}
            setProductNotFound={setProductNotFound}
            setAssociateBarcodeWithProduct={setAssociateBarcodeWithProduct}
          />
        )}

      {activeIcon === "Receive" &&
        productNotFound &&
        associateBarcodeWithProduct &&
        productsSearchText &&
        !selectedProductForAssoc && (
          <NDCProductList
            user={user}
            productsSearchText={productsSearchText}
            selectedProductForAssoc={selectedProductForAssoc}
            setSelectedProductForAssoc={setSelectedProductForAssoc}
          />
        )}

      {activeIcon === "Receive" && selectedProductForAssoc && (
        <Confirmation
          scannedBarcodes={scannedBarcodes}
          setScannedBarcodes={setScannedBarcodes}
          user={user}
          productBarcode={productBarcode}
          setSelectedProductForAssoc={setSelectedProductForAssoc}
          selectedProductForAssoc={selectedProductForAssoc}
          userCredentials={userCredentials}
          setUserCredentials={setUserCredentials}
          newAssociationComplete={newAssociationComplete}
          setNewAssociationComplete={setNewAssociationComplete}
          setProductNotFound={setProductNotFound}
          setAssociateBarcodeWithProduct={setAssociateBarcodeWithProduct}
          setProductsSearchText={setProductsSearchText}
          setProductScanned={setProductScanned}
          productScannedLot={productScannedLot}
          setProductScannedLot={setProductScannedLot}
          productScannedExpDate={productScannedExpDate}
          setProductScannedExpDate={setProductScannedExpDate}
          setPreviousProductScanned={setPreviousProductScanned}
          authorizationFailure={authorizationFailure}
          setAuthorizationFailure={setAuthorizationFailure}
          stockLocationScanned={stockLocationScanned}
          setProductRescanned={setProductRescanned}
          productRescannedLot={productRescannedLot}
          setProductRescannedLot={setProductRescannedLot}
          productRescannedExpDate={productRescannedExpDate}
          setProductRescannedExpDate={setProductRescannedExpDate}
          setProductAssociationNotFound={setProductAssociationNotFound}
          setScannedProducts={setScannedProducts}
          setScannedStockedItemsQuantities={setScannedStockedItemsQuantities}
        />
      )}

      {activeIcon === "Receive" &&
        productScanned &&
        productScannedLot &&
        productScannedExpDate && (
          <React.Fragment>
            {!stockLocationScanned && !newAssociationComplete && (
              <ScannedProductDisplay productScanned={productScanned} />
            )}

            {!stockLocationScanned &&
              stockLocations.length > 0 &&
              getStockedItemsComplete &&
              !newAssociationComplete && (
                <StockLocations
                  user={user}
                  site={site}
                  productScanned={productScanned}
                  productScannedLot={productScannedLot}
                  setProductScannedLot={setProductScannedLot}
                  productScannedExpDate={productScannedExpDate}
                  setProductScannedExpDate={setProductScannedExpDate}
                  stockedItems={stockedItems}
                  stockedItemsList={stockedItemsList}
                  stockLocations={stockLocations}
                  sortedStockLocations={sortedStockLocations}
                  setSortedStockLocations={setSortedStockLocations}
                  onSetErrorHandler={onSetErrorHandler}
                />
              )}

            {stockLocationScanned &&
              (!productRescanned || !productAssociationNotFound) &&
              !productNotFound && (
                <StockLocationScanned
                  stockLocationScanned={stockLocationScanned}
                  bottomBodyPadding={
                    !smScreen && !productRescanned ? true : false
                  }
                />
              )}

            {false &&
              stockLocationScanned &&
              productRescanned &&
              productAssociationNotFound && (
                <ProductAssociationMessage
                  user={user}
                  site={site}
                  stockLocationScanned={stockLocationScanned}
                  setStockLocationScanned={setStockLocationScanned}
                  productRescanned={productRescanned}
                  setProductRescanned={setProductRescanned}
                  productRescannedLot={productRescannedLot}
                  setProductRescannedLot={setProductRescannedLot}
                  productRescannedExpDate={productRescannedExpDate}
                  setProductAssociationNotFound={setProductAssociationNotFound}
                  setScannedProducts={setScannedProducts}
                  scannedProducts={scannedProducts}
                  scannedStockedItemsQuantities={scannedStockedItemsQuantities}
                  setScannedStockedItemsQuantities={
                    setScannedStockedItemsQuantities
                  }
                />
              )}

            {!stockLocationScanned &&
              productRescanned &&
              productAssociationNotFound &&
              !newAssociationComplete && (
                <ScannedProductDisplay
                  productScanned={
                    productRescanned ? productRescanned : productScanned
                  }
                />
              )}

            {stockedItemObjs.length > 0 &&
              !productNotFound &&
              stockLocationScanned && (
                <React.Fragment>
                  <StockedItemsDisplayList
                    stockedItemObjs={stockedItemObjs}
                    handleStockedItemObjs={handleStockedItemObjs}
                    setInputFieldQty={setInputFieldQty}
                    setEnforceInputFocus={setEnforceInputFocus}
                    productAssociationNotFound={productAssociationNotFound}
                  />

                  {scannedProducts.length > 0 &&
                    (!productRescanned || !productAssociationNotFound) &&
                    !productNotFound && (
                      <CreateStockedItems
                        secondProductScan={secondProductScan}
                        productRescannedEntryComplete={
                          productRescannedEntryComplete
                        }
                        currentStatusObj={currentStatusObj}
                        handleCurrentStatusObj={handleCurrentStatusObj}
                        stockedItemObjs={stockedItemObjs}
                        handleStockedItemObjs={handleStockedItemObjs}
                        barcodesToBeAssociated={barcodesToBeAssociated}
                        setBarcodesToBeAssociated={setBarcodesToBeAssociated}
                        setScannedBarcodes={setScannedBarcodes}
                        setProductScannedLot={setProductScannedLot}
                        setProductScannedExpDate={setProductScannedExpDate}
                        type="submit"
                        site={site}
                        setSite={setSite}
                        user={user}
                        productBarcode={productBarcode}
                        setScannedProducts={setScannedProducts}
                        stockLocationScanned={stockLocationScanned}
                        productAssociationNotFound={productAssociationNotFound}
                        setProductScanned={setProductScanned}
                        setStockedItems={setStockedItems}
                        setGetStockedItemsComplete={setGetStockedItemsComplete}
                        setStockedItemsQty={setStockedItemsQty}
                        setStockLocations={setStockLocations}
                        setInputFieldQty={setInputFieldQty}
                        setSortedStockLocations={setSortedStockLocations}
                        setStockLocationScanned={setStockLocationScanned}
                        setProductRescanned={setProductRescanned}
                        setQtyError={setQtyError}
                        setQtyErrorArray={setQtyErrorArray}
                        setPullStock={setPullStock}
                        setPlaceStock={setPlaceStock}
                        setScannedStockedItems={setScannedStockedItems}
                        setScannedItemIndex={setScannedItemIndex}
                        setScannedStockedItemsQuantities={
                          setScannedStockedItemsQuantities
                        }
                        setUpdatedScannedStockedItems={
                          setUpdatedScannedStockedItems
                        }
                        setNeededMeds={setNeededMeds}
                        setEnforceInputFocus={setEnforceInputFocus}
                        setPreviousProductScanned={setPreviousProductScanned}
                        setProductAssociationFound={setProductAssociationFound}
                        setProductAssociationNotFound={
                          setProductAssociationNotFound
                        }
                        setProductNotFound={setProductNotFound}
                        setProductBarcode={setProductBarcode}
                        setAssociateBarcodeWithProduct={
                          setAssociateBarcodeWithProduct
                        }
                        setProductsSearchText={setProductsSearchText}
                        setSelectedProductForAssoc={setSelectedProductForAssoc}
                        setUserCredentials={setUserCredentials}
                        setNewAssociationComplete={setNewAssociationComplete}
                        setAuthorizationFailure={setAuthorizationFailure}
                        updatedStockLocations={updatedStockLocations}
                        handleUpdatedStockLocations={
                          handleUpdatedStockLocations
                        }
                        onSetErrorHandler={onSetErrorHandler}
                        setProductRescannedLot={setProductRescannedLot}
                        setProductRescannedExpDate={setProductRescannedExpDate}
                      />
                    )}
                </React.Fragment>
              )}
          </React.Fragment>
        )}
    </section>
  );
};

export default StockBody;
