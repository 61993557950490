import React, { useState, useEffect, useRef } from "react";
import EmailValidator from "email-validator";
import Input from "./Input";
import validate from "../../validation/LogInValidationRules";
import AuthenticateController from "../Authenticate/AuthenticateController";
import { useTranslation } from "react-i18next";
import { useKeyPress } from "../../components/InputHooks";
import styles from "./LoginForm.module.css";

const LogInForm = ({
  authorizationFailure,
  setAuthorizationFailure,
  setUserCredentials,
}) => {
  const { t, i18n } = useTranslation("logIn");

  const _controller = useRef(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({ email: "", password: "" });
  const [error, setError] = useState("");

  const enterPressed = useKeyPress("Enter");

  useEffect(() => {
    _controller.current = new AuthenticateController();
  }, []);

  useEffect(() => {
    if (isSubmitting && !(errors.email || errors.password)) {
      const callback = (error, authorizedUser) => {
        if (!error) {
          setUserCredentials("Authorized");
          console.log("authorizedUser", authorizedUser);
        } else {
          setAuthorizationFailure(true);
          console.log("error:", error);
        }
      };
      _controller.current.isAuthorized(email, password, callback);
      setIsSubmitting(false);
    }
  }, [isSubmitting]);

  useEffect(() => {
    if (enterPressed) {
      handleLogIn();
    }
  }, [enterPressed]);

  const handleLogIn = (e) => {
    console.log("login handled");

    if (e) {
      e.preventDefault();
    }

    let validationErrors = validate({
      password: password,
    });

    console.log(validationErrors);

    let emailValidated = EmailValidator.validate(email);

    let emailErrorMsg;

    if (emailValidated) {
      validationErrors.email = "";
    } else {
      validationErrors.email = "invalidEmailFormat";
    }

    if (validationErrors.password || !emailValidated) {
      setIsSubmitting(false);
    } else {
      setIsSubmitting(true);
    }

    setErrors(validationErrors);
  };

  return (
    <div className={styles.formContainer}>
      {/* {error && (
        <>
          <ErrorBanner message={error} onClose={() => setError(null)} />
        </>
      )} */}
      <p className={styles.title}>{t("approvalTitle")}</p>
      <form className={styles.form}>
        <Input
          validationText={t(errors.email, {ns: "validation"})}
          isEnabled={true}
          focus={true}
          labelName=""
          onChange={(value) => {
            setEmail(value);
          }}
          placeholder={t("emailPlaceholder")}
          type="email"
          value={email}
          autoComplete="new-password"
        />
        <Input
          validationText={t(errors.password, {ns: "validation"})}
          isEnabled={true}
          focus={false}
          labelName=""
          onChange={(value) => {
            setPassword(value);
          }}
          placeholder={t("passwordPlaceholder")}
          type="password"
          value={password}
          autoComplete="new-password"
        />
        <button className={styles.logInButton} onClick={handleLogIn}>
          {t("buttonLabel")}
        </button>
      </form>
    </div>
  );
};

export default LogInForm;
