export default function validate(values) {
    let errors = {};

    if(!values.name) {
        errors.name = 'missingSiteName'
    }

    if(!values.type) {
        errors.type = 'missingStockLocationType'
    }

    return errors;
}
